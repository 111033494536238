import { HelpIcon } from '@acua/common/components';
import { betweenValues, composeValidators, mustBeNumber } from '@acua/common/utils';
import styled from '@emotion/styled';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslations } from '@react-utils/intl';
import { Switch } from '@rmwc/switch';
import { TextField } from '@rmwc/textfield';
import { Typography } from '@rmwc/typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';

const Container = styled.div`
  border-radius: 3px;
  border: solid 1px #dadada;
  padding: 6px 16px;
`;

const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Block = styled.div`
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
`;

const validate = composeValidators(mustBeNumber, betweenValues(-999999999999, 999999999999));

function RecalculateField({ disabledRecalculate, disabledPulses }) {
  const t = useTranslations();
  const [helpText, setHelpText] = useState(null);
  const { input } = useField('recalculate', { defaultValue: !disabledRecalculate });
  const {
    input: inputOffset,
    meta: { error, invalid, touched }
  } = useField('offset', { validate });

  useEffect(() => {
    if (error) {
      setHelpText({
        persistent: true,
        validationMsg: true,
        children: <span style={{ color: '#f36060' }}>{t(error)}</span>
      });
    } else {
      setHelpText(null);
    }
  }, [error]);

  return (
    <Container>
      <ContainerLabel>
        <Typography use="button" tag="label" htmlFor="recalculate">
          {t('INSERT_READING_FIELD_RECALCULATE')}{' '}
          <HelpIcon tooltip={t('INSERT_READING_FIELD_RECALCULATE_HELP')} />
        </Typography>
        <Switch
          {...input}
          id="modals_recalculate_switch"
          onChange={(e) => {
            input.onChange(e);
            inputOffset.onChange('');
          }}
          checked={input.value}
          disabled={disabledRecalculate}
        />
      </ContainerLabel>

      {!disabledRecalculate && !disabledPulses && input.value && (
        <Block>
          <TextField
            // We need to propagate onBlur and onFocus for updating touched
            {...inputOffset}
            label={`${t('PULSES')}`}
            trailingIcon={
              inputOffset.value ? (
                <ClearIcon onClick={() => inputOffset.onChange('')} />
              ) : (
                <ModeEditIcon />
              )
            }
            id="modals_pulses_input"
            type="text"
            pattern="^-?\d*"
            maxLength="13"
            invalid={invalid && touched}
            helpText={{
              ...(helpText || {
                persistent: true,
                children: t('INSERT_READING_PULSES_SUBTITLE')
              })
            }}
          />
        </Block>
      )}
    </Container>
  );
}

RecalculateField.propTypes = {
  disabledRecalculate: PropTypes.bool,
  disabledPulses: PropTypes.bool
};

RecalculateField.defaultProps = {
  disabledRecalculate: false,
  disabledPulses: true
};

export default RecalculateField;
