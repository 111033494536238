import { lazy } from 'react';

export const Admin = lazy(() => import('@acua/admin'));
export const Alarms = lazy(() => import('@acua/alarms'));
export const Analytics = lazy(() => import('@acua/analytics'));
export const Consumptions = lazy(() => import('@acua/consumptions'));
export const Configurations = lazy(() => import('@acua/configurations'));
export const Dashboard = lazy(() => import('@acua/dashboard'));
export const FileManager = lazy(() => import('@acua/file-manager'));
export const Health = lazy(() => import('@acua/health'));
export const Devices = lazy(() => import('@acua/inventory/Devices'));
export const Transmitters = lazy(() => import('@acua/inventory/Transmitters'));
export const Metrics = lazy(() => import('@acua/metrics'));
export const Sidebar = lazy(() => import('@acua/sidebar'));
