import { startsWith } from 'ramda';
import { SAVE_FILE, DELETE_FILE } from '../actions';

const defaultState = {
  loading: false,
  loaded: false,
  error: null,
  file: []
};

const safeRegex = (reg) => (str) => (reg.exec(str) || []).slice(1);
const ACTION_STATUS_REGEX = /\w+_(PENDING|REJECTED|FULFILLED)/;
const getStatus = (type) => safeRegex(ACTION_STATUS_REGEX)(type)[0];

export default function (state, { type, payload }) {
  if (typeof state === 'undefined') return defaultState;

  if (startsWith('IMPORT_', type)) {
    switch (getStatus(type)) {
      case 'PENDING':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'REJECTED':
        return {
          ...state,
          loading: false,
          error: payload
        };
      case 'FULFILLED':
        return {
          ...state,
          loaded: true,
          loading: false,
          ...payload
        };
      default:
        break;
    }
  }
  if (type === SAVE_FILE) {
    return {
      ...state,
      file: payload
    };
  }
  if (type === DELETE_FILE) {
    return {
      ...state,
      file: []
    };
  }
  return state;
}
