import styled from '@emotion/styled';
import { Typography } from '@rmwc/typography';

export const Container = styled.span`
  display: flex;
`;

export const First = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 75%;
`;

export const Counter = styled(Typography)`
  color: ${({ theme }) => theme.primary};
  margin-left: 3px;
`;
