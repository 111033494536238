// * Try to mantain uniqueness of values (at least for the same page)

export const QS_KEY_APP_MAIN_DRAWER = 'drawer';
export const QS_KEY_APP_MAIN_DRAWER_IMPORT = 'import';
export const QS_KEY_APP_NESTED_DRAWER = 'nestedDrawer';
export const QS_KEY_APP_NESTED_DRAWER_AUDIT = 'nestedDrawerAudit';
export const QS_KEY_APP_ID = 'app-id';
export const QS_KEY_SEARCH = 's';
export const QS_KEY_VALUES = 'val';
export const QS_KEY_SELECTED = 'sel';
export const QS_KEY_ENTITIES = 'ent';
export const QS_KEY_INPUT = 'input';
export const QS_KEY_MAIN_DATES = 'mainDates';

export const QS_KEY_ALARMS_HELP = 'legend';
export const QS_KEY_MARKER_SIDEBAR = 'marker';
export const QS_KEY_ALARMS_STATUS = 'st';
export const QS_KEY_ALARMS_ALARM_TYPES = 'a_type';
export const QS_KEY_ALARMS_SEARCH_MANAGER = 'asm';
export const QS_KEY_ALARMS_SEARCH_CUSTOMER = 'asc';
export const QS_KEY_ALARMS_CRITICITY = 'cr';
export const QS_KEY_ALARMS_COMMENT = 'com';
export const QS_KEY_ALARMS_JUSTIFIED = 'jus';
export const QS_KEY_ALARMS_CONFIGURATION_TYPE = 'ct';
export const QS_KEY_ALARMS_CUSTOMER_NOTIFIED = 'cn';
export const QS_KEY_ALARMS_ACTIVATION_DATE = 'ad';
export const QS_KEY_ALARMS_ROLE = 'role';

export const QS_KEY_ANALYTICS_PRODUCT_CONNECTION_STATUS = 'conn_st';
export const QS_KEY_ANALYTICS_PRODUCT_WITH_ALARMS = 'alarms';
export const QS_KEY_ANALYTICS_PRODUCT_ALARM_TYPES = 'alarmType';
export const QS_KEY_ANALYTICS_PRODUCT_WITH_CRITICAL_METERS = 'cm';
export const QS_KEY_ANALYTICS_PRODUCT_LAST_CONNECTION_DATE = 'conn_d';
export const QS_KEY_ANALYTICS_PRODUCT_LAST_READING_DATE = 'read_d';
export const QS_KEY_ANALYTICS_PRODUCT_SOFTWARE = 'sw';
export const QS_KEY_ANALYTICS_PRODUCT_FIRMWARE = 'fw';

export const QS_KEY_CONSUMS_PERIOD = 'period';

export const QS_KEY_INVENTORY_IMPORT_LOADS_VIEW_MODE = 'vm';
export const QS_KEY_INVENTORY_IMPORT_EXTERNAL_VIEW_MODE = 'vme';
export const QS_KEY_INVENTORY_PRODUCT_CREATION_DATE = 'cr_d';
export const QS_KEY_INVENTORY_PRODUCT_DOWN_DATE = 'down_d';
export const QS_KEY_INVENTORY_PRODUCT_INSTALLATION_DATE = 'ins_d';
export const QS_KEY_INVENTORY_PRODUCT_METER_TYPE = 'm_type';
export const QS_KEY_INVENTORY_PRODUCT_SEARCH_METERS = 'm_s';
export const QS_KEY_INVENTORY_PRODUCT_TENANT_IDS = 'tenant';
export const QS_KEY_INVENTORY_PRODUCT_SITE_IDS = 'site';
export const QS_KEY_INVENTORY_PRODUCT_SECTOR_IDS = 'sector';
export const QS_KEY_INVENTORY_PRODUCT_SUBSECTOR_IDS = 'subsector';
export const QS_KEY_INVENTORY_PRODUCT_ZONE_MANAGEMENT_IDS = 'dz';
export const QS_KEY_INVENTORY_PRODUCT_CONCESSION_MANAGEMENT_IDS = 'dc';
export const QS_KEY_INVENTORY_PRODUCT_AREA_MANAGER_IDS = 'am';
export const QS_KEY_INVENTORY_PRODUCT_STATUS = 'st';
export const QS_KEY_INVENTORY_PRODUCT_SUBSTATUS = 'subst';
export const QS_KEY_INVENTORY_PRODUCT_GGCC = 'ggcc';
export const QS_KEY_INVENTORY_PRODUCT_CONTROL = 'control';
export const QS_KEY_INVENTORY_PRODUCT_BILLABLE = 'billable';
export const QS_KEY_INVENTORY_PRODUCT_NETWORK = 'network';
export const QS_KEY_INVENTORY_PRODUCT_STATUSIOT = 'stiot';
export const QS_KEY_INVENTORY_PRODUCT_CRITICAL = 'crit';
export const QS_KEY_ANALYTICS_PRODUCT_REDUNDANCY = 'redundancy';
export const QS_KEY_INVENTORY_PRODUCT_TAG = 'tag';
export const QS_KEY_INVENTORY_PRODUCT_STATUSLNS = 'lns_st';
export const QS_KEY_INVENTORY_PRODUCT_MODEL = 'model';
export const QS_KEY_INVENTORY_PRODUCT_MANUFACTURER = 'manufacturer';
export const QS_KEY_INVENTORY_PRODUCT_CALIBER = 'caliber';
export const QS_KEY_INVENTORY_PRODUCT_COVERAGE = 'coverage';

export const QS_KEY_PRODUCT_EQUIPMENT_TYPE = 'eq_type';
export const QS_KEY_PRODUCT_SEARCH_EQUIPMENTS = 'eq_s';
export const QS_KEY_PRODUCT_SEARCH_TRANSMITTERS = 't_s';
export const QS_KEY_PRODUCT_TRANSMITTER_TYPE = 't_type';
export const QS_KEY_DISABLED_INDIVIDUAL_SUBSCRIPTION_NOTIFICATION = 'ind_notif';
export const QS_KEY_EXTERNAL_IMPORTS_VISIBLE = 'ext';
