import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import {
  LegendContainerClosed,
  TitlePopUp,
  StyledLegendIcon,
  LayerContent,
  Dot,
  Square
} from './elements';

function Legend({ legend }) {
  const t = useTranslations();
  const [legendOpen, setLegendOpen] = useState(false);
  return (
    <LegendContainerClosed className="map-legend">
      <TitlePopUp onClick={() => setLegendOpen(!legendOpen)}>
        <StyledLegendIcon />
        {t('MAP_TITLE_LEGEND')}
      </TitlePopUp>
      {legendOpen && (
        <LayerContent>
          {legend.map((option) => (
            <div key={option.label}>
              {option.type === 'square' && (
                <Square style={{ backgroundColor: `rgb(${option.color.join(',')}, 1)` }} />
              )}
              {option.type !== 'square' && (
                <Dot style={{ backgroundColor: `rgb(${option.color.join(',')}, 1)` }} />
              )}
              <span> {t(option.label)}</span>
            </div>
          ))}
        </LayerContent>
      )}
    </LegendContainerClosed>
  );
}

Legend.propTypes = {
  legend: PropTypes.arrayOf(
    PropTypes.shapeOf({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string
    })
  )
};

Legend.defaultProps = {
  legend: []
};

export default Legend;
