import { includes, gt, gte, lt, lte, isEmpty } from 'ramda';

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const required = (value) =>
  value === null || value === undefined || isEmpty(value)
    ? 'FORM_VALIDATION_ERROR_REQUIRED'
    : undefined;

const isNumeric = (str) => {
  if (typeof str === 'string') {
    let code;

    for (let index = 0, len = str.length; index < len; index += 1) {
      code = str.charCodeAt(index);
      if (!((code > 47 && code < 58) || (code === 45 && index === 0 && str.charCodeAt(1) !== 48))) {
        return false;
      }
    }
  }
  return true;
};

export const mustBeNumber = (value) =>
  value !== null && value !== undefined && (!isNumeric(value) || Number.isNaN(parseInt(value, 10)))
    ? 'FORM_VALIDATION_ERROR_MUST_BE_NUMBER'
    : undefined;

export const mustBeFloat = (value) =>
  value !== null && value !== undefined && Number.isNaN(value)
    ? 'FORM_VALIDATION_ERROR_MUST_BE_NUMBER'
    : undefined;

export const greaterOrEqual = (min) => (value) =>
  value !== null && value !== undefined && value < min
    ? 'FORM_VALIDATION_ERROR_OUT_OF_RANGE'
    : undefined;

// export const maxValue = max => value =>
//   Number.isNaN(value) || value <= max ? undefined : `Should be greater than ${max}`;

export const betweenValues =
  (min, max, includingValues = true) =>
  (value) => {
    if (value == null || (isNumeric(min) && !isNumeric(value))) return undefined;
    if (includingValues) {
      if (gte(value, min) && lte(value, max)) return undefined;
    } else if (gt(value, min) && lt(value, max)) return undefined;
    return 'FORM_VALIDATION_ERROR_OUT_OF_RANGE';
  };

const defaultParser = (value) => value;

export const includedIn =
  (values, parser = defaultParser) =>
  (value) =>
    includes(parser(value), values.map(parser))
      ? undefined
      : 'FORM_VALIDATION_ERROR_NOT_BETWEEN_THE_OPTIONS';

const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const isValidEmail = (value) =>
  EMAIL_REGEX.test(String(value).toLowerCase()) ? undefined : 'FORM_VALIDATION_ERROR_INVALID_EMAIL';
