import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { numberFormat } from '@acua/common/utils';

const Uncalculated = memo(({ value }) => {
  const t = useTranslations();
  const spanContent = value ? numberFormat(value) : t('ALARM_ANALYTICS_TABLE__UNCALCULATED');
  return <span title={spanContent}>{spanContent}</span>;
});

Uncalculated.propTypes = {
  value: PropTypes.number.isRequired
};

export default Uncalculated;
