import React from 'react';
import styled from '@emotion/styled';
import DateRangePickerWrapper from './DateRangePickerWrapper';

const StyledDateRangePicker = styled(DateRangePickerWrapper)`
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  background-color: #f5f5f5;
  border-radius: 4px 4px 0 0;
  padding: 5px 0;
`;

function DateRangePickerMaterial(props) {
  return <StyledDateRangePicker {...props} />;
}

export default DateRangePickerMaterial;
