import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { path } from 'ramda';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext, useAppContext } from '@acua/common/hooks';
import { ProductsService } from '@acua/common/services';
import CustomSimpleDialog from '../components/CustomSimpleDialog';
import Status from '../components/Status';

const CommentField = styled(TextField)`
  border-color: rgba(112, 114, 115, 0.73) !important;
  textarea {
    resize: none !important;
    border: solid 0.5px #d0d2d3;
  }
`;

const extractComments = path(['inventory', 'comments']);

function AddCommentToProduct({ data: { row }, ...props }) {
  const t = useTranslations();
  const [comments, setComments] = useState(extractComments(row) || '');
  const [updateCommentsStatus, setUpdateCommentsStatus] = useState({});
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  return (
    <CustomSimpleDialog
      {...props}
      onAccept={() =>
        ProductsService.updateComments(row.productId, row.tenantId, comments.trim())
          .then(() => {
            setTimeout(() => {
              addSnackbar('SNACKBAR_COMMENT_SAVE_SUCCESS');
              refreshSearchKey();
            }, 3000);
          })
          .catch((error) => {
            setUpdateCommentsStatus({ error });
            throw error;
          })
      }
      title={t('PRODUCT_ACTION_ADD_COMENT')}
      body={
        <>
          <Status data={updateCommentsStatus} />
          <CommentField
            id="modals_comment_field"
            name="textareaRevisionModal"
            textarea
            fullwidth
            outlined
            rows="4"
            value={comments}
            maxLength="1000"
            characterCount
            onChange={(event) => setComments(event.target.value)}
          />
        </>
      }
    />
  );
}

AddCommentToProduct.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.object
  }).isRequired
};

export default AddCommentToProduct;
