import { not, isEmpty } from 'ramda';
import { RequestError } from '@acua/common/utils';

const extractNetworkErrors = ({ message, networkError }) => ({
  title: message,
  details:
    !!networkError.result &&
    not(isEmpty(networkError.result)) &&
    networkError.result.errors &&
    networkError.result.errors.length
      ? networkError.result.errors.map((e) => e.message)
      : []
});

const getCodeLiteral = (code) => {
  switch (code) {
    case 'NotAllowedAudError':
      return 'ERROR_MSG_FORBIDDEN';
    case 'NOT_FOUND_ERROR':
      return 'ERROR_MSG_NOT_FOUND';
    case 'ValidationError':
      return 'ERROR_MSG_VALIDATION';
    case 'NOT_AUTHORIZED':
      return 'ERROR_MSG_UNAUTHORIZED';
    case 'NoAccessTokenFoundError':
    case 'TokenDecodeError':
    case 'TokenExpiredError':
    case 'INTERNAL_SERVER_ERROR':
    case 'UnknownError':
    default:
      return 'ERROR_MSG_UNKNOWN';
  }
};

const extractGraphqlErrors = ({ graphQLErrors }) => {
  const codes = graphQLErrors.map(({ extensions }) => extensions.code).sort();
  const codeLiterals = Array.from(new Set(codes)).map(getCodeLiteral);
  return {
    title: codeLiterals.length === 1 ? codeLiterals[0] : 'DEFAULT_TITLE_ERROR_LIST',
    details: codeLiterals.length > 1 ? codeLiterals.map((literal) => literal) : []
  };
};

export default (error) => {
  if (!error) return {};
  if (typeof error === 'string') {
    return { title: error };
  }
  if (error instanceof RequestError) {
    const title = error.literal ? error.literal.message : 'ERROR_MSG_UNKNOWN';
    return { title };
  }
  if (error.networkError && not(isEmpty(error.networkError))) {
    return extractNetworkErrors(error);
  }
  if (error.graphQLErrors && not(isEmpty(error.graphQLErrors))) {
    return extractGraphqlErrors(error);
  }
  return { title: error.message };
};
