import React from 'react';
import { Global, css } from '@emotion/react';
import themeVars from '../../variables';

/* TODO AML delete when nautilus/components deleted */
function Nautilus() {
  return (
    <Global
      styles={css`
        .mdc-tab-bar {
          width: auto;
        }
        .nt-header {
          /** Bugfix:
        There is a conflict between shadows this header and the second header of analytics.
        Using z-index directly over both sibling headers resolves the bug of shadows, but
        It creates a new bug: The popovers of NTHeader are overlapped by the second header.
         */
          box-shadow: none;
          /*
        z-index: auto;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px 0 rgba(0, 0, 0, 0.14);
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
        } */
        }

        .MuiPopperUnstyled-root {
          z-index: 7 !important;
        }

        .MuiAutocomplete-endAdornment {
          display: none;
        }

        .nt-permanent-drawer__header-title {
          line-height: initial;
        }

        .nt-data-list__menu .mdc-menu-surface {
          transform-origin: right top 0px !important;
          right: 100% !important;
          left: auto !important;
        }

        .nt-data-list__column--sortable {
          /* TODO: min-content is not the solution (it breaks the line always) */
          /* > span {
          width: min-content;
        } */
          > i {
            margin-left: 4px;
          }
        }

        div.nt-pagination__select {
          .mdc-select__selected-text-container {
            display: none;
          }
        }

        select.nt-pagination__select {
          -webkit-appearance: none;
          -moz-appearance: none;
          margin: 0;
          border-left: none;
          border-right: none;
          border-top: none;
          &::-ms-expand {
            display: none;
          }
          &:focus-visible {
            outline: none;
          }
        }

        .nt-file-uploader {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 12.5rem;
          border: 2px dashed rgba(0, 0, 0, 0.54);
          cursor: pointer;

          &--active {
            background-color: #e3f7e3;
          }

          &--accepted {
            background-color: ligthGreen;
          }

          &__icon {
            font-size: 3rem;
            color: ${themeVars.darkButonBg};
          }
        }

        .nt-chip-multiselect {
          .mdc-chip {
            max-width: 100%;
            &__text {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          &__content {
            min-height: 44px;
            max-height: 165px;
            padding: 2px;
            box-shadow: none;
            &__input {
              flex-grow: 1;
              &::placeholder {
                font-style: italic;
                font-size: 0.9em;
              }
            }
          }
        }
        .nt-async-list__load-more-button-cell {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
        .nt-async-list__load-more-button {
          min-width: 150px;
        }
        .nt-autocomplete {
          position: relative;
        }
        .nt-autocomplete__options {
          position: absolute;
          z-index: 3;
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        .nt-autocomplete__input {
          width: 100%;
        }
        .nt-autocomplete__loading {
          position: absolute;
          z-index: 4;
          bottom: 0;
          height: 2px;
        }
        .nt-autocomplete-card.hidden {
          display: none;
        }
        .nt-chip-multiselect {
          position: relative;
          width: 100%;
        }
        .nt-chip-multiselect--disabled {
          color: rgba(0, 0, 0, 0.38) !important;
          pointer-events: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .nt-chip-multiselect--disabled .nt-chip-multiselect__content {
          border-color: rgba(0, 0, 0, 0.38) !important;
        }
        .nt-chip-multiselect--disabled .nt-chip-multiselect__content__chip {
          opacity: 0.7;
        }
        .nt-chip-multiselect .mdc-text-field--textarea {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          width: auto;
          height: auto;
          border: 1px solid;
          border-radius: 4px;
          -webkit-transition: none;
          transition: none;
        }
        .nt-chip-multiselect__content {
          position: relative;
          overflow: visible;
          max-height: 8.375rem;
          cursor: text;
          min-height: 54px;
          overflow-y: auto;
          padding-top: 0.5rem;
        }
        .nt-chip-multiselect__content--focused {
          border-color: #415d9a;
          -webkit-box-shadow: 0 0 0 1px #415d9a;
          box-shadow: 0 0 0 1px #415d9a;
        }
        .nt-chip-multiselect__content--focused.mdc-text-field--textarea:not(
            .mdc-text-field--disabled
          ) {
          border-color: #415d9a;
        }
        .nt-chip-multiselect__content__chip i {
          font-size: 18px;
        }
        .nt-chip-multiselect__content__input-label {
          position: absolute;
          top: 0.5rem;
          left: 10px;
          padding: 0 6px;
          font-size: 1rem;
          -webkit-transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
          transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-color: #fff;
        }
        .nt-chip-multiselect__content__input-label--focused {
          font-size: 0.75rem;
          -webkit-transform: translateY(-1rem);
          transform: translateY(-1rem);
        }
        .nt-chip-multiselect__content__input {
          margin: 0 0.5rem;
          border: 0 !important;
          font-size: 1rem;
          font-weight: 400;
          letter-spacing: 0.00937em;
          line-height: 1.75rem;
          max-width: calc(100% - 1rem);
          outline: none;
        }
        .nt-chip-multiselect__content__helper-text {
          margin: 8px 0;
          padding-left: 1rem;
        }
        .nt-chip-multiselect__loading {
          position: absolute;
          z-index: 4;
          height: 2px;
          margin-bottom: 0.2rem;
        }
        .nt-chip-multiselect__autocomplete {
          position: absolute;
          z-index: 3;
          width: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          max-height: 12rem;
          overflow-y: scroll;
        }
        .nt-collapsible {
          overflow: hidden;
        }
        .nt-collapsible__header {
          z-index: 1;
          cursor: default;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .nt-collapsible__header--border {
          border-bottom: 1px solid hsla(0, 0%, 62.7%, 0.2);
        }
        .nt-collapsible__content {
          position: relative;
          z-index: 2;
          overflow: hidden;
        }
        .nt-collapsible__content--open.nt-collapsible__content--border {
          border-bottom: 1px solid hsla(0, 0%, 62.7%, 0.2);
        }
        .nt-collapsible__inner--padding {
          padding: 2rem;
        }
        .nt-collapsible__icon {
          font-size: 1.6rem;
          text-indent: 0;
          -webkit-transition: -webkit-transform 0.2s ease-in;
          transition: -webkit-transform 0.2s ease-in;
          transition: transform 0.2s ease-in;
          transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
        }
        .nt-collapsible__icon--open {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        .nt-data-list {
          padding: 0;
        }
        .nt-data-list__scrollable-side {
          overflow-x: auto;
        }
        .nt-data-list__scrollable-side > * {
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .nt-data-list__sticky-side {
          float: right;
          width: 48px;
        }
        .nt-data-list__header {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
        }
        .nt-data-list__header > span {
          line-height: 1.2;
          white-space: normal;
        }
        .nt-data-list__divider {
          border-color: rgba(0, 0, 0, 0.2);
        }
        .nt-data-list__header,
        .nt-data-list__row {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          height: 48px;
          padding: 0 16px;
          white-space: nowrap;
        }
        .nt-data-list__header--active,
        .nt-data-list__row--active {
          background-color: rgba(0, 0, 0, 0.03);
        }
        .nt-data-list__header:hover,
        .nt-data-list__row:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
        .nt-data-list__menu {
          display: none;
          visibility: hidden;
        }
        .nt-data-list__menu--visible {
          visibility: visible;
        }
        .nt-data-list--with-menu > .nt-data-list__scrollable-side {
          margin-right: 48px;
        }
        .nt-data-list--with-menu.nt-data-list--dense > .nt-data-list__scrollable-side {
          margin-right: 40px;
        }
        .nt-data-list--with-menu .nt-data-list__menu {
          display: initial;
        }
        .nt-data-list--dense > .nt-data-list__sticky-side {
          width: 40px;
        }
        .nt-data-list--dense .nt-data-list__menu-icon-button {
          width: 40px;
          height: 40px;
          padding: 8px;
        }
        .nt-data-list--dense .nt-data-list__header,
        .nt-data-list--dense .nt-data-list__row {
          height: 40px;
          padding: 0 12px;
        }
        .nt-data-list__cell,
        .nt-data-list__column {
          -ms-flex-preferred-size: 0%;
          flex-basis: 0%;
          -webkit-box-flex: 1;
          -ms-flex-positive: 1;
          flex-grow: 1;
          -ms-flex-negative: 1;
          flex-shrink: 1;
        }
        .nt-data-list__column {
          color: rgba(0, 0, 0, 0.54);
        }
        .nt-data-list__column--sortable {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          cursor: pointer;
        }
        .nt-data-list__column--sorted,
        .nt-data-list__column:hover {
          color: rgba(0, 0, 0, 0.87);
        }
        .nt-data-list__column--sorted i,
        .nt-data-list__column:hover i {
          font-size: 20px;
          color: inherit;
        }
        .nt-data-list__cell,
        .nt-data-list__column {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .nt-data-list__cell:not(:last-child),
        .nt-data-list__column:not(:last-child) {
          padding-right: 10px;
        }
        .nt-data-list__lateral-bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
        }
        .nt-data-table {
          width: 100%;
          padding: 0;
          border: 0;
        }
        .nt-data-table__content {
          min-width: 100%;
          background-color: transparent;
        }
        .nt-data-table__column--sortable > span {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          cursor: pointer;
        }
        .nt-data-table__column--sorted,
        .nt-data-table__column:hover {
          color: rgba(0, 0, 0, 0.87);
        }
        .nt-data-table__column--sorted i,
        .nt-data-table__column:hover i {
          font-size: 20px;
          color: inherit;
        }
        .nt-data-table__cell {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          background-color: transparent;
        }
        .nt-data-table__cell:not(:last-child) {
          padding-right: 0.5em;
        }
        .nt-data-table__row--active {
          background-color: rgba(0, 0, 0, 0.03);
        }
        .nt-data-table__row:hover {
          background-color: rgba(0, 0, 0, 0.06);
        }
        .nt-data-table__body--with-expandible-rows tr:nth-child(odd):not(:first-child) > td {
          border-top-width: 0;
        }
        .nt-data-table__body--with-expandible-rows tr:nth-child(odd):not(:nth-last-child(2)) > td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
        .nt-data-table__body--with-expandible-rows tr:nth-last-child(2) > td {
          border-bottom-width: 0;
        }
        .nt-data-table__body--with-expandible-rows:not(:last-child) tr:nth-last-child(2) > td {
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
        .nt-data-table__body--with-expandible-rows:last-child tr:nth-last-child(2) > td {
          border-bottom-width: 0;
        }
        .nt-data-table__body--with-expandible-rows tr:nth-child(2n) {
          padding: 0;
          border: 0;
        }
        .nt-data-table__body--with-expandible-rows tr:nth-child(2n) > td {
          padding: 0;
          border: 0;
          min-width: 100%;
        }
        .nt-data-table__footer > td {
          border-top: 1px solid rgba(0, 0, 0, 0.06);
        }
        .nt-data-table__lateral-bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
        }
        .nt-fab__main-button__label,
        .nt-fab__suboption-button__label {
          overflow: hidden;
          margin: 0 0.5rem;
          opacity: 0;
          text-overflow: ellipsis;
          -webkit-transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
          white-space: nowrap;
        }
        .nt-fab {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .nt-fab__main-button {
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          width: auto;
          height: 3.5rem;
          border-radius: 1.75rem;
          -webkit-transition: max-width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          transition: max-width 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          max-width: 0;
          min-height: 3.5rem;
          min-width: 3.5rem;
        }
        .nt-fab__main-button:hover {
          max-width: 15rem;
        }
        .nt-fab__main-button:hover .nt-fab__main-button__label {
          opacity: 1;
          -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
          -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
        }
        .nt-fab__main-button--open {
          max-width: 15rem;
        }
        .nt-fab__main-button--open .nt-fab__main-button__label {
          opacity: 1;
        }
        .nt-fab__suboption-button {
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          width: auto;
          height: 2.5rem;
          padding: 0.1rem 0.4rem 0;
          border-radius: 1.25rem;
          opacity: 0;
          -webkit-transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
          margin-top: 1rem;
          max-width: 0;
          min-height: 2.5rem;
          min-width: 2.5rem;
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
        .nt-fab__suboption-button--open {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        .nt-fab__suboption-button:hover {
          max-width: 15rem;
        }
        .nt-fab__suboption-button:hover .nt-fab__suboption-button__label {
          opacity: 1;
        }
        .nt-fab-group {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
        }
        .nt-fab-group__selected {
          width: auto;
          min-width: 48px;
          max-height: 48px;
        }
        .nt-fab-group__option {
          margin-top: 1rem;
          width: auto;
          min-width: 40px;
          max-height: 40px;
        }
        .nt-filter__outer-card {
          position: relative;
          padding: 1rem;
        }
        .nt-filter__header-row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
        .nt-filter__expand-icon {
          position: absolute;
          top: 1rem;
          right: 1rem;
          display: inline-block;
          color: rgba(0, 0, 0, 0.54);
          cursor: pointer;
          -webkit-transition: -webkit-transform 0.2s ease-in-out;
          transition: -webkit-transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
        }
        .nt-filter__expand-icon--expanded {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
        .nt-filter__expand-icon:hover {
          color: rgba(0, 0, 0, 0.87);
        }
        .nt-filter__search-text-field {
          margin-bottom: 0;
          margin-top: 0;
        }
        .nt-filter .mdc-text-field--with-trailing-icon .mdc-text-field__input {
          padding-left: 0.9375rem;
        }
        .nt-filter__action-button-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          visibility: hidden;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          height: 0;
          opacity: 0;
          -webkit-transition: height 0.2s cubic-bezier(0, 0, 0.2, 1),
            margin-top 0.2s cubic-bezier(0, 0, 0.2, 1), opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
            visibility 0ms linear 0.22s;
          transition: height 0.2s cubic-bezier(0, 0, 0.2, 1),
            margin-top 0.2s cubic-bezier(0, 0, 0.2, 1), opacity 0.2s cubic-bezier(0, 0, 0.2, 1),
            visibility 0ms linear 0.22s;
        }
        .nt-filter__action-button-container--show {
          visibility: visible;
          height: 2.25rem;
          opacity: 1;
          -webkit-transition: height 0.2s cubic-bezier(0, 0, 0.2, 1),
            margin-top 0.2s cubic-bezier(0, 0, 0.2, 1), opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
          transition: height 0.2s cubic-bezier(0, 0, 0.2, 1),
            margin-top 0.2s cubic-bezier(0, 0, 0.2, 1), opacity 0.2s cubic-bezier(0, 0, 0.2, 1);
          margin-top: 1rem;
        }
        .nt-filter__reset-button {
          margin-left: 1rem;
          -webkit-transition: left 0.2s cubic-bezier(0.4, 0, 1, 1);
          transition: left 0.2s cubic-bezier(0.4, 0, 1, 1);
        }
        .nt-filter__reset-button--shift {
          -webkit-transition: left 0.2s cubic-bezier(0, 0, 0.2, 1);
          transition: left 0.2s cubic-bezier(0, 0, 0.2, 1);
        }
        .nt-filter__apply-button {
          position: relative;
          visibility: visible;
          opacity: 1;
          -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
          transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
          margin-left: 1rem;
        }
        .nt-filter__apply-button--hidden {
          visibility: hidden;
          opacity: 0;
          -webkit-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0 linear 0.25s;
          transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), visibility 0 linear 0.25s;
        }
        .nt-filter__slide-row {
          height: 0;
          margin-bottom: -1px;
          margin-top: -1px;
          overflow-y: hidden;
          padding-bottom: 1px;
          padding-top: 1px;
          -webkit-transition: height 0.2s cubic-bezier(0, 0, 0.2, 1);
          transition: height 0.2s cubic-bezier(0, 0, 0.2, 1);
        }
        .nt-header {
          position: relative;
          z-index: 4;
          -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px 0 rgba(0, 0, 0, 0.14);
          box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12),
            0 2px 4px 0 rgba(0, 0, 0, 0.14);
          background-color: #415d9a;
        }
        .nt-header__main {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          height: 4rem;
        }
        .nt-header__main__left-side,
        .nt-header__main__left-side .left-side__menu {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          height: 100%;
        }
        .nt-header__main__left-side .left-side__menu {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 5rem;
        }
        .nt-header__main__left-side .left-side__menu__icon {
          color: #fff;
          cursor: pointer;
        }
        .nt-header__main__left-side .left-side__logo {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-pack: center;
        }
        .nt-header__main__content,
        .nt-header__main__left-side .left-side__logo {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
        }
        .nt-header__main__content {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          -ms-flex-pack: center;
        }
        .nt-header__main__right-side {
          height: 100%;
        }
        .nt-header__main__right-side,
        .nt-header__main__right-side .right-side__tools {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .nt-header__main__right-side .right-side__tools {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          margin-right: 1.5rem;
        }
        .nt-header__main__right-side .right-side__tools__tool:not(:first-child) {
          margin-left: 1.5rem;
        }
        .nt-header__main__right-side .right-side__user-container {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 4rem;
          height: 100%;
        }
        .nt-header__main__right-side .right-side__user-container,
        .nt-subheader {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .nt-subheader {
          height: 2rem;
          padding-left: 1.5rem;
        }
        .nt-subheader__title {
          font-size: 0.875rem;
          color: #fff;
        }
        .nt-subheader__breadcrumb {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          margin-right: 0.5rem;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }
        .nt-subheader__breadcrumb__title {
          border: 0;
          font-size: 0.75rem;
          color: hsla(0, 0%, 100%, 0.7);
          background-color: initial;
          margin-right: 0.5rem;
          outline: none;
        }
        .nt-subheader__breadcrumb__title--selectable:hover {
          color: #fff;
          cursor: pointer;
        }
        .nt-subheader__breadcrumb__icon {
          font-size: 1.5rem;
          color: hsla(0, 0%, 100%, 0.7);
        }
        .nt-subheader__breadcrumb:last-child .nt-subheader__breadcrumb__title {
          font-size: 0.875rem;
          font-weight: 500;
          color: #fff;
        }
        .nt-subheader__breadcrumb:last-child .nt-subheader__breadcrumb__icon {
          display: none;
          visibility: hidden;
        }
        .nt-header-tabs {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 3rem;
          padding-left: 3.5rem;
        }
        .nt-header-tabs .mdc-tab-bar {
          margin: 0;
        }
        .nt-header-tabs .mdc-tab-bar__indicator {
          background-color: #fff;
        }
        .nt-pagination {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          padding: 0 0.5em;
          font-size: 0.9em;
        }
        .nt-pagination,
        .nt-pagination > span {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
        .nt-pagination > span + span {
          margin-left: 1em;
        }
        .nt-pagination p {
          white-space: nowrap;
          margin: 0;
        }
        .nt-pagination__select {
          height: 24px;
          margin-top: -2px;
          margin-left: 10px;
          background-position: right 4px bottom 10px;
        }
        .nt-pagination__select select {
          height: 24px;
          padding: 0 18px 0 5px;
          min-width: 47px;
          font-size: inherit;
          line-height: 24px;
        }
        .nt-pagination__button--disabled {
          color: rgba(0, 0, 0, 0.38);
        }
        .nt-data-list--dense .nt-pagination__button {
          width: 40px;
          height: 40px;
          padding: 8px;
        }
        .nt-permanent-drawer {
          position: fixed;
          top: 0;
          overflow: visible;
          width: 180px;
          height: 100vh;
          font-size: 14px;
          background: #fff;
          -webkit-box-shadow: none;
          box-shadow: none;
          border-right: 1px solid #d9dadb;
        }
        .nt-permanent-drawer__mobile-header {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          height: 50px;
          background: #fff;
        }
        .nt-permanent-drawer__mobile-header-icon {
          padding: 17px;
        }
        .nt-permanent-drawer__header-content {
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          height: 6rem;
          background-color: #fff;
          max-height: 70px;
        }
        .nt-permanent-drawer__header-content,
        .nt-permanent-drawer__header-logo {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        .nt-permanent-drawer__header-logo {
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin: 10px 10px 20px;
          border-radius: 4px;
          text-align: center;
          font-size: 28px;
          color: #fff;
          background: linear-gradient(300deg, #72c7d7 44%, transparent 0),
            linear-gradient(205deg, #98c121 44%, #203564 0);
        }
        .nt-permanent-drawer__header-title {
          font-size: 1em;
          font-weight: 800;
          letter-spacing: 0.75px;
        }
        .nt-permanent-drawer__header-subtitle {
          font-size: 0.8em;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          letter-spacing: 0.75px;
        }
        .nt-permanent-drawer__list {
          padding: 0 8px;
          font-size: 14px;
        }
        .nt-permanent-drawer__account-popup-label {
          font-size: 1em;
          color: rgba(0, 0, 0, 0.25);
          margin-left: 8px;
          letter-spacing: 0.75px;
        }
        .nt-permanent-drawer__list-item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
          padding: 8px 10px;
          color: rgba(0, 0, 0, 0.25);
          cursor: pointer;
          list-style: none;
          text-decoration: none;
        }
        .nt-permanent-drawer__list-item > i {
          padding: 4px 0;
          font-size: 1.5em;
        }
        .nt-permanent-drawer__list-item-selected {
          color: #000;
        }
        .nt-permanent-drawer__list-item-selected:after {
          position: absolute;
          right: 0;
          width: 3px;
          height: 37px;
          content: '';
          background-color: #415d9a;
        }
        .nt-permanent-drawer__list-item:hover {
          border-radius: 4px;
          background: rgba(0, 0, 0, 0.06274509803921569);
        }
        .nt-permanent-drawer__list-item-text {
          font-size: 1em;
          margin-left: 15px;
          letter-spacing: 0.75px;
        }
        .nt-permanent-drawer__bottom-tool {
          overflow: hidden;
          padding: 0 10px;
        }
        .nt-permanent-drawer__footer {
          position: absolute;
          bottom: 0;
          width: 100%;
          padding: 8px 0;
          border-top: 1px solid #d9dadb;
        }
        .nt-permanent-drawer__account-popup {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          padding: 3px 0;
        }
        @media only screen and (min-device-width: 360px) and (max-device-width: 736px) {
          .nt-permanent-drawer__header-content {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }
        }
        @media only screen and (min-width: 800px) {
          .nt-permanent-drawer {
            display: block;
            width: 60px;
          }
          .nt-permanent-drawer__header-text,
          .nt-permanent-drawer__mobile-header {
            display: none;
          }
          .nt-permanent-drawer__header-content {
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
          }
          .nt-permanent-drawer__account-popup-label,
          .nt-permanent-drawer__list-item-text {
            display: none;
          }
          .nt-permanent-drawer__list-item:hover .nt-permanent-drawer__list-item-text {
            position: fixed;
            left: 0;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 0.8em;
            color: #fff;
            background: #000;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
            margin-left: 70px;
          }
        }
        @media only screen and (min-width: 1500px) {
          .nt-permanent-drawer {
            width: 180px;
          }
          .nt-permanent-drawer__header-text,
          .nt-permanent-drawer__list-item-text {
            display: block;
          }
          .nt-permanent-drawer__account-popup-label {
            display: inline;
          }
          .nt-permanent-drawer__list-item:hover .nt-permanent-drawer__list-item-text {
            position: relative;
            display: inline-block;
            padding: 0;
            font-size: 1em;
            color: inherit;
            background: none;
            margin-left: 15px;
          }
        }

        .nt-stepper {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          width: 100%;
        }
        .nt-stepper:before {
          position: absolute;
          z-index: 0;
          width: 100%;
          border-bottom: 1px solid #cfd8dc;
          content: '';
        }
        .nt-stepper__step {
          z-index: 1;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 0 0.5rem;
          border: 0;
          cursor: pointer;
          background-color: #fff;
          outline: none;
        }
        .nt-stepper__step:last-child {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }
        .nt-stepper__step-index {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          border-radius: 1rem;
          font-size: 1rem;
          font-weight: 500;
          color: #fff;
          background-color: #cfd8dc;
        }
        .nt-stepper__step-label {
          margin: 0 0.5rem;
          color: rgba(0, 0, 0, 0.38);
        }
        .nt-stepper__step--active .nt-stepper__step-index {
          background-color: #ff9800;
        }
        .nt-stepper__step--active .nt-stepper__step-label {
          font-weight: 500;
          color: rgba(0, 0, 0, 0.87);
        }
        .nt-stepper__step--completed .nt-stepper__step-label {
          font-weight: 400;
          color: rgba(0, 0, 0, 0.54);
        }
        .nt-textarea {
          resize: vertical;
        }
        .nt-toggle-button-group {
          position: relative;
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          padding-top: 0.5rem;
        }
        .nt-toggle-button-group__label {
          position: absolute;
          top: -0.5rem;
          left: 0.5rem;
          font-size: 0.75rem;
          opacity: 0.54;
        }
        .nt-toggle-button-group__button {
          display: -webkit-inline-box;
          display: -ms-inline-flexbox;
          display: inline-flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 3rem;
          height: 3rem;
          padding: 0;
          border: 1px solid hsla(0, 0%, 62.7%, 0.2);
          border-radius: 0;
          color: rgba(0, 0, 0, 0.38);
          background-color: #fff;
        }
        .nt-toggle-button-group__button:active,
        .nt-toggle-button-group__button:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
        .nt-toggle-button-group__button:disabled {
          color: rgba(0, 0, 0, 0.04);
          background-color: #fff;
        }
        .nt-toggle-button-group__button:first-of-type {
          border-bottom-left-radius: 6px;
          border-top-left-radius: 6px;
        }
        .nt-toggle-button-group__button:not(:first-of-type) {
          border-left: 0;
        }
        .nt-toggle-button-group__button:last-of-type {
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
        }
        .nt-toggle-button-group__button--active {
          background-color: rgba(0, 0, 0, 0.12);
          color: rgba(0, 0, 0, 0.87);
        }
        @font-face {
          font-family: 'material-icons-swap';
          src: url('fonts/material-icons-swap.eot?cwo237');
          src: url('fonts/material-icons-swap.eot?cwo237#iefix') format('embedded-opentype'),
            url('fonts/material-icons-swap.ttf?cwo237') format('truetype'),
            url('fonts/material-icons-swap.woff?cwo237') format('woff'),
            url('fonts/material-icons-swap.svg?cwo237#material-icons-swap') format('svg');
          font-weight: normal;
          font-style: normal;
        }

        [class^='swap-'],
        [class*=' swap-'] {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'material-icons-swap' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .swap-help_outline::before {
          content: '\e900';
        }
        .swap-accessible::before {
          content: '\e901';
        }
        .swap-accessibility::before {
          content: '\e902';
        }
        .swap-access_time::before {
          content: '\e903';
        }
        .swap-account-alert::before {
          content: '\e904';
        }
        .swap-account-card-details::before {
          content: '\e905';
        }
        .swap-account-check::before {
          content: '\e906';
        }
        .swap-account-convert::before {
          content: '\e907';
        }
        .swap-account-edit::before {
          content: '\e908';
        }
        .swap-account-key::before {
          content: '\e909';
        }
        .swap-account-minus::before {
          content: '\e90a';
        }
        .swap-account-multiple-minus::before {
          content: '\e90b';
        }
        .swap-account-multiple-plus::before {
          content: '\e90c';
        }
        .swap-account-network::before {
          content: '\e90d';
        }
        .swap-account-off::before {
          content: '\e90e';
        }
        .swap-account-plus::before {
          content: '\e90f';
        }
        .swap-account-remove::before {
          content: '\e910';
        }
        .swap-account-search::before {
          content: '\e911';
        }
        .swap-account-settings-variant::before {
          content: '\e912';
        }
        .swap-account-settings::before {
          content: '\e913';
        }
        .swap-account-switch::before {
          content: '\e914';
        }
        .swap-account_balance::before {
          content: '\e915';
        }
        .swap-account_circle::before {
          content: '\e916';
        }
        .swap-ac_unit::before {
          content: '\e917';
        }
        .swap-add::before {
          content: '\e918';
        }
        .swap-add_alert::before {
          content: '\e919';
        }
        .swap-add_box::before {
          content: '\e91a';
        }
        .swap-add_circle::before {
          content: '\e91b';
        }
        .swap-add_circle_outline::before {
          content: '\e91c';
        }
        .swap-add_location::before {
          content: '\e91d';
        }
        .swap-add_shopping_cart::before {
          content: '\e91e';
        }
        .swap-alarm::before {
          content: '\e91f';
        }
        .swap-alarm_add::before {
          content: '\e920';
        }
        .swap-account_box::before {
          content: '\e921';
        }
        .swap-add_to_queue::before {
          content: '\e922';
        }
        .swap-alarm_off::before {
          content: '\e923';
        }
        .swap-alarm_on::before {
          content: '\e924';
        }
        .swap-announcement::before {
          content: '\e925';
        }
        .swap-apps::before {
          content: '\e926';
        }
        .swap-archive::before {
          content: '\e927';
        }
        .swap-arrow_back::before {
          content: '\e928';
        }
        .swap-arrow_downward::before {
          content: '\e929';
        }
        .swap-arrow_drop_down::before {
          content: '\e92a';
        }
        .swap-arrow_drop_down_circle::before {
          content: '\e92b';
        }
        .swap-arrow_drop_up::before {
          content: '\e92c';
        }
        .swap-arrow_forward::before {
          content: '\e92d';
        }
        .swap-arrow_upward::before {
          content: '\e92e';
        }
        .swap-assessment::before {
          content: '\e92f';
        }
        .swap-assignment::before {
          content: '\e930';
        }
        .swap-assignment_ind::before {
          content: '\e931';
        }
        .swap-assignment_late::before {
          content: '\e932';
        }
        .swap-assignment_return::before {
          content: '\e933';
        }
        .swap-assignment_returned::before {
          content: '\e934';
        }
        .swap-assignment_turned_in::before {
          content: '\e935';
        }
        .swap-attachment::before {
          content: '\e936';
        }
        .swap-auto-fix::before {
          content: '\e937';
        }
        .swap-autorenew::before {
          content: '\e938';
        }
        .swap-beach_access::before {
          content: '\e939';
        }
        .swap-beenhere::before {
          content: '\e93a';
        }
        .swap-beer::before {
          content: '\e93b';
        }
        .swap-bell-ring-outline::before {
          content: '\e93c';
        }
        .swap-block::before {
          content: '\e93d';
        }
        .swap-book::before {
          content: '\e93e';
        }
        .swap-bookmark::before {
          content: '\e93f';
        }
        .swap-bookmark_border::before {
          content: '\e940';
        }
        .swap-brush::before {
          content: '\e941';
        }
        .swap-bug_report::before {
          content: '\e942';
        }
        .swap-build::before {
          content: '\e943';
        }
        .swap-bullhorn::before {
          content: '\e944';
        }
        .swap-business_center::before {
          content: '\e945';
        }
        .swap-cached::before {
          content: '\e946';
        }
        .swap-cake::before {
          content: '\e947';
        }
        .swap-call::before {
          content: '\e948';
        }
        .swap-call_end::before {
          content: '\e949';
        }
        .swap-call_made::before {
          content: '\e94a';
        }
        .swap-call_missed::before {
          content: '\e94b';
        }
        .swap-call_missed_outgoing::before {
          content: '\e94c';
        }
        .swap-call_received::before {
          content: '\e94d';
        }
        .swap-camera_alt::before {
          content: '\e94e';
        }
        .swap-cancel::before {
          content: '\e94f';
        }
        .swap-card_travel::before {
          content: '\e950';
        }
        .swap-cart-outline::before {
          content: '\e951';
        }
        .swap-cctv::before {
          content: '\e952';
        }
        .swap-chart-bar-stacked::before {
          content: '\e953';
        }
        .swap-chart-bar::before {
          content: '\e954';
        }
        .swap-chart-gantt::before {
          content: '\e955';
        }
        .swap-chart-line::before {
          content: '\e956';
        }
        .swap-chart-pie::before {
          content: '\e957';
        }
        .swap-chat::before {
          content: '\e958';
        }
        .swap-chat_bubble::before {
          content: '\e959';
        }
        .swap-chat_bubble_outline::before {
          content: '\e95a';
        }
        .swap-check-all::before {
          content: '\e95b';
        }
        .swap-check::before {
          content: '\e95c';
        }
        .swap-check_box::before {
          content: '\e95d';
        }
        .swap-check_box_outline_blank::before {
          content: '\e95e';
        }
        .swap-check_circle::before {
          content: '\e95f';
        }
        .swap-child_friendly::before {
          content: '\e960';
        }
        .swap-chrome_reader_mode::before {
          content: '\e961';
        }
        .swap-clear::before {
          content: '\e962';
        }
        .swap-clock-alert::before {
          content: '\e963';
        }
        .swap-cloud::before {
          content: '\e964';
        }
        .swap-cloud_circle::before {
          content: '\e965';
        }
        .swap-cloud_done::before {
          content: '\e966';
        }
        .swap-cloud_download::before {
          content: '\e967';
        }
        .swap-cloud_off::before {
          content: '\e968';
        }
        .swap-cloud_queue::before {
          content: '\e969';
        }
        .swap-cloud_upload::before {
          content: '\e96a';
        }
        .swap-code::before {
          content: '\e96b';
        }
        .swap-colorize::before {
          content: '\e96c';
        }
        .swap-color_lens::before {
          content: '\e96d';
        }
        .swap-comment::before {
          content: '\e96e';
        }
        .swap-compare_arrows::before {
          content: '\e96f';
        }
        .swap-computer::before {
          content: '\e970';
        }
        .swap-contact_mail::before {
          content: '\e971';
        }
        .swap-content_copy::before {
          content: '\e972';
        }
        .swap-contact_phone::before {
          content: '\e973';
        }
        .swap-content_cut::before {
          content: '\e974';
        }
        .swap-content_paste::before {
          content: '\e975';
        }
        .swap-copyright::before {
          content: '\e976';
        }
        .swap-create::before {
          content: '\e977';
        }
        .swap-create_new_folder::before {
          content: '\e978';
        }
        .swap-credit_card::before {
          content: '\e979';
        }
        .swap-crop::before {
          content: '\e97a';
        }
        .swap-crop_free::before {
          content: '\e97b';
        }
        .swap-crop_original::before {
          content: '\e97c';
        }
        .swap-dashboard::before {
          content: '\e97d';
        }
        .swap-database-minus::before {
          content: '\e97e';
        }
        .swap-database-plus::before {
          content: '\e97f';
        }
        .swap-database::before {
          content: '\e980';
        }
        .swap-date_range::before {
          content: '\e981';
        }
        .swap-delete-circle::before {
          content: '\e982';
        }
        .swap-delete-empty::before {
          content: '\e983';
        }
        .swap-delete::before {
          content: '\e984';
        }
        .swap-delete_forever::before {
          content: '\e985';
        }
        .swap-delete_sweep::before {
          content: '\e986';
        }
        .swap-description::before {
          content: '\e987';
        }
        .swap-deskphone::before {
          content: '\e988';
        }
        .swap-desktop_mac::before {
          content: '\e989';
        }
        .swap-developer_board::before {
          content: '\e98a';
        }
        .swap-devices::before {
          content: '\e98b';
        }
        .swap-device_hub::before {
          content: '\e98c';
        }
        .swap-dialpad::before {
          content: '\e98d';
        }
        .swap-diamond::before {
          content: '\e98e';
        }
        .swap-directions::before {
          content: '\e98f';
        }
        .swap-directions_bike::before {
          content: '\e990';
        }
        .swap-directions_boat::before {
          content: '\e991';
        }
        .swap-directions_bus::before {
          content: '\e992';
        }
        .swap-directions_car::before {
          content: '\e993';
        }
        .swap-directions_run::before {
          content: '\e994';
        }
        .swap-directions_subway::before {
          content: '\e995';
        }
        .swap-directions_walk::before {
          content: '\e996';
        }
        .swap-disc_full::before {
          content: '\e997';
        }
        .swap-dns::before {
          content: '\e998';
        }
        .swap-domain::before {
          content: '\e999';
        }
        .swap-donut_large::before {
          content: '\e99a';
        }
        .swap-donut_small::before {
          content: '\e99b';
        }
        .swap-do_not_disturb_off::before {
          content: '\e99c';
        }
        .swap-do_not_disturb_on::before {
          content: '\e99d';
        }
        .swap-drafts::before {
          content: '\e99e';
        }
        .swap-dvr::before {
          content: '\e99f';
        }
        .swap-edit_location::before {
          content: '\e9a0';
        }
        .swap-eject::before {
          content: '\e9a1';
        }
        .swap-email::before {
          content: '\e9a2';
        }
        .swap-enhanced_encryption::before {
          content: '\e9a3';
        }
        .swap-equalizer::before {
          content: '\e9a4';
        }
        .swap-error::before {
          content: '\e9a5';
        }
        .swap-error_outline::before {
          content: '\e9a6';
        }
        .swap-event::before {
          content: '\e9a7';
        }
        .swap-event_available::before {
          content: '\e9a8';
        }
        .swap-event_busy::before {
          content: '\e9a9';
        }
        .swap-event_note::before {
          content: '\e9aa';
        }
        .swap-exit_to_app::before {
          content: '\e9ab';
        }
        .swap-explore::before {
          content: '\e9ac';
        }
        .swap-extension::before {
          content: '\e9ad';
        }
        .swap-eye-outline-off::before {
          content: '\e9ae';
        }
        .swap-eye-outline::before {
          content: '\e9af';
        }
        .swap-fast_forward::before {
          content: '\e9b0';
        }
        .swap-fast_rewind::before {
          content: '\e9b1';
        }
        .swap-favorite::before {
          content: '\e9b2';
        }
        .swap-favorite_border::before {
          content: '\e9b3';
        }
        .swap-file-account::before {
          content: '\e9b4';
        }
        .swap-file-chart::before {
          content: '\e9b5';
        }
        .swap-file-check::before {
          content: '\e9b6';
        }
        .swap-file-find::before {
          content: '\e9b7';
        }
        .swap-file-image::before {
          content: '\e9b8';
        }
        .swap-file-lock::before {
          content: '\e9b9';
        }
        .swap-file-pdf::before {
          content: '\e9ba';
        }
        .swap-file-powerpoint::before {
          content: '\e9bb';
        }
        .swap-file-restore::before {
          content: '\e9bc';
        }
        .swap-file-video::before {
          content: '\e9bd';
        }
        .swap-file-word::before {
          content: '\e9be';
        }
        .swap-file::before {
          content: '\e9bf';
        }
        .swap-file_download::before {
          content: '\e9c0';
        }
        .swap-file_upload::before {
          content: '\e9c1';
        }
        .swap-filter-outline::before {
          content: '\e9c2';
        }
        .swap-filter-remove-outline::before {
          content: '\e9c3';
        }
        .swap-filter-remove::before {
          content: '\e9c4';
        }
        .swap-filter::before {
          content: '\e9c5';
        }
        .swap-filter_list::before {
          content: '\e9c6';
        }
        .swap-find_in_page::before {
          content: '\e9c7';
        }
        .swap-find_replace::before {
          content: '\e9c8';
        }
        .swap-fingerprint::before {
          content: '\e9c9';
        }
        .swap-first_page::before {
          content: '\e9ca';
        }
        .swap-fish::before {
          content: '\e9cb';
        }
        .swap-flag-outline::before {
          content: '\e9cc';
        }
        .swap-flag::before {
          content: '\e9cd';
        }
        .swap-flash_on::before {
          content: '\e9ce';
        }
        .swap-flask-empty-outline::before {
          content: '\e9cf';
        }
        .swap-flask-empty::before {
          content: '\e9d0';
        }
        .swap-flask-outline::before {
          content: '\e9d1';
        }
        .swap-flask::before {
          content: '\e9d2';
        }
        .swap-flight::before {
          content: '\e9d3';
        }
        .swap-floppy::before {
          content: '\e9d4';
        }
        .swap-folder-download::before {
          content: '\e9d5';
        }
        .swap-folder-lock-open::before {
          content: '\e9d6';
        }
        .swap-folder-lock::before {
          content: '\e9d7';
        }
        .swap-folder-plus::before {
          content: '\e9d8';
        }
        .swap-folder-remove::before {
          content: '\e9d9';
        }
        .swap-folder-star::before {
          content: '\e9da';
        }
        .swap-folder-upload::before {
          content: '\e9db';
        }
        .swap-folder::before {
          content: '\e9dc';
        }
        .swap-folder_open::before {
          content: '\e9dd';
        }
        .swap-folder_shared::before {
          content: '\e9de';
        }
        .swap-forum::before {
          content: '\e9df';
        }
        .swap-fullscreen::before {
          content: '\e9e0';
        }
        .swap-golf_course::before {
          content: '\e9e1';
        }
        .swap-gps_fixed::before {
          content: '\e9e2';
        }
        .swap-gps_not_fixed::before {
          content: '\e9e3';
        }
        .swap-gps_off::before {
          content: '\e9e4';
        }
        .swap-headset-off::before {
          content: '\e9e5';
        }
        .swap-headset::before {
          content: '\e9e6';
        }
        .swap-highlight_off::before {
          content: '\e9e7';
        }
        .swap-history::before {
          content: '\e9e8';
        }
        .swap-home::before {
          content: '\e9e9';
        }
        .swap-hotel::before {
          content: '\e9ea';
        }
        .swap-hourglass_empty::before {
          content: '\e9eb';
        }
        .swap-hourglass_full::before {
          content: '\e9ec';
        }
        .swap-human-greeting::before {
          content: '\e9ed';
        }
        .swap-human-handsdown::before {
          content: '\e9ee';
        }
        .swap-human-handsup::before {
          content: '\e9ef';
        }
        .swap-image::before {
          content: '\e9f0';
        }
        .swap-important_devices::before {
          content: '\e9f1';
        }
        .swap-import_contacts::before {
          content: '\e9f2';
        }
        .swap-inbox::before {
          content: '\e9f3';
        }
        .swap-indeterminate_check_box::before {
          content: '\e9f4';
        }
        .swap-info::before {
          content: '\e9f5';
        }
        .swap-info_outline::before {
          content: '\e9f6';
        }
        .swap-input::before {
          content: '\e9f7';
        }
        .swap-insert_chart::before {
          content: '\e9f8';
        }
        .swap-insert_comment::before {
          content: '\e9f9';
        }
        .swap-insert_invitation::before {
          content: '\e9fa';
        }
        .swap-insert_link::before {
          content: '\e9fb';
        }
        .swap-key-minus::before {
          content: '\e9fc';
        }
        .swap-key-plus::before {
          content: '\e9fd';
        }
        .swap-key-remove::before {
          content: '\e9fe';
        }
        .swap-key-variant::before {
          content: '\e9ff';
        }
        .swap-keyboard::before {
          content: '\ea00';
        }
        .swap-keyboard_arrow_down::before {
          content: '\ea01';
        }
        .swap-keyboard_arrow_left::before {
          content: '\ea02';
        }
        .swap-keyboard_arrow_right::before {
          content: '\ea03';
        }
        .swap-keyboard_arrow_up::before {
          content: '\ea04';
        }
        .swap-label::before {
          content: '\ea05';
        }
        .swap-label_outline::before {
          content: '\ea06';
        }
        .swap-landscape::before {
          content: '\ea07';
        }
        .swap-last_page::before {
          content: '\ea08';
        }
        .swap-layers::before {
          content: '\ea09';
        }
        .swap-layers_clear::before {
          content: '\ea0a';
        }
        .swap-leaf::before {
          content: '\ea0b';
        }
        .swap-lightbulb-on-outline::before {
          content: '\ea0c';
        }
        .swap-lightbulb-on::before {
          content: '\ea0d';
        }
        .swap-lightbulb-outline::before {
          content: '\ea0e';
        }
        .swap-lightbulb::before {
          content: '\ea0f';
        }
        .swap-lightbulb_outline::before {
          content: '\ea10';
        }
        .swap-line_style::before {
          content: '\ea11';
        }
        .swap-link-off::before {
          content: '\ea12';
        }
        .swap-live_help::before {
          content: '\ea13';
        }
        .swap-live_tv::before {
          content: '\ea14';
        }
        .swap-local_bar::before {
          content: '\ea15';
        }
        .swap-local_cafe::before {
          content: '\ea16';
        }
        .swap-local_dining::before {
          content: '\ea17';
        }
        .swap-local_florist::before {
          content: '\ea18';
        }
        .swap-local_gas_station::before {
          content: '\ea19';
        }
        .swap-local_hospital::before {
          content: '\ea1a';
        }
        .swap-local_offer::before {
          content: '\ea1b';
        }
        .swap-local_parking::before {
          content: '\ea1c';
        }
        .swap-local_shipping::before {
          content: '\ea1d';
        }
        .swap-local_taxi::before {
          content: '\ea1e';
        }
        .swap-location_city::before {
          content: '\ea1f';
        }
        .swap-location_off::before {
          content: '\ea20';
        }
        .swap-location_on::before {
          content: '\ea21';
        }
        .swap-lock::before {
          content: '\ea22';
        }
        .swap-lock_open::before {
          content: '\ea23';
        }
        .swap-lock_outline::before {
          content: '\ea24';
        }
        .swap-login::before {
          content: '\ea25';
        }
        .swap-logout::before {
          content: '\ea26';
        }
        .swap-loop::before {
          content: '\ea27';
        }
        .swap-mail_outline::before {
          content: '\ea28';
        }
        .swap-map::before {
          content: '\ea29';
        }
        .swap-markunread_mailbox::before {
          content: '\ea2a';
        }
        .swap-memory::before {
          content: '\ea2b';
        }
        .swap-menu::before {
          content: '\ea2c';
        }
        .swap-message::before {
          content: '\ea2d';
        }
        .swap-mms::before {
          content: '\ea2e';
        }
        .swap-mode_comment::before {
          content: '\ea2f';
        }
        .swap-mode_edit::before {
          content: '\ea30';
        }
        .swap-mood::before {
          content: '\ea31';
        }
        .swap-mood_bad::before {
          content: '\ea32';
        }
        .swap-more_horiz::before {
          content: '\ea33';
        }
        .swap-more_vert::before {
          content: '\ea34';
        }
        .swap-mouse::before {
          content: '\ea35';
        }
        .swap-move_to_inbox::before {
          content: '\ea36';
        }
        .swap-movie::before {
          content: '\ea37';
        }
        .swap-music_note::before {
          content: '\ea38';
        }
        .swap-near_me::before {
          content: '\ea39';
        }
        .swap-network_check::before {
          content: '\ea3a';
        }
        .swap-note::before {
          content: '\ea3b';
        }
        .swap-note_add::before {
          content: '\ea3c';
        }
        .swap-notifications::before {
          content: '\ea3d';
        }
        .swap-notifications_active::before {
          content: '\ea3e';
        }
        .swap-notifications_none::before {
          content: '\ea3f';
        }
        .swap-notifications_off::before {
          content: '\ea40';
        }
        .swap-not_interested::before {
          content: '\ea41';
        }
        .swap-open_in_new::before {
          content: '\ea42';
        }
        .swap-open_with::before {
          content: '\ea43';
        }
        .swap-pan_tool::before {
          content: '\ea44';
        }
        .swap-pause::before {
          content: '\ea45';
        }
        .swap-pause_circle_filled::before {
          content: '\ea46';
        }
        .swap-pause_circle_outline::before {
          content: '\ea47';
        }
        .swap-payment::before {
          content: '\ea48';
        }
        .swap-people::before {
          content: '\ea49';
        }
        .swap-people_outline::before {
          content: '\ea4a';
        }
        .swap-perm_contact_calendar::before {
          content: '\ea4b';
        }
        .swap-perm_device_information::before {
          content: '\ea4c';
        }
        .swap-perm_phone_msg::before {
          content: '\ea4d';
        }
        .swap-person::before {
          content: '\ea4e';
        }
        .swap-person_outline::before {
          content: '\ea4f';
        }
        .swap-person_pin::before {
          content: '\ea50';
        }
        .swap-person_pin_circle::before {
          content: '\ea51';
        }
        .swap-phonelink_erase::before {
          content: '\ea52';
        }
        .swap-phone_forwarded::before {
          content: '\ea53';
        }
        .swap-phone_in_talk::before {
          content: '\ea54';
        }
        .swap-phone_missed::before {
          content: '\ea55';
        }
        .swap-phone_paused::before {
          content: '\ea56';
        }
        .swap-photo_album::before {
          content: '\ea57';
        }
        .swap-pie_chart::before {
          content: '\ea58';
        }
        .swap-pie_chart_outlined::before {
          content: '\ea59';
        }
        .swap-pin::before {
          content: '\ea5a';
        }
        .swap-pine-tree-box::before {
          content: '\ea5b';
        }
        .swap-pine-tree::before {
          content: '\ea5c';
        }
        .swap-pin_drop::before {
          content: '\ea5d';
        }
        .swap-play_arrow::before {
          content: '\ea5e';
        }
        .swap-play_circle_filled::before {
          content: '\ea5f';
        }
        .swap-play_circle_outline::before {
          content: '\ea60';
        }
        .swap-pool::before {
          content: '\ea61';
        }
        .swap-pool_2::before {
          content: '\ea62';
        }
        .swap-power_settings_new::before {
          content: '\ea63';
        }
        .swap-print::before {
          content: '\ea64';
        }
        .swap-public::before {
          content: '\ea65';
        }
        .swap-question_answer::before {
          content: '\ea66';
        }
        .swap-radio_button_checked::before {
          content: '\ea67';
        }
        .swap-radio_button_unchecked::before {
          content: '\ea68';
        }
        .swap-rate_review::before {
          content: '\ea69';
        }
        .swap-record_voice_over::before {
          content: '\ea6a';
        }
        .swap-redo::before {
          content: '\ea6b';
        }
        .swap-refresh::before {
          content: '\ea6c';
        }
        .swap-remove_circle::before {
          content: '\ea6d';
        }
        .swap-remove_circle_outline::before {
          content: '\ea6e';
        }
        .swap-remove_shopping_cart::before {
          content: '\ea6f';
        }
        .swap-reorder::before {
          content: '\ea70';
        }
        .swap-reply::before {
          content: '\ea71';
        }
        .swap-reply_all::before {
          content: '\ea72';
        }
        .swap-report::before {
          content: '\ea73';
        }
        .swap-report_problem::before {
          content: '\ea74';
        }
        .swap-restore_page::before {
          content: '\ea75';
        }
        .swap-ring_volume::before {
          content: '\ea76';
        }
        .swap-room::before {
          content: '\ea77';
        }
        .swap-room_service::before {
          content: '\ea78';
        }
        .swap-router::before {
          content: '\ea79';
        }
        .swap-save::before {
          content: '\ea7a';
        }
        .swap-school::before {
          content: '\ea7b';
        }
        .swap-screen_share::before {
          content: '\ea7c';
        }
        .swap-search::before {
          content: '\ea7d';
        }
        .swap-security::before {
          content: '\ea7e';
        }
        .swap-send::before {
          content: '\ea7f';
        }
        .swap-server-off::before {
          content: '\ea80';
        }
        .swap-server-security::before {
          content: '\ea81';
        }
        .swap-server::before {
          content: '\ea82';
        }
        .swap-settings::before {
          content: '\ea83';
        }
        .swap-settings_applications::before {
          content: '\ea84';
        }
        .swap-settings_ethernet::before {
          content: '\ea85';
        }
        .swap-settings_input_component::before {
          content: '\ea86';
        }
        .swap-settings_phone::before {
          content: '\ea87';
        }
        .swap-share::before {
          content: '\ea88';
        }
        .swap-shape-plus::before {
          content: '\ea89';
        }
        .swap-shop::before {
          content: '\ea8a';
        }
        .swap-shopping_basket::before {
          content: '\ea8b';
        }
        .swap-shopping_cart::before {
          content: '\ea8c';
        }
        .swap-sign-caution::before {
          content: '\ea8d';
        }
        .swap-sign-direction::before {
          content: '\ea8e';
        }
        .swap-skip_next::before {
          content: '\ea8f';
        }
        .swap-skip_previous::before {
          content: '\ea90';
        }
        .swap-sign-text::before {
          content: '\ea91';
        }
        .swap-sms::before {
          content: '\ea92';
        }
        .swap-sms_failed::before {
          content: '\ea93';
        }
        .swap-snowflake::before {
          content: '\ea94';
        }
        .swap-speaker_notes::before {
          content: '\ea95';
        }
        .swap-speaker_notes_off::before {
          content: '\ea96';
        }
        .swap-speedometer::before {
          content: '\ea97';
        }
        .swap-star::before {
          content: '\ea98';
        }
        .swap-stars::before {
          content: '\ea99';
        }
        .swap-star_border::before {
          content: '\ea9a';
        }
        .swap-star_half::before {
          content: '\ea9b';
        }
        .swap-stay_current_landscape::before {
          content: '\ea9c';
        }
        .swap-stay_current_portrait::before {
          content: '\ea9d';
        }
        .swap-storage::before {
          content: '\ea9e';
        }
        .swap-store::before {
          content: '\ea9f';
        }
        .swap-straighten::before {
          content: '\eaa0';
        }
        .swap-style::before {
          content: '\eaa1';
        }
        .swap-subdirectory_arrow_left::before {
          content: '\eaa2';
        }
        .swap-subdirectory_arrow_right::before {
          content: '\eaa3';
        }
        .swap-swap_horiz::before {
          content: '\eaa4';
        }
        .swap-swap_vert::before {
          content: '\eaa5';
        }
        .swap-swap_vertical_circle::before {
          content: '\eaa6';
        }
        .swap-sync_problem::before {
          content: '\eaa7';
        }
        .swap-system_update::before {
          content: '\eaa8';
        }
        .swap-system_update_alt::before {
          content: '\eaa9';
        }
        .swap-tablet-ipad::before {
          content: '\eaaa';
        }
        .swap-tag-outline::before {
          content: '\eaab';
        }
        .swap-tag-text-outline::before {
          content: '\eaac';
        }
        .swap-tap_and_play::before {
          content: '\eaad';
        }
        .swap-target::before {
          content: '\eaae';
        }
        .swap-theaters::before {
          content: '\eaaf';
        }
        .swap-thumbs_up_down::before {
          content: '\eab0';
        }
        .swap-thumb_down::before {
          content: '\eab1';
        }
        .swap-thumb_up::before {
          content: '\eab2';
        }
        .swap-timeline::before {
          content: '\eab3';
        }
        .swap-timer::before {
          content: '\eab4';
        }
        .swap-timer_off::before {
          content: '\eab5';
        }
        .swap-toc::before {
          content: '\eab6';
        }
        .swap-today::before {
          content: '\eab7';
        }
        .swap-touch_app::before {
          content: '\eab8';
        }
        .swap-tower-beach::before {
          content: '\eab9';
        }
        .swap-traffic::before {
          content: '\eaba';
        }
        .swap-translate::before {
          content: '\eabb';
        }
        .swap-trophy-outline::before {
          content: '\eabc';
        }
        .swap-tv::before {
          content: '\eabd';
        }
        .swap-unarchive::before {
          content: '\eabe';
        }
        .swap-undo::before {
          content: '\eabf';
        }
        .swap-unfold_less::before {
          content: '\eac0';
        }
        .swap-unfold_more::before {
          content: '\eac1';
        }
        .swap-vanish::before {
          content: '\eac2';
        }
        .swap-verified_user::before {
          content: '\eac3';
        }
        .swap-videocam::before {
          content: '\eac4';
        }
        .swap-view_agenda::before {
          content: '\eac5';
        }
        .swap-view_array::before {
          content: '\eac6';
        }
        .swap-view_carousel::before {
          content: '\eac7';
        }
        .swap-view_column::before {
          content: '\eac8';
        }
        .swap-view_day::before {
          content: '\eac9';
        }
        .swap-view_headline::before {
          content: '\eaca';
        }
        .swap-view_list::before {
          content: '\eacb';
        }
        .swap-view_module::before {
          content: '\eacc';
        }
        .swap-view_quilt::before {
          content: '\eacd';
        }
        .swap-view_stream::before {
          content: '\eace';
        }
        .swap-view_week::before {
          content: '\eacf';
        }
        .swap-visibility::before {
          content: '\ead0';
        }
        .swap-visibility_off::before {
          content: '\ead1';
        }
        .swap-voice_chat::before {
          content: '\ead2';
        }
        .swap-vpn_key::before {
          content: '\ead3';
        }
        .swap-wallpaper::before {
          content: '\ead4';
        }
        .swap-warning::before {
          content: '\ead5';
        }
        .swap-watch::before {
          content: '\ead6';
        }
        .swap-watch_later::before {
          content: '\ead7';
        }
        .swap-water-pump::before {
          content: '\ead8';
        }
        .swap-wc::before {
          content: '\ead9';
        }
        .swap-web::before {
          content: '\eada';
        }
        .swap-widgets::before {
          content: '\eadb';
        }
        .swap-wifi::before {
          content: '\eadc';
        }
        .swap-whatshot::before {
          content: '\eadd';
        }
        .swap-work::before {
          content: '\eade';
        }
        .swap-worker::before {
          content: '\eadf';
        }
        .swap-xml::before {
          content: '\eae0';
        }
        .swap-zoom_in::before {
          content: '\eae1';
        }
        .swap-zoom_out::before {
          content: '\eae2';
        }
        .swap-zoom_out_map::before {
          content: '\eae3';
        }
      `}
    />
  );
}

export default Nautilus;
