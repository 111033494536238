import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '@react-utils/intl';

import { useQueryParam } from 'use-query-params';
import { StringParam, withDefault } from 'serialize-query-params';
import { debounce } from '@mui/material';
import { FilterBarSearch } from '@naui/core';

import { useSearchEntity } from '../../hooks';
import { ALL, ALL_NAME, getAllDevices } from './utils';
import { StyledCard, StyledItem } from './elements';
import SearchItem from './SearchItem';

function SearchInputSimpleElement({
  includeAllItemsOption,
  minChars,
  includeFreeSearch,
  onChange,
  placeholder,
  clearLabel,
  queryParamKey,
  disabled,
  searchEntities,
  searchFamilies,
  supplyPointTypes,
  tenantIds,
  SelectorProps,
  selectorOptions,
  selectorValue,
  setSelectorValue,
  hasSelector,
  clearInputAfterChange,
  value: externalValue,
  inputValue: externalInputValue,
  ...props
}) {
  const t = useTranslations();
  const [qsValue, setQsValue] = useQueryParam(queryParamKey, withDefault(StringParam, ''));
  const [value, setValue] = useState(externalValue || qsValue);
  const [search, setSearch] = useState(value || '');
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const { data, loading } = useSearchEntity({
    search,
    skip: !searchEntities || !search,
    types: searchFamilies,
    tenantIds,
    supplyPointTypes
  });
  const getAutoCompleteItems = () => {
    const autoCompleteItems = [];
    if (items?.length) {
      autoCompleteItems.push(...items);
    }
    if (includeAllItemsOption && value !== ALL) {
      autoCompleteItems.push(getAllDevices(t));
    }
    return autoCompleteItems;
  };

  const getItemLabel = (item) =>
    item?.id === ALL ? t(ALL_NAME) : item?.key || item?.displayName || item;

  const onChangeFunction = (_, values) => {
    onChange(values);
    setInputValue(clearInputAfterChange ? '' : getItemLabel(values) || '');
    setValue(clearInputAfterChange ? '' : getItemLabel(values) || '');
    if (queryParamKey) {
      setQsValue(getItemLabel(values) || '');
    }
  };

  useEffect(() => {
    if (externalInputValue?.length && externalInputValue !== inputValue) {
      setInputValue(externalInputValue);
    }
  }, [externalInputValue]);

  useEffect(() => {
    if (data !== items) {
      setItems(data);
    }
    if (data?.length && value && !inputValue) {
      if (value === t(ALL_NAME)) {
        onChangeFunction(null, getAllDevices(t));
      } else {
        const selectedItem = data.find((d) => d.key === value);
        onChangeFunction(null, selectedItem);
      }
    }
  }, [data, inputValue]);

  useEffect(() => {
    if (value === ALL && !includeAllItemsOption) {
      onChangeFunction(null, null);
    }
  }, [includeAllItemsOption]);

  const searchDelayed = useMemo(() => debounce(setSearch, 500), [search]);

  useEffect(() => {
    if (!inputValue) {
      setItems([]);
    }
  }, [inputValue]);

  const getSearchValue = () => (value === ALL ? t('SEARCH_ENTITY__ALL_RIGHTS') : value);
  return (
    <FilterBarSearch
      searchTestId="search_input"
      selectorTestId="selector"
      selectorOptionTestId="option"
      clearButtonTestId="clear_button"
      searchValue={getSearchValue()}
      onSearch={onChangeFunction}
      onClear={() => onChangeFunction(null, null)}
      placeholder={placeholder || t('SEARCH_ENTITY__PLACEHOLDER')}
      clearSearchesLabel={clearLabel || t('RESTORE_SEARCH_BUTTON')}
      disabled={disabled}
      AutocompleteProps={
        searchEntities
          ? {
              onChange: onChangeFunction,
              inputValue,
              freeSolo: includeFreeSearch,
              options: getAutoCompleteItems(),
              getOptionLabel: getItemLabel,
              isOptionEqualToValue: (option, v) => v === option.key,
              filterOptions: (options) => options,
              renderOption: (p, item) => (
                <StyledItem {...p}>
                  <SearchItem key={item.id} searchValue={search} {...item} />
                </StyledItem>
              ),
              loading,
              loadingText: t('LOADING_MESSAGE'),
              onInputChange: (e) => {
                if (e && searchEntities) {
                  if (e.target?.value?.length >= minChars) {
                    searchDelayed(e.target.value);
                  }
                  setInputValue(e.target?.value || '');
                }
              },
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.defaultMuiPrevented = true;
                  onChangeFunction(search, value);
                }
              },
              ...props,
              multiple: false
            }
          : { ...props, multiple: false }
      }
      SelectorProps={SelectorProps}
      options={selectorOptions}
      selectorValue={selectorValue}
      onSelect={setSelectorValue}
      hasSelector={hasSelector}
    />
  );
}

function SearchInputSimple({ avoidCard, style, ...props }) {
  return avoidCard ? (
    <SearchInputSimpleElement {...props} />
  ) : (
    <StyledCard outlined style={style}>
      <SearchInputSimpleElement {...props} />
    </StyledCard>
  );
}

SearchInputSimpleElement.propTypes = {
  searchFamilies: PropTypes.arrayOf(PropTypes.string).isRequired,
  supplyPointTypes: PropTypes.arrayOf(PropTypes.string),
  tenantIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  includeAllItemsOption: PropTypes.bool,
  includeFreeSearch: PropTypes.bool,
  searchEntities: PropTypes.bool,
  minChars: PropTypes.number,
  queryParamKey: PropTypes.string,
  clearLabel: PropTypes.string,
  disabled: PropTypes.bool,
  SelectorProps: PropTypes.shape({}),
  selectorOptions: PropTypes.arrayOf(PropTypes.string),
  selectorValue: PropTypes.string,
  setSelectorValue: PropTypes.func,
  hasSelector: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.arrayOf(PropTypes.string),
  clearInputAfterChange: PropTypes.bool
};

SearchInputSimpleElement.defaultProps = {
  supplyPointTypes: undefined,
  tenantIds: undefined,
  placeholder: null,
  minChars: 2,
  includeAllItemsOption: false,
  includeFreeSearch: true,
  searchEntities: false,
  queryParamKey: null,
  clearLabel: null,
  disabled: false,
  SelectorProps: {},
  selectorOptions: null,
  selectorValue: null,
  setSelectorValue: null,
  hasSelector: false,
  clearInputAfterChange: false,
  value: null,
  inputValue: null
};

SearchInputSimple.propTypes = {
  style: PropTypes.shape({}),
  avoidCard: PropTypes.bool
};

SearchInputSimple.defaultProps = {
  style: {},
  avoidCard: false
};

SearchInputSimple.displayName = 'SearchInputSimple';

export default SearchInputSimple;
