export const STORAGE_KEY_ANALYTICS_DEVICES_VISIBLE_COLS = 'analyticsDevicesVisibleCols_v1';
export const STORAGE_KEY_ANALYTICS_TRANSMITTERS_VISIBLE_COLS =
  'analyticsTransmittersVisibleCols_v1';
export const STORAGE_KEY_DASHBOARD_SELECTED_ITEMS = 'dashboardSelectedItems_v1';
export const STORAGE_KEY_INVENTORY_EQUIPMENTS_VIS_COLS = 'eqipmentsVisibleCols_v1';
export const STORAGE_KEY_INVENTORY_METERS_VIS_COLS = 'metersVisibleCols_v1';
export const STORAGE_KEY_INVENTORY_TRANSMITTERS_VIS_COLS = 'transmittersVisibleCols_v1';
export const STORAGE_KEY_CONSUMS_CHART_TYPE = 'consumptionsChartType_v1';
export const STORAGE_KEY_CONSUMS_CHART_SIDEBAR_TYPE = 'consumptionsChartSidebarType_v1';
export const STORAGE_KEY_CONSUMS_METERS_VISIBLE_COLS = 'consumptionsMetersVisibleCols_v1';
export const STORAGE_KEY_CONSUMS_METERS_INTRADAILY_VISIBLE_COLS =
  'consumptionsMetersIntradailyVisibleCols_v1';
export const STORAGE_KEY_CONSUMS_SITES_VISIBLE_COLS = 'consumptionsSitesVisibleCols_v1';
export const STORAGE_KEY_CONSUMS_SUPPLY_POINTS_VISIBLE_COLS =
  'consumptionsSupplyPointsVisibleCols_v1';
export const STORAGE_KEY_CONSUMS_SECTORS_VISIBLE_COLS = 'consumptionsSectorsVisibleCols_v2';
export const STORAGE_KEY_CONSUMS_SUBSECTORS_VISIBLE_COLS = 'consumptionsSubsectorsVisibleCols_v2';
export const STORAGE_KEY_SIDEBAR_OPEN_SECTIONS = 'sidebarOpenSections_v1';
export const STORAGE_KEY_SIDEBAR_SHOW_MAP = 'sidebarOpenMap_v1';
export const STORAGE_KEY_ALARM_ANALYTICS_VIS_COLS = 'alarmAnalyticsVisibleCols_v1';
export const STORAGE_KEY_ALARM_CUSTOMER_VIS_COLS = 'alarmCustomerVisibleCols_v1';
export const STORAGE_KEY_PULSES_VIS_COLS = 'pulsesVisibleCols_v1';
export const STORAGE_KEY_CONSUMPTIONS_ESTIMATED_VISIBLE = 'estimatedVisible_v1';
export const STORAGE_KEY_COVERAGE_VIS_COLS = 'coverageVisibleCols_v1';
