import { useContext, useEffect, useCallback } from 'react';
import { StorageContext } from '../contexts/Storage';
import useIsMounted from './useIsMounted';
import useUserContext from './useUserContext';
import { existsKey } from '../utils';

const deleteOldLocalStorageKeys = (key) => {
  const [keyWithOutVersion] = key.split('_');
  Object.keys(localStorage)
    .filter(
      (localStorageKey) => localStorageKey.startsWith(keyWithOutVersion) && localStorageKey !== key
    )
    .forEach((localStorageKey) => localStorage.removeItem(localStorageKey));
};

const tryParse = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

const setData = (obj, accountId, data, withAccount) => {
  if (!withAccount) {
    return data;
  }
  if (!obj || Array.isArray(obj)) {
    return {
      [accountId]: data
    };
  }
  return {
    ...obj,
    [accountId]: data
  };
};

const getData = (obj, accountId, withAccount, allowEmpty, initialValue) => {
  if (!withAccount) {
    return obj;
  }
  if (!obj || Array.isArray(obj)) {
    return obj;
  }
  const valueParsed = obj[accountId];
  if (!allowEmpty && (!valueParsed || valueParsed.length === 0)) {
    return initialValue;
  }
  return valueParsed || initialValue;
};

const useLocalStorage = (key, initialValue = '', allowEmpty = true, withAccount = false) => {
  const [appStore, setAppStore] = useContext(StorageContext);
  const { profile } = useUserContext();
  const isMounted = useIsMounted();

  const getInitValue = () => {
    if (existsKey(appStore, key)) {
      return getData(appStore[key], profile.id, withAccount, allowEmpty, initialValue);
    }
    const storedValue = window.localStorage.getItem(key);
    if (storedValue) {
      const valueParsed = tryParse(storedValue);
      if (!allowEmpty && (!valueParsed || valueParsed.length === 0)) {
        return initialValue;
      }
      return getData(valueParsed, profile.id, withAccount, allowEmpty, initialValue);
    }
    return initialValue;
  };

  const value = isMounted ? appStore[key] : getInitValue();

  useEffect(() => {
    if (!existsKey(appStore, key)) {
      deleteOldLocalStorageKeys(key);
      const storedValue = tryParse(window.localStorage.getItem(key));
      const valueToSet = setData(storedValue, profile.id, value, withAccount);
      setAppStore({ [key]: valueToSet });
      window.localStorage.setItem(key, JSON.stringify(valueToSet));
    }
  }, []);

  const setValue = (newValue) => {
    const valueToSet = setData(appStore[key], profile.id, newValue, withAccount);
    setAppStore({ [key]: valueToSet });
    window.localStorage.setItem(key, JSON.stringify(valueToSet));
  };

  const handleStorage = useCallback(
    (e) => {
      if (e.key === key && e.newValue !== value) {
        setValue(tryParse(e.newValue));
      }
    },
    [value]
  );

  useEffect(() => {
    // Necessary to listen storage changes on other browser tabs
    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [handleStorage]);

  return [withAccount && value[profile.id] ? value[profile.id] : value, setValue];
};

export default useLocalStorage;
