import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { useField } from 'react-final-form';
import { Select } from '@rmwc/select';
import { buildOptions, required } from '@acua/common/utils';
import ErrorLabel from '../common/ErrorLabel';
import {
  alarmSubscriptionFrequencyTypes,
  alarmSubscriptionFrequencyTypesI18nKeys
} from '../../../../../common/constants';

function Frequency({ individualNotificationDisabled, setFreq }) {
  const t = useTranslations();
  const frequencyOptions = buildOptions(alarmSubscriptionFrequencyTypesI18nKeys, t);

  const { input, meta } = useField('freq', { validate: required });

  useEffect(() => {
    if (individualNotificationDisabled) {
      input.onChange(alarmSubscriptionFrequencyTypes.DAILY);
      setFreq(alarmSubscriptionFrequencyTypes.DAILY);
    }
  }, []);

  return (
    <div>
      <Select
        {...input}
        id="modals_frequency_input"
        disabled={individualNotificationDisabled}
        onChange={(e) => {
          setFreq(e.target.value);
          if (input.onChange) {
            input.onChange(e);
          }
        }}
        label={t('MODAL__ADD_ALARM_SUBSCRIPTION__FIELD__FREQUENCY')}
        placeholder=""
      >
        {frequencyOptions.map((opt) =>
          individualNotificationDisabled &&
          opt.value === alarmSubscriptionFrequencyTypes.ACTIVATION ? null : (
            <option value={opt.value} key={opt.value} id={`modals_frequency_opt_${opt.value}`}>
              {opt.label}
            </option>
          )
        )}
      </Select>
      {individualNotificationDisabled && <p>{t('ACTIVATION_OPTION_DISABLED_TOOLTIP')}</p>}
      {meta.touched && meta.invalid && <ErrorLabel>{t(meta.error)}</ErrorLabel>}
    </div>
  );
}

Frequency.propTypes = {
  setFreq: PropTypes.func.isRequired,
  individualNotificationDisabled: PropTypes.bool.isRequired
};

export default Frequency;
