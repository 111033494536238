import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCell } from '@rmwc/grid';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext, useAppContext, useUserContext } from '@acua/common/hooks';
import { deviceTypes, permissions } from '@acua/common/constants';
import { PublishService } from '@acua/common/services';
import { Form } from 'react-final-form';
import auth from '@acua/common/auth';
import moment from 'moment';
import Status from '../../components/Status';
import DateField from '../common/DateField';
import TimeField from '../common/TimeField';
import ReadingValueField from './ReadingValueField';
import RecalculateField from './RecalculateField';
import { StyledOverflowDialog } from '../common/elements';

const allowedRecalculateEquipmentTypes = [
  deviceTypes.MODULE_VHF_1G,
  deviceTypes.MODULE_VHF_2G,
  deviceTypes.IMETER_C,
  deviceTypes.HYDROSENSE_METERING,
  deviceTypes.LORA
];

function InsertReading({ data: { row }, ...props }) {
  const t = useTranslations();
  const [insertReadingStatus, setInsertReadingStatus] = useState({});
  const { addSnackbar } = useSnackbarsContext();
  const { productId, inventory, tenantId } = row;
  const { refreshSearchKey } = useAppContext();
  const { getTenant } = useUserContext();
  const {
    profile: {
      preferences: { volumeUnit }
    }
  } = useUserContext();
  const { installationDate, associatedEquipment } = inventory || {};
  const associatedEquipmentType = associatedEquipment && associatedEquipment.productType;
  return (
    <Form
      onSubmit={({ date, time, ...restFields }) => {
        const [hour, min] = time.split(':');
        const readingDate = moment(date, 'DD/MM/YYYY').toDate();
        readingDate.setHours(hour);
        readingDate.setMinutes(min);
        readingDate.setSeconds(0);
        return PublishService.insertReading(productId, tenantId, {
          readingDate: readingDate.toISOString(),
          volumeUnit,
          ...restFields
        })
          .then(() => {
            addSnackbar('SNACKBAR_READING_SENT_SUCCESS');
            refreshSearchKey();
          })
          .catch((error) => {
            setInsertReadingStatus({ error });
            addSnackbar(auth.getFormattedError(error, t));
          });
      }}
      subscription={{ invalid: true }}
    >
      {({ handleSubmit, form }) => (
        <StyledOverflowDialog
          {...props}
          tag="form"
          onAccept={handleSubmit}
          acceptDisabled={form.getState().invalid}
          title={t('INSERT_READING_TITLE')}
          body={
            <>
              <Status data={insertReadingStatus} />
              <Grid>
                <GridCell desktop="6" tablet="4">
                  <DateField initialDate={installationDate} />
                </GridCell>
                <GridCell desktop="6" tablet="4">
                  <TimeField />
                </GridCell>
                <GridCell desktop="12" tablet="8">
                  <ReadingValueField />
                </GridCell>
                <GridCell desktop="12" tablet="8">
                  <RecalculateField
                    disabledRecalculate={allowedRecalculateEquipmentTypes.every(
                      (type) => associatedEquipmentType !== type
                    )}
                    disabledPulses={
                      !getTenant(tenantId)?.capabilities?.includes(
                        permissions.manualWatervoulmeSetOffset
                      )
                    }
                  />
                </GridCell>
              </Grid>
            </>
          }
        />
      )}
    </Form>
  );
}

InsertReading.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      tenantId: PropTypes.string.isRequired,
      inventory: PropTypes.shape({
        installationDate: PropTypes.string.isRequired,
        associatedEquipment: PropTypes.shape({
          productType: PropTypes.string.isRequired
        })
      })
    })
  }).isRequired
};

export default InsertReading;
