import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from '@naui/theme';
import GlobalStyles from './globalStyles';
import variables from './variables';

import '@naui/theme/src/styles.css';

/** RMWC */
import '@rmwc/circular-progress/circular-progress.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/button/styles';
import '@rmwc/card/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/chip/styles';
import '@rmwc/dialog/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/drawer/styles';
import '@rmwc/elevation/styles';
import '@rmwc/fab/styles';
import '@rmwc/grid/styles';
import '@rmwc/formfield/styles';
import '@rmwc/image-list/styles';
import '@rmwc/linear-progress/styles';
import '@rmwc/list/styles';
import '@rmwc/menu/styles';
import '@rmwc/select/styles';
import '@rmwc/radio/styles';
import '@rmwc/slider/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/switch/styles';
import '@rmwc/tabs/styles';
import '@rmwc/textfield/styles';
import '@rmwc/theme/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/typography/styles';
import '@rmwc/avatar/styles';
import '@rmwc/data-table/styles';

/** Other Libraries */
import 'microtip/microtip.css';
import 'nouislider/distribute/nouislider.css';

const muiTheme = createTheme({
  ...theme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 600,
      lg: 1500,
      xl: 2000
    }
  },
  palette: {
    ...theme.palette,
    text: {
      primary: '#283238'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: '#dd3f0e'
    }
  },
  customComponents: {
    ...theme.customComponents,
    NavBar: {
      ...theme.customComponents.NavBar,
      color: '#fff',
      activeColor: '#fff'
    }
  },
  ...variables
});

function ThemeProviders({ children }) {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <GlobalStyles />
      {children}
    </MuiThemeProvider>
  );
}

ThemeProviders.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeProviders;
