import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Typography } from '@rmwc/typography';
import { useTranslations } from '@react-utils/intl';
import CustomSimpleDialog from '../../components/CustomSimpleDialog';
import Messages from './Messages';

const Block = styled.div`
  margin-bottom: 12px;
`;

function ImportDetails({ rowNumber, errors, warnings, completed, ...props }) {
  const t = useTranslations();
  return (
    <CustomSimpleDialog
      {...props}
      cancelLabel={t('BTN_CLOSE')}
      title={t('IMPORT_ERROR_DETAIL_MODAL_TITLE')}
      body={
        <>
          {errors && errors.length > 0 && (
            <Block>
              <Typography use="body1" style={{ display: 'block' }}>
                {`${t('IMPORT_ERROR_DETAIL_MODAL_DESC')} ${rowNumber}:`}
              </Typography>
              <Messages messages={errors} />
            </Block>
          )}
          {warnings && warnings.length > 0 && (
            <Block>
              <Typography use="body1" style={{ display: 'block' }}>
                {`${t('IMPORT_ERROR_DETAIL_MODAL_WARNINGS')} ${rowNumber}:`}
              </Typography>
              <Messages messages={warnings} />
            </Block>
          )}
          {completed && (
            <Block>
              <Typography use="body1" style={{ display: 'block' }}>
                {t('IMPORT_ERROR_DETAIL_MODAL_COMPLETED')}
              </Typography>
              <Messages messages={completed} />
            </Block>
          )}
        </>
      }
    />
  );
}

ImportDetails.propTypes = {
  rowNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      args: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
    })
  ),
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      args: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
    })
  ),
  completed: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      args: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
    })
  )
};

ImportDetails.defaultProps = {
  errors: [],
  warnings: [],
  completed: []
};

export default ImportDetails;
