import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { CircularProgress } from '@rmwc/circular-progress';
import { Typography } from '@rmwc/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3em 0;
`;

const Backdrop = styled.div`
  pointer-events: all;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: ${({ opacity }) => opacity};
  background-color: #fff;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: auto;

  > ${Backdrop} {
    z-index: 1;
  }

  > ${Container} {
    position: relative;
    z-index: 2;
    height: 100%;
    padding: 0;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.textSecondaryOnBackground};
  font-weight: '600';
  margin-left: 8px;
  margin-bottom: 0;
  text-shadow: 0 0 5px #fff, 0 0 1px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #fff;
`;

function Loading({ loading, message, spinnerSize, ...props }) {
  return (
    loading && (
      <Container id="module_loading" {...props}>
        <CircularProgress size={spinnerSize} />
        {(message && typeof message === 'string' && (
          <Title tag="p" use="headline6">
            {message}
          </Title>
        )) ||
          message}
      </Container>
    )
  );
}

Loading.AbsoluteContainer = function ({ loading, withBackdrop, opacity, children, ...props }) {
  return (
    loading && (
      <AbsoluteContainer {...props}>
        {withBackdrop && <Backdrop opacity={opacity} />}
        {children}
      </AbsoluteContainer>
    )
  );
};

Loading.propTypes = {
  message: PropTypes.node,
  spinnerSize: PropTypes.number,
  loading: PropTypes.bool
};

Loading.defaultProps = {
  message: null,
  spinnerSize: 60,
  loading: true
};

Loading.AbsoluteContainer.propTypes = {
  children: PropTypes.node.isRequired,
  withBackdrop: PropTypes.bool,
  opacity: PropTypes.string,
  loading: PropTypes.bool
};

Loading.AbsoluteContainer.defaultProps = {
  withBackdrop: false,
  opacity: '0.6',
  loading: true
};

export default Loading;
