import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import styled from '@emotion/styled';

const StyledSnackbar = styled(Snackbar)`
  display: flex;

  justify-content: center;
  align-items: center;
`;

function NewVersion({ data, deleteSnackbar }) {
  const t = useTranslations();
  const [open, setOpen] = useState(true);

  return (
    <StyledSnackbar
      id="snackbar_content_version"
      open={open}
      onClose={() => {
        setOpen(false);
        deleteSnackbar();
      }}
      message={t('NEW_VERSION_RELEASED', { version: data.version })}
      dismissesOnAction
      action={[<SnackbarAction label={t('MODAL_BTN_ACCEPT')} />]}
      leading
      timeout={1000000}
    />
  );
}

NewVersion.propTypes = {
  data: PropTypes.shape({
    version: PropTypes.string.isRequired
  }).isRequired,
  deleteSnackbar: PropTypes.func.isRequired
};

export default NewVersion;
