import auth from 'nautilus-auth';
import { authSSOUrl, appUrl, productId, projectName } from '../config';
import { getApplicationId } from '../utils';
import { getTenantFromBody, hasGraphqlTokenExpiredError } from './utils';

export const setAuthConfig = () => {
  return auth.initApp({
    clientUrl: appUrl,
    authUrl: authSSOUrl,
    app: projectName,
    productId
  });
};

export const goToLogout = () => auth.goToLogout();

const setOptions = (options = {}) => {
  const instanceId = options.instanceId || getTenantFromBody(options) || getApplicationId();
  return {
    ...options,
    headers: {
      ...(instanceId ? { 'x-instanceid': instanceId } : {}),
      ...(options.headers || {}),
      ...(options.json
        ? {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        : {})
    }
  };
};

export const authFetch = async (url, rawOptions = {}) => {
  try {
    const req = auth.getAuthFetch({})(url, setOptions(rawOptions));
    if (rawOptions.json) {
      const response = await req;
      return response.json();
    }
    return req;
  } catch (e) {
    if (e.json) {
      throw await e.json();
    }
    throw e;
  }
};

export const graphqlAuthFetch = auth.getAuthFetch({
  hasTokenDecodeError: hasGraphqlTokenExpiredError
});
