import { accountUrl } from '../config';

export const getAccessToken = () => sessionStorage.getItem('accessToken');
export const setAccessToken = (token) => sessionStorage.setItem('accessToken', token);
export const resetAccessToken = () => sessionStorage.clear();

const getTenantFromBodyObj = (body) =>
  body.variables?.tenantId || body.variables?.tenants?.[0] || body.variables?.tenantIds?.[0];

export const getTenantFromBody = (options) => {
  if (options.body) {
    if (typeof options.body === 'string') {
      const parseBody = JSON.parse(options.body);
      return getTenantFromBodyObj(parseBody);
    }
    return getTenantFromBodyObj(options.body);
  }
  return null;
};

export const hasGraphqlTokenExpiredError = async (res) => res.status === 444 || res.status === 401;

export const getFormattedError = (error, t) =>
  error.error.data.map((e) => t(e.description, { s1: e.args[0], s2: e.args[1] })).join(',');

export const goToAccount = () => window.open(accountUrl, '_blank');
