import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { Select } from '@rmwc/select';
import { useAppContext } from '@acua/common/hooks';

function TenantSelector({ value, onChange }) {
  const t = useTranslations();
  const { currentApps } = useAppContext();

  return currentApps.length <= 1 ? null : (
    <Select
      label={t('SELECT_TENANTS__LABEL')}
      data-testid="tenant_selector"
      options={currentApps.map(({ id, name }) => ({ label: name, value: id }))}
      value={value}
      onChange={onChange}
    />
  );
}

TenantSelector.defaultProps = {
  value: undefined,
  onChange: () => {}
};

TenantSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default TenantSelector;
