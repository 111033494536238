// eslint-disable-next-line import/prefer-default-export
export const numberFormat = (numberToFormat, options = {}) => {
  if ([null, undefined].includes(numberToFormat)) return null;
  const userLang = 'es-ES';
  const number = Number(numberToFormat);
  const result = number.toLocaleString(userLang, options);
  if (result === 'NaN') return null;
  if (number > 999 && number < 10000 && !result.includes('.')) {
    return `${result.slice(0, 1)}.${result.slice(1)}`;
  }
  return result;
};
