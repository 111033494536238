export const alarmOriginTypes = {
  MANAGER: 'manager',
  CUSTOMER: 'customer',
  ANALYTICS: 'analytics'
};

export const alarmRoleTypes = {
  MANAGER: 'manager',
  CUSTOMER: 'customer',
  TECHNICAL: 'technical'
};

export const devicesAlarmTypes = {
  BACK_FLOW_PULSE_COUNTER_ALARM: 'backFlowPulseCounterAlarm',
  DEVICE_ACCESS_MEMORY_ALARM: 'deviceAccessMemoryAlarm',
  DEVICE_FAULT_ALARM: 'deviceFaultAlarm',
  DEVICE_TAMPERING_ALARM: 'deviceTamperingAlarm',
  DEVICE_MAGNETIC_TAMPERING_ALARM: 'magneticFraudDetectedAlarm',
  DEVICE_LOW_BATTERY_ALARM: 'deviceLowBatteryAlarm',
  LOW_COVERAGE_ALARM: 'lowCoverageAlarm',
  SAMPLING_DISABLED_ALARM: 'samplingDisabledAlarm',
  SENDING_CONFIG_DISABLED_ALARM: 'sendingConfigDisabledAlarm'
};

export const transmittersAlarmTypes = {
  DEVICE_MEMORY_FAIL_ALARM: 'deviceMemoryFailAlarm',
  DEVICE_BATTERY_LOW_ALARM: 'deviceBatteryLowAlarm',
  FILES_MEMORY_FAIL_ALARM: 'filesMemoryFailAlarm',
  LOW_COVERAGE_LEVEL_ALARM: 'lowCoverageLevelAlarm',
  LOW_FRAMES_ALARM: 'lowFramesAlarm',
  MAIN_SUPPLY_FAILURE_ALARM: 'mainSupplyFailureAlarm',
  NTP_SYNC_FAIL_ALARM: 'ntpSyncFailAlarm'
};

export const consumptionsAlarms = {
  CONTINUOUS_CONSUMPTION_ALARM: 'continuousConsumptionAlarm',
  ABSENCE_OF_READING_ALARM: 'absenceOfReadingAlarm',
  CONSUMPTION_INCREASE_ALARM: 'consumptionIncreaseAlarm',
  CONSUMPTION_DECREASE_ALARM: 'consumptionDecreaseAlarm',
  CONSUMPTION_INACTIVITY_ALARM: 'consumptionInactivityAlarm',
  NEGATIVE_CONSUMPTION_ALARM: 'negativeConsumptionAlarm'
};

export const metersAlarmTypes = {
  ...consumptionsAlarms,
  METER_LOW_BATTERY_ALARM: 'meterLowBatteryAlarm'
};

export const alarmConfigurationTypes = {
  ABSENCE_OF_READING: 'absenceOfReading',
  CONSUMPTION_DECREASE: 'consumptionDecrease',
  CONSUMPTION_INACTIVITY: 'consumptionInactivity',
  CONSUMPTION_INCREASE: 'consumptionIncrease',
  CONTINUOUS_CONSUMPTION: 'continuousConsumption',
  NEGATIVE_CONSUMPTION: 'negativeConsumption'
};

export const alarmSubscriptionFrequencyTypes = {
  ACTIVATION: 'activation',
  DAILY: 'daily'
};

export const alarmStatusTypes = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

export const weekdayTypes = {
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THU: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
  SUN: 'sunday'
};

export const criticityTypes = {
  INFO: 'info',
  INFO2: 'informational',
  LOW: 'low',
  MINOR: 'minor',
  HIGH: 'high',
  MAJOR: 'major',
  CRITICAL: 'critical'
};

export const criticityIcons = {
  [criticityTypes.INFO]: 'info',
  [criticityTypes.INFO2]: 'info',
  [criticityTypes.LOW]: 'arrow_downward',
  [criticityTypes.MINOR]: 'arrow_downward',
  [criticityTypes.HIGH]: 'call_made',
  [criticityTypes.MAJOR]: 'call_made',
  [criticityTypes.CRITICAL]: 'arrow_upward'
};

export const criticityColors = {
  [criticityTypes.INFO]: 'colorFontInfo',
  [criticityTypes.INFO2]: 'colorFontInfo',
  [criticityTypes.LOW]: 'colorFontSuccess',
  [criticityTypes.MINOR]: 'colorFontSuccess',
  [criticityTypes.HIGH]: 'colorFontWarning',
  [criticityTypes.MAJOR]: 'colorFontWarning',
  [criticityTypes.CRITICAL]: 'colorFontError'
};

export const subscriptionStatuses = {
  Planned: 'Planned',
  InProgress: 'InProgress',
  Delivered: 'Delivered',
  Failed: 'Failed',
  Cancelled: 'Cancelled'
};

export const subscriptionFrequency = {
  daily: 'daily',
  activation: 'activation'
};

export const activationReasonTypes = {
  DEVICE_ALERT: 'Device alert',
  DAYS_WITHOUT_KEEP_ALIVE: 'days without KeepAlive',
  CONTINUOUS_CONSUMPTION: 'Continuous Consumption',
  NO_CONSUMPTION_CONFIGURED: 'No consumption for configured period',
  CONSUMPTION_BELOW_THRESHOLD: 'Consumption below threshold',
  CONSUMPTION_ABOVE_THRESHOLD: 'Consumption above threshold',
  CONSUMPTION_NEGATIVE: 'Negative consumption and above threshold',
  CONECTION_NO_ACTIVE: 'Conection no active',
  LECTURE_NO_ACTIVE: 'Lecture no active'
};
