import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Typography } from '@rmwc/typography';
import { Select } from '@rmwc/select';
import PropTypes from 'prop-types';
import CustomSimpleDialog from '../../components/CustomSimpleDialog';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSelect = styled(Select)`
  margin-top: 1.5em;
`;

function ImportSelectSite({ data: { callback, sites }, ...props }) {
  const t = useTranslations();
  const [siteValue, setSiteValue] = useState(undefined);

  const sitesOptions = sites.map((item) => ({
    label: item.name,
    value: item.id
  }));

  const confirmSite = () => {
    callback({ siteId: siteValue });
  };

  return (
    <CustomSimpleDialog
      {...props}
      acceptDisabled={!siteValue}
      onAccept={confirmSite}
      title={t('IMPORT_SELECT_SITE__TITLE')}
      body={
        <BodyWrapper>
          <Typography use="body2">{t('IMPORT_SELECT_SITE__SUBTITLE')}</Typography>
          <StyledSelect
            label={t('SITE')}
            value={siteValue}
            options={sitesOptions}
            onChange={(e) => setSiteValue(e.target.value)}
          />
        </BodyWrapper>
      }
    />
  );
}

ImportSelectSite.propTypes = {
  data: PropTypes.shape({
    callback: PropTypes.func.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
      })
    ).isRequired
  }).isRequired
};

export default ImportSelectSite;
