import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { Grid, GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';
import { useAppContext, useProduct, useUserContext } from '../../../../../../common/hooks';
import { Loading } from '../../../../../../common/components';
import Status from '../../../components/Status';
import { StyledOverflowMarginDialog } from '../../common/elements';
import Site from '../Site';
import Tenant from '../Tenant';
import useSendOtherTenant from '../useSendOtherTenant';
import DateField from '../../common/DateField';
import TimeField from '../../common/TimeField';
import { getProductById } from '../getProductById.gql';
import { extractThresholdDate } from '../utils';

function ChangeTenant({ data: { row }, ...props }) {
  const t = useTranslations();
  const { currentApps } = useAppContext();
  const { allSites } = useUserContext();
  const { sendData, modalError } = useSendOtherTenant(row.productId);

  const { data, loading } = useProduct(row.id, getProductById);
  const startDate = extractThresholdDate(row, data);

  return (
    <Form
      onSubmit={(values) => sendData({ ...values, withPeer: true })}
      subscription={{ invalid: true }}
    >
      {({ form, handleSubmit }) => (
        <StyledOverflowMarginDialog
          {...props}
          tag="form"
          title={t('PRODUCT_ACTION_CHANGE_TENANT')}
          onAccept={handleSubmit}
          acceptLabel={t('SEND')}
          acceptDisabled={form.getState().invalid}
        >
          <Status data={modalError} />
          <span>{t('CHANGE_TENANT_MODAL_TEXT')}</span>
          <Tenant tenantId={row.tenantId} tenants={currentApps} productType={row.productType} />
          <Site sites={allSites} />
          <Typography tag="p" use="body2" style={{ marginBottom: '0px' }}>
            {t('CHANGE_TENANT_MODAL_TEXT_DATE')}
          </Typography>
          <Grid style={{ marginTop: '0px' }}>
            <GridCell desktop="6" tablet="4">
              <DateField initialDate={startDate} />
            </GridCell>
            <GridCell desktop="6" tablet="4">
              <TimeField />
            </GridCell>
          </Grid>
          {loading && (
            <Loading.AbsoluteContainer withBackdrop>
              <Loading message={t('LOADING_MESSAGE')} spinnerSize={30} />
            </Loading.AbsoluteContainer>
          )}
        </StyledOverflowMarginDialog>
      )}
    </Form>
  );
}

ChangeTenant.propTypes = {
  data: PropTypes.shapeOf({
    row: PropTypes.shapeOf({
      productId: PropTypes.string
    })
  }).isRequired
};
export default ChangeTenant;
