import { navigate } from '@reach/router';
import { userDocsTranslationLanguages, userDocsUrl } from '@acua/common/config';
import HomeIcon from '@mui/icons-material/Home';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import MemoryIcon from '@mui/icons-material/Memory';
import RouterIcon from '@mui/icons-material/Router';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';

export default (t, drawerClose, userLang) => {
  const onSectionClickByPath = (sectionPath) => () => {
    if (window.location.pathname !== sectionPath) {
      navigate(sectionPath);
    }
    drawerClose();
  };
  const isSelected = (page) => {
    const pathnames = window.location.pathname.split('/');
    if (page.includes('/')) return `${pathnames[1]}/${pathnames[2]}` === page;
    return pathnames[1] === page;
  };

  return [
    {
      id: 'DRAWER_ITEM_HOME_TITLE',
      text: t('DRAWER_ITEM_HOME_TITLE'),
      Icon: HomeIcon,
      onClick: onSectionClickByPath('/'),
      active: isSelected('')
    },
    {
      id: 'DRAWER_ITEM_CONSUMPTION_TITLE',
      text: t('DRAWER_ITEM_CONSUMPTION_TITLE'),
      Icon: ShowChartIcon,
      onClick: onSectionClickByPath('/consumptions'),
      active: isSelected('consumptions')
    },
    {
      id: 'DRAWER_ITEM_ALARMS_TITLE',
      text: t('DRAWER_ITEM_ALARMS_TITLE'),
      Icon: NotificationsActiveOutlinedIcon,
      onClick: onSectionClickByPath('/alarms'),
      active: isSelected('alarms')
    },
    {
      id: 'DRAWER_ITEM_DEVICES_TITLE',
      text: t('DRAWER_ITEM_DEVICES_TITLE'),
      Icon: MemoryIcon,
      onClick: onSectionClickByPath('/devices'),
      active: isSelected('devices')
    },
    {
      id: 'DRAWER_ITEM_TRANSMITTERS_TITLE',
      text: t('DRAWER_ITEM_TRANSMITTERS_TITLE'),
      Icon: RouterIcon,
      onClick: onSectionClickByPath('/transmitters'),
      active: isSelected('transmitters')
    },
    {
      id: 'DRAWER_ITEM_ANALYTICS_TITLE',
      text: t('DRAWER_ITEM_ANALYTICS_TITLE'),
      Icon: DonutLargeIcon,
      onClick: onSectionClickByPath('/analytics'),
      active: isSelected('analytics')
    },
    {
      id: 'DRAWER_ITEM_DEVICE_METRICS_TITLE',
      text: t('DRAWER_ITEM_DEVICE_METRICS_TITLE'),
      Icon: InsertChartIcon,
      onClick: onSectionClickByPath('/metrics/pulses'),
      active: isSelected('metrics')
    },
    {
      id: 'DRAWER_ITEM_FILE_MANAGER_TITLE',
      text: t('DRAWER_ITEM_FILE_MANAGER_TITLE'),
      Icon: SwapVertIcon,
      onClick: onSectionClickByPath('/file-manager'),
      active: isSelected('file-manager')
    },
    {
      id: 'DRAWER_ITEM_USER_DOCS_TITLE',
      text: t('DRAWER_ITEM_USER_DOCS_TITLE'),
      Icon: ChromeReaderModeIcon,
      active: false,
      onClick: () => {
        const locale = userLang.split('-')[0];
        if (userDocsTranslationLanguages.includes(locale)) {
          window.open(`${userDocsUrl}/${locale}/`, '_blank')?.focus();
        } else {
          window.open(`${userDocsUrl}/`, '_blank')?.focus();
        }
        drawerClose();
      }
    }
  ];
};
