import React from 'react';
import Microtip from './microtip';
import Nautilus from './nautilus';
import NoUiSlider from './noUiSlider';
import Recharts from './recharts';
import Rmwc from './rmwc';

export default function () {
  return (
    <>
      <Rmwc />
      <Microtip />
      <Nautilus />
      <NoUiSlider />
      <Recharts />
    </>
  );
}
