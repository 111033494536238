import { consumptionOriginTypesI18nKeys } from '@acua/common/constants';
import { formatDateTime, FORMAT_DATE_TIME, numberFormat } from '@acua/common/utils';
import moment from 'moment-timezone';
import { getVolumeUnitKey } from '../../../../../common/units';
import { productTypesAnonymize } from '../../../../../common/utils/anonymize/anonymize';
import meterCommonColumns from './meterCommonColumns';

const meterIntradayColumns = (t, { volumeUnit, getTenant, timeZone }) => [
  {
    dataKey: 'meterSerialNumber',
    label: t('RESULTS_TABLE_COL_METER_ID'),
    isSortable: true,
    anonymize: (value, { productType }) => productTypesAnonymize[productType](value),
    isToggleable: true,
    cellProps: {
      style: { minWidth: 150 }
    },
    excelWidth: 20,
    exportSourceName: 'meter'
  },
  {
    dataKey: 'equipment.serialNumber',
    label: t('RESULTS_TABLE_COL_ASSOCIATED_EQUIPMENT_ID'),
    isSortable: true,
    anonymize: (value, row) => productTypesAnonymize[row?.equipment?.technology](value),
    isToggleable: true,
    cellProps: {
      style: { minWidth: 150 }
    },
    excelWidth: 20,
    exportSourceName: 'device'
  },
  ...meterCommonColumns(t),
  {
    dataKey: 'sampleDate',
    label: t('RESULTS_TABLE_COL_DATE_TIME'),
    isSortable: true,
    isToggleable: false,
    renderCell: (row) =>
      formatDateTime(
        row.sampleDate,
        undefined,
        row.tenantId ? getTenant(row.tenantId)?.settings?.data?.timeZone : timeZone
      ),
    cellProps: {
      style: {
        minWidth: 150,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    },
    excelWidth: 20,
    exportSourceName: 'readingDate',
    format: {
      type: 'date',
      pattern: FORMAT_DATE_TIME,
      additionalInfo: JSON.stringify({ timeZone: moment.tz.guess() })
    }
  },
  {
    dataKey: 'reading',
    label: `${t('RESULTS_TABLE_COL_READING')} (${t(getVolumeUnitKey(volumeUnit))})`,
    isSortable: true,
    isToggleable: false,
    renderCell: (row) => numberFormat(row.reading),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'readingValue',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },
  {
    dataKey: 'value',
    label: `${t('RESULTS_TABLE_COL_CONSUMPTION')} (${t(getVolumeUnitKey(volumeUnit))})`,
    isSortable: true,
    isToggleable: false,
    renderCell: (row) => numberFormat(row.value),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'consumptionValue',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },
  {
    dataKey: 'origin',
    label: t('RESULTS_TABLE_COL_CONSUMPTION_TYPE'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) => t(`RESULTS_TABLE_COL_CONSUMPTION_TYPE_${row.origin.toUpperCase()}`),
    customMapping: Object.keys(consumptionOriginTypesI18nKeys).map((key) => ({
      key,
      value: t(consumptionOriginTypesI18nKeys[key])
    })),
    excelWidth: 20,
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    }
  },
  {
    dataKey: 'invalidityReason',
    label: t('RESULTS_TABLE_COL_INVALIDITY_REASON'),
    isSortable: false,
    isToggleable: true,
    cellProps: {
      style: {
        minWidth: 150
      }
    },
    excelWidth: 40,
    renderCell: (row) =>
      row.invalidityReason &&
      t(`INVALID_ERROR_${row.invalidityReason.toUpperCase()}`) !==
        `INVALID_ERROR_${row.invalidityReason.toUpperCase()}`
        ? t(`INVALID_ERROR_${row.invalidityReason.toUpperCase()}`)
        : ''
  }
];

export default meterIntradayColumns;
