import React, { useEffect, useState } from 'react';
import { Loading } from '@acua/common/components';
import auth from '@acua/common/auth';
import withContextProviders from '../withContextProviders';
import App from './App';

export default function (props) {
  const [tokenLoading, setTokenLoading] = useState(true);

  useEffect(() => {
    auth.setAuthConfig().then((data) => {
      if (data) setTokenLoading(false);
    });
  }, []);

  if (tokenLoading) {
    return (
      <Loading.AbsoluteContainer>
        <Loading spinnerSize={80} />
      </Loading.AbsoluteContainer>
    );
  }

  const AppComponent = withContextProviders(App);
  return AppComponent ? <AppComponent {...props} /> : null;
}
