import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

export const ModalsContext = createContext();

const openModal = (modals, payload) => [
  ...modals,
  {
    id: Date.now(),
    ...payload
  }
];

const deleteModal = (modals, id) => modals.filter((modal) => modal.id !== id);

export function ModalsProvider({ children }) {
  const [modals, setModals] = useState([]);
  const modalsContainerRef = useRef();
  return (
    <ModalsContext.Provider
      value={{
        modals,
        modalsContainerRef, // useful for Portal component
        openModal: (payload) => setModals(openModal(modals, payload)),
        deleteModal: (id) => setModals(deleteModal(modals, id))
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}

ModalsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
