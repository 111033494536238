import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import {
  LayerContainerClosed,
  LayerOption,
  CustomSwitch,
  TitlePopUp,
  LayerContent,
  StyledLayersIcon
} from './elements';

function LayerMenu({ layerOptions, triggerChange }) {
  const t = useTranslations();
  const [layerOpen, setLayerOpen] = useState(false);

  return layerOptions.length ? (
    <LayerContainerClosed className="map-layer-menu">
      <TitlePopUp onClick={() => setLayerOpen(!layerOpen)}>
        <StyledLayersIcon />
        {t('MAP_TITLE_LAYERS')}
      </TitlePopUp>
      {layerOpen && (
        <LayerContent>
          {layerOptions.map((option) => (
            <LayerOption key={option.label}>
              {t(option.label)}
              <CustomSwitch
                checked={option.checked}
                disabled={option.disabled}
                onChange={(e) => {
                  option.onChange(!!e.currentTarget.checked);
                  triggerChange({ ...option, checked: !!e.currentTarget.checked });
                }}
              />
            </LayerOption>
          ))}
        </LayerContent>
      )}
    </LayerContainerClosed>
  ) : null;
}

LayerMenu.propTypes = {
  triggerChange: PropTypes.func,
  layerOptions: PropTypes.arrayOf(
    PropTypes.shapeOf({
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.fn,
      disabled: PropTypes.bool
    })
  )
};

LayerMenu.defaultProps = {
  layerOptions: [],
  triggerChange: () => {}
};

export default LayerMenu;
