import { consumptionOriginTypesI18nKeys, showEstimated } from '@acua/common/constants';
import { formatDateTime, FORMAT_DATE_TIME, numberFormat } from '@acua/common/utils';
import moment from 'moment-timezone';
import { getVolumeUnitKey } from '../../../../../common/units';
import { productTypesAnonymize } from '../../../../../common/utils/anonymize/anonymize';
import meterCommonColumns from './meterCommonColumns';

const meterColumns = (t, { volumeUnit, volumeFlowUnit, getTenant, enableEstimated }) => [
  {
    dataKey: 'meterSerialNumber',
    label: t('RESULTS_TABLE_COL_METER_ID'),
    isSortable: true,
    isToggleable: true,
    anonymize: (value, { productType }) => productTypesAnonymize[productType](value),
    cellProps: {
      style: { minWidth: 150 }
    },
    excelWidth: 20,
    exportSourceName: 'meter'
  },
  {
    dataKey: 'equipment.serialNumber',
    label: t('FAMILY_TYPE_DEVICE'),
    isSortable: true,
    isToggleable: true,
    anonymize: (value, row) => productTypesAnonymize[row?.equipment?.technology](value),
    cellProps: {
      style: { minWidth: 120 }
    },
    excelWidth: 20,
    exportSourceName: 'device'
  },
  ...meterCommonColumns(t),
  {
    dataKey: 'sampleDate',
    label: t('RESULTS_TABLE_COL_DATE_TIME'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      formatDateTime(
        enableEstimated && row.estimatedValues?.sampleTime
          ? row.estimatedValues.sampleTime
          : row.sampleDate,
        undefined,
        getTenant(row.tenantId)?.settings?.data?.timeZone
      ),
    cellProps: {
      style: {
        minWidth: 150,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    },
    excelWidth: 20,
    exportSourceName: 'readingDate',
    format: {
      type: 'date',
      pattern: FORMAT_DATE_TIME,
      additionalInfo: JSON.stringify({ timeZone: moment.tz.guess() })
    }
  },
  {
    dataKey: 'reading',
    label: `${t('RESULTS_TABLE_COL_READING')} (${t(getVolumeUnitKey(volumeUnit))})`,
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      numberFormat(
        enableEstimated &&
          row.estimatedValues?.reading !== undefined &&
          row.estimatedValues?.reading !== null
          ? row.estimatedValues.reading || 0
          : row.reading || 0
      ),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'readingValue',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },
  {
    dataKey: 'value',
    label: `${t('RESULTS_TABLE_COL_CONSUMPTION')} (${t(getVolumeUnitKey(volumeUnit))})`,
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      numberFormat(
        enableEstimated &&
          row.estimatedValues?.value !== undefined &&
          row.estimatedValues?.value !== null
          ? row.estimatedValues.value || 0
          : row.value || 0
      ),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'consumptionValue',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },

  {
    dataKey: 'qMin.value',
    label: `${t('RESULTS_TABLE_COL_QMIN')} (${volumeFlowUnit})`,
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      numberFormat(
        enableEstimated &&
          row.estimatedValues?.qMin?.value !== undefined &&
          row.estimatedValues?.qMin?.value !== null
          ? row.estimatedValues.qMin.value || 0
          : row.qMin.value || 0
      ),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'qMin',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },
  {
    dataKey: 'qMin.sampleTime',
    label: t('EXPORT_COL_QMIN_DATE'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      formatDateTime(
        enableEstimated && row.estimatedValues?.qMin?.sampleTime
          ? row.estimatedValues?.qMin?.sampleTime
          : row.qMin?.sampleTime,
        undefined,
        getTenant(row.tenantId)?.settings?.data?.timeZone
      ),
    cellProps: {
      style: {
        minWidth: 150,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    },
    excelWidth: 20,
    exportSourceName: 'qMinSampleTime',
    format: {
      type: 'date',
      pattern: FORMAT_DATE_TIME,
      additionalInfo: JSON.stringify({ timeZone: moment.tz.guess() })
    }
  },
  {
    dataKey: 'qMax.value',
    label: `${t('RESULTS_TABLE_COL_QMAX')} (${volumeFlowUnit})`,
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      numberFormat(
        enableEstimated &&
          row.estimatedValues?.qMax?.value !== undefined &&
          row.estimatedValues?.qMax?.value !== null
          ? row.estimatedValues.qMax.value || 0
          : row.qMax.value || 0
      ),
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'qMax',
    format: {
      type: 'volumeUnit',
      pattern: volumeUnit
    }
  },
  {
    dataKey: 'qMax.sampleTime',
    label: t('EXPORT_COL_QMAX_DATE'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      formatDateTime(
        enableEstimated && row.estimatedValues?.qMax?.sampleTime
          ? row.estimatedValues?.qMax?.sampleTime
          : row.qMax?.sampleTime,
        undefined,
        getTenant(row.tenantId)?.settings?.data?.timeZone
      ),
    cellProps: {
      style: {
        minWidth: 150,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    },
    excelWidth: 20,
    exportSourceName: 'qMaxSampleTime',
    format: {
      type: 'date',
      pattern: FORMAT_DATE_TIME,
      additionalInfo: JSON.stringify({ timeZone: moment.tz.guess() })
    }
  },
  {
    dataKey: 'origin',
    label: t('RESULTS_TABLE_COL_CONSUMPTION_TYPE'),
    isSortable: true,
    isToggleable: true,
    cellProps: {
      style: {
        minWidth: 120,
        textAlign: 'center',
        justifyContent: 'center'
      },
      title: null
    },
    excelWidth: 20,
    renderCell: (row) =>
      showEstimated(row, enableEstimated)
        ? t('RESULTS_TABLE_COL_CONSUMPTION_TYPE_ESTIMATED')
        : t(`RESULTS_TABLE_COL_CONSUMPTION_TYPE_${row.origin.toUpperCase()}`),
    customMapping: Object.keys(consumptionOriginTypesI18nKeys).map((key) => ({
      key,
      value: t(consumptionOriginTypesI18nKeys[key])
    }))
  },
  {
    dataKey: 'estimatedValues.realPercentage',
    label: t('RESULTS_TABLE_COL_CONSUMPTION_ESTIMATED_PERCENTAGE'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) =>
      row.estimatedValues?.realPercentage !== undefined &&
      row.estimatedValues?.realPercentage !== null
        ? `${numberFormat(100 - +row.estimatedValues.realPercentage, {
            maximumSignificantDigits: 4
          })}%`
        : '',
    cellProps: {
      style: {
        minWidth: 130,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 20,
    exportSourceName: 'estimatedValuesRealPercentage',
    format: {
      type: 'numeric'
    }
  }
];

export default meterColumns;
