import { pathEq } from 'ramda';
import { formatDateTime } from '../dates';

export const isErrorNotFound = pathEq(
  ['graphQLErrors', 0, 'extensions', 'response', 'status'],
  404
);

const ISO_DATE_REGEX =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$/;

export const translateAndFormat = (t, message, args) => {
  const formattedArgs =
    args &&
    args.reduce((acc, item, idx) => {
      const key = `s${idx + 1}`;
      if (Array.isArray(item)) {
        return { ...acc, [key]: item.map((val) => t(val) || val).join(', ') };
      }
      if (typeof item !== 'string') {
        return { ...acc, [key]: JSON.stringify(item) };
      }
      if (item.split(',').length > 1) {
        return {
          ...acc,
          [key]: item
            .split(',')
            .map((val) => t(val) || val)
            .join(', ')
        };
      }
      if (item.match(ISO_DATE_REGEX)) {
        return { ...acc, [key]: formatDateTime(item) };
      }
      return { ...acc, [key]: t(item.toUpperCase()) || item };
    }, {});
  return t(message, formattedArgs);
};
