import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Typography } from '@rmwc/typography';
import { useTranslations } from '@react-utils/intl';
import { ErrorBoundary } from 'react-error-boundary';
import { withProps } from 'recompose';
import ErrorSvg from './ErrorSvg';

const Container = styled.div`
  text-align: center;
  position: relative;
`;

const Details = styled.details`
  white-space: pre-wrap;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
`;

function FallbackComponent({ componentStack, error }) {
  const t = useTranslations();
  return (
    <Container>
      <ErrorSvg />
      <Typography tag="p" use="body1" theme="textSecondaryOnBackground">
        {t('INTERNAL_SERVER_ERROR')}
      </Typography>
      {process.env.NODE_ENV === 'development' && (
        <Details>
          <p>{error.toString()}</p>
          <p>{componentStack}</p>
        </Details>
      )}
    </Container>
  );
}

FallbackComponent.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  componentStack: PropTypes.string.isRequired
};

export default withProps({ FallbackComponent })(ErrorBoundary);
