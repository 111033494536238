import styled from '@emotion/styled';
import { withProps } from 'recompose';
import { Typography } from '@rmwc/typography';

export default withProps({
  children: process.env.VERSION,
  tag: 'p',
  use: 'caption'
})(styled(Typography)`
  opacity: 0.25;
  position: fixed;
  bottom: 0;
  text-align: center;
  margin: 2px 0;
  margin-left: -20px;
  width: fit-content;
  left: 50%;
  font-size: 10px;
  z-index: -2;
`);
