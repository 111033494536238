import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext, useModalsContext, useAppContext } from '@acua/common/hooks';
import { productStatus, productSubstatus, modalTypes } from '@acua/common/constants';
import { ProductsService } from '@acua/common/services';
import CustomSimpleDialog from '../components/CustomSimpleDialog';

function TestProduct({ data: { row }, ...props }) {
  const t = useTranslations();
  const { addSnackbar } = useSnackbarsContext();
  const { openModal } = useModalsContext();
  const { refreshSearchKey } = useAppContext();
  return (
    <CustomSimpleDialog
      {...props}
      onAccept={() =>
        ProductsService.update(row.productId, row.tenantId, {
          status: productStatus.INSTALLED,
          substatus: productSubstatus.TEST
        })
          .then(() => {
            addSnackbar('SNACKBAR_SAVE_SUCCESS');
            refreshSearchKey();
          })
          .catch((error) => {
            openModal({ type: modalTypes.ERROR_DIALOG, data: { error } });
            throw error;
          })
      }
      title={t('MODAL_CHANGE_SUBSTATUS_TITLE', { substatus: t('PRODUCT_SUBSTATUS_TEST') })}
    />
  );
}

TestProduct.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      tenantId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default TestProduct;
