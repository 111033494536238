import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '@react-utils/intl';

import { DateRangePickerMaterial } from '@acua/common/components';
import { useAppContext } from '@acua/common/hooks';
import { FormContainer, TenantSelector, SearchInput } from './components';

function Diary({
  state: {
    tenantId,
    search,
    dateRange: { startDate, endDate }
  },
  setState
}) {
  const t = useTranslations();
  const { currentApps } = useAppContext();

  useEffect(() => {
    if (currentApps.length === 1) {
      setState({ tenantId: currentApps[0].id });
    }
  }, []);

  const disabled = currentApps.length > 1 && !tenantId;
  return (
    <FormContainer>
      <TenantSelector
        value={tenantId}
        onChange={(event) => setState({ tenantId: event.currentTarget.value, search: [] })}
      />
      <SearchInput
        placeholder={t('SEARCH_DEVICES__LABEL')}
        entities={search}
        onChange={(event) => setState({ search: event })}
        disabled={disabled}
        tenantIds={[tenantId]}
      />
      {!disabled && <span>{t('SEARCH_DEVICES__HINT')}</span>}
      <DateRangePickerMaterial
        value={{
          startDate,
          endDate
        }}
        onChange={(newDates) => setState({ dateRange: newDates })}
        maxRange={[1, 'year']}
      />
    </FormContainer>
  );
}

Diary.propTypes = {
  state: PropTypes.shape({
    tenantId: PropTypes.string,
    search: PropTypes.array,
    dateRange: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })
  }).isRequired,
  setState: PropTypes.func.isRequired
};

export default Diary;
