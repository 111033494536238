import { Card } from '@rmwc/card';
import styled from '@emotion/styled';

const BORDER_RADIUS = 6;

export const AutocompleteItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.textPrimaryOnDark};
  width: 100%;
  position: sticky;
  bottom: 0;
`;

export const AutocompleteItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

export const ColorChips = styled('span')`
  span {
    ${({ color }) =>
      color
        ? `
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: ${BORDER_RADIUS * 2}px;
    height: ${BORDER_RADIUS * 2}px;
    border-radius: ${BORDER_RADIUS}px;
    background-color: ${color};
    border: 1px solid #666;
    box-sizing: border-box;
    `
        : 'display: none'};
  }
  opacity: ${({ value, selecteditem }) => (selecteditem && value !== selecteditem ? '0.3' : '1')};
  cursor: ${({ selecteditem }) => (selecteditem ? 'pointer' : 'inherit')};
`;

export const StyledCard = styled(Card)`
  flex: 1;
  margin: 24px 0 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border: 0px solid #666;
`;

export const StyledItem = styled('li')`
  margin: 0;
  padding: 0 !important;
  height: 100%;
  min-height: 40px;
  border-bottom: solid 1px #dadada;
  ${({ stickyLastChild }) =>
    stickyLastChild
      ? `
  &:last-child {
    position: sticky;
    bottom: 0;
  }
  `
      : ''}
`;
