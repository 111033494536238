import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useUserContext from '../hooks/useUserContext';
import { getFeatureFlags } from '../utils/featureFlags';

export const FeatureFlagContext = createContext();

export function FeatureFlagProvider({ children }) {
  const {
    profile: { email }
  } = useUserContext();
  const [featureFlags, setFeatureFlags] = useState({});

  useEffect(() => {
    if (email) {
      const userObject = {
        identifier: email
      };
      getFeatureFlags(userObject).then((flags) => setFeatureFlags(flags));
    }
  }, [email]);

  return (
    <FeatureFlagContext.Provider value={{ featureFlags }}>{children}</FeatureFlagContext.Provider>
  );
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node.isRequired
};
