import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { translateAndFormat } from '@acua/common/utils';
import { TotalInfo, TitlePopUp, StyledLocationOnIcon, StyledLocationLoading } from './elements';

function Total({ totalCount }) {
  const t = useTranslations();
  return totalCount ? (
    <TotalInfo className="map-total">
      {totalCount.tenantsLoaded === totalCount.totalTenants ? (
        <StyledLocationOnIcon />
      ) : (
        <StyledLocationLoading />
      )}
      <div style={{ paddingRight: '20px' }}>
        <TitlePopUp style={{ paddingRight: '4px' }}>{`${t('MAP_TOTAL_ROWS')}: `}</TitlePopUp>
        <TitlePopUp style={{ fontWeight: 100 }}>
          {(totalCount.totalRows || 0).toLocaleString()}
        </TitlePopUp>
        <p
          style={{
            margin: 0,
            fontSize: '12px',
            opacity: '0.7',
            marginTop: '2px',
            fontWeight: 'normal'
          }}
        >
          {translateAndFormat(t, 'MAP_TENANTS_DOWNLOAD', [
            totalCount.tenantsLoaded,
            totalCount.totalTenants
          ])}
        </p>
      </div>
    </TotalInfo>
  ) : null;
}

Total.propTypes = {
  totalCount: PropTypes.shapeOf({
    totalRows: PropTypes.number.isRequired,
    totalTenants: PropTypes.number.isRequired,
    tenantsLoaded: PropTypes.number.isRequired
  })
};

Total.defaultProps = {
  totalCount: null
};

export default Total;
