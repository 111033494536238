import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';

import moment from 'moment';
import styled from '@emotion/styled';
import { TimePicker } from '@naui/core';
import { FORMAT_TIME } from '@acua/common/utils';

const Container = styled.div`
  position: relative;
  height: 56px;
`;

const TimeInput = styled(TimePicker)`
  input {
    height: 35px;
    width: 200px;
  }
`;

function TimePickerInput({ value, onChange, disabled, inputProps, timePickerProps, ...props }) {
  const t = useTranslations();
  const [timePickerValue, setTimePickerValue] = useState();

  useEffect(() => {
    const parsedValue =
      typeof value === 'string' && value.length ? moment(value, FORMAT_TIME) : value;
    setTimePickerValue(parsedValue || null);
  }, [value]);

  return (
    <Container id="date_picker_input" {...props}>
      <TimeInput
        label={t('FIELD_HOUR')}
        value={timePickerValue}
        onChange={(time) => {
          if (!time) {
            onChange(null);
            return;
          }
          const [hours, mins] = time.split(':');
          onChange({
            time,
            hour: hours,
            minute: mins
          });
        }}
        renderTimeFormat="HH:mm"
        disabled={disabled}
        timePickerInputProps={{ height: '200px' }}
        withClearButton={false}
      />
    </Container>
  );
}

TimePickerInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    helpText: PropTypes.shape({
      persistent: PropTypes.bool,
      validationMsg: PropTypes.bool,
      children: PropTypes.node
    }),
    invalid: PropTypes.bool
  }),
  timePickerProps: PropTypes.shape()
};

TimePickerInput.defaultProps = {
  value: '',
  onChange: (newTime) => newTime,
  disabled: false,
  inputProps: {},
  timePickerProps: {}
};

export default TimePickerInput;
