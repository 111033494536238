import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext, useAppContext, useProduct } from '@acua/common/hooks';
import { deviceTypes, entityTypes } from '@acua/common/constants';
import { ProductsService } from '@acua/common/services';
import { getReducedArrKeys } from '@acua/common/utils';
import { Loading, ErrorRefetch } from '@acua/common/components';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell } from '@rmwc/grid';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { Form } from 'react-final-form';
import moment from 'moment';
import useWarehouses from './useWarehouses';
import { StyledOverflowDialog } from '../common/elements';
import Status from '../../components/Status';
import TransmitterDown from './TransmitterDown';
import { getProductById } from './getProductById.gql';
import DeviceDown from '../common/DeviceDown';
import DateField from '../common/DateField';
import TimeField from '../common/TimeField';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentField = styled(TextField)`
  margin-top: 12px;
  textarea {
    resize: none !important;
    border: solid 0.5px #d0d2d3;
  }
`;

const infoMessages = {
  [entityTypes.TRANSMITTER]: 'MODAL_INFO_TRANSMITTER_NO_ASSOC',
  [entityTypes.DEVICES]: 'MODAL_INFO_EQUIPMENT_NO_ASSOC',
  [entityTypes.METERS]: 'MODAL_INFO_EQUIPMENT_NO_ASSOC'
};

const extractThresholdDate = (data, inventory) => {
  const associationDate = path(['product', 'inventory', 'associationDate'], data);
  return [inventory?.installationDate, associationDate, inventory?.creationDate]
    .filter((e) => e)
    .sort()
    .pop();
};

function SendProductToWarehouse({
  data: {
    entityType,
    row: { id, productId, productType, inventory, tenantId }
  },
  ...props
}) {
  const t = useTranslations();
  const isBus = [deviceTypes.BUSVHF_V1, deviceTypes.BUSNBIOT].includes(productType);
  const [commentsValue, setCommentsValue] = useState(inventory?.comments || '');
  const [meterLinked, setMeterLinked] = useState(!isBus);
  const simLinked = true;
  const [modemLinked, setModemLinked] = useState(true);
  const [sendToWarehouseStatus, setSendToWarehouseStatus] = useState({});
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  const {
    warehouses,
    warehouseOptions,
    queryStatus: warehousesQueryStatus
  } = useWarehouses(tenantId);
  const [warehouseValue, setWarehouseValue] = useState(null);
  const { data, loading, error, refetch } = useProduct(id, getProductById);
  const startDate = extractThresholdDate(data, inventory);

  useEffect(() => {
    if (warehouses.length && !warehouseValue) {
      setWarehouseValue(warehouses[0].name);
    }
  }, [warehouses]);

  const showFeedbackAndRefresh = () => {
    addSnackbar('SNACKBAR_SAVE_SUCCESS');
    refreshSearchKey();
  };

  return (
    <Form
      onSubmit={({ date, time }) => {
        const [hour, minute] = time.split(':');
        const downDate = moment(date, 'DD/MM/YYYY').toDate();

        downDate.setHours(hour, minute, 0);

        const body = {
          downDate,
          ...(warehouseValue ? { warehouse: warehouseValue } : {}),
          ...(commentsValue ? { comments: commentsValue } : {}),
          unlinkedSlaveProductTypes:
            data?.product?.inventory?.linkeds?.length > 0
              ? getReducedArrKeys({
                  sim: !simLinked,
                  modem: !modemLinked,
                  mMeter: !meterLinked,
                  mMeterSPDE: !meterLinked,
                  eMeter: !meterLinked,
                  eMeterSPDE: !meterLinked
                })
              : undefined
        };

        ProductsService.sendToWarehouse(productId, tenantId, body)
          .then(showFeedbackAndRefresh)
          .catch((err) => {
            setSendToWarehouseStatus({ error: err });
            throw err;
          });
      }}
      subscription={{ invalid: true }}
    >
      {({ handleSubmit, form }) => (
        <StyledOverflowDialog
          {...props}
          acceptDisabled={
            error ||
            warehousesQueryStatus.error ||
            loading ||
            warehousesQueryStatus.loading ||
            (warehouses.length > 0 && !warehouseValue) ||
            form.getState().invalid
          }
          onAccept={handleSubmit}
          title={t('MODAL_CHANGE_STATUS_TITLE', { status: t('PRODUCT_STATUS_WAREHOUSE') })}
          body={
            <BodyWrapper>
              <Status data={sendToWarehouseStatus} />
              {(loading || warehousesQueryStatus.loading) && (
                <Loading.AbsoluteContainer withBackdrop>
                  <Loading message={t('LOADING_MESSAGE')} spinnerSize={30} />
                </Loading.AbsoluteContainer>
              )}
              {error && <ErrorRefetch refetch={refetch} />}
              {warehousesQueryStatus.error && (
                <ErrorRefetch refetch={warehousesQueryStatus.refetch} />
              )}
              {warehouses.length > 0 && (
                <>
                  <Typography use="body2">{t('MODAL_SUBTITLE_WAREHOUSE_REASON')}</Typography>
                  <Select
                    id="modals_warehouse_select"
                    key="name"
                    label={t('MODAL_SELECT_WAREHOUSE')}
                    value={warehouseValue || ''}
                    options={warehouseOptions}
                    onChange={(e) => setWarehouseValue(e.target.value)}
                  />
                </>
              )}
              <Typography use="body2">{t('MODAL_SUBTITLE_WAREHOUSE')}</Typography>
              <Grid>
                <GridCell desktop="6" tablet="4">
                  <DateField initialDate={startDate} />
                </GridCell>
                <GridCell desktop="6" tablet="4">
                  <TimeField />
                </GridCell>
              </Grid>
              {data?.product?.inventory?.linkeds?.length === 0 && (
                <Typography use="caption" style={{ marginTop: '1em' }}>
                  {t(infoMessages[entityType])}
                </Typography>
              )}
              {(warehouses.length === 0 || warehouseValue) &&
                data?.product?.inventory?.linkeds?.length > 0 && (
                  <>
                    {entityType === entityTypes.TRANSMITTER && (
                      <TransmitterDown modemLinked={modemLinked} setModemLinked={setModemLinked} />
                    )}
                    {[entityTypes.DEVICES, entityTypes.METERS].includes(entityType) && !isBus && (
                      <DeviceDown
                        dissasociateText="MODAL_OPTION_UNLINKED"
                        linkedText="MODAL_OPTION_LINKED"
                        meterLinked={meterLinked}
                        setMeterLinked={setMeterLinked}
                      />
                    )}
                  </>
                )}
              {entityType === entityTypes.TRANSMITTERS && (
                <CommentField
                  label={t('PRODUCT_ACTION_ADD_COMENT')}
                  textarea
                  outlined
                  fullwidth
                  rows={3}
                  value={commentsValue}
                  maxLength="1000"
                  characterCount
                  onChange={(event) => setCommentsValue(event.target.value)}
                />
              )}
            </BodyWrapper>
          }
        />
      )}
    </Form>
  );
}

SendProductToWarehouse.propTypes = {
  data: PropTypes.shape({
    entityType: PropTypes.oneOf([entityTypes.TRANSMITTERS, entityTypes.DEVICES, entityTypes.METERS])
      .isRequired,
    row: PropTypes.object
  }).isRequired
};

export default SendProductToWarehouse;
