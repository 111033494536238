import { transformToUTCDates } from '@acua/common/utils/dates';
import { consumptionOriginTypes } from '../../../../../../common/constants';
import { ExportsService } from '../../../../../../common/services';
import { mapColumns } from '../../../../../../common/utils';
import consumptionTypes from '../constants/consumptionTypes';

const useExport = (
  { consumptionType, tenantId, entities, dateRange, enableEstimated },
  columns
) => {
  const dateRangeTransformed = transformToUTCDates(dateRange);
  const operationName =
    consumptionType === consumptionTypes.DIARY
      ? 'getAggregateDailyMeterConsumptions'
      : 'getAggregateIntraDailyMeterConsumptions';

  const handleExport = (format) =>
    ExportsService.exportFile({
      operationName,
      variables: {
        tenantId,
        dateRange: dateRangeTransformed,
        ...entities,
        format,
        enableEstimated,
        origins: enableEstimated
          ? [consumptionOriginTypes.ESTIMATED, consumptionOriginTypes.CALCULATED]
          : [consumptionOriginTypes.CALCULATED],
        columns: mapColumns(columns)
      }
    });
  return {
    handleExport
  };
};

export default useExport;
