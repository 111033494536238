import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import localforage from 'localforage';
import { Loading } from '../../components/loadings';
import { getApolloClients, persistedCache } from './apollo';

export const ApolloClientsContext = createContext();

/* Clients:
 *
 * - client (ApolloProvider): Default client of useQuery(s)
 *    - Persist: No
 *    - fetchPolicy: cache-first (If cached, query is not done) https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
 *
 * - persistedClient (ApolloClientsContext): Use in the most blocker Queries (user, instanceSites,...)
 *    - Persist: Yes
 *    - fetchPolicy: cache-and-network
 *
 * - exportClient (ApolloClientsContext): This client is only used by export search actions
 *    - Persist: No
 *    - fetchPolicy: network-only
 *    - Different Uri
 */

const ONE_MB = 2 ** 20;

// ? Increment version manually after breaking changes in schemas (in persisted queries)
const SCHEMA_VERSION = '1'; // Must be a string.
const SCHEMA_VERSION_KEY = 'apollo-schema-version';

async function checkPersistedSchemaVersion() {
  const currentVersion = await localforage.getItem(SCHEMA_VERSION_KEY);
  if (currentVersion !== SCHEMA_VERSION) {
    // If the current version does not match the latest version, we'll want to purge the
    // outdated persisted cache and mark ourselves as having updated to the latest version.
    await localforage.clear();
    await localforage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }
}

export function ApolloClientsProvider({ children }) {
  const [persistedClient, setPersistedClient] = useState();
  const { client, exportClient, mapsClient, link } = getApolloClients();

  useEffect(() => {
    async function init() {
      await checkPersistedSchemaVersion();
      await persistCache({
        cache: persistedCache,
        storage: new LocalStorageWrapper(localforage),
        maxSize: 2 * ONE_MB // defaults to 1 MB
      });
      setPersistedClient(
        new ApolloClient({
          defaultOptions: {
            // The useQuery hook uses Apollo Client's watchQuery function
            watchQuery: {
              fetchPolicy: 'cache-and-network', // https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
              nextFetchPolicy: 'cache-first',
              errorPolicy: 'all'
            }
          },
          cache: persistedCache,
          link,
          connectToDevTools: true
        })
      );
    }

    init().catch(console.error);
  }, []);

  if (!persistedClient) {
    return (
      <Loading.AbsoluteContainer>
        <Loading message="Loading cached data..." />
      </Loading.AbsoluteContainer>
    );
  }
  return (
    <ApolloProvider client={client}>
      <ApolloClientsContext.Provider
        value={{
          persistedClient,
          exportClient,
          mapsClient
        }}
      >
        {children}
      </ApolloClientsContext.Provider>
    </ApolloProvider>
  );
}

ApolloClientsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
