import React from 'react';
import { composeValidators, required, mustBeFloat } from '@acua/common/utils';
import { TextField } from '@rmwc/textfield';
import { useField } from 'react-final-form';
import { useTranslations } from '@react-utils/intl';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ClearIcon from '@mui/icons-material/Clear';
import { getVolumeUnitKey } from '../../../../../common/units';
import { useUserContext } from '../../../../../common/hooks';

const validate = composeValidators(required, mustBeFloat);

function ReadingValueField() {
  const {
    profile: {
      preferences: { volumeUnit }
    }
  } = useUserContext();
  const {
    input,
    meta: { error, invalid, touched }
  } = useField('readingValue', { validate });
  const t = useTranslations();
  return (
    <TextField
      label={`${t('INSERT_READING_FIELD_READING')} (${t(getVolumeUnitKey(volumeUnit))})`}
      type="number"
      id="modals_reading_value"
      trailingIcon={
        input.value ? <ClearIcon onClick={() => input.onChange('')} /> : <ModeEditIcon />
      }
      helpText={{
        persistent: false,
        validationMsg: true,
        children: t(error)
      }}
      invalid={invalid && touched}
      // We need to propagate onBlur and onFocus for updating touched
      {...input}
    />
  );
}

export default ReadingValueField;
