import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@rmwc/button';
import ClearIcon from '@mui/icons-material/Clear';

const SmallButton = styled(Button)`
  padding: 2px;
  min-width: 0px;
  height: 28px;
  width: 28px;
  line-height: 28px;
  i {
    font-size: 16px;
    color: ${({ theme }) => theme.textPrimaryOnLight};
  }
`;

function CloseButton({ iconProps, children, ...props }) {
  return (
    <SmallButton title="Cerrar" {...props}>
      {children || <ClearIcon {...iconProps} />}
    </SmallButton>
  );
}

CloseButton.propTypes = {
  children: PropTypes.node,
  iconProps: PropTypes.shape({})
};

CloseButton.defaultProps = {
  children: null,
  iconProps: {}
};

export default CloseButton;
