import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useAppContext, useSnackbarsContext } from '@acua/common/hooks';
import { updateAlarmMetadata } from './mutation.gql';

export default ({ id, tenantId }) => {
  const [updateCommentStatus, setUpdateCommentStatus] = useState({});
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  const [handleActivate] = useMutation(updateAlarmMetadata);

  return {
    updateMetadata: (metadata) => {
      handleActivate({
        variables: {
          metadata: {
            justify: metadata.justify,
            ...(metadata.comment ? { comment: metadata.comment } : {})
          },
          tenantId,
          alarmId: id
        }
      })
        .then(() => {
          setTimeout(() => {
            addSnackbar('SNACKBAR_COMMENT_SAVE_SUCCESS');
            refreshSearchKey();
          }, 3000);
        })
        .catch((error) => {
          setUpdateCommentStatus({ error });
          throw error;
        });
    },
    updateCommentStatus
  };
};
