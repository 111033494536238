import { consumptionOriginTypes, metaTagsTypes } from './types';

export const consumptionOriginTypesI18nKeys = {
  [consumptionOriginTypes.ESTIMATED]: 'RESULTS_TABLE_COL_CONSUMPTION_TYPE_ESTIMATED',
  [consumptionOriginTypes.INVALID]: 'RESULTS_TABLE_COL_CONSUMPTION_TYPE_INVALID',
  [consumptionOriginTypes.RECALCULATED]: 'RESULTS_TABLE_COL_CONSUMPTION_TYPE_RECALCULATED',
  [consumptionOriginTypes.CALCULATED]: 'RESULTS_TABLE_COL_CONSUMPTION_TYPE_CALCULATED'
};

export const distributionNetworkAggregationTypes = ['auto-billable', 'primary', 'secondary'];

export const metaTagsTypesI18nKeys = {
  [metaTagsTypes.index_reset]: 'INDEX_RESET_METATAG',
  [metaTagsTypes.no_estimation]: 'NO_ESTIMATION_METATAG'
};
