import { entityTypes } from '@acua/common/constants';
import { useProduct } from '@acua/common/hooks';
import { useEffect, useState } from 'react';
import { getProductById } from './getProductById.gql';

const useAssociatedProducts = (id, entityType) => {
  const [actions, setActions] = useState([]);

  const setAction = (newAction) => {
    setActions(
      actions.map((item) => (item.productType === newAction.productType ? newAction : item))
    );
  };

  const { data, loading, error, refetch } = useProduct(id, getProductById);
  const { products: associatedProducts, hasHistoric } = data?.product?.inventory
    ?.linkedsHistoric || { hasHistoric: false };

  useEffect(() => {
    if (
      Array.isArray(associatedProducts) &&
      [entityTypes.TRANSMITTERS, entityTypes.METERS].includes(entityType) &&
      associatedProducts.length > 0
    ) {
      setActions(
        associatedProducts.map(({ id: associatedId, productType }) => ({
          id: associatedId,
          productType,
          action: 'down'
        }))
      );
    }
  }, [associatedProducts]);

  return {
    data,
    actions,
    setAction,
    loading,
    associatedProducts,
    hasHistoric,
    error,
    refetch
  };
};

export default useAssociatedProducts;
