const getNestedProp = (obj, str) =>
  str.split('.').reduce((acc, x) => (acc && x ? acc[x] : acc), obj);

/* eslint-disable no-param-reassign */
const bubbleSort = (arr, sorter) => {
  let swaps;
  do {
    swaps = false;
    for (let i = 0; i < arr.length - 1; i += 1) {
      if (sorter(arr[i], arr[i + 1]) > 0) {
        const temp = arr[i + 1];
        arr[i + 1] = arr[i];
        arr[i] = temp;
        swaps = true;
      }
    }
  } while (swaps);
  return arr;
};

const sortArray = (hasNestedProps, column, sorterArr) => (objA, objB) => {
  let a = null;
  let b = null;
  if (hasNestedProps) {
    a = getNestedProp(objA, column);
    b = getNestedProp(objB, column);
  } else {
    a = objA[column];
    b = objB[column];
  }
  const aIndex = sorterArr.indexOf(a);
  const bIndex = sorterArr.indexOf(b);
  if (aIndex >= 0 && aIndex < bIndex) return -1;
  if (bIndex >= 0 && aIndex > bIndex) return 1;
  if (aIndex === bIndex) return 0;
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const sortItem = (hasNestedProps, column) => (objA, objB) => {
  let a = null;
  let b = null;
  if (hasNestedProps) {
    a = getNestedProp(objA, column);
    b = getNestedProp(objB, column);
  } else {
    a = objA[column];
    b = objB[column];
  }
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export default (data, column, options = {}) => {
  const { orderAsc = true, sorterArr = [], usingBubble = false } = options;

  if (!column || data.length === 0) return data;

  const newDataArr = data.slice();
  const hasNestedProps = column.includes('.');

  const sorterAsc =
    sorterArr && sorterArr.length > 0
      ? sortArray(hasNestedProps, column, sorterArr)
      : sortItem(hasNestedProps, column);

  const sorterDesc = (objA, objB) => -1 * sorterAsc(objA, objB);

  if (orderAsc) {
    return usingBubble ? bubbleSort(newDataArr, sorterAsc) : newDataArr.sort(sorterAsc);
  }
  return usingBubble ? bubbleSort(newDataArr, sorterDesc) : newDataArr.sort(sorterDesc);
};
