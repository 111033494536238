import { ImporterService } from '@acua/common/services';

export const IMPORT_TRANSMITTERS = 'IMPORT_TRANSMITTERS';
export const IMPORT_DEVICES = 'IMPORT_DEVICES';
export const IMPORT_READINGS = 'IMPORT_READINGS';
export const SAVE_FILE = 'SAVE_FILE';
export const DELETE_FILE = 'DELETE_FILE';

export const importTransmitters = (payload) => ({
  type: IMPORT_TRANSMITTERS,
  payload: ImporterService.importTransmitters(payload)
});

export const importDevices = (payload) => ({
  type: IMPORT_DEVICES,
  payload: ImporterService.importDevices(payload)
});

export const importReadings = (payload) => ({
  type: IMPORT_READINGS,
  payload: ImporterService.importReadings(payload)
});

export const saveFile = (file) => ({
  type: SAVE_FILE,
  payload: file
});

export const deleteFile = (payload) => ({
  type: DELETE_FILE,
  payload
});
