import React from 'react';
import { Global, css } from '@emotion/react';
import themeVars from '../variables';

function Base() {
  return (
    <Global
      styles={css`
        :root {
          --mdc-theme-primary: #415d9a;
        }
        .MuiAutocomplete-root {
          .MuiInputBase-root {
            background-color: #fff !important;
          }
        }

        .MuiAutocomplete-listbox {
          padding: 0 !important;
        }

        * {
          box-sizing: content-box;
        }

        html {
          scroll-behavior: smooth;
        }

        html,
        body {
          height: 100%;
          height: 100vh;
          overflow: hidden;
          margin: 0;
          padding: 0;
          font-family: 'Roboto', 'Helvetica', sans-serif;
          font-size: 16px;
        }

        body {
          background-color: ${themeVars.appBgColor};
          line-height: initial;
        }

        a {
          color: inherit;
          text-decoration: inherit;
        }

        strong {
          font-weight: 500;
        }

        /* Turn Off Number Input Spinners */
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }

        /* Hides "x" button on IExplorer */
        input[type='text']::-ms-clear,
        input[type='color']::-ms-clear,
        input[type='date']::-ms-clear,
        input[type='datetime-local']::-ms-clear,
        input[type='email']::-ms-clear,
        input[type='month']::-ms-clear,
        input[type='number']::-ms-clear,
        input[type='range']::-ms-clear,
        input[type='search']::-ms-clear,
        input[type='tel']::-ms-clear,
        input[type='time']::-ms-clear,
        input[type='url']::-ms-clear,
        input[type='week']::-ms-clear {
          display: none;
        }

        .map-icon {
          width: 22px;
          height: 22px;
          z-index: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Material Symbols Outlined';

          &::before {
            position: relative;
            z-index: 2;
            color: #fff;
            font-size: 18px;
            transform: translateY(-22px);
          }

          &::after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: ${themeVars.colorFontInfo};
            border-radius: 50% 50% 0 50%;
            border: 1px solid #555;

            display: block;
            transform: translateY(-22px) rotate(45deg);
          }
          &--status-ok {
            &::before {
              content: '\\e5ca';
            }
            &::after {
              background-color: ${themeVars.colorChartOk};
            }
          }
          &--status-ko {
            &::before {
              content: '\\e5cd';
            }
            &::after {
              background-color: ${themeVars.colorFontError};
            }
          }
          &--status-unknown {
            &::before {
              content: '?';
            }
            &::after {
              background-color: ${themeVars.colorFontDisabled};
            }
          }
        }
      `}
    />
  );
}

export default Base;
