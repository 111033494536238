import {
  deviceTypes,
  entityTypes,
  meterTypes,
  transmitterTypes,
  deviceTechnologies
} from '../../constants/products/types';
import { padLeftZero } from '../strings';
// TODO: This function should not depend on the year, it should be a static value. Otherwise tests are going to fail every year
const changeYear = (key) =>
  [key.slice(0, 1), (new Date().getFullYear() + 1).toString().slice(-2), key.slice(3)].join('');

// TODO: This function should not depend on the year, it should be a static value. Otherwise tests are going to fail every year
const changeYearStart = (key) =>
  [(new Date().getFullYear() + 1).toString().slice(-2), key.slice(2)].join('');

export const hashString = (s) => {
  if (typeof s === 'string' && s?.length) {
    let hash = s.split('').reduce((a, b) => {
      // eslint-disable-next-line no-bitwise, no-param-reassign
      a = (a << 5) - a + b.charCodeAt(0);
      // eslint-disable-next-line no-bitwise
      return a & a;
    }, 0);
    hash = hash < 0 ? hash * -1 : hash;
    const stringHash = String(hash);
    const res =
      stringHash.length > s.length
        ? stringHash.slice(0, s.length)
        : padLeftZero(stringHash, s.length);
    return res.toUpperCase();
  }
  return '';
};

const spde = (key) => {
  const newKey = changeYear(key);
  return [newKey.slice(0, 5), hashString(newKey.slice(6, 12)), newKey.slice(-1)]
    .join('')
    .toUpperCase();
};
const noSpde1011 = (key) => {
  const newKey = changeYear(key);
  return [newKey.slice(0, 5), hashString(newKey.slice(5))].join('').toUpperCase();
};

const noSpde9 = (key) => {
  const newKey = changeYearStart(key);
  return [newKey.slice(0, 5), hashString(newKey.slice(5))].join('').toUpperCase();
};

const vhf1g = (key) => {
  return [
    key.slice(0, 1),
    hashString(key.slice(1, 3)),
    'k',
    hashString(key.slice(4, 8)),
    key.slice(8)
  ]
    .join('')
    .toUpperCase();
};
const vhf2g = (key) => {
  return [key.slice(0, 1), hashString(key.slice(1, 8)), key.slice(8)].join('').toUpperCase();
};
const busVhf = (key) => {
  return [key.slice(0, 1), hashString(key.slice(1, 6)), key.slice(6)].join('').toUpperCase();
};
const imeter = (key) => {
  const newKey = changeYearStart(key);
  return [
    newKey.slice(0, 2),
    hashString(newKey.slice(2, 4)),
    newKey.slice(4, 5),
    hashString(newKey.slice(5))
  ]
    .join('')
    .toUpperCase();
};
const hydrosense = (key) => {
  const newKey = changeYearStart(key);
  return [newKey.slice(0, 2), hashString(newKey.slice(2, 7)), newKey.slice(7)]
    .join('')
    .toUpperCase();
};
const busnb = (key) => {
  return [hashString(key.slice(0, 17)), key.slice(-1)].join('').toUpperCase();
};

const ondeo1g = (key) => {
  return ['2222', hashString(key.slice(5))].join('').toUpperCase();
};
const ondeo2g = (key) => {
  return ['5555', hashString(key.slice(5))].join('').toUpperCase();
};

const serialNumberLenghtAnonymize = {
  12: spde,
  11: noSpde1011,
  10: noSpde1011,
  9: noSpde9
};

export const anSerialNumber = (key) => {
  return serialNumberLenghtAnonymize[key?.length]
    ? serialNumberLenghtAnonymize[key.length](key)
    : hashString(key);
};

const meterSerialNumberAnonymize = {
  [meterTypes.EMETER]: anSerialNumber,
  [meterTypes.EMETER_SPDE]: anSerialNumber,
  [meterTypes.MMETER]: anSerialNumber,
  [meterTypes.MMETER_SPDE]: anSerialNumber
};

const deviceSerialNumberAnonymize = {
  [deviceTypes.BUSVHF_V1]: busVhf,
  [deviceTypes.MODULE_VHF_1G]: vhf1g,
  [deviceTypes.MODULE_VHF_2G]: vhf2g,
  [deviceTypes.IMETER_C]: imeter,
  [deviceTypes.IMETER_E]: imeter,
  [deviceTypes.HYDROSENSE_METERING]: hydrosense,
  [deviceTypes.BUSNBIOT]: busnb,
  [deviceTypes.GENERIC]: hashString,
  [deviceTypes.LORA]: hashString,
  [deviceTypes.NBIOTDEVICE]: hashString
};

const transmitterSerialNumberAnonymize = {
  [transmitterTypes.ONDEO_1G]: ondeo1g,
  [transmitterTypes.ONDEO_2G]: ondeo2g,
  [transmitterTypes.LORA_GATEWAY]: hashString
};

const deviceTechnologiesAnonymize = {
  [deviceTechnologies.BUS_VHF]: busVhf,
  [deviceTechnologies.MODULE_VHF]: vhf1g,
  [deviceTechnologies.IMETER]: imeter,
  [deviceTechnologies.HYDROSENSE]: hydrosense,
  [deviceTechnologies.GENERIC]: hashString,
  [deviceTechnologies.BUSNBIOT]: busnb,
  [deviceTechnologies.LORA]: hashString,
  [deviceTechnologies.NBIOT]: busnb,
  [deviceTechnologies.ondeoTransmitter]: ondeo1g,
  [deviceTechnologies.ondeoTransmitter1G]: ondeo1g,
  [deviceTechnologies.ondeoTransmitter2G]: ondeo2g
};

export const productTypesAnonymize = {
  ...meterSerialNumberAnonymize,
  ...deviceSerialNumberAnonymize,
  ...transmitterSerialNumberAnonymize,
  ...deviceTechnologiesAnonymize,
  [undefined]: hashString
};

export const anDeviceSerialNumber = (key, deviceType) => {
  return deviceSerialNumberAnonymize[deviceType]
    ? deviceSerialNumberAnonymize[deviceType](key)
    : hashString(key);
};

export const transmitterSerialNumber = (key, transmitterType) => {
  return transmitterSerialNumberAnonymize[transmitterType]
    ? transmitterSerialNumberAnonymize[transmitterType](key)
    : hashString(key);
};
export const anTenant = (t) => ['TENANT ', t?.slice(25, 37).toUpperCase()].join('');
const getRowEOSiteId = (v, row) => {
  if (row && row.organizationalStructure && row.organizationalStructure.siteId) {
    return row.organizationalStructure.siteId?.slice(25, 37).toUpperCase();
  }
  return v?.slice(25, 37).toUpperCase();
};

export const anSite = (s, row) => ['EXPLOTACION ', getRowEOSiteId(s, row)].join('');
export const anZone = (z, row) => ['GT Zone Management ', getRowEOSiteId(z, row)].join('');
export const anConcession = (c, row) =>
  ['DT Concession Management ', getRowEOSiteId(c, row)].join('');
export const anArea = (a, row) => ['DR Area Manager ', getRowEOSiteId(a, row)].join('');
export const anSector = (s, row) => {
  if (!s) return null;
  if (row && row.inventory && row.inventory.site) {
    return ['SECTOR ', row.inventory.site.id?.slice(25, 37).toUpperCase()].join('');
  }
  return ['SECTOR ', getRowEOSiteId(s, row)].join('');
};
export const anSubsector = (s, row) => {
  if (!s) return null;
  if (row && row.inventory && row.inventory.site) {
    return ['SUBSECTOR ', row.inventory.site.id?.slice(25, 37).toUpperCase()].join('');
  }
  return ['SUBSECTOR ', getRowEOSiteId(s, row)].join('');
};

const getRowSupplyId = (v, row) => {
  if (row && row.supplyPoint && row.supplyPoint.id) {
    return row.supplyPoint.id?.slice(1, 10).toUpperCase();
  }
  if (Array.isArray(v)) {
    return v.map((va) => va?.slice(1, 10).toUpperCase());
  }
  return v?.slice(1, 10).toUpperCase();
};
export const anSupplyPoint = (s, row) => {
  if (!s) return null;
  return ['PUNTO SUMINISTRO ', getRowSupplyId(s, row)].join('');
};
export const anPsKey = (p) => hashString(p);
export const anAlias = (a, row) => {
  if (!a) return null;
  return ['ALIAS ', getRowSupplyId(a, row)].join('');
};
export const anContractKey = (key) => hashString(key);
export const anCoordinate = (c = 0) => Math.round(c * 1000) / 1000;
export const anAddicionalKey = (key) => hashString(key);
export const joinEui = (key) => hashString(key);
export const anSim = (key) => hashString(key);
export const anLockerSn = (key) => hashString(key);
export const anIp = () => 'n.0.n.0.n';
export const anApn = () => 'apn.company.ext';
export const anPhone = (p) => hashString(p);
export const anCompany = () => 'Compañía telecomunicaciones';
export const subscriptorMail = () => 'suscriptor.test@testmail.com';
export const appKey = () =>
  [...Array(32)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('')
    .toUpperCase();
export const anComments = (n) => (n ? 'Comment manually added by user' : n);
export const aComment = () => 'Modo Demo';
export const anAlarmCreator = () => 'Usuario creador de Alarma';
export const anPowerBI = () => 'No disponible en modo demo';

export const anonymizeEntityTypes = {
  [entityTypes.TRANSMITTERS]: transmitterSerialNumber,
  [entityTypes.DEVICES]: anDeviceSerialNumber,
  [entityTypes.METERS]: anSerialNumber,
  [entityTypes.SIM]: anSim,
  [entityTypes.MODEM]: anSim,
  [entityTypes.SUPPLY_POINTS]: anSupplyPoint,
  [entityTypes.SITES]: anSite,
  [entityTypes.SECTORS]: anSector,
  [entityTypes.SUBSECTORS]: anSubsector,
  [undefined]: hashString
};
