import { useEffect } from 'react';
import { useSnackbarsContext } from '.';
import { snackbarTypes } from '../constants';
import { cache, exportCache, persistedCache } from '../contexts/ApolloClients/apollo';

const versionKey = 'DM_VERSION';

const useVersionCheck = () => {
  const { addSnackbar } = useSnackbarsContext();
  const savedVersion = window.localStorage.getItem(versionKey);
  const currentVersion = process.env.VERSION;

  useEffect(() => {
    if (!savedVersion || savedVersion !== currentVersion) {
      cache.reset();
      persistedCache.reset();
      exportCache.reset();
      addSnackbar(null, {
        customType: snackbarTypes.NEW_VERSION,
        data: { version: process.env.VERSION }
      });
      window.localStorage.setItem(versionKey, process.env.VERSION);
    }
  }, []);
};

export default useVersionCheck;
