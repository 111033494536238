import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorSvg from '../../errors/ErrorSvg';

const ErrorContainer = styled.div`
  margin-top: 5rem;
`;

const ErrorTitle = styled.div`
  font-size: 1.5rem;
  opacity: 0.38;
  font-weight: 500;
  display: flex;
  justify-content: center;
  i {
    margin-right: 1rem;
    font-size: 2rem;
    align-self: center;
  }
`;

function ErrorWrapper({ label }) {
  return (
    <ErrorContainer>
      <ErrorSvg />
      <ErrorTitle>
        <WarningIcon /> {label}
      </ErrorTitle>
    </ErrorContainer>
  );
}

ErrorWrapper.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

ErrorWrapper.defaultProps = {
  label: 'Error'
};

export default ErrorWrapper;
