import { Typography } from '@rmwc/typography';
import styled from '@emotion/styled';

const Badge = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'fitContent' && prop !== 'color'
})`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 4px 7px;
  line-height: initial;
  height: auto;
  border-radius: 12px;
  box-sizing: border-box;
  ${({ fitContent }) => fitContent && 'width: fit-content;'}
  ${({ theme: { stausBadgeColors }, color }) => `
    color: ${stausBadgeColors[color].text};
    background-color: ${stausBadgeColors[color].bg};
  `};
`;

export default Badge;
