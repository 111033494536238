import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from './Tooltip';

const StyledIcon = styled(HelpOutlineOutlinedIcon)`
  font-size: 14px !important;
  margin: 0 4px;
`;

const StyledTooltip = styled(Tooltip)`
  ::after {
    white-space: normal !important;
    width: 260px;
  }
`;

function HelpIcon({ tooltip, ...props }) {
  return (
    <StyledTooltip position="top" {...props} label={tooltip}>
      <StyledIcon name="help_outline" theme="textSecondaryOnLight" />
    </StyledTooltip>
  );
}

HelpIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  position: PropTypes.string
};

HelpIcon.defaultProps = {
  position: 'top'
};

export default HelpIcon;
