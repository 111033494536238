import SelectAllIcon from '@mui/icons-material/SelectAll';
import { Typography } from '@rmwc/typography';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import React from 'react';
import { EntityAutocompleteItem } from '..';
import { AutocompleteItem, AutocompleteItemContent } from './elements';
import { ALL } from './utils';

function SearchItem({
  id,
  displayName,
  tenantId,
  linkedEntities,
  contract,
  contractKey,
  siteId,
  family,
  searchValue
}) {
  const t = useTranslations();
  return id === ALL ? (
    <AutocompleteItem id="all_item">
      <SelectAllIcon style={{ marginLeft: '10px' }} />
      <AutocompleteItemContent>
        <Typography use="body2">{t('SEARCH_ENTITY__NOTHING_SEARCH')}</Typography>
        <Typography use="caption">{t('SEARCH_ENTITY__INCLUDE_ALL_DEVICES')}</Typography>
      </AutocompleteItemContent>
    </AutocompleteItem>
  ) : (
    <EntityAutocompleteItem
      key={id}
      searchValue={searchValue}
      tenantId={tenantId}
      siteId={siteId}
      linkedEntities={linkedEntities}
      family={family}
      contract={contract || { key: contractKey }}
      displayName={displayName}
    />
  );
}

SearchItem.propTypes = {
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  linkedEntities: PropTypes.shapeOf({}).isRequired,
  contract: PropTypes.string.isRequired,
  contractKey: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  family: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired
};

export default SearchItem;
