import {
  devicesAlarmTypes,
  transmittersAlarmTypes,
  metersAlarmTypes,
  alarmConfigurationTypes,
  alarmSubscriptionFrequencyTypes,
  weekdayTypes,
  criticityTypes,
  alarmRoleTypes,
  subscriptionStatuses,
  activationReasonTypes,
  subscriptionFrequency
} from './types';

export const devicesAlarmTypesI18nKeys = {
  [devicesAlarmTypes.BACK_FLOW_PULSE_COUNTER_ALARM]: 'ALARM_TYPE__BACK_FLOW',
  [devicesAlarmTypes.DEVICE_ACCESS_MEMORY_ALARM]: 'ALARM_TYPE__ACCESS_MEMORY_FAIL',
  [devicesAlarmTypes.DEVICE_FAULT_ALARM]: 'ALARM_TYPE__DEVICE_FAULT',
  [devicesAlarmTypes.DEVICE_TAMPERING_ALARM]: 'ALARM_TYPE__TAMPERING',
  [devicesAlarmTypes.DEVICE_MAGNETIC_TAMPERING_ALARM]: 'ALARM_TYPE__MAGNETIC_TAMPERING',
  [devicesAlarmTypes.DEVICE_LOW_BATTERY_ALARM]: 'ALARM_TYPE__LOW_BATTERY_DEVICE',
  [devicesAlarmTypes.LOW_COVERAGE_ALARM]: 'ALARM_TYPE__LOW_COVERAGE',
  [devicesAlarmTypes.SAMPLING_DISABLED_ALARM]: 'ALARM_TYPE__SAMPLING_PERIOD_DISABLED',
  [devicesAlarmTypes.SENDING_CONFIG_DISABLED_ALARM]: 'ALARM_TYPE__SENDING_CONFIG_DISABLED'
};

export const transmittersAlarmTypesI18nKeys = {
  [transmittersAlarmTypes.DEVICE_MEMORY_FAIL_ALARM]: 'ALARM_TYPE__MEMORY_FAIL',
  [transmittersAlarmTypes.DEVICE_BATTERY_LOW_ALARM]: 'ALARM_TYPE__LOW_BATTERY_TRANSMITTERS',
  [transmittersAlarmTypes.FILES_MEMORY_FAIL_ALARM]: 'ALARM_TYPE__FILES_MEMORY_FAIL',
  [transmittersAlarmTypes.LOW_COVERAGE_LEVEL_ALARM]: 'ALARM_TYPE__LOW_COVERAGE',
  [transmittersAlarmTypes.LOW_FRAMES_ALARM]: 'ALARM_TYPE__LOW_FRAMES',
  [transmittersAlarmTypes.MAIN_SUPPLY_FAILURE_ALARM]: 'ALARM_TYPE__MAIN_SUPPLY_FAILURE',
  [transmittersAlarmTypes.NTP_SYNC_FAIL_ALARM]: 'ALARM_TYPE__NTP_SYNC_FAIL'
};

export const metersAlarmTypesI18nKeys = {
  [metersAlarmTypes.METER_LOW_BATTERY_ALARM]: 'ALARM_TYPE__LOW_BATTERY_METER',
  [metersAlarmTypes.CONTINUOUS_CONSUMPTION_ALARM]: 'ALARM_TYPE__CONTINUOUS_CONSUMPTION',
  [metersAlarmTypes.ABSENCE_OF_READING_ALARM]: 'ALARM_TYPE__ABSENCE_OF_READING',
  [metersAlarmTypes.CONSUMPTION_INCREASE_ALARM]: 'ALARM_TYPE__CONSUMPTION_INCREASE',
  [metersAlarmTypes.CONSUMPTION_DECREASE_ALARM]: 'ALARM_TYPE__CONSUMPTION_DECREASE',
  [metersAlarmTypes.CONSUMPTION_INACTIVITY_ALARM]: 'ALARM_TYPE__CONSUMPTION_INACTIVITY',
  [metersAlarmTypes.NEGATIVE_CONSUMPTION_ALARM]: 'ALARM_TYPE__NEGATIVE_CONSUMPTION'
};

export const productAlarmTypesI18nKeys = {
  ...devicesAlarmTypesI18nKeys,
  ...transmittersAlarmTypesI18nKeys,
  ...metersAlarmTypesI18nKeys,
  [transmittersAlarmTypes.LOW_COVERAGE_LEVEL_ALARM]: 'ALARM_TYPE__LOW_COVERAGE_LEVEL',
  backFlowAlarm: 'ALARM_TYPE__BACK_FLOW'
};

export const productOptionsAlarmTypesI18nKeys = {
  ...devicesAlarmTypesI18nKeys,
  ...transmittersAlarmTypesI18nKeys,
  ...metersAlarmTypesI18nKeys,
  [transmittersAlarmTypes.LOW_COVERAGE_LEVEL_ALARM]: 'ALARM_TYPE__LOW_COVERAGE_LEVEL'
};

export const alarmConfigurationTypesI18nKeys = {
  [alarmConfigurationTypes.ABSENCE_OF_READING]: 'ALARM_TYPE__ABSENCE_OF_READING',
  [alarmConfigurationTypes.CONSUMPTION_DECREASE]: 'ALARM_TYPE__CONSUMPTION_DECREASE',
  [alarmConfigurationTypes.CONSUMPTION_INACTIVITY]: 'ALARM_TYPE__CONSUMPTION_INACTIVITY',
  [alarmConfigurationTypes.CONSUMPTION_INCREASE]: 'ALARM_TYPE__CONSUMPTION_INCREASE',
  [alarmConfigurationTypes.CONTINUOUS_CONSUMPTION]: 'ALARM_TYPE__CONTINUOUS_CONSUMPTION',
  [alarmConfigurationTypes.NEGATIVE_CONSUMPTION]: 'ALARM_TYPE__NEGATIVE_CONSUMPTION'
};

export const managerAlarmConfigurationTypesI18nKeys = {
  [alarmConfigurationTypes.ABSENCE_OF_READING]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.ABSENCE_OF_READING],
  [alarmConfigurationTypes.CONSUMPTION_DECREASE]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_DECREASE],
  [alarmConfigurationTypes.CONSUMPTION_INACTIVITY]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_INACTIVITY],
  [alarmConfigurationTypes.CONSUMPTION_INCREASE]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_INCREASE],
  [alarmConfigurationTypes.CONTINUOUS_CONSUMPTION]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONTINUOUS_CONSUMPTION],
  [alarmConfigurationTypes.NEGATIVE_CONSUMPTION]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.NEGATIVE_CONSUMPTION]
};

export const customerAlarmConfigurationTypesI18nKeys = {
  [alarmConfigurationTypes.CONSUMPTION_DECREASE]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_DECREASE],
  [alarmConfigurationTypes.CONSUMPTION_INACTIVITY]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_INACTIVITY],
  [alarmConfigurationTypes.CONSUMPTION_INCREASE]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONSUMPTION_INCREASE],
  [alarmConfigurationTypes.CONTINUOUS_CONSUMPTION]:
    alarmConfigurationTypesI18nKeys[alarmConfigurationTypes.CONTINUOUS_CONSUMPTION]
};

export const alarmSubscriptionFrequencyTypesI18nKeys = {
  [alarmSubscriptionFrequencyTypes.ACTIVATION]: 'ALARM_SUBSCRIPTION_FREQ_TYPE__ACTIVATION',
  [alarmSubscriptionFrequencyTypes.DAILY]: 'ALARM_SUBSCRIPTION_FREQ_TYPE__DAILY'
};

export const weekdayTypesShortI18nKeys = {
  [weekdayTypes.MON]: 'WEEKDAY__SHORT_MON',
  [weekdayTypes.TUE]: 'WEEKDAY__SHORT_TUE',
  [weekdayTypes.WED]: 'WEEKDAY__SHORT_WED',
  [weekdayTypes.THU]: 'WEEKDAY__SHORT_THU',
  [weekdayTypes.FRI]: 'WEEKDAY__SHORT_FRI',
  [weekdayTypes.SAT]: 'WEEKDAY__SHORT_SAT',
  [weekdayTypes.SUN]: 'WEEKDAY__SHORT_SUN'
};

export const criticityTypesI18nKeys = {
  [criticityTypes.INFO]: 'CRITICITY__TYPE_INFO',
  [criticityTypes.LOW]: 'CRITICITY__TYPE_MINOR',
  [criticityTypes.HIGH]: 'CRITICITY__TYPE_MAJOR',
  [criticityTypes.CRITICAL]: 'CRITICITY__TYPE_CRITICAL'
};

export const allCriticityTypesI18nKeys = {
  ...criticityTypesI18nKeys,
  [criticityTypes.INFO2]: 'CRITICITY__TYPE_INFO',
  [criticityTypes.MINOR]: 'CRITICITY__TYPE_MINOR',
  [criticityTypes.MAJOR]: 'CRITICITY__TYPE_MAJOR'
};

export const alarmAnalyticsCriticityTypesI18nKeys = {
  [criticityTypes.INFO2]: 'CRITICITY__TYPE_INFO',
  [criticityTypes.MINOR]: 'CRITICITY__TYPE_MINOR',
  [criticityTypes.MAJOR]: 'CRITICITY__TYPE_MAJOR',
  [criticityTypes.CRITICAL]: 'CRITICITY__TYPE_CRITICAL'
};

export const alarmAnalyticsCommentTypesI18nKeys = {
  yes: 'CUSTOMER_NOTIFIED__YES',
  no: 'CUSTOMER_NOTIFIED__NO'
};

export const alarmAnalyticsJustifiedTypesI18nKeys = {
  yes: 'CUSTOMER_NOTIFIED__YES',
  no: 'CUSTOMER_NOTIFIED__NO'
};

export const alarmStatusI18nKeys = {
  enabled: 'ALARM_STATUS_OPTION_ENABLED',
  disabled: 'ALARM_STATUS_OPTION_DISABLED'
};

export const alarmRoleTypesI18nKeys = {
  [alarmRoleTypes.MANAGER]: 'ALARM_ROLE_TYPE__MANAGER',
  [alarmRoleTypes.CUSTOMER]: 'ALARM_ROLE_TYPE__CUSTOMER',
  [alarmRoleTypes.TECHNICAL]: 'ALARM_ROLE_TYPE__TECHNICAL'
};

export const customerNotifiedTypesI18nKeys = {
  yes: 'CUSTOMER_NOTIFIED__YES',
  no: 'CUSTOMER_NOTIFIED__NO'
};

export const alarmImportType = 'ALARM';

export const subscriptionStatusesi18n = {
  [subscriptionStatuses.Planned]: 'ALARM_SUBSCRIPTION_STATUS__PLANNED',
  [subscriptionStatuses.InProgress]: 'ALARM_SUBSCRIPTION_STATUS__IN_PROGRESS',
  [subscriptionStatuses.Delivered]: 'ALARM_SUBSCRIPTION_STATUS__DELIVERED',
  [subscriptionStatuses.Failed]: 'ALARM_SUBSCRIPTION_STATUS__FAILED',
  [subscriptionStatuses.Cancelled]: 'ALARM_SUBSCRIPTION_STATUS__CANCELLED'
};

export const frequencyi18n = {
  [subscriptionFrequency.daily]: 'ALARM_SUBSCRIPTION_FREQUENCY__DAILY',
  [subscriptionFrequency.activation]: 'ALARM_SUBSCRIPTION_FREQUENCY__ACTIVATION'
};

export const activationReasonTypesi18n = {
  [activationReasonTypes.DEVICE_ALERT]: { literal: 'ACTIVATION_REASON__DEVICE_ALERT' },
  [activationReasonTypes.DAYS_WITHOUT_KEEP_ALIVE]: {
    literal: 'ACTIVATION_REASON__DAYS_WITHOUT_KEEP_ALIVE',
    formatLiteral: (value, label) => {
      const match = value.match(/\d* /);
      return `${match ? match[0] : ''}${label}`;
    }
  },
  [activationReasonTypes.CONTINUOUS_CONSUMPTION]: {
    literal: 'ACTIVATION_REASON__CONTINUOUS_CONSUMPTION'
  },
  [activationReasonTypes.NO_CONSUMPTION_CONFIGURED]: {
    literal: 'ACTIVATION_REASON__NO_CONSUMPTION_CONFIGURED'
  },
  [activationReasonTypes.CONSUMPTION_BELOW_THRESHOLD]: {
    literal: 'ACTIVATION_REASON__CONSUMPTION_BELOW_THRESHOLD'
  },
  [activationReasonTypes.CONSUMPTION_NEGATIVE]: {
    literal: 'ACTIVATION_REASON__CONSUMPTION_NEGATIVE'
  },
  [activationReasonTypes.CONECTION_NO_ACTIVE]: {
    literal: 'ACTIVATION_REASON__CONECTION_NO_ACTIVE'
  },
  [activationReasonTypes.LECTURE_NO_ACTIVE]: { literal: 'ACTIVATION_REASON__LECTURE_NO_ACTIVE' },
  [activationReasonTypes.CONSUMPTION_ABOVE_THRESHOLD]: {
    literal: 'ACTIVATION_REASON__CONSUMPTION_ABOVE_THRESHOLD'
  }
};
