import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { productTypesI18NKeys } from '@acua/common/constants';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell } from '@rmwc/grid';
import { Radio } from '@rmwc/radio';

const Container = styled(Grid)`
  background-color: rgba(216, 216, 216, 0.2);
  padding: 12px 18px;
  margin: 0;
`;

const actionOptions = [
  {
    action: 'warehouse',
    i18nKey: 'MODAL_OPTION_SEND_WAREHOUSE'
  },
  {
    action: 'down',
    i18nKey: 'MODAL_OPTION_DOWN'
  }
];

function TransmitterDown({ actions, setAction }) {
  const t = useTranslations();
  return (
    <Container>
      <GridCell desktop="12" tablet="8">
        <Typography use="subtitle2">{t('MODAL_OPTION_ASSOC_PRODUCTS')}</Typography>
      </GridCell>
      {actions.map((productAction) => (
        <Fragment key={productAction.id}>
          <GridCell align="middle" desktop="2" tablet="2">
            <Typography style={{ fontWeight: 'bold' }} use="body2">
              {t(productTypesI18NKeys[productAction.productType])}
            </Typography>
          </GridCell>
          {actionOptions.map(({ action, i18nKey }) => (
            <GridCell key={action} desktop="5" tablet="3">
              <Radio
                value={action}
                checked={action === productAction.action}
                onChange={() =>
                  setAction({
                    ...productAction,
                    action
                  })
                }
                id={`modals_associated_transmitter_${productAction.productType}_${action}_option`}
              >
                {t(i18nKey)}
              </Radio>
            </GridCell>
          ))}
        </Fragment>
      ))}
    </Container>
  );
}

TransmitterDown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired
    })
  ).isRequired,
  setAction: PropTypes.func.isRequired
};

export default TransmitterDown;
