const units = require('acua-convert-units');

export const toUserVolume = (value, user) => units.toUserVolume(value, { user });

export const getVolumeUnitKey = (volumeUnit) => `VOLUME_UNIT__${volumeUnit.toUpperCase()}`;

export default {
  getVolumeUnitKey,
  toUserVolume
};
