import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { useAppContext } from '@acua/common/hooks';
import CustomSimpleDialog from '../components/CustomSimpleDialog';

function ConfirmModal({ actionKey, actionData, title, ...props }) {
  const t = useTranslations();
  const { initActionToConfirm, confirmAction, resetActionToConfirm } = useAppContext();

  useEffect(() => {
    initActionToConfirm(actionKey, actionData);
  }, []);

  return (
    <CustomSimpleDialog
      key="confirm_modal"
      title={title || t('MODAL_CONFIRM__TITLE')}
      // you can pass custom props as title, body, acceptLabel,... but they should be only serializable props (strings)
      {...props}
      onAccept={() => (props.onAccept ? props.onAccept() : confirmAction(actionKey))}
      onCancel={() => (props.onCancel ? props.onCancel() : resetActionToConfirm(actionKey))}
    />
  );
}

ConfirmModal.propTypes = {
  actionKey: PropTypes.string.isRequired,
  title: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  actionData: PropTypes.any
};

ConfirmModal.defaultProps = {
  actionData: null,
  title: null,
  onAccept: null,
  onCancel: null
};

export default ConfirmModal;
