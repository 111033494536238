import React from 'react';
import { useTranslations } from '@react-utils/intl';
import { useField } from 'react-final-form';
import { TextField } from '@rmwc/textfield';
import { composeValidators, required, isValidEmail } from '@acua/common/utils';
import ErrorLabel from '../common/ErrorLabel';

const validate = composeValidators(required, isValidEmail);

export default function () {
  const t = useTranslations();
  const { input, meta } = useField('email', { validate });
  return (
    <div>
      <TextField
        {...input}
        id="modals_email_input"
        label={t('MODAL__ADD_ALARM_SUBSCRIPTION__FIELD__EMAIL')}
        value={input.value}
        type="text"
        invalid={meta.touched && meta.invalid}
      />
      {meta.touched && meta.invalid && <ErrorLabel>{t(meta.error)}</ErrorLabel>}
    </div>
  );
}
