import {
  alarmStatusI18nKeys,
  productAlarmTypesI18nKeys,
  booleanI18nKeys
} from '@acua/common/constants';
import { formatDateTime, FORMAT_DATE_TIME } from '@acua/common/utils';
import moment from 'moment-timezone';
import { isEmpty, path } from 'ramda';
import React from 'react';
import { Counter, First, Container } from '../../common/components/Table/Cells/ArrayCell/elements';
import { UninformedCell } from '../../common/components/Table';
import AlarmStatusBadge from '../../common/components/AlarmStatusBadge';
import {
  anSerialNumber,
  anSite,
  anDeviceSerialNumber,
  anComments,
  anSupplyPoint,
  anSector,
  anSubsector,
  anContractKey,
  anTenant
} from '../../common/utils/anonymize/anonymize';
import Uncalculated from '../Analytics/Content/Results/Uncalculated';

export const alarmAnalyticsShortKeyToPath = {
  tenantName: 'tenant.name',
  siteName: 'site.name',
  meter: 'meter',
  equipment: 'equipment',
  supplyPoint: 'supplyPoint.description',
  sector: 'supplyPoint.sector',
  subsector: 'supplyPoint.subsector',
  contractKey: 'supplyPoint.contractKey',
  alarmType: 'alarmType',
  startAt: 'startAt',
  endAt: 'endAt',
  daysActive: 'daysActive',
  status: 'status',
  accumulatedVolume: 'accumulatedVolume',
  comment: 'comment',
  justify: 'justify',
  siteId: 'site.id',
  tenantId: 'tenantId'
};

export const alarmAnalyticsPathToShortKey = Object.entries(alarmAnalyticsShortKeyToPath).reduce(
  (acc, [dataKey, p]) => ({
    ...acc,
    [p]: dataKey
  }),
  {}
);

const getStatus = path(alarmAnalyticsShortKeyToPath.status.split('.'));
const getAccumulatedVolume = path(alarmAnalyticsShortKeyToPath.accumulatedVolume.split('.'));

const linkedElements = (value, linkedValue, anonymize, anonymizeParams) => (
  <Container
    title={anonymize(value || linkedValue?.label, anonymizeParams)}
    key={value || linkedValue?.label}
  >
    {value ? (
      <First>{anonymize(value, anonymizeParams)}</First>
    ) : (
      <First>
        {linkedValue?.label ? `${anonymize(linkedValue.label, anonymizeParams)}...` : ''}
      </First>
    )}
    {linkedValue?.label && linkedValue?.count > 1 ? (
      <div>
        <Counter>+{linkedValue.count}</Counter>
      </div>
    ) : null}
  </Container>
);
const formatDate = () => ({
  type: 'date',
  pattern: FORMAT_DATE_TIME,
  additionalInfo: JSON.stringify({ timeZone: moment.tz.guess() })
});

const TENANT = {
  dataKey: alarmAnalyticsShortKeyToPath.tenantId,
  dataKeyRaw: 'tenantId',
  i18nKey: 'FILTER_TITLE_TENANT',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anTenant,
  cellProps: {
    style: {
      minWidth: 140
    }
  }
};
const SITE = {
  dataKey: alarmAnalyticsShortKeyToPath.siteId,
  dataKeyRaw: 'organizationalStructure.properties.name',
  i18nKey: 'PRODUCT__SITE',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anSite,
  cellProps: {
    style: {
      minWidth: 140
    }
  }
};

const SECTOR = {
  dataKey: alarmAnalyticsShortKeyToPath.sector,
  i18nKey: 'RESULTS_TABLE_COL_SECTOR',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anSector,
  renderCell: (row, id, anonymize) =>
    linkedElements(row.supplyPoint?.sector, row.linkedDevices?.sector, anonymize, row)
};

const SUBSECTOR = {
  dataKey: alarmAnalyticsShortKeyToPath.subsector,
  i18nKey: 'RESULTS_TABLE_COL_SUBSECTOR',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anSubsector,
  renderCell: (row, id, anonymize) =>
    linkedElements(row.supplyPoint?.subsector, row.linkedDevices?.subsector, anonymize, row)
};

function METER(filters) {
  const isTransmiterSelected = () => {
    const productTypeFilter = filters.find((filter) => filter.filterValue === 'productTypes');
    return productTypeFilter && productTypeFilter.value === 'Transmiter';
  };
  const isNotApplyed = isTransmiterSelected();
  return {
    dataKey: alarmAnalyticsShortKeyToPath.meter,
    i18nKey: 'ALARM_ANALYTICS_TABLE__METER',
    isSortable: true,
    isToggleable: true,
    isInitVisible: true,
    isNotApplyed,
    anonymize: anSerialNumber,
    cellProps: {
      style: {
        minWidth: '150px'
      }
    },
    renderCell: (row) => (
      <UninformedCell value={row[alarmAnalyticsShortKeyToPath.meter]} i18nKey="NOT_APPLY_VALUE" />
    )
  };
}

const EQUIPMENT = {
  dataKey: alarmAnalyticsShortKeyToPath.equipment,
  i18nKey: 'ALARM_ANALYTICS_TABLE__EQUIPMENT',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anDeviceSerialNumber
};

const SUPPLY_POINT = {
  dataKey: alarmAnalyticsShortKeyToPath.supplyPoint,
  i18nKey: 'ALARM_ANALYTICS_TABLE__SUPPLY_POINT',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  anonymize: anSupplyPoint,
  renderCell: (row, id, anonymize) =>
    linkedElements(row.supplyPoint?.description, row.linkedDevices?.supplyPoint, anonymize, row)
};

function CONTRACT_KEY(filters) {
  const isTransmiterSelected = () => {
    const productTypeFilter = filters.find((filter) => filter.filterValue === 'productTypes');
    return productTypeFilter && productTypeFilter.value === 'Transmiter';
  };
  const isNotApplyed = isTransmiterSelected();
  return {
    dataKey: alarmAnalyticsShortKeyToPath.contractKey,
    i18nKey: 'ALARM_ANALYTICS_TABLE__CONTRACT_KEY',
    isSortable: true,
    isToggleable: true,
    isInitVisible: true,
    isNotApplyed,
    anonymize: anContractKey,
    renderCell: (row, id, anonymize) => {
      return (
        row.supplyPoint?.contractKey &&
        linkedElements(row.supplyPoint?.contractKey, row.linkedDevices?.contractKey, anonymize)
      );
    }
  };
}

const ALARM_TYPE = (filters) => {
  const productTypeFilter = filters.find((filter) => filter.filterValue === 'productTypes');

  return {
    dataKey: alarmAnalyticsShortKeyToPath.alarmType,
    i18nKey: 'ALARM_ANALYTICS_TABLE__ALARM_TYPE',
    isSortable: productTypeFilter && productTypeFilter.value && !isEmpty(productTypeFilter.value),
    isToggleable: true,
    isInitVisible: true,

    translateAndFormatValue: (t) => (value) => t(productAlarmTypesI18nKeys[value]),
    customMapping: (translate) =>
      Object.keys(productAlarmTypesI18nKeys).map((key) => ({
        key,
        value: translate(productAlarmTypesI18nKeys[key])
      }))
  };
};

const START_AT = {
  dataKey: alarmAnalyticsShortKeyToPath.startAt,
  i18nKey: 'ALARM_ANALYTICS_TABLE__START_AT',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  formatValue: formatDateTime,
  format: formatDate
};

const END_AT = {
  dataKey: alarmAnalyticsShortKeyToPath.endAt,
  i18nKey: 'ALARM_ANALYTICS_TABLE__END_AT',
  isSortable: true,
  isToggleable: true,
  isInitVisible: false,
  formatValue: formatDateTime,
  format: formatDate
};

const DAYS_ACTIVE = {
  dataKey: alarmAnalyticsShortKeyToPath.daysActive,
  i18nKey: 'ALARM_ANALYTICS_TABLE__DAYS_ACTIVE',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true
};

const STATUS = {
  dataKey: alarmAnalyticsShortKeyToPath.status,
  i18nKey: 'ALARM_ANALYTICS_TABLE__STATUS',
  isSortable: true,
  isToggleable: true,
  isInitVisible: true,
  format: (t) => ({
    type: 'boolean',
    pattern: `${t(alarmStatusI18nKeys.enabled)};${t(alarmStatusI18nKeys.disabled)}`
  }),
  renderCell: (props) => <AlarmStatusBadge value={getStatus(props)} fitContent />,
  cellProps: {
    style: {
      display: 'flex',
      aligItems: 'center'
    }
  }
};

function ACCUMULATED_VOLUME(t, volumeKey, filters) {
  const isContinuedConsumption = () => {
    const alarmTypeFilter = filters.find((filter) => filter.filterValue === 'alarmTypes');
    return alarmTypeFilter && alarmTypeFilter.value.includes('continuousConsumptionAlarm');
  };
  const isNotApplyed = !isContinuedConsumption();

  return {
    dataKey: alarmAnalyticsShortKeyToPath.accumulatedVolume,
    label: `${t('ALARM_ANALYTICS_TABLE__ACCUMULATED_VOLUME')} (${t(volumeKey)})`,
    isSortable: true,
    isToggleable: true,
    isNotApplyed,
    isInitVisible: false,
    renderCell: (props) => <Uncalculated value={getAccumulatedVolume(props)} />
  };
}

const COMMENT = {
  dataKey: alarmAnalyticsShortKeyToPath.comment,
  i18nKey: 'ALARM_ANALYTICS_TABLE__COMMENTS',
  isSortable: false,
  isToggleable: true,
  isInitVisible: false,
  anonymize: anComments
};

const JUSTIFY = {
  dataKey: alarmAnalyticsShortKeyToPath.justify,
  i18nKey: 'ALARM_ANALYTICS_TABLE__JUSTIFY',
  isSortable: false,
  isToggleable: true,
  isInitVisible: false,
  translateAndFormatValue: (t) =>
    function (value) {
      return <UninformedCell value={t(booleanI18nKeys[value] || booleanI18nKeys.false)} />;
    },
  format: (t) => ({
    type: 'boolean',
    pattern: `${t(booleanI18nKeys.true)};${t(booleanI18nKeys.false)}`
  })
};

export const columns = (t, volumeKey, filters) => [
  TENANT,
  SITE,
  SECTOR,
  SUBSECTOR,
  METER(filters),
  EQUIPMENT,
  SUPPLY_POINT,
  CONTRACT_KEY(filters),
  ALARM_TYPE(filters),
  ACCUMULATED_VOLUME(t, volumeKey, filters),
  START_AT,
  END_AT,
  DAYS_ACTIVE,
  STATUS,
  JUSTIFY,
  COMMENT
];
