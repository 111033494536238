export function capitalize(str) {
  return typeof str === 'string' ? str.charAt(0).toUpperCase().concat(str.slice(1)) : null;
}

export function decapitalize(str) {
  return typeof str === 'string' ? str.charAt(0).toLowerCase().concat(str.slice(1)) : null;
}

export function upperCase(str) {
  return typeof str === 'string' ? str.toUpperCase() : null;
}

export const padLeftZero = (val, length = 2, str = '0') => `${val}`.padStart(length, str);

export const sortAlphabetically = (prop) => (a, b) => a[prop]?.localeCompare(b[prop]);
export const sortNumbers = (prop) => (a, b) => +a[prop] - +b[prop];
