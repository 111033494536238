import {
  ApolloClientsProvider,
  AppProvider,
  ModalsProvider,
  ReduxProvider,
  SnackbarsProvider,
  StorageProvider,
  UserProvider,
  FeatureFlagProvider
} from '@acua/common/contexts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { globalHistory, Router } from '@reach/router';
import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import ThemeProviders from '../../common/contexts/ThemeProviders';
import { Health } from '../lazyModules';

export default (TargetComponent) => {
  function ContextProviders(props) {
    return (
      <QueryParamProvider reachHistory={globalHistory}>
        <ThemeProviders>
          <ApolloClientsProvider>
            <ReduxProvider>
              <StorageProvider>
                <UserProvider>
                  <AppProvider>
                    <ModalsProvider>
                      <SnackbarsProvider>
                        <FeatureFlagProvider>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Router>
                              <Health path="/health" />
                              <TargetComponent path="/*" {...props} />
                            </Router>
                          </LocalizationProvider>
                        </FeatureFlagProvider>
                      </SnackbarsProvider>
                    </ModalsProvider>
                  </AppProvider>
                </UserProvider>
              </StorageProvider>
            </ReduxProvider>
          </ApolloClientsProvider>
        </ThemeProviders>
      </QueryParamProvider>
    );
  }

  ContextProviders.displayName = 'ContextProviders';
  return ContextProviders;
};
