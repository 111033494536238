import React from 'react';

import { useTranslations } from '@react-utils/intl';
import styled from '@emotion/styled';

import { NavBarContainer, NavBarAccount, NavBarApps, NavBarItem } from '@naui/core';
import { Avatar } from '@mui/material';
import { AccountCircle, AppsOutlined } from '@mui/icons-material';

import auth from '@acua/common/auth';
import { PlatformService } from '@acua/common/services';
import { RMWCVariables } from '@acua/common/contexts/ThemeProviders/variables';
import { useService, useUserContext } from '@acua/common/hooks';
import getDrawerSections from './getDrawerSections';
import Item from './Item';
import dinapsisLogo from '../../../../static/assets/dmLogo.png';

const computeApps = (apps) => {
  const className = 'app-navigator-item-icon';
  const styles = { fontSize: '3rem', color: RMWCVariables.secondary, opacity: '0.54' };

  return (apps || []).map(({ id, name, ...rest }, i) => ({
    id: id || i,
    name: name.replace('(', ' (').replace(')', ') '),
    ...rest,
    icon:
      i === 0 ? (
        <AccountCircle className={className} style={styles} />
      ) : (
        <AppsOutlined className={className} style={styles} />
      )
  }));
};

const Image = styled.img`
  width: 100%;
`;

const ItemContainer = styled.div`
  ${({ active }) =>
    active
      ? 'border-right: 4px solid rgb(114, 199, 215);'
      : 'border-right: 4px solid rgba(114, 199, 215, 0);'}
  li {
    ${({ active }) => (active ? '' : 'color: rgba(255, 255, 255, 1);')}
    button {
      ${({ active }) => (active ? 'background-color: rgb(41, 81, 132);' : '')}
    }
  }
`;

const DrawerContainer = styled.div`
  height: 100vh;
  width: 100vw;
  .MuiBackdrop-root {
    z-index: 2;
  }
`;

// eslint-disable-next-line react/prop-types
function Menu({ children }) {
  const t = useTranslations();
  const { profile, instances } = useUserContext();

  const { data } = useService(() => PlatformService.getUserApps(), {
    initialData: null,
    compute: computeApps
  });
  const avatarImg = instances.find((i) => i.imageUrl !== null)?.imageUrl;
  const title = t('MAIN_LOGO_TITLE');

  return (
    <DrawerContainer>
      <NavBarContainer
        title={title}
        id="header_drawer_dm"
        logo={
          <Avatar variant="rounded">
            <Image data-testid="logo" alt="logo" src={avatarImg || dinapsisLogo} />
          </Avatar>
        }
        renderItems={() =>
          getDrawerSections(t, () => {}, profile.language).map(({ Icon, ...item }) => (
            <NavBarItem {...item} icon={<Icon />} noAlphaColor />
          ))
        }
        renderTools={() => (
          <ItemContainer>
            <NavBarApps
              textLabel="Apps"
              items={data}
              getKeyItem={(item) => item.id}
              renderItem={({ item: { id, ...rest } }) => <Item id={id} {...rest} />}
            />
            <NavBarAccount
              avatar={<Avatar src={profile.accountInfo?.userImageLarge} />}
              name={profile.fullName}
              email={profile.email}
              onShowAccount={auth.goToAccount}
              onLogOut={auth.goToLogout}
              showAccountLabel={t('ACCOUNT_POPUP_LEFT_BUTTON_TITLE')}
              logOutLabel={t('ACCOUNT_POPUP_RIGHT_BUTTON_TITLE')}
            />
          </ItemContainer>
        )}
      >
        {children}
      </NavBarContainer>
    </DrawerContainer>
  );
}

export default Menu;
