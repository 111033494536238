import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { ProductsService } from '@acua/common/services';
import { modalTypes } from '@acua/common/constants';
import { useModalsContext, useSnackbarsContext } from '@acua/common/hooks';
import CustomSimpleDialog from '../components/CustomSimpleDialog';

function ConfirmReprocessModal({ data: { row }, ...props }) {
  const t = useTranslations();
  const title = t('MODAL_REPROCESS_PRODUCT_TITLE');
  const { openModal } = useModalsContext();
  const { addSnackbar } = useSnackbarsContext();

  const onAccept = () =>
    ProductsService.reprocess(row.productId, row.tenantId)
      .then(() => {
        addSnackbar('SNACKBAR_SAVE_SUCCESS');
      })
      .catch((err) => {
        openModal({ type: modalTypes.ERROR_DIALOG, data: { error: err } });
      });

  return <CustomSimpleDialog title={title} {...props} onAccept={onAccept} />;
}

ConfirmReprocessModal.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      tenantId: PropTypes.string.isRequired
    })
  }).isRequired
};

export default ConfirmReprocessModal;
