import { useEffect, useMemo } from 'react';

import { not, isEmpty, isNil, type, trim, pickBy } from 'ramda';
import { useTranslations } from '@react-utils/intl';
import { useQuery, useLazyQuery } from '@apollo/client';

import { getStatusOptions } from '../utils/helpers';
import useUserContext from './useUserContext';
import useApolloClientsContext from './useApolloClientsContext';
import useAppContext from './useAppContext';
import usePrevious from './usePrevious';
import useFeatureFlagContext from './useFeatureFlagContext';
import { entityTypes, productPathToShortKey } from '../constants/products';
import { mapColumns } from '../utils';
import {
  filterTenantId,
  getFilterSingleValue,
  getFilterValues
} from '../components/DrawerFilter/helpers/filters';
import { alarmAnalyticsPathToShortKey } from '../../Alarms/AnalitycsV2/columns';

const hasValue = (val) =>
  not(isEmpty(val) || isNil(val) || (type(val) === 'String' && isEmpty(trim(val))));

const cleanObject = pickBy(hasValue);

export default (family, query, { filters, form, pagination, sort }, columns = []) => {
  const t = useTranslations();

  const { exportClient } = useApolloClientsContext();
  const { featureFlags } = useFeatureFlagContext();

  const { getSitesByTenant } = useUserContext();
  const { currentApp, currentApps, searchKey } = useAppContext();
  const tenantFilterItem = filters
    ? filters.find((f) => getFilterSingleValue(f) === filterTenantId)
    : null;
  const tenantIds = tenantFilterItem?.value?.length
    ? tenantFilterItem?.value
    : currentApps.map((app) => app.id);

  const isSearchAlarmAnalyticsV2 = family === entityTypes.ALARMS;

  const variables = {
    tenantIds: form ? [currentApp?.id] : tenantIds,
    family,
    filters: {
      ...(form
        ? {
            siteIds: getSitesByTenant(currentApp?.id).map(({ id }) => id),
            status: getStatusOptions(currentApp?.capabilities, t).map((s) => s.value),
            ...cleanObject(form)
          }
        : {}),
      ...(filters
        ? {
            siteIds: tenantIds.flatMap((tenantId) =>
              getSitesByTenant(tenantId).map(({ id }) => id)
            ),
            ...getFilterValues(filters)
          }
        : {})
    },
    ...(form ? currentApp?.parsedCapabilities : {}),
    pageSize: pagination.size,
    offset: (pagination.page - 1) * pagination.size,
    sortField: isSearchAlarmAnalyticsV2
      ? alarmAnalyticsPathToShortKey[sort.column]
      : productPathToShortKey[sort.column],
    sortDirection: sort.order
  };

  const { data, loading, error, refetch, networkStatus } = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables,
    fetchPolicy: 'no-cache'
  });

  const [exportData, exportQueryStatus] = useLazyQuery(query, {
    client: exportClient
  });

  const handleExport = (format, tenantId) => {
    const mappedColumns = mapColumns(columns, t, variables);

    const updatedColumns = mappedColumns.map((column) => {
      if (column.sourceName === 'organizationalStructure.properties.name') {
        return {
          ...column,
          sourceName: 'site.name'
        };
      }
      return column;
    });

    return exportData({
      variables: {
        ...variables,
        tenantId,
        format,
        columns: updatedColumns,
        sheets: null,
        anonymize: featureFlags.usersEnabledDemo
      }
    });
  };

  const prevSearchKey = usePrevious(searchKey);
  useEffect(() => {
    if (prevSearchKey) {
      setTimeout(() => {
        refetch();
      }, 1500);
    }
  }, [searchKey]);

  const [totalCount, products] = useMemo(
    () =>
      data
        ? [data.searchAlarmAnalyticsV2.totalCount, data.searchAlarmAnalyticsV2.alarmAnalytics]
        : [0, null],
    [data]
  );

  return {
    totalCount,
    products,
    queryStatus: {
      loading,
      error,
      refetch,
      networkStatus
    },
    handleExport,
    exportQueryStatus
  };
};
