import React from 'react';
import PropTypes from 'prop-types';

import Highlight from 'react-highlighter';
import { Typography } from '@rmwc/typography';
import { LinkedEntities } from '@acua/common/components';
import styled from '@emotion/styled';
import { entityTypeIcons } from '../constants';
import { useFeatureFlagContext, useUserContext } from '../hooks';
import {
  anTenant,
  anSite,
  anContractKey,
  anonymizeEntityTypes,
  productTypesAnonymize
} from '../utils/anonymize/anonymize';

const Label = styled(Typography)`
  font-weight: 600;
  margin: 0;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  width: auto;
  margin: 2px 8px 0 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const InstanceLabel = styled.span`
  opacity: 0.5;
  font-weight: normal;
`;

function AnonymizedEntity({
  searchValue,
  tenant,
  site,
  linkedEntities,
  contract,
  displayName,
  family,
  tenantNewLine
}) {
  return (
    <Content>
      <Label tag="p" use="subtitle1">
        <Highlight search={searchValue}>{anonymizeEntityTypes[family](displayName)}</Highlight>
        {!tenantNewLine && tenant && <InstanceLabel>{` | ${anTenant(tenant)}`}</InstanceLabel>}
        {site && family !== 'sites' && <InstanceLabel>{` (${anSite(site)})`}</InstanceLabel>}
      </Label>
      {tenantNewLine && tenant && (
        <Label tag="p" use="subtitle1">
          <InstanceLabel>{anTenant(tenant)}</InstanceLabel>
        </Label>
      )}
      <LinkedEntities
        linkedEntities={linkedEntities?.map((l) => ({
          ...l,
          key: productTypesAnonymize[l.productType](l.key)
        }))}
        contract={{ key: anContractKey(contract?.key) }}
        inputSearch={searchValue}
      />
    </Content>
  );
}

function EntityAutocompleteItem({
  searchValue,
  tenantId,
  siteId,
  linkedEntities,
  contract,
  displayName,
  family,
  tenantNewLine
}) {
  const { instances, getSitesByTenant } = useUserContext();
  const { featureFlags } = useFeatureFlagContext();
  const tenant = instances.find((instance) => instance.id === tenantId);
  const site = siteId ? getSitesByTenant(tenantId)?.find((s) => s.id === siteId) : null;
  const Icon = entityTypeIcons[family];
  const getEmtpyDisplayName = () => linkedEntities?.map((l) => l?.key).join(', ');
  return (
    <StyledDiv>
      {Icon && (
        <IconContainer>
          <Icon
            style={{
              verticalAlign: 'middle',
              marginRight: '0.3em',
              color: '#415d9a'
            }}
          />
        </IconContainer>
      )}
      {featureFlags?.usersEnabledDemo ? (
        <AnonymizedEntity
          searchValue={searchValue}
          tenant={tenant.id}
          site={site.id}
          linkedEntities={linkedEntities}
          contract={contract}
          displayName={displayName}
          family={family}
          tenantNewLine={tenantNewLine}
        />
      ) : (
        <Content>
          <Label tag="p" use="subtitle1">
            <Highlight search={searchValue}>{displayName || getEmtpyDisplayName()}</Highlight>
            {!tenantNewLine && tenant && <InstanceLabel>{` | ${tenant.name}`}</InstanceLabel>}
            {site && family !== 'sites' && <InstanceLabel>{` (${site.name})`}</InstanceLabel>}
          </Label>
          {tenantNewLine && tenant && (
            <Label tag="p" use="subtitle1">
              <InstanceLabel>{tenant.name}</InstanceLabel>
            </Label>
          )}
          <LinkedEntities
            linkedEntities={linkedEntities}
            contract={contract}
            inputSearch={searchValue}
          />
        </Content>
      )}
    </StyledDiv>
  );
}

AnonymizedEntity.propTypes = {
  searchValue: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
  tenantNewLine: PropTypes.bool.isRequired,
  linkedEntities: PropTypes.arrayOf({
    productType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }).isRequired,
  contract: PropTypes.objectOf({
    key: PropTypes.string.isRequired
  }).isRequired,
  displayName: PropTypes.string.isRequired,
  family: PropTypes.string.isRequired
};

EntityAutocompleteItem.propTypes = {
  searchValue: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  tenantNewLine: PropTypes.bool,
  linkedEntities: PropTypes.arrayOf({
    productType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }).isRequired,
  contract: PropTypes.objectOf({
    key: PropTypes.string.isRequired
  }).isRequired,
  displayName: PropTypes.string.isRequired,
  family: PropTypes.string.isRequired
};

EntityAutocompleteItem.defaultProps = {
  tenantNewLine: false
};

export default EntityAutocompleteItem;
