export const EXPORT_THRESHOLD = 1000;

export const mapColumns = (columns, t, variables, currentApps) =>
  columns
    .filter((c) => c.includeInExports !== false)
    .map(
      ({
        exportSourceName,
        excelWidth,
        dataKey,
        dataKeyRaw,
        label,
        stringLabel,
        customMapping,
        format
      }) => ({
        sourceName: exportSourceName || dataKeyRaw || dataKey,
        targetName: stringLabel || label,
        excelWidth,
        ...(customMapping
          ? {
              customMapping:
                typeof customMapping === 'function' ? customMapping(t, currentApps) : customMapping
            }
          : {}),
        ...(format
          ? { format: typeof format === 'function' ? format(t, variables, currentApps) : format }
          : {})
      })
    )
    .filter((e) => e.sourceName && e.targetName);
