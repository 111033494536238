import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import background from './background.svg';
import boat from './boat.svg';
import sea from './sea.svg';

const boatAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(6deg);
  }
  50% {
    transform: rotateZ(0deg);
  }
  75% {
    transform: rotateZ(-6deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
`;

const ImgContainer = styled.div`
  background-color: transparent;
  width: 300px;
  height: 160px;
  margin: 0 auto;
  position: relative;
`;

const Img = styled.img`
  position: absolute;
`;

const Background = styled(Img)`
  width: 217px;
  height: 120px;
  left: 41px;
  top: 0;
`;

const Boat = styled(Img)`
  width: 210px;
  left: 42px;
  top: 11px;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
  animation: ${boatAnimation} 3.2s linear 0s 1 normal forwards running;
  animation-iteration-count: infinite;
`;

const Sea = styled(Img)`
  height: 63px;
  width: 100%;
  left: 0px;
  bottom: 0;
`;

export default function () {
  return (
    <ImgContainer>
      <Background src={background} alt="Something were worng..." />
      <Boat src={boat} alt="x" />
      <Sea src={sea} alt="x" />
    </ImgContainer>
  );
}
