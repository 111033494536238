import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell } from '@rmwc/grid';
import { Radio } from '@rmwc/radio';
import { entityTypesI18nKeys, getEntityType } from '@acua/common/constants';

const DeviceDownSection = styled(Grid)`
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 0 10px 10px;
  margin: 0;
`;

const actionOptions = [
  {
    action: 'warehouse',
    i18nKey: 'MODAL_OPTION_SEND_WAREHOUSE'
  },
  {
    action: 'down',
    i18nKey: 'MODAL_OPTION_DOWN'
  }
];

function MeterDown({ actions, setAction, associatedProducts }) {
  const t = useTranslations();
  const hasProductsHidden = (associatedProducts || []).some((prod) => prod.hasOtherMeters);
  const listOfProducts = (associatedProducts || []).filter((prod) => !prod.hasOtherMeters);

  if (hasProductsHidden && listOfProducts.length === 0) {
    return null;
  }

  return listOfProducts.length === 0 ? (
    <Typography use="subtitle2">{t('MODAL_OPTION_METER_ASSOC_PRODUCTS_EMPTY')}</Typography>
  ) : (
    <DeviceDownSection>
      <GridCell desktop="12" tablet="8">
        <Typography use="subtitle2">{t('MODAL_OPTION_ASSOC_PRODUCTS')}</Typography>
      </GridCell>
      {actions.map((productAction) => (
        <>
          <GridCell align="middle" desktop="2" tablet="2">
            <Typography style={{ fontWeight: 'bold' }} use="body2">
              {t(entityTypesI18nKeys[getEntityType(productAction.productType)])}
            </Typography>
          </GridCell>
          {actionOptions.map(({ action, i18nKey }) => (
            <GridCell key={action} desktop="5" tablet="3">
              <Radio
                value={action}
                checked={action === productAction.action}
                onChange={() =>
                  setAction({
                    ...productAction,
                    action
                  })
                }
                id={`modals_associated_meter_${action}_option`}
              >
                {t(i18nKey)}
              </Radio>
            </GridCell>
          ))}
        </>
      ))}
    </DeviceDownSection>
  );
}

MeterDown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired,
      action: PropTypes.string.isRequired,
      hasOtherMeters: PropTypes.bool
    })
  ).isRequired,
  setAction: PropTypes.func.isRequired,
  associatedProducts: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      productType: PropTypes.string.isRequired
    })
  ).isRequired
};

export default MeterDown;
