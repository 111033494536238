import React from 'react';
import PropTypes from 'prop-types';

import 'dayjs';

import moment from 'moment';
import { useTranslations } from '@react-utils/intl';
import styled from '@emotion/styled';

import { DatePicker } from '@naui/core';
import { useUserContext } from '../hooks';

const Container = styled.span`
  position: relative;
  display: inline-block;
`;
const DateInput = styled(DatePicker)`
  input {
    height: 35px;
    width: 200px;
  }
`;

function DatePickerInput({
  label,
  date,
  onChange,
  disabled,
  inputProps,
  datePickerProps,
  ...props
}) {
  const t = useTranslations();

  const {
    profile: {
      preferences: { language }
    }
  } = useUserContext();

  return (
    <Container id="date_picker_input" {...props}>
      <DateInput
        label={label || t('DATEPICKER_INPUT_LABEL')}
        value={date}
        onChange={(v) => {
          if (!v) {
            onChange(null);
            return;
          }
          onChange(v);
        }}
        onBlur={(v) => {
          const { value } = v.target;
          onChange(value);
        }}
        disableFuture
        inputProps={inputProps}
        disabled={disabled}
        calendarInputProps={datePickerProps}
        renderDateFormat="DD/MM/YYYY"
        stringObjectDateFormat="DD/MM/YYYY"
        locale={language?.split('-')[0] || 'es'}
        calendarComponentsProps={{
          leftArrowButton: {
            title: t('PREVIOUS_MONTH_ARROW')
          },
          rightArrowButton: {
            title: t('NEXT_MONTH_ARROW')
          }
        }}
        withClearButton={false}
      />
    </Container>
  );
}

DatePickerInput.propTypes = {
  label: PropTypes.string,
  date: PropTypes.instanceOf(moment),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    helpText: PropTypes.shape({
      persistent: PropTypes.bool,
      validationMsg: PropTypes.bool,
      children: PropTypes.node
    }),
    invalid: PropTypes.bool
  }),
  datePickerProps: PropTypes.shape()
};

DatePickerInput.defaultProps = {
  label: null,
  date: null,
  disabled: false,
  inputProps: {},
  datePickerProps: {}
};

export default DatePickerInput;
