import { sortBy } from '../../utils';
import { moduleNames, permissions } from '../../constants';

const getModulePermissions = (capabilities = []) => ({
  [moduleNames.ADMIN]: capabilities.includes(permissions.adminTasks),
  [moduleNames.ALARMS]:
    capabilities.includes(permissions.readAlarmConfigurationsManager) ||
    capabilities.includes(permissions.readAlarmConfigurationsTechnical) ||
    capabilities.includes(permissions.readAlarmConfigurationsCustomer),
  [moduleNames.FILE_MANAGER]: true,
  [moduleNames.ANALYTICS]: capabilities.includes(permissions.readAnalytics),
  [moduleNames.CONSUMPTIONS]: capabilities.includes(permissions.readConsumptions),
  [moduleNames.CONFIGURATIONS]: capabilities.includes(permissions.readDeviceConfiguration),
  [moduleNames.DEVICES]: capabilities.includes(permissions.readDevices),
  [moduleNames.METRICS]: capabilities.includes(permissions.readMetrics),
  [moduleNames.IMPORT]:
    capabilities.includes(permissions.importTransmitters) ||
    capabilities.includes(permissions.importDevices) ||
    capabilities.includes(permissions.exportData),
  [moduleNames.TRANSMITTERS]: capabilities.includes(permissions.readTransmitters)
});

const mergeModulePermisions = (objA, objB) =>
  Object.keys(objA).reduce((acc, key) => ({ ...acc, [key]: objA[key] || acc[key] }), objB);

const getAppModulePermissions = (instances) =>
  instances.reduce(
    (acc, instance) => mergeModulePermisions(acc, getModulePermissions(instance.capabilities)),
    getModulePermissions()
  );

const parseCapabilities = (capabilities) =>
  Object.values(permissions).reduce(
    (acc, key) => ({ ...acc, [key]: capabilities.includes(key) }),
    {}
  );

export default (user) => ({
  instances: user.instances.map((instance) => ({
    ...instance,
    parsedCapabilities: parseCapabilities(instance.capabilities),
    instanceModulePermissions: getModulePermissions(instance.capabilities)
  })),
  appModulePermissions: getAppModulePermissions(user.instances),
  tags: sortBy(user.tags, 'name')
});
