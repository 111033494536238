import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@rmwc/typography';
import { useTranslations } from '@react-utils/intl';
import extractErrors from './extractErrors';
import { StyledWarningIcon, TitleWrapper, Title, Ul } from './elements';

function ErrorMsg({ error, titleTag, titleUse, icon, ...props }) {
  const t = useTranslations();
  const { title = null, details = [] } = useMemo(() => extractErrors(error), [error]);

  if (!error) return null;

  return (
    <div {...props}>
      <TitleWrapper>
        {icon === 'warning' ? <StyledWarningIcon /> : icon}
        <Title tag={titleTag} use={titleUse}>
          {t(title) || title}
        </Title>
      </TitleWrapper>
      {details.length > 0 && (
        <Ul>
          {details.map((err) => (
            <li key={err}>
              <Typography tag="p" use="body1">
                {err}
              </Typography>
            </li>
          ))}
        </Ul>
      )}
    </div>
  );
}

ErrorMsg.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)]),
  titleTag: PropTypes.string,
  titleUse: PropTypes.string,
  icon: PropTypes.node
};

ErrorMsg.defaultProps = {
  error: null,
  titleTag: 'h3',
  titleUse: 'body1',
  icon: null
};

export default ErrorMsg;
