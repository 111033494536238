import React from 'react';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext } from '@acua/common/hooks';
import CustomSnackbar from './components/CustomSnackbar';
import NewVersion from './components/NewVersion';
import { snackbarTypes } from '../../../common/constants';

const customSnackbars = {
  [snackbarTypes.NEW_VERSION]: NewVersion
};

const goToFileManager = (tenantId, isExport) => {
  let appId = tenantId;
  if (!appId) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    appId = params['app-id'] || null;
  }
  window.location.href = `/file-manager/${isExport ? 'exports' : 'imports'}${
    appId ? `?app-id=${appId}` : ''
  }`;
};

function Snackbars() {
  const t = useTranslations();
  const { snackbars, deleteSnackbar } = useSnackbarsContext();
  if (snackbars.length === 0) return null;

  return (
    <>
      {snackbars.map(({ id, message, isExport, isImport, tenantId, customType, data }) => {
        if (customType) {
          const Component = customSnackbars[customType];
          return Component ? (
            <Component key={id} data={data} deleteSnackbar={() => deleteSnackbar(id)} />
          ) : null;
        }
        return (
          <CustomSnackbar
            key={id}
            id="snackbar_content"
            message={t(message) || message}
            deleteSnackbar={() => deleteSnackbar(id)}
            actionLabel={isExport || isImport ? t('GO_TO_FILE_MANAGER') : t('SNACKBAR_ACCEPT_BTN')}
            onAccept={isExport || isImport ? () => goToFileManager(tenantId, isExport) : null}
          />
        );
      })}
    </>
  );
}

export default Snackbars;
