import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import { Typography } from '@rmwc/typography';
import React, { useState } from 'react';
import { Grid, GridCell } from '@rmwc/grid';
import { Form } from 'react-final-form';
import { Notification, Loading } from '@acua/common/components';
import { deviceTypes, meterTypes } from '../../../../../../common/constants';
import { useAppContext, useProduct, useUserContext } from '../../../../../../common/hooks';
import Status from '../../../components/Status';
import { StyledOverflowMarginDialog } from '../../common/elements';
import Site from '../Site';
import Tenant from '../Tenant';
import DateField from '../../common/DateField';
import TimeField from '../../common/TimeField';
import useSendOtherTenant from '../useSendOtherTenant';
import { getProductById } from '../getProductById.gql';
import DeviceDown from '../../common/DeviceDown';
import { extractThresholdDate } from '../utils';

const hasAssociatedMeter = (data) =>
  data?.product?.inventory?.linkeds?.length &&
  data.product.inventory.linkeds.some((a) => Object.values(meterTypes).includes(a.productType));

const hasAssociatedDevice = (data) =>
  data?.product?.inventory?.linkeds?.length &&
  data.product.inventory.linkeds.some((a) => Object.values(deviceTypes).includes(a.productType));

function ChangeTenantDevices({ data: { row, productType }, ...props }) {
  const t = useTranslations();
  const { currentApps } = useAppContext();
  const { allSites } = useUserContext();
  const { sendData, modalError } = useSendOtherTenant(row.productId);

  const { data, loading } = useProduct(row.id, getProductById);
  const startDate = extractThresholdDate(row, data);
  const deviceProductType = data?.product?.productType ? data?.product?.productType : productType;
  const isBus = [deviceTypes.BUSVHF_V1, deviceTypes.BUSNBIOT].includes(deviceProductType);
  const [meterLinked, setMeterLinked] = useState(!isBus);
  const hasAssociatedItem = hasAssociatedMeter(data) || hasAssociatedDevice(data);
  const belongsToLNS =
    deviceTypes.LORA === deviceProductType ||
    (row?.inventory?.associatedEquipment?.productType || {}) === deviceTypes.LORA;

  return (
    <Form
      onSubmit={(values) => sendData({ ...values, withPeer: meterLinked })}
      subscription={{ invalid: true }}
    >
      {({ form, handleSubmit }) => (
        <StyledOverflowMarginDialog
          {...props}
          tag="form"
          title={t('PRODUCT_ACTION_CHANGE_TENANT')}
          onAccept={handleSubmit}
          acceptLabel={t('SEND')}
          acceptDisabled={form.getState().invalid}
        >
          <Status data={modalError} />
          {belongsToLNS ? (
            <Notification
              type="warning"
              label={t('CHANGE_TENANT_MODAL_LNS_TEXT')}
              style={{ marginBottom: 20 }}
            />
          ) : null}
          <Typography tag="p" use="body2" style={{ marginTop: '15px' }}>
            {t('CHANGE_TENANT_MODAL_TEXT_DEVICE')}
          </Typography>
          <Tenant
            tenantId={row.tenantId}
            tenants={currentApps}
            productType={row.inventory.associatedEquipment?.productType || row.productType}
          />
          <Site sites={allSites} />

          <Typography tag="p" use="body2" style={{ marginBottom: '0px' }}>
            {t('CHANGE_TENANT_MODAL_TEXT_DATE')}
          </Typography>
          <Grid style={{ marginTop: '0px' }}>
            <GridCell desktop="6" tablet="4">
              <DateField initialDate={startDate} />
            </GridCell>
            <GridCell desktop="6" tablet="4">
              <TimeField />
            </GridCell>
          </Grid>
          {loading && (
            <Loading.AbsoluteContainer withBackdrop>
              <Loading message={t('LOADING_MESSAGE')} spinnerSize={30} />
            </Loading.AbsoluteContainer>
          )}
          {!loading && hasAssociatedItem && !isBus && (
            <DeviceDown
              dissasociateText="MODAL_OPTION_UNLINKED_AND_KEEP_TENANT"
              linkedText="MODAL_OPTION_LINKED_PLURAL"
              meterLinked={meterLinked}
              setMeterLinked={setMeterLinked}
              showModalLabel={false}
            />
          )}
        </StyledOverflowMarginDialog>
      )}
    </Form>
  );
}

ChangeTenantDevices.propTypes = {
  data: PropTypes.shapeOf({
    row: PropTypes.shapeOf({
      productId: PropTypes.string
    })
  }).isRequired
};
export default ChangeTenantDevices;
