const WHITE = '#fff';
const WHITE_05 = '#fefefe';
const WHITE_BG = '#f5f5f5';
const BLACK = '#000';
const BLACK_54 = '#757575';
const BLACK_38 = '#9c9c9c';
const BLUE = '#415d9a';
const BLUE_DARK = '#213e4a';
const GUNMETAL = '#283238';
const GREEN = '#6d8f00';
const ERROR_RED = '#f36060';
const ERROR_RED_BACKGROUND = '#f3606026';
const ERROR_ORANGE_RED = '#dd3f0e';
// const ERROR_DARK_RED = '#b00020';
const WARNING_ORANGE_YELLOW = '#f49719';

const SUCCESS_GREEN = '#6d8f00';
const SHINE_GREEN = '#23be5d';
const INFO_BLUE = '#4a90e2';

const BLUE_NIMB = '#415c9a';
const ORANGE_CARROT = '#F49719';
const YELLOW_CACA = '#c3d442';
const VIOLET = '#774181';
const MAGENTA = '#e44985';
const TURQUOISE = '#3a9679';

export const RMWCVariables = {
  primary: BLUE, // The theme primary color
  secondary: BLUE_DARK, // The theme secondary color
  background: WHITE, // The theme background color
  surface: WHITE, // The theme surface color
  error: ERROR_RED,

  onPrimary: WHITE, // A text/iconography color that is usable on top of primary color
  onSecondary: WHITE, // A text/iconography color that is usable on top of secondary color
  onSurface: BLACK, // A text/iconography color that is usable on top of surface color

  textPrimaryOnBackground: GUNMETAL, // 'rgba(0, 0, 0, 0.87)',
  textSecondaryOnBackground: BLACK_54,
  textHintOnBackground: BLACK_38,
  textDisabledOnBackground: BLACK_38,
  textIconOnBackground: BLACK_38,

  // text-<TEXT_STYLE>-on-light - TEXT_STYLE on top of a light background
  textPrimaryOnLight: GUNMETAL, // 'rgba(0, 0, 0, 0.87)',
  textSecondaryOnLight: BLACK_54,
  textHintOnLight: BLACK_38,
  textDisabledOnLight: BLACK_38,
  textIconOnLight: BLACK_38,

  // text-<TEXT_STYLE>-on-dark - TEXT_STYLE on top of a dark background
  textPrimaryOnDark: WHITE,
  textSecondaryOnDark: WHITE_05,
  textHintOnDark: WHITE_05,
  textDisabledOnDark: WHITE_05,
  textIconOnDark: WHITE_05
};

export default {
  ...RMWCVariables,

  appBgColor: WHITE_BG,
  primaryText: GUNMETAL,
  secondaryText: '#b2b2b2',

  colorFontInfo: INFO_BLUE,
  colorFontSuccess: SUCCESS_GREEN,
  colorChartOk: SHINE_GREEN,
  colorFontWarning: WARNING_ORANGE_YELLOW,
  colorFontError: ERROR_RED,
  colorFontErrorBackground: ERROR_RED_BACKGROUND,
  colorFontDisabled: BLACK_38,
  colorFontWhite: WHITE_05,

  darkButonBg: BLACK_54,
  darkBtnBorder: BLACK_38,

  maxContentWidth: '1240px',
  filterWidth: '220px',
  sidebarWidth: '450px',

  inputBg: '#f5f5f5',

  zIndexContainer_Sidebar: 5, // Caution: It breaks the modals placed inside the DrawerAppContent
  zIndexHeader: 5,
  zIndexModal: 10,
  zIndexSnack: 15,
  zIndexTooltip: 410, // because .leaflet-pane has z-index: 400

  stausBadgeColors: {
    TRANSPARENT: { text: 'rgba(0, 0, 0, 0.87)', bg: 'transparent' },
    GREEN: { text: GREEN, bg: 'rgba(109, 143, 0, 0.25)' },
    ORANGE: { text: WARNING_ORANGE_YELLOW, bg: 'rgba(244, 151, 25, 0.2)' },
    RED: { text: ERROR_ORANGE_RED, bg: 'rgba(221, 63, 14, 0.2)' },
    GREY: { text: '#828282', bg: 'rgba(130, 130, 130, 0.2)' },
    BLUE: { text: '#415d9a', bg: 'rgba(89, 120, 191, 0.2)' },
    BLUE_DARK: { text: WHITE, bg: 'rgba(63, 93, 154, 0.9)' },
    GREEN_WHITE: { text: WHITE, bg: 'rgba(109, 143, 0, 0.35)' }
  },
  productColors: [BLUE_NIMB, ORANGE_CARROT, YELLOW_CACA, VIOLET, MAGENTA, TURQUOISE],
  chartColors: {
    chartLine1: '#a81671',
    chartLine2: '#415c9a',
    chartLine3: '#f49719',
    chartLine4: '#BAD7E9',
    chartLine5: '#C85C8E',
    chartLine6: '#3A8891',
    chartLine7: '#63AABC',
    chartLine8: '#BAABDA',
    chartLine9: '#FBCFFC',
    chartLine10: '#FE7E6D'
  },

  scrollbarColor: '#babac0',
  scrollbarHoverColor: '#9595a2',

  chartLine1: '#a81671',
  chartLine2: '#415c9a',
  chartLine3: '#f49719'
};
