import moment from 'moment';

export const filterTenantId = 'tenantIds';
export const filterSiteId = 'siteIds';
export const filterTenantDependants = [
  filterSiteId,
  'zoneManagementsIds',
  'concessionManagementsIds',
  'areaManagersIds',
  'sectorIds',
  'subsectorIds'
];
export const filterSiteDependants = ['sectorIds', 'subsectorIds'];
export const getFilterSingleValue = (item) => item.filterValue || item.id;

export const isValueEmpty = (value) =>
  value === undefined ||
  value === null ||
  (Array.isArray(value) && value.length === 0) ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  value === 'all';

export const getValue = (acc, curr) => {
  let v = curr.value;
  if (Array.isArray(acc?.[getFilterSingleValue(curr)])) {
    v = Array.from(new Set([...acc[getFilterSingleValue(curr)], ...v]));
  } else if (v === 'true') {
    v = true;
  } else if (v === 'false') {
    v = false;
  } else if (curr.type === 'date' && Object.keys(v).length > 0) {
    const { startDate, endDate } = v || {};
    v = {
      startDate: startDate ? moment(startDate).startOf('day').toISOString() : undefined,
      endDate: endDate ? moment(endDate).endOf('day').toISOString() : undefined
    };
  }
  return isValueEmpty(v) ? undefined : v;
};

export const getFilterValues = (filters, isAggregate = false) => {
  const aggregateFilters = [
    filterTenantId,
    filterSiteId,
    'zoneManagementsIds',
    'concessionManagementsIds',
    'areaManagersIds',
    'sectorIds',
    'subsectorIds'
  ];
  const notIncludedInNoAggregateFilter = [filterTenantId];
  return filters
    .filter((f) =>
      isAggregate
        ? aggregateFilters.includes(getFilterSingleValue(f))
        : !notIncludedInNoAggregateFilter.includes(getFilterSingleValue(f))
    )
    .reduce((acc, curr) => {
      let v = getValue(acc, curr);
      if (curr.id === filterSiteId && !v) {
        const tenants = filters.find((f) => f.id === filterTenantId)?.value;
        v = curr.options
          .filter((s) => !tenants?.length || tenants.includes(s.tenantId))
          .map((s) => s.id);
      }
      return {
        ...acc,
        ...(v !== undefined ? { [getFilterSingleValue(curr)]: v } : {})
      };
    }, {});
};

export const cleanValues = (item, value) => {
  if (Array.isArray(item.value)) {
    if (value) {
      // eslint-disable-next-line no-param-reassign
      item.value = item.value.filter((v) => v !== value);
    } else {
      // eslint-disable-next-line no-param-reassign
      item.value = item.defaultValue || [];
    }
  } else if (typeof value === 'object') {
    // eslint-disable-next-line no-param-reassign
    item.value = {};
  } else {
    // eslint-disable-next-line no-param-reassign
    item.value = 'all';
  }
  return item;
};

export const cleanFilter = (filters, setFilters, setQSParams) => {
  setFilters(
    filters.map((f) => {
      const newValue = cleanValues(f);
      if (setQSParams && f.qsParam) {
        setQSParams({ [f.qsParam]: getValue(null, newValue) });
      }
      return newValue;
    })
  );
};

export const isFilterEmpty = (filters) => filters.every(({ value }) => isValueEmpty(value));

const setRelatedFilterValues = (id, filters, value) => {
  const qsParams = {};
  if (id === filterTenantId) {
    filterTenantDependants.forEach((f) => {
      const item = filters.find((filter) => filter.id === f);
      if (item?.value?.length) {
        item.value = item.value.filter((v) => {
          const itemTenant = item.options?.find((opt) => opt.id === v)?.tenantId;
          return value.some((val) => val === itemTenant);
        });
        qsParams[item.qsParam] = item.value;
      }
    });
  }
  if (id === filterSiteId) {
    filterSiteDependants.forEach((f) => {
      const item = filters.find((filter) => filter.id === f);
      if (item?.value?.length) {
        item.value = item.value.filter((v) => {
          const itemSite = item.options?.find((opt) => opt.id === v)?.siteId;
          return value.some((val) => val === itemSite);
        });
        qsParams[item.qsParam] = item.value;
      }
    });
  }
  return qsParams;
};

export const removeFilterValue = (filterItem, value, filters, setFilters, setQSParams) => {
  const id = getFilterSingleValue(filterItem);
  setFilters(
    filters.map((filter) => {
      if (getFilterSingleValue(filter) === id) {
        const newValue = cleanValues(filter, value);
        const qsParams = setRelatedFilterValues(id, filters, newValue.value);
        if (setQSParams && filter.qsParam) {
          qsParams[filter.qsParam] = getValue(null, newValue);
          setQSParams(qsParams);
        }
        return newValue;
      }
      return filter;
    })
  );
};

export const setFiltersValue = (filters, setFilters, { id, value }, setQSParams) => {
  const qsParams = setRelatedFilterValues(id, filters, value);
  setFilters(
    filters.map((filter) => {
      if (setQSParams && filter.qsParam && filter.id === id) {
        qsParams[filter.qsParam] = getValue(null, { value });
        setQSParams(qsParams);
      }
      return { ...filter, value: filter.id === id ? value : filter.value };
    })
  );
};

export const getTenantFromFilter = (filters) => {
  const tenants = new Set([
    ...filterTenantDependants.flatMap((f) => {
      const item = filters.find((filter) => filter.id === f);
      return item?.value?.length
        ? item.value.map((v) => item.options?.find((opt) => opt.id === v)?.tenantId)
        : [];
    }),
    ...(filters.find((f) => f.id === filterTenantId)?.value || [])
  ]);
  return tenants.size === 1 ? Array.from(tenants)[0] : null;
};
