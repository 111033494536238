import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  QS_KEY_INVENTORY_PRODUCT_CALIBER,
  QS_KEY_INVENTORY_PRODUCT_MANUFACTURER,
  QS_KEY_INVENTORY_PRODUCT_MODEL
} from './queryStringKeys';

export const moduleNames = {
  ADMIN: 'admin',
  ALARMS: 'alarms',
  ANALYTICS: 'analytics',
  CONFIGURATIONS: 'configurations',
  CONSUMPTIONS: 'consumptions',
  DEVICES: 'devices',
  METRICS: 'metrics',
  FILE_MANAGER: 'fileManager',
  IMPORT: 'import',
  SIDEBAR: 'sidebar',
  TRANSMITTERS: 'transmitters'
};

export const modules = {
  [moduleNames.ADMIN]: {
    path: 'administration',
    isMultitenant: true,
    isAdmin: true
  },
  [moduleNames.ALARMS]: {
    path: 'alarms',
    isMultitenant: false,
    navBar: () => [
      {
        i18nKey: 'NAV_BAR__ALARMS__ANALYTICS',
        path: 'analytics'
      },
      { i18nKey: 'NAV_BAR__ALARMS__MANAGER_CONFIG', path: 'manager' },
      { i18nKey: 'NAV_BAR__ALARMS__CLIENT_CONFIG', path: 'customer' }
    ]
  },
  [moduleNames.ANALYTICS]: {
    path: 'analytics',
    isMultitenant: false,
    navBar: (featureFlags) => [
      { i18nKey: 'NAV_BAR__ANALYTICS__OVERVIEW_LABEL', path: '' },
      { i18nKey: 'NAV_BAR__ANALYTICS__TRANSMITTERS_LABEL', path: 'transmitters' },
      { i18nKey: 'NAV_BAR__ANALYTICS__DEVICES_LABEL', path: 'devices' },
      {
        i18nKey: 'NAB_BAR__ANALYTICS__COVERAGE',
        path: 'coverage',
        style: featureFlags.coverage ? {} : { display: 'none' }
      },
      {
        i18nKey: 'NAB_BAR__ANALYTICS__READING_QUALITY',
        path: 'reading-quality',
        style: featureFlags.qualityReadings ? {} : { display: 'none' }
      },
      {
        i18nKey: 'NAB_BAR__ANALYTICS__POWERBI_REPORTS',
        path: 'powerbi-reports'
      }
    ]
  },
  [moduleNames.CONFIGURATIONS]: {
    path: 'configurations',
    isMultitenant: false
  },
  [moduleNames.CONSUMPTIONS]: {
    path: 'consumptions',
    isMultitenant: true
  },
  [moduleNames.DEVICES]: {
    path: 'devices',
    isMultitenant: true,
    keepQsParamsBetweenTabs: true,
    avoidShareQs: [
      QS_KEY_INVENTORY_PRODUCT_CALIBER,
      QS_KEY_INVENTORY_PRODUCT_MANUFACTURER,
      QS_KEY_INVENTORY_PRODUCT_MODEL
    ],
    navBar: () => [
      { i18nKey: 'METERS', path: 'meters' },
      { i18nKey: 'EQUIPMENT', path: 'equipments' }
    ]
  },
  [moduleNames.METRICS]: {
    path: 'metrics',
    isMultitenant: true
  },
  [moduleNames.FILE_MANAGER]: {
    path: 'file-manager',
    isMultitenant: true,
    navBar: () => [
      {
        i18nKey: 'NAV_BAR__FILE_MANAGER__IMPORTS',
        path: 'imports',
        Icon: FileUploadIcon
      },
      {
        i18nKey: 'NAV_BAR__FILE_MANAGER__DOWNLOADS',
        path: 'exports',
        Icon: FileDownloadIcon
      }
    ]
  },
  [moduleNames.IMPORT]: {
    isMultitenant: false
  },
  [moduleNames.SIDEBAR]: {
    isMultitenant: false
  },
  [moduleNames.TRANSMITTERS]: {
    path: 'transmitters',
    isMultitenant: false
  }
};
