import React from 'react';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Grid, GridCell } from '@rmwc/grid';
import { Radio } from '@rmwc/radio';
import { Typography } from '@rmwc/typography';
import PropTypes from 'prop-types';

const DeviceDownSection = styled(Grid)`
  background-color: rgba(216, 216, 216, 0.2);
  padding: 10px 0 10px 10px;
  margin: 0;
`;

function DeviceDown({
  metersAction,
  setMetersAction,
  meterLinked,
  setMeterLinked,
  dissasociateText,
  linkedText,
  showModalLabel
}) {
  const t = useTranslations();
  return (
    <DeviceDownSection>
      <GridCell desktop="12" tablet="8">
        <Typography use="subtitle2">{t('MODAL_OPTION_ASSOC_PRODUCTS')}</Typography>
      </GridCell>
      {showModalLabel && (
        <GridCell align="middle" desktop="2" tablet="2">
          <Typography style={{ fontWeight: 'bold' }} use="body2">
            {t('MODAL_OPTION_METER')}
          </Typography>
        </GridCell>
      )}
      <GridCell desktop={showModalLabel ? 5 : 6} tablet={showModalLabel ? 3 : 4}>
        <Radio
          {...(metersAction
            ? {
                value: 'warehouse',
                checked: metersAction === 'warehouse'
              }
            : {
                checked: meterLinked
              })}
          onChange={(evt) =>
            setMetersAction ? setMetersAction(evt.currentTarget.value) : setMeterLinked(true)
          }
          id={metersAction ? 'modals_associated_device_warehouse_option' : 'modals_linked_option'}
        >
          {t(linkedText)}
        </Radio>
      </GridCell>
      <GridCell desktop={showModalLabel ? 5 : 6} tablet={showModalLabel ? 3 : 4}>
        <Radio
          {...(metersAction
            ? {
                value: 'down',
                checked: metersAction === 'down'
              }
            : {
                checked: !meterLinked
              })}
          onChange={(evt) =>
            setMetersAction ? setMetersAction(evt.currentTarget.value) : setMeterLinked(false)
          }
          id={
            metersAction
              ? 'modals_associated_device_warehouse_option'
              : 'modals_dissasociate_option'
          }
        >
          {t(dissasociateText)}
        </Radio>
      </GridCell>
    </DeviceDownSection>
  );
}

DeviceDown.propTypes = {
  metersAction: PropTypes.string.isRequired,
  setMetersAction: PropTypes.func.isRequired,
  meterLinked: PropTypes.bool.isRequired,
  setMeterLinked: PropTypes.func.isRequired,
  dissasociateText: PropTypes.string.isRequired,
  linkedText: PropTypes.string.isRequired,
  showModalLabel: PropTypes.bool.isRequired
};

export default DeviceDown;
