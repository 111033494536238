import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { GridCell } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';

const Icon = styled.i`
  font-size: 3rem;
  color: ${({ theme }) => theme.secondary};
  opacity: 0.54;
`;

const Name = styled(Typography)`
  line-height: 1em;
  text-align: center;

  /* multiline ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledGridCell = styled(GridCell)`
  min-height: 60px;
  max-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 3px;

  background-color: #fff;
  &:hover {
    transition: background-color 0.2s linear;
    background: #eee;
  }

  ${Icon} + ${Name} {
    -webkit-line-clamp: 2;
  }
`;

function Item({ name, url, icon }) {
  return (
    <StyledGridCell
      desktop={4}
      tablet={2}
      phone={2}
      tag="a"
      href={url}
      target="_blank"
      rel="noopener"
    >
      {icon}
      <Name use="subtitle2">{name}</Name>
    </StyledGridCell>
  );
}

Item.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  icon: PropTypes.string
};

Item.defaultProps = {
  url: '#',
  icon: null
};

export default Item;
