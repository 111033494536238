import { deviceTypes, meterTypes, transmitterTypes, entityTypes } from './types';

const getEntityType = (productType) => {
  if (Object.values(deviceTypes).some((d) => d === productType)) {
    return entityTypes.DEVICES;
  }
  if (Object.values(meterTypes).some((m) => m === productType)) {
    return entityTypes.METERS;
  }
  if (Object.values(transmitterTypes).some((t) => t === productType)) {
    return entityTypes.TRANSMITTERS;
  }
  return productType;
};

// eslint-disable-next-line import/prefer-default-export
export { getEntityType };
