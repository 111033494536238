import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const SnackbarsContext = createContext();

const addSnackbar = (snackbars, message, opts) => [
  ...snackbars,
  {
    id: new Date().getTime(),
    message,
    ...opts
  }
];

const deleteSnackbar = (snackbars, id) => snackbars.filter((snackbar) => snackbar.id !== id);

export function SnackbarsProvider({ children }) {
  const [snackbars, setSnackbars] = useState([]);
  return (
    <SnackbarsContext.Provider
      value={{
        snackbars,
        addSnackbar: (message, opts) => setSnackbars(addSnackbar(snackbars, message, opts)),
        deleteSnackbar: (id) => setSnackbars(deleteSnackbar(snackbars, id))
      }}
    >
      {children}
    </SnackbarsContext.Provider>
  );
}

SnackbarsProvider.propTypes = {
  children: PropTypes.node.isRequired
};
