import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';

const StyledSnackbar = styled(Snackbar)`
  z-index: ${({ theme }) => theme.zIndexSnack};
`;

function CustomSnackbar({ onAccept, deleteSnackbar, actionLabel, ...props }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <StyledSnackbar
      {...props}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        deleteSnackbar();
      }}
      action={<SnackbarAction label={actionLabel} onClick={() => onAccept && onAccept()} />}
      dismissesOnAction
    />
  );
}

CustomSnackbar.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.node.isRequired, //    A string or other renderable JSX to be used as the message body.
  deleteSnackbar: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired, // Label for the action button.
  onAccept: PropTypes.func, // A callback thats fired when the Snackbar hides.
  // onShow: PropTypes.func, // A callback thats fired when the Snackbar shows.
  timeout: PropTypes.number, //    Milliseconds to show the Snackbar for.
  stacked: PropTypes.bool, // Places the action underneath the message text.
  leading: PropTypes.bool
};

CustomSnackbar.defaultProps = {
  onAccept: () => {},
  timeout: 5000,
  stacked: false,
  leading: false
};

export default CustomSnackbar;
