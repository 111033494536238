import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function Notification({ label, message, type, isCollapsible, ...props }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Collapse in={isOpen}>
      <Alert
        severity={type}
        action={
          <IconButton aria-label="close" color="inherit" size="small">
            {isCollapsible && <CloseIcon fontSize="inherit" onClick={() => setIsOpen(false)} />}
          </IconButton>
        }
        {...props}
      >
        <AlertTitle style={{ fontSize: '14px' }}>{label}</AlertTitle>
        {message}
      </Alert>
    </Collapse>
  );
}

Notification.propTypes = {
  label: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  isCollapsible: PropTypes.bool
};

Notification.defaultProps = {
  label: null,
  message: null,
  type: 'info',
  isCollapsible: false
};

export default Notification;
