import { SearchInputMultiple } from '@acua/common/components';
import { entityTypes } from '@acua/common/constants';
import PropTypes from 'prop-types';
import React from 'react';
import consumptionTypes from '../constants/consumptionTypes';

function SearchInput({
  maxItems,
  entities,
  onChange,
  placeholder,
  disabled,
  consumptionType,
  tenantIds
}) {
  return (
    <SearchInputMultiple
      id="consumptions_search_input"
      avoidCard
      searchEntities
      searchFamilies={
        consumptionType === consumptionTypes.DIARY
          ? [entityTypes.METERS, entityTypes.SUPPLY_POINTS, entityTypes.SITES]
          : [entityTypes.METERS]
      }
      tenantIds={tenantIds}
      onChange={onChange}
      maxChips={maxItems}
      disabled={disabled}
      placeholder={entities.length < maxItems ? placeholder : null}
      value={entities}
    />
  );
}

SearchInput.defaultProps = {
  onChange: () => {},
  placeholder: '',
  maxItems: 4,
  disabled: false,
  consumptionType: consumptionTypes.DIARY
};

SearchInput.propTypes = {
  entities: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      tenantId: PropTypes.string,
      family: PropTypes.string,
      displayName: PropTypes.string,
      icon: PropTypes.string
    })
  ]).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  maxItems: PropTypes.number,
  disabled: PropTypes.bool,
  tenantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  consumptionType: PropTypes.number
};

export default SearchInput;
