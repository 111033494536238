import { indexBy, merge, mergeWith, prop, values } from 'ramda';

export { default as sortBy } from './sortBy';

export const arrToObj = (arr, idKey = 'id') =>
  arr.reduce((memo, curr) => ({ ...memo, [curr[idKey]]: curr }), {});

export const arrToMap = (arr, idKey = 'id') =>
  arr.reduce((map, item) => map.set(item[idKey], item), new Map());

export const mergeArraysByKey = (key, arr1, arr2) => {
  const obj1 = indexBy(prop(key), arr1);
  const obj2 = indexBy(prop(key), arr2);
  return values(mergeWith(merge, obj1, obj2));
};

export const getReducedArrKeys = (obj) =>
  Object.entries(obj).reduce((acc, [key, value]) => (value ? acc.concat(key) : acc), []);
