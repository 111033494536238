import queryString from 'query-string';
import auth from '@acua/common/auth';
import { gatewayUrl } from '../config';

const importerUrl = `${gatewayUrl}/importer`;

const importAlarms = ({ file, type }) => {
  const formData = new FormData();
  formData.append('file', file[0]);

  return auth.authFetch(`${importerUrl}/alarms/${type}`, {
    method: 'POST',
    body: formData
  });
};

const importTransmitters = ({ file, status, instanceId }) => {
  const formData = new FormData();
  formData.append('file', file[0]);

  return auth.authFetch(`${importerUrl}/transmitters?status=${status}`, {
    method: 'POST',
    body: formData,
    instanceId
  });
};

const importDevices = ({ file, status, siteId, fileName, instanceId }) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  const query = queryString.stringify({ status, siteId, fileName }, { arrayFormat: 'bracket' });

  return auth.authFetch(`${importerUrl}/devices?${query}`, {
    method: 'POST',
    body: formData,
    instanceId
  });
};

const importReadings = ({ file, siteId, fileName, instanceId }) => {
  const formData = new FormData();
  formData.append('file', file[0]);
  const query = queryString.stringify({ siteId, fileName }, { arrayFormat: 'bracket' });

  return auth.authFetch(`${importerUrl}/readings?${query}`, {
    method: 'POST',
    body: formData,
    instanceId
  });
};

export default { importAlarms, importTransmitters, importDevices, importReadings };
