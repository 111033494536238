import { ErrorRefetch, Loading } from '@acua/common/components';
import { useUserContext } from '@acua/common/hooks';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React from 'react';

const FullPageContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function UserGuard({ children }) {
  const t = useTranslations();
  const { appModulePermissions, initialLoading, refetching, error, refetch } = useUserContext();
  if ((initialLoading && isEmpty(appModulePermissions)) || refetching) {
    return (
      <Loading.AbsoluteContainer>
        <Loading spinnerSize={80} />
      </Loading.AbsoluteContainer>
    );
  }
  if (error) {
    return (
      <FullPageContainer>
        <ErrorRefetch errorMsg={t('ERROR_MSG_QUERY_USER_RETRY')} refetch={refetch} />
      </FullPageContainer>
    );
  }
  return children;
}

UserGuard.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserGuard;
