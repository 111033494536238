import React from 'react';
import PropTypes from 'prop-types';
import { isValidDate } from '@acua/common/utils';
import { useField } from 'react-final-form';
import { useTranslations } from '@react-utils/intl';
import { DatePickerInput } from '@acua/common/components';
import moment from 'moment';

function DateField({ initialDate, disabled }) {
  const {
    input: { onChange, value, onBlur },
    meta: { error, invalid, touched }
  } = useField('date', {
    validate: (v) => {
      if (!v) {
        return 'Required';
      }
      if (!isValidDate(v)) {
        return 'Invalid date format';
      }
      if (moment(v, 'DD/MM/YYYY').isAfter(moment())) {
        return 'Invalid date format';
      }
      return undefined;
    }
  });
  const t = useTranslations();
  return (
    <DatePickerInput
      date={value}
      onChange={onChange}
      disabled={disabled}
      id="modals_date_picker_input"
      datePickerProps={{
        disableFuture: true,
        minDate: initialDate
      }}
      inputProps={{
        helpText: {
          persistent: false,
          validationMsg: true,
          children: t(error)
        },
        invalid: invalid && touched
      }}
      tabIndex="0"
      style={{ outline: 'none' }}
      //* We need to propagate onBlur in order to let react-final-form know about component's focus
      onBlur={onBlur}
    />
  );
}

DateField.propTypes = {
  initialDate: PropTypes.string,
  disabled: PropTypes.bool
};

DateField.defaultProps = {
  initialDate: null,
  disabled: false
};

export default DateField;
