import { permissions } from './constants';

export const projectName = 'acua';
export const appUrl = process.env.APP_URL;
export const gatewayUrl = process.env.GATEWAY_URL;
export const gatewayMapsUrl = process.env.GATEWAY_MAPS_URL;
export const platformUrl = process.env.PLATFORM_URL;
export const userDocsUrl = process.env.USER_DOCS_URL;
export const authSSOUrl = process.env.AUTHSSO_URL;
export const accountUrl = process.env.ACCOUNT_URL;
export const sharedResourcesUrl = process.env.SHAREDRESOURCES_URL;
export const configCatKey = process.env.CONFIG_CAT_KEY;
export const defaultTenant = process.env.DEFAULT_TENANT;
export const productId = process.env.PRODUCT_ID;

export const env = process.env.NODE_ENV;
export const acuaEnvironment = process.env.ACUA_ENVIRONMENT;

export const userDocsTranslationLanguages = ['en', 'pt'];

export const refreshTokenUrl = `${authSSOUrl}/refresh_token`;
export const defaultAvatarUrl = `${sharedResourcesUrl}/avatar/avatar05.svg`;
export const defaultAppUrl = `${sharedResourcesUrl}/logo/app01.svg`;

export const powerBiReports = [
  {
    id: process.env.POWERBI_REPORT__TRANSMITTER_PERFORMANCE__ID,
    datasetId: process.env.POWERBI_REPORT__TRANSMITTER_PERFORMANCE__DATASET_ID,
    path: 'transmitters-performance',
    i18nKey: 'POWERBI_REPORTS__REPORT__TRANSMITTER_PERFORMANCE',
    permission: permissions.readPwBIReportTransmittersPerformance
  },
  {
    id: process.env.POWERBI_REPORT__CRITICAL_METERS__ID,
    datasetId: process.env.POWERBI_REPORT__CRITICAL_METERS__DATASET_ID,
    path: 'critical-meters',
    i18nKey: 'POWERBI_REPORTS__REPORT__CRITICAL_METERS',
    permission: permissions.readPwBIReportCriticalMeters
  },
  {
    id: process.env.POWERBI_REPORT__EQUIPMENT_ANALYTICS__ID,
    datasetId: process.env.POWERBI_REPORT__EQUIPMENT_ANALYTICS__DATASET_ID,
    path: 'equipment-analytics',
    i18nKey: 'POWERBI_REPORTS__REPORT__EQUIPMENT_ANALYTICS',
    permission: permissions.readPwBIReportEquipmentAnalytics
  },
  {
    id: process.env.POWERBI_REPORT__KPI_PERFORMANCE__ID,
    datasetId: process.env.POWERBI_REPORT__KPI_PERFORMANCE__DATASET_ID,
    path: 'kpi-performance',
    i18nKey: 'POWERBI_REPORTS__REPORT__KPI_PERFORMANCE',
    permission: permissions.readPwBIReportKpiPerformance
  },
  {
    id: process.env.POWERBI_REPORT__METER_CONSUMPTION__ID,
    datasetId: process.env.POWERBI_REPORT__METER_CONSUMPTION__DATASET_ID,
    path: 'aquadom-meter-consumption-comparison',
    i18nKey: 'POWERBI_REPORTS__REPORT__METER_CONSUMPTION',
    permission: permissions.readPwBIReportAquadomMeterConsumptionComparison
  }
].filter(({ id }) => id);
