import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IntlProvider } from '@react-utils/intl';
import { useUserContext } from './hooks';
import { LoadingComponent } from './components';

const cacheTranslations = {};

const getLangByAvailability = (availableLangs, userLang) => {
  if (userLang) {
    if (availableLangs.includes(userLang)) return userLang;
    const [userMainLang] = userLang.split('-');

    const userAvailableLang = availableLangs.find((item) => item.startsWith(userMainLang));

    if (userAvailableLang) return userAvailableLang;
  }
  const browserLang = navigator.language;
  if (availableLangs.includes(browserLang)) return browserLang;

  const [mainLang] = browserLang.split('-');
  const availableLang = availableLangs.find((item) => item.startsWith(mainLang));
  if (availableLang) return availableLang;

  return availableLang.find((item) => item.startsWith('en'));
};

const getTranslationsFromNetwork = async (moduleName, currentLang) => {
  const availableLangs = await import(`../../static/intl/${moduleName}/locales.json`).then(
    (res) => res.default
  );
  const lang = getLangByAvailability(availableLangs, currentLang);

  const translations = await import(`../../static/intl/${moduleName}/${lang}.json`).then(
    (res) => res.default
  );

  cacheTranslations[[`${moduleName}|${currentLang}`]] = translations;
  return translations;
};

const getTranslations =
  ({ moduleNames }) =>
  (currentLang) => {
    return Promise.all(
      moduleNames.map((moduleName) => {
        const cached = cacheTranslations[`${moduleName}|${currentLang}`];
        if (cached) return cached;
        return getTranslationsFromNetwork(moduleName, currentLang);
      })
    ).then((arrays) =>
      arrays.reduce((result, current) => {
        return Object.assign(result, current);
      }, {})
    );
  };

export default function ModuleTranslationsProvider({ moduleNames, children }) {
  const { profile } = useUserContext();
  const [lang, setLang] = useState(null);

  useEffect(() => {
    const language = profile?.preferences?.language;
    if (language) {
      setLang(language);
      const datesLang = language.split('-')[0];
      moment.locale(datesLang);
    }
  }, [profile]);

  return lang ? (
    <IntlProvider
      defaultLang={lang}
      getTranslations={getTranslations({ moduleNames })}
      options={{ empty: process.env.NODE_ENV !== 'development' }}
    >
      {children}
    </IntlProvider>
  ) : (
    <LoadingComponent />
  );
}

ModuleTranslationsProvider.propTypes = {
  moduleNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired
};
