import {
  booleanI18nKeys,
  distributionNetworkI18nKeys,
  productTypesI18NKeys
} from '@acua/common/constants';
import { path } from 'ramda';
import React from 'react';
import { UninformedCell } from '../../../../../common/components';
import {
  anAlias,
  anArea,
  anConcession,
  anContractKey,
  anSector,
  anSite,
  anSubsector,
  anSupplyPoint,
  anZone
} from '../../../../../common/utils/anonymize/anonymize';

const meterCommonColumns = (t) => [
  {
    dataKey: 'associatedEquipmentTechnology',
    label: t('RESULTS_TABLE_COL_EQUIPMENT_TECHNOLOGY'),
    isSortable: true,
    isToggleable: true,
    cellProps: {
      style: {
        minWidth: 150
      }
    },
    renderCell: (row) => (
      <UninformedCell value={t(productTypesI18NKeys[row.associatedEquipmentTechnology])} />
    ),
    excelWidth: 20,
    exportSourceName: 'deviceTechnology'
  },
  {
    dataKey: 'supplyPoint.alias',
    label: t('PRODUCT__SUPPLY_POINT__ALIAS'),
    isSortable: true,
    isToggleable: true,
    anonymize: anAlias,
    renderCell: (row, id, anonymize) => (
      <UninformedCell value={anonymize(path('supplyPoint.alias'.split('.'), row), row)} />
    ),
    exportSourceName: 'supplyPointAlias',
    excelWidth: 40,
    cellProps: {
      style: {
        minWidth: 200
      }
    }
  },
  {
    dataKey: 'supplyPoint.description',
    label: t('RESULTS_TABLE_COL_DESCRIPTION'),
    isSortable: true,
    isToggleable: true,
    exportSourceName: 'supplyPointKey',
    excelWidth: 40,
    anonymize: anSupplyPoint,
    cellProps: {
      style: {
        minWidth: 230
      }
    }
  },
  {
    dataKey: 'supplyPoint.contract.key',
    label: t('RESULTS_TABLE_COL_POLICY'),
    isSortable: true,
    isToggleable: true,
    anonymize: anContractKey,
    renderCell: (row, id, anonymize) => (
      <UninformedCell value={anonymize(path('supplyPoint.contract.key'.split('.'), row))} />
    ),
    cellProps: {
      style: {
        minWidth: 100
      }
    },
    excelWidth: 20,
    exportSourceName: 'supplyPointContractKey'
  },
  {
    dataKey: 'supplyPoint.sector',
    label: t('RESULTS_TABLE_COL_SECTOR'),
    isSortable: true,
    isToggleable: true,
    exportSourceName: 'supplyPointSector',
    excelWidth: 40,
    anonymize: anSector,
    cellProps: {
      style: {
        minWidth: 200
      }
    }
  },
  {
    dataKey: 'supplyPoint.subsector',
    label: t('RESULTS_TABLE_COL_SUBSECTOR'),
    isSortable: true,
    isToggleable: true,
    excelWidth: 40,
    anonymize: anSubsector,
    exportSourceName: 'supplyPointSubsector',
    cellProps: {
      style: {
        minWidth: 150
      }
    }
  },
  {
    dataKey: 'organizationalStructure.siteName',
    label: t('RESULTS_TABLE_COL_SITE'),
    isSortable: true,
    isToggleable: true,
    excelWidth: 40,
    anonymize: anSite,
    exportSourceName: 'orgStructureSiteName',
    cellProps: {
      style: {
        minWidth: 180
      }
    }
  },
  {
    dataKey: 'organizationalStructure.areaManagerName',
    label: t('PRODUCT__EO_LEVEL_1'),
    isToggleable: true,
    anonymize: anArea,
    cellProps: {
      style: {
        minWidth: 180,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 40,
    exportSourceName: 'orgStructureAreaManagerName'
  },
  {
    dataKey: 'organizationalStructure.concessionAddressName',
    label: t('PRODUCT__EO_LEVEL_2'),
    isToggleable: true,
    anonymize: anConcession,
    cellProps: {
      style: {
        minWidth: 180,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 40,
    exportSourceName: 'orgStructureConcessionAddressName'
  },
  {
    dataKey: 'organizationalStructure.zoneAddressName',
    label: t('PRODUCT__EO_LEVEL_3'),
    isToggleable: true,
    anonymize: anZone,
    cellProps: {
      style: {
        minWidth: 180,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 40,
    exportSourceName: 'orgStructureZoneAddressName'
  },
  {
    dataKey: 'supplyPoint.distributionNetwork',
    label: t('RESULTS_TABLE_COL_DISTRIBUTION_NETWORK'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) => (
      <UninformedCell
        value={t(
          distributionNetworkI18nKeys[path('supplyPoint.distributionNetwork'.split('.'), row)]
        )}
      />
    ),
    excelWidth: 20,
    exportSourceName: 'supplyPointDistributionNetwork',
    customMapping: Object.keys(distributionNetworkI18nKeys).map((key) => ({
      key,
      value: t(distributionNetworkI18nKeys[key])
    })),
    cellProps: {
      style: {
        minWidth: 150,
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },
  {
    dataKey: 'supplyPoint.control',
    label: t('RESULTS_TABLE_COL_CONTROL'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) => (
      <UninformedCell value={t(booleanI18nKeys[path('supplyPoint.control'.split('.'), row)])} />
    ),
    cellProps: {
      style: {
        minWidth: 80,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 10,
    exportSourceName: 'supplyPointControl',
    format: {
      type: 'boolean',
      pattern: `${t(booleanI18nKeys.true)};${t(booleanI18nKeys.false)}`
    }
  },
  {
    dataKey: 'supplyPoint.ggcc',
    label: t('RESULTS_TABLE_COL_GGCC'),
    isSortable: true,
    isToggleable: true,
    renderCell: (row) => (
      <UninformedCell value={t(booleanI18nKeys[path('supplyPoint.ggcc'.split('.'), row)])} />
    ),
    cellProps: {
      style: {
        minWidth: 80,
        textAlign: 'center',
        justifyContent: 'center'
      }
    },
    excelWidth: 10,
    exportSourceName: 'supplyPointGGCC',
    format: {
      type: 'boolean',
      pattern: `${t(booleanI18nKeys.true)};${t(booleanI18nKeys.false)}`
    }
  }
];

export default meterCommonColumns;
