import { SearchInputSimple } from '@acua/common/components';
import {
  QS_KEY_APP_ID,
  QS_KEY_APP_MAIN_DRAWER,
  QS_KEY_APP_NESTED_DRAWER,
  QS_KEY_APP_NESTED_DRAWER_AUDIT,
  QS_KEY_INPUT,
  QS_KEY_INVENTORY_PRODUCT_SEARCH_METERS,
  QS_KEY_PRODUCT_SEARCH_EQUIPMENTS,
  QS_KEY_PRODUCT_SEARCH_TRANSMITTERS
} from '@acua/common/constants';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Card } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { StyledOverflowDialog } from '../common/elements';
import SelectedItem from './SelectedItem';

const StyledCard = styled(Card)`
  max-width: 600px;
  width: 100%;
`;

const productSearchKeys = {
  meters: QS_KEY_INVENTORY_PRODUCT_SEARCH_METERS,
  devices: QS_KEY_PRODUCT_SEARCH_EQUIPMENTS,
  transmitters: QS_KEY_PRODUCT_SEARCH_TRANSMITTERS
};

function TenantLocator({ familyTarget, ...props }) {
  const t = useTranslations();
  const [selected, setSelected] = useState(null);

  const [, setQueryParams] = useQueryParams({
    [QS_KEY_APP_ID]: StringParam,
    [QS_KEY_INVENTORY_PRODUCT_SEARCH_METERS]: StringParam,
    [QS_KEY_PRODUCT_SEARCH_EQUIPMENTS]: StringParam,
    [QS_KEY_PRODUCT_SEARCH_TRANSMITTERS]: StringParam,
    [QS_KEY_APP_MAIN_DRAWER]: StringParam,
    [QS_KEY_APP_NESTED_DRAWER]: StringParam,
    [QS_KEY_APP_NESTED_DRAWER_AUDIT]: StringParam
  });

  const onLocalize = ({ tenantId, key }) => {
    setQueryParams({
      [QS_KEY_APP_ID]: tenantId,
      [productSearchKeys[familyTarget]]: key,
      [QS_KEY_APP_MAIN_DRAWER]: undefined,
      [QS_KEY_APP_NESTED_DRAWER]: undefined,
      [QS_KEY_APP_NESTED_DRAWER_AUDIT]: undefined
    });
  };
  const i18nFamilyPrefix = familyTarget.toUpperCase();

  return (
    <StyledOverflowDialog
      {...props}
      acceptLabel={t('TRANSVERSAL_LOCATOR_ACCEPT')}
      onAccept={() => onLocalize(selected)}
      acceptDisabled={!selected}
      title={t(`TRANSVERSAL_LOCATOR_${i18nFamilyPrefix}`)}
      body={
        <>
          <Typography use="body1" tag="p" theme="textSecondaryOnLight">
            {t(`TRANSVERSAL_LOCATOR_${i18nFamilyPrefix}_TEXT`)}
          </Typography>
          {selected ? (
            <SelectedItem {...selected} onRemove={() => setSelected(null)} />
          ) : (
            <StyledCard outlined>
              <SearchInputSimple
                avoidCard
                searchEntities
                searchFamilies={[familyTarget]}
                placeholder={t(`TRANSVERSAL_LOCATOR_${i18nFamilyPrefix}_INPUT`)}
                onChange={setSelected}
                queryParamKey={QS_KEY_INPUT}
              />
            </StyledCard>
          )}
        </>
      }
    />
  );
}

TenantLocator.propTypes = {
  familyTarget: PropTypes.string.isRequired
};

export default TenantLocator;
