import React from 'react';
import { lighten } from 'polished';
import { Global, css } from '@emotion/react';
import themeVars from '../../variables';

function RMWC() {
  return (
    <Global
      styles={css`
        :root {
          --mdc-theme-error: ${themeVars.colorFontError};
        }

        .rmwc-circular-progress {
          color: ${themeVars.primary};
        }

        .mdc-layout-grid {
          padding: 12px 0;
        }

        .mdc-text-field {
          width: 100%;

          &--focused {
            &:not(.mdc-text-field--disabled) .mdc-floating-label {
              color: ${themeVars.primary};
            }
          }
        }

        .mdc-list-item {
          &__text {
            font-size: 14px;
          }
        }

        .mdc-menu {
          &__items {
            .mdc-list-item {
              white-space: nowrap;
              &[disabled] {
                color: ${themeVars.textDisabledOnLight};
                pointer-events: none;
              }
            }
          }
        }

        .mdc-radio {
          &__inner-circle {
            border-color: ${themeVars.primary} !important;
            color: ${themeVars.primary};
          }
          &__outer-circle {
            border-color: ${themeVars.primary} !important;
            color: ${themeVars.primary};
          }
          &::before {
            content: none;
          }
        }

        .mdc-checkbox {
          &__background {
            &:before {
              background-color: ${themeVars.primary} !important;
            }
          }
          .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
          .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
            border-color: ${themeVars.primary} !important;
            background-color: ${themeVars.primary} !important;
          }
        }

        .mdc-switch--checked:not(.mdc-switch--disabled) .mdc-switch {
          &__track {
            background-color: ${lighten(0.2, themeVars.primary)} !important;
            border-color: ${lighten(0.15, themeVars.primary)} !important;
          }
          &__thumb {
            background-color: ${themeVars.primary} !important;
            border-color: ${themeVars.primary} !important;
          }
        }

        .mdc-select {
          width: 100%;
        }

        .mdc-select__anchor {
          width: 100%;

          &:not(.mdc-select__anchor--disabled) .rmwc-select__native-control:focus {
            ~ .mdc-line-ripple {
              background-color: ${themeVars.primary};
            }
            ~ .mdc-floating-label {
              color: ${themeVars.primary};
            }
          }

          .mdc-select__selected-text-container {
            padding-top: 25px;
            display: inline-block;
          }

          .mdc-select__dropdown-icon {
            display: none;
          }
          /* Since we are hiding __dropdown-icon, we dont need 40px for right padding */

          .rmwc-select__native-control:not(.nt-pagination__select) {
            padding-top: 10px;
            font-size: 16px;
          }

          .rmwc-select__native-control {
            padding-right: 16px;
            border: none;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
            width: 100%;
            height: 100%;
            padding-left: 15px;

            option {
              -webkit-font-smoothing: antialiased;
              font-size: 1rem;
              line-height: 1.75rem;
              font-weight: 400;
              letter-spacing: 0.00937em;
              text-decoration: inherit;
              text-transform: inherit;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              height: 56px;
              padding: 20px 52px 4px 16px;
              margin-left: 10px;
              border: none;
              border-bottom: 1px solid;
              outline: none;
              background-color: transparent;
              color: inherit;
              white-space: nowrap;
              cursor: pointer;
            }

            &:focus-visible {
              outline: none;
            }
            &:focus {
              outline: none;
            }
          }
        }

        .mdc-dialog {
          z-index: 1;
          .mdc-dialog__surface {
            min-width: 350px;
          }
          &__body--scrollable {
            max-height: 80vh;
            overflow-y: auto;
            border-top-width: 0;
            border-bottom-width: 0;
          }
        }

        /* IE Edge bug */
        .mdc-button {
          &--raised,
          &--unelevated {
            &:not(:disabled) {
              background-color: ${themeVars.primary};
            }
            &.danger {
              background-color: ${themeVars.error};
            }
          }
        }

        .mdc-button .mdc-button__icon:last-child {
          margin-right: 0;
        }

        .mdc-slider {
          &:not(.mdc-slider--disabled) {
            .mdc-slider {
              &__track {
                background-color: ${themeVars.primary};
              }
              &__track-container {
                background-color: rgba(0, 0, 0, 0.26);
              }
              &__thumb {
                stroke: ${themeVars.primary};
                fill: ${lighten(0.3, themeVars.primary)};
              }
              &__focus-ring,
              &__pin {
                background-color: #fff;
              }
              &__pin {
                border: 1px solid ${themeVars.primary};
                background-color: ${lighten(0.3, themeVars.primary)};
              }
            }
          }
        }
      `}
    />
  );
}

export default RMWC;
