import React from 'react';
import { Global, css } from '@emotion/react';
import themeVars from '../../variables';

function Microtip() {
  return (
    <Global
      styles={css`
        [aria-label][role~='tooltip'] {
          &::before,
          &::after {
            z-index: ${themeVars.zIndexTooltip};
          }
          &::after {
            white-space: pre;
            text-align: left;
            line-height: 20px;
          }
        }
      `}
    />
  );
}

export default Microtip;
