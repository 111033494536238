import RouterIcon from '@mui/icons-material/Router';
import MemoryIcon from '@mui/icons-material/Memory';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

export const entityTypes = {
  TRANSMITTERS: 'transmitters',
  DEVICES: 'devices',
  METERS: 'meters',
  SIM: 'sim',
  MODEM: 'modem',
  SUPPLY_POINTS: 'supplyPoints',
  SITES: 'sites',
  SECTORS: 'sectors',
  SUBSECTORS: 'subsectors',
  ALARM_CONFIGURATIONS: 'alarmConfigurations',
  ALARMS: 'alarms'
};

export const entityTypeIcons = {
  [entityTypes.TRANSMITTERS]: RouterIcon,
  [entityTypes.DEVICES]: MemoryIcon,
  [entityTypes.METERS]: WaterDamageIcon,
  [entityTypes.SUPPLY_POINTS]: ShareLocationIcon,
  [entityTypes.SITES]: LocationCityIcon,
  [entityTypes.SECTORS]: CenterFocusWeakIcon,
  [entityTypes.SUBSECTORS]: CenterFocusStrongIcon,
  [entityTypes.ALARM_CONFIGURATIONS]: AlarmOnIcon,
  [entityTypes.ALARMS]: NotificationsNoneIcon
};

export const deviceTechnologies = {
  BUS_VHF: 'BUSVHF',
  MODULE_VHF: 'MODULEVHF',
  IMETER: 'IMETER',
  HYDROSENSE: 'HYDROSENSE',
  GENERIC: 'GENERIC',
  BUSNBIOT: 'BUSNBIOT',
  LORA: 'LORA',
  NBIOT: 'NBIOT',
  ondeoTransmitter: 'ondeoTransmitter',
  ondeoTransmitter1G: 'ondeoTransmitter1G',
  ondeoTransmitter2G: 'ondeoTransmitter2G'
};

export const deviceTypes = {
  BUSVHF_V1: 'busVhfV1',
  MODULE_VHF_1G: 'moduleVhfG1',
  MODULE_VHF_2G: 'moduleVhfG2',
  IMETER_C: 'iMeterC',
  IMETER_E: 'iMeterE',
  HYDROSENSE_METERING: 'hydrosenseMetering',
  GENERIC: 'genericDevice',
  BUSNBIOT: 'busNbIot',
  LORA: 'loraDevice',
  NBIOTDEVICE: 'nbIotDevice'
};

export const coverageLevel = {
  EXCELENT: '6',
  GOOD: '5',
  REGULAR: '4',
  BAD: '3',
  WEAK: '2',
  NO_CONNECTION: '1'
};

export const meterTypes = {
  EMETER: 'eMeter',
  EMETER_SPDE: 'eMeterSPDE',
  MMETER: 'mMeter',
  MMETER_SPDE: 'mMeterSPDE'
};

export const transmitterTypes = {
  ONDEO_1G: 'ondeoTransmitterG1',
  ONDEO_2G: 'ondeoTransmitterG2',
  LORA_GATEWAY: 'loraGateway'
};

export const productStatus = {
  WAREHOUSE: 1,
  INSTALLED: 2,
  REVIEW: 3,
  REMOVED: 4
};

export const productSubstatus = {
  NEW: 1, // WAREHOUSE
  VALIDATED: 2, // INSTALLED
  PENDING_VALIDATION: 3, // INSTALLED
  REJECTED: 4, // INSTALLED
  TEST: 5, // INSTALLED
  USED: 6, // WAREHOUSE
  ASIGNED: 7 // REMOVED
};

export const supplyPointTypes = {
  GENERIC: 'generic',
  LOCATION: 'location',
  MEASUREMENT: 'measurement'
};

export const distributionNetworkTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export const connectionStatusTypes = {
  OK: 'OK',
  KO: 'KO'
};

export const iotStatusTypes = {
  OK: 'OK',
  KO: 'KO',
  PENDING: 'PENDING'
};

export const fileFormats = {
  CSV: 'csv',
  XLSX: 'xlsx'
};

export const downloadImportsTypes = {
  ALL: 'all',
  ERRORS: 'errors'
};

export const importTemplateNames = {
  TRANSMITTER_WAREHOUSE_TEMPLATE: 'transmitter_warehouse',
  TRANSMITTER_INSTALL_TEMPLATE: 'transmitter_installation',
  DEVICE_WAREHOUSE_TEMPLATE: 'device_warehouse',
  DEVICE_INSTALL_TEMPLATE: 'device_installation',
  DEVICE_WITHDRAWAL_TEMPLATE: 'device_withdrawal',
  READING_MANUAL_TEMPLATE: 'reading_manual'
};

export const exportsStatus = {
  PENDING: 1,
  RUNNING: 2,
  DONE: 3,
  ERROR: 4
};

export const productStatusIOTTypes = {
  OK: 'OK',
  KO: 'KO',
  PENDING: 'PENDING'
};

export const productReConfigStatusTypes = {
  OK: 'OK',
  KO: 'KO',
  PENDING: 'PENDING'
};

export const lnsStatusTypes = {
  OK: 'OK',
  KO: 'KO',
  PENDING: 'PENDING'
};
