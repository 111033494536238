import React, { useEffect, useState } from 'react';
// TODO AML find way to import locales dinamically of user language
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import PropTypes from 'prop-types';

import moment from 'moment';
import { useTranslations } from '@react-utils/intl';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@emotion/styled';
import { FilterRangeDateBase } from '@naui/core';

import { useUserContext } from '../hooks';
import { ISO_FORMAT_DATE } from '../utils';

const StyledContainer = styled.div`
  .MuiBox-root {
    i {
      height: 100%;
      display: flex;
      line-height: inherit;
      align-self: center;
    }
  }
`;

const StyledFilterRangeDateBase = styled(FilterRangeDateBase)`
  border-radius: 4px;
  &.search_bar_input {
    border: 1px solid #bbbbbb !important;
    div {
      background: #fff;
      border-radius: 4px;
    }
    input {
      font-size: 14px !important;
      padding: 7px 8px !important;
      text-align: center;
    }
    svg {
      background-color: #fff;
      border-bottom: 0px;
    }
    .MuiInputBase-root:before {
      border-bottom: 0px !important;
    }
  }
`;

const CalendarInfo = styled.div`
  padding: 0 10px 10px 10px;
  width: 320px;
  color: ${({ theme }) => theme.textHintOnLight};
  line-height: 18px;
  letter-spacing: 0.43px;
  font-size: 14px;
`;

function DateRangePickerWrapper({
  firstInputProps,
  secondInputProps,
  calendarInputProps,
  onChange,
  maxRange,
  searchBarLocation,
  hideClearButton,
  ...props
}) {
  const t = useTranslations();
  const [value, setValue] = useState(props.value);
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  const {
    profile: {
      preferences: { language }
    }
  } = useUserContext();

  useEffect(() => {
    if (hideClearButton || searchBarLocation) {
      const element = document.querySelector(
        '[data-testid="date_range_picker_clear_button"]'
      )?.parentElement;
      if (element) {
        element.style.display = 'none';
      }
    }
  }, []);

  const calcMinDate = (date) => {
    return moment(date)
      .subtract(...maxRange)
      .startOf('day');
  };

  const calcMaxDate = (date) => {
    const newMaxDate = moment(date)
      .add(...maxRange)
      .endOf('day');

    const today = moment().endOf('day');

    return today.valueOf() <= newMaxDate.valueOf() ? today : newMaxDate;
  };

  useEffect(() => {
    if (!maxRange || (Array.isArray(maxRange) && maxRange.length <= 1)) {
      setMinDate(null);
      setMaxDate(null);
      return;
    }

    const { startDate, endDate } = value;

    if ((startDate && !endDate) || (startDate && endDate)) {
      setMaxDate(calcMaxDate(startDate));
      return;
    }

    if (endDate && !startDate) {
      setMinDate(calcMinDate(endDate));
    }
  }, [value, maxRange]);

  return (
    <StyledContainer
      className={searchBarLocation ? 'DateRangePickerInput search_bar' : 'DateRangePickerInput'}
    >
      <StyledFilterRangeDateBase
        className={searchBarLocation ? 'search_bar_input' : ''}
        testId="date_range_picker"
        clearButtonTestId="date_range_picker_clear_button"
        clearButtonLabel={t('RESTORE_SEARCH_BUTTON')}
        pickerDaysTestId="date_range_picker_days"
        calendarPopperTestId="date_range_picker_calendar"
        firstInputProps={{
          placeHolderText: t('DATEPICKER_FROM'),
          onClick: () => {
            setMinDate(null);
            setMaxDate(null);
          },
          ...firstInputProps
        }}
        secondInputProps={{
          placeHolderText: t('DATEPICKER_TO'),
          ...secondInputProps
        }}
        calendarInfo={
          <CalendarInfo>
            <InfoIcon style={{ marginRight: '4px', fontSize: '1rem' }} />
            {t('CALENDAR_SELECTION_INFO')}
          </CalendarInfo>
        }
        locale={language?.split('-')[0] || 'es'}
        {...props}
        calendarInputProps={{
          leftArrowButton: {
            title: t('PREVIOUS_MONTH')
          },
          rightArrowButton: {
            title: t('NEXT_MONTH')
          },
          switchViewButton: {
            title: t('SELECT_YEAR')
          },
          maxDate: maxDate || new Date(),
          minDate: minDate || new Date('2018'),
          /* views: ['year', 'month', 'day'], */
          ...calendarInputProps
        }}
        onChange={(v) => {
          const dates = {
            startDate: null,
            endDate: null,
            ...v
          };

          if (Array.isArray(maxRange) && maxRange.length > 1) {
            const startChanged = dates.startDate !== value.startDate;
            if (startChanged && dates.endDate) {
              const selectedEnd = moment(dates.endDate);
              const calcEnd = calcMaxDate(dates.startDate);

              dates.endDate =
                selectedEnd.valueOf() <= calcEnd.valueOf()
                  ? selectedEnd.format(ISO_FORMAT_DATE)
                  : calcEnd.format(ISO_FORMAT_DATE);
            }
          }

          setValue(dates || {});
          onChange(dates || {});
        }}
      />
    </StyledContainer>
  );
}

DateRangePickerWrapper.propTypes = {
  id: PropTypes.string,
  value: PropTypes.shapeOf({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  calendarInputProps: PropTypes.shape,
  firstInputProps: PropTypes.shape,
  secondInputProps: PropTypes.shape,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  customInputIcon: PropTypes.node,
  customArrowIcon: PropTypes.node,
  maxRange: PropTypes.array,
  searchBarLocation: PropTypes.bool,
  hideClearButton: PropTypes.bool
};

DateRangePickerWrapper.defaultProps = {
  id: 'date_picker',
  value: {},
  calendarInputProps: {},
  firstInputProps: {},
  secondInputProps: {},
  disabled: false,
  onChange: () => {},
  customArrowIcon: null,
  customInputIcon: null,
  maxRange: null,
  searchBarLocation: false,
  hideClearButton: false
};

export default DateRangePickerWrapper;
