// import React from 'react';
import styled from '@emotion/styled';
import { withProps } from 'recompose';
import { Typography } from '@rmwc/typography';

export default withProps((props) => ({
  tag: 'p',
  use: 'caption',
  ...props
}))(styled(Typography)`
  margin: 0;
  color: ${({ theme }) => theme.colorFontError};
`);
