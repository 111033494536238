import React from 'react';
import { Global, css } from '@emotion/react';

function Recharts() {
  return (
    <Global
      styles={css`
        .recharts-surface {
          user-select: none;
          .recharts-brush-texts {
            font-size: 11px;
          }
        }
      `}
    />
  );
}

export default Recharts;
