import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { equals } from 'ramda';
import { useField, useFormState } from 'react-final-form';
import { useTranslations } from '@react-utils/intl';
import Highlight from 'react-highlighter';
import ClearIcon from '@mui/icons-material/Clear';
import { useUserContext, useBeforeUnload } from '@acua/common/hooks';
import { MAX_TAGS_PER_USER, TAG_MAX_LENGTH } from '@acua/common/constants';
import { TextField, TextFieldHelperText } from '@rmwc/textfield';
import { List, ListItem, ListDivider } from '@rmwc/list';
import { Chip, ChipSet } from '@rmwc/chip';

const StyledList = styled(List)`
  max-height: 300px;
  overflow-y: auto;
`;

const StyledChipSet = styled(ChipSet)`
  max-height: 300px;
  overflow-y: auto;
`;

const accents = {
  a: 'á|à|ã|â',
  e: 'é|è|ê',
  i: 'í|ì|î',
  o: 'ó|ò|ô|õ',
  u: 'ú|ù|û|ü'
};

const removeAccent = (text) =>
  Object.keys(accents).reduce(
    (acc, vowelKey) => acc.replace(new RegExp(accents[vowelKey], 'g'), vowelKey),
    text
  );

const REGEX_FORBIDEN_CHARS = /[^\w]/gi;

const normalize = (str) =>
  str ? removeAccent(str.trim().toLowerCase()).replace(REGEX_FORBIDEN_CHARS, '') : '';

const isEqual = (initTags, newTags) => {
  if (Array.isArray(initTags) && Array.isArray(newTags)) {
    return initTags.length === newTags.length && equals([...initTags].sort(), [...newTags].sort());
  }
  return initTags === newTags;
};

function Body({ productTags }) {
  const t = useTranslations();
  const [inputValue, setInputValue] = useState('');
  const {
    input: { onChange, value: tagsValue, ...inputProps }
  } = useField('productTags', { initialValue: productTags, isEqual });
  const { tags } = useUserContext();
  const { pristine } = useFormState();
  useBeforeUnload(!pristine);
  const filteredUserTags = tags.filter(({ name }) => !tagsValue.includes(name));
  return (
    <>
      <TextField
        id="modals_product_tag_input"
        label={t('MODAL_MANAGE_PRODUCT_TAGS_TEXTFIELD_LABEL')}
        {...inputProps}
        value={inputValue}
        onChange={({ target: { value } }) => setInputValue(normalize(value))}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            if (
              inputValue &&
              !tagsValue.includes(inputValue) &&
              tagsValue.length < MAX_TAGS_PER_USER
            ) {
              onChange(tagsValue.concat(inputValue));
              setInputValue('');
            }
          }
        }}
        trailingIcon={<ClearIcon onClick={() => setInputValue('')} />}
        maxLength={TAG_MAX_LENGTH}
      />
      <span>{t('MODAL_MANAGE_PRODUCT_TAGS_HELP')}</span>
      {tagsValue.length >= MAX_TAGS_PER_USER && (
        <TextFieldHelperText persistent>
          {t('MODAL_MANAGE_PRODUCT_TAGS_TEXTFIELD_HELPER_TEXT')}
        </TextFieldHelperText>
      )}
      <ChipSet tag="ul">
        {tagsValue.map((tag) => (
          <Chip
            key={tag}
            tag="li"
            type="button"
            label={tag}
            trailingIcon={<ClearIcon />}
            onRemove={() => onChange(tagsValue.filter((item) => item !== tag))}
          />
        ))}
      </ChipSet>
      <span>{t('MODAL_MANAGE_PRODUCT_TAGS_USER_HELP')}</span>
      {tags.length > 15 ? (
        <>
          <ListDivider />
          <StyledChipSet tag="ol">
            {filteredUserTags.map(({ name }) => {
              const disabled = inputValue && !name.includes(inputValue);
              return (
                <Chip
                  key={name}
                  tag="li"
                  onClick={disabled ? null : () => onChange(tagsValue.concat(name))}
                  type="button"
                  style={{ opacity: disabled ? 0.6 : 1 }}
                >
                  {!inputValue || disabled ? (
                    name
                  ) : (
                    <Highlight search={inputValue}>{name}</Highlight>
                  )}
                </Chip>
              );
            })}
          </StyledChipSet>
        </>
      ) : (
        <StyledList tag="ol">
          {filteredUserTags.map(({ name }, i) => (
            <Fragment key={name}>
              {i > 0 && <ListDivider />}
              <ListItem onClick={() => onChange(tagsValue.concat(name))}>
                {inputValue ? <Highlight search={inputValue}>{name}</Highlight> : name}
              </ListItem>
            </Fragment>
          ))}
        </StyledList>
      )}
    </>
  );
}

Body.propTypes = {
  productTags: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Body;
