import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell } from '@rmwc/grid';
import { Radio } from '@rmwc/radio';

const Container = styled(Grid)`
  background-color: rgba(216, 216, 216, 0.2);
  padding: 12px 18px;
  margin: 0;
  margin-top: 1em;
`;

function TransmitterDown({ modemLinked, setModemLinked }) {
  const t = useTranslations();
  return (
    <Container>
      <GridCell align="middle" desktop="2" tablet="2">
        <Typography style={{ fontWeight: 'bold' }} use="body2">
          {t('MODAL_MODEM')}
        </Typography>
      </GridCell>
      <GridCell key="modemlink" desktop="5" tablet="3">
        <Radio
          checked={modemLinked}
          onChange={() => setModemLinked(true)}
          id="modals_linked_option"
        >
          {t('MODAL_OPTION_LINKED')}
        </Radio>
      </GridCell>
      <GridCell key="modemUnlink" desktop="5" tablet="3">
        <Radio
          checked={!modemLinked}
          onChange={() => setModemLinked(false)}
          id="modals_unlinked_option"
        >
          {t('MODAL_OPTION_UNLINKED')}
        </Radio>
      </GridCell>
    </Container>
  );
}

TransmitterDown.propTypes = {
  modemLinked: PropTypes.bool.isRequired,
  setModemLinked: PropTypes.func.isRequired
};

export default TransmitterDown;
