import React from 'react';
import { lighten } from 'polished';
import { Global, css } from '@emotion/react';
import themeVars from '../../variables';

function NoUiSlider() {
  return (
    <Global
      styles={css`
        .noUi-target {
          border: 0;
          box-shadow: none;
          /* background-color: rgba(0, 0, 0, 0.26); */
          background: repeating-linear-gradient(
            0,
            #fff,
            #fff 5px,
            rgba(0, 0, 0, 0.26) 5px,
            rgba(0, 0, 0, 0.26) 7px
          );

          &.noUi-horizontal {
            height: 10px;
          }

          .noUi-handle {
            cursor: pointer;
            width: 11px;
            height: 11px;
            top: -1px;
            right: -6px;
            border: 2px solid ${themeVars.primary};
            background-color: ${lighten(0.3, themeVars.primary)};
            border-radius: 50%;

            &:before,
            &:after {
              content: normal;
            }
          }
        }

        .noUi-connects {
          border-radius: 0;
          cursor: pointer;
          .noUi-connect {
            background-color: ${themeVars.primary};
            height: 2px;
            margin-top: 3px;
          }
        }
      `}
    />
  );
}

export default NoUiSlider;
