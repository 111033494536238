import { useQuery } from '@apollo/client';
import { entityTypes } from '@acua/common/constants/products/types';
import { searchEntity } from './searchEntity.gql';

const allEntityTypes = Object.values(entityTypes);

const useSearchEntity = ({
  search,
  supplyPointTypes,
  types = allEntityTypes,
  tenantIds = null,
  siteIds = null,
  skip = false,
  page = 0,
  size = 10
}) => {
  let query = null;
  if (search) {
    query = typeof search === 'string' ? [search.trim()] : search.map((s) => s.trim());
  }
  const { data, ...rest } = useQuery(searchEntity, {
    skip,
    variables: {
      query,
      tenantIds,
      siteIds,
      types,
      supplyPointTypes,
      page,
      size
    }
  });

  return {
    data: data?.searchEntity?.docs,
    total: data?.searchEntity?.total,
    ...rest
  };
};

export default useSearchEntity;
