import { assoc, curry, keys, reduce, reject, isEmpty, isNil, dissoc, mapObjIndexed } from 'ramda';
import { getReducedArrKeys } from './arrays';

// https://eslint.org/docs/rules/no-prototype-builtins
export const existsKey = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key);

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

export const getKeyByValue = (object, value) =>
  Object.keys(object).find((key) => object[key] === value);

export const classNames = (...args) =>
  args
    .filter(Boolean)
    .reduce(
      (acc, arg) =>
        typeof arg === 'string' ? acc.concat(arg) : acc.concat(getReducedArrKeys(arg)),
      []
    )
    .join(' ');

// Remove nulls and undefineds
export const removeNils = reject(isNil);

// Remove empty strings, objects and arrays, but no nulls and undefineds
export const removeEmpties = reject(isEmpty);

export const cleanDeeply = (obj, cleanerFn = removeNils) =>
  Array.isArray(obj)
    ? cleanerFn(obj).map((v) => (v && typeof v === 'object' ? cleanDeeply(v, cleanerFn) : v))
    : Object.entries(cleanerFn(obj)).reduce(
        (a, [k, v]) => ({ ...a, [k]: typeof v === 'object' ? cleanDeeply(v, cleanerFn) : v }),
        {}
      );

const dissocTypename = dissoc('__typename');

export const dissocTypenameDeeply = (x) => {
  if (!x) return x; // because typeof null === 'object'
  if (Array.isArray(x)) return x.map((item) => dissocTypenameDeeply(item));
  if (typeof x === 'object') return mapObjIndexed(dissocTypenameDeeply, dissocTypename(x));
  return x;
};

export const getUndefinedValue = (value) => {
  if ((!value && value !== 0 && value !== false) || (Array.isArray(value) && value.length === 0))
    return undefined;
  return value;
};
