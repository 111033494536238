import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';

import { ArrayParam, StringParam, withDefault } from 'serialize-query-params';
import { useQueryParam } from 'use-query-params';

import { Cancel } from '@mui/icons-material';
import { Chip, debounce } from '@mui/material';
import { FilterBarSearch } from '@naui/core';

import { PERIOD_TYPES } from '@acua/consumptions/Home/constants';
import { useFeatureFlagContext, useSearchEntity } from '../../hooks';
import { ALL, ALL_NAME, getAllDevices } from './utils';
import { anonymizeEntityTypes } from '../../utils/anonymize/anonymize';
import { QS_KEY_SELECTED } from '../../constants';
import SearchItem from './SearchItem';
import { ColorChips, StyledCard, StyledItem } from './elements';

const getInitialValue = (externalValue, qsValue, t) => {
  if (externalValue?.length) {
    return externalValue;
  }
  if (qsValue?.length && qsValue[0] === ALL) {
    return [getAllDevices(t)];
  }
  return [];
};

function SearchInputMultipleElement({
  includeAllItemsOption,
  onSelectItem,
  entityColors,
  selectedItem: selecteditem,
  maxChips,
  minChars,
  includeFreeSearch,
  onChange,
  placeholder,
  clearLabel,
  queryParamKey,
  disabled,
  searchEntities,
  searchFamilies,
  supplyPointTypes,
  tenantIds,
  SelectorProps,
  selectorOptions,
  selectorValue,
  setSelectorValue,
  hasSelector,
  value: externalValue,
  inputValue: externalInputValue,
  ...props
}) {
  const t = useTranslations();
  const { featureFlags } = useFeatureFlagContext();
  const [qsValue, setQsValue] = useQueryParam(queryParamKey, withDefault(ArrayParam, []));
  const [value, setValue] = useState(getInitialValue(externalValue, qsValue, t));
  const [search, setSearch] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [, setSelectedItem] = useQueryParam(QS_KEY_SELECTED, withDefault(StringParam, ''));
  const [items, setItems] = useState([]);
  const { data, loading } = useSearchEntity({
    search,
    skip: !searchEntities || !search,
    types: searchFamilies,
    tenantIds,
    supplyPointTypes
  });
  const maxItemsRaised = maxChips && value.length >= maxChips;
  const getAutoCompleteItems = () => {
    const autoCompleteItems = [];
    if (items?.length) {
      autoCompleteItems.push(...items);
    }
    if (includeAllItemsOption && value.every((v) => v !== ALL)) {
      autoCompleteItems.push(getAllDevices(t));
    }
    return autoCompleteItems;
  };

  const getItemLabel = (item) =>
    item === ALL || item?.id === ALL ? t(ALL_NAME) : item.key || item.displayName || item;

  const setNewValue = (newItem, values) => {
    let newValue;
    if (!newItem || typeof newItem !== 'string') {
      newValue = [...values];
    } else if (newItem === ALL) {
      newValue = [ALL];
    } else {
      newValue = [...value, newItem.trim().toUpperCase()];
    }

    const labels = newValue.map(getItemLabel);
    setSearch('');
    setItems([]);
    setInputValue('');
    setValue([...newValue]);
    return { newValue, labels };
  };

  const onChangeFunction = (newItem, values) => {
    const allChipsIndex = values.findIndex((chip) => chip.id === 'all');
    const allChips = values.find((chip) => chip.id === 'all');

    let filteredValues = [...values];
    if (allChips && values.length > 1) {
      if (allChipsIndex === 0) {
        filteredValues.shift();
      } else {
        filteredValues = [allChips];
      }
    }

    if (maxChips && filteredValues.length > maxChips) {
      return;
    }

    if (queryParamKey) {
      setQsValue(
        filteredValues.map((val) => {
          if (typeof val === 'object') {
            return val;
          }
          return val.toUpperCase();
        })
      );
    }

    const { newValue, labels } = setNewValue(newItem, filteredValues);
    onChange([...newValue]);
    if (onSelectItem) {
      onSelectItem(labels[labels.length - 1]);
      setSelectedItem(labels[labels.length - 1]);
    }
  };

  useEffect(() => {
    if (selectorValue === PERIOD_TYPES.INTRADAILY && value.find((val) => val.id === 'all')) {
      onChangeFunction(null, []);
    }
  }, [selectorValue, value]);

  useEffect(() => {
    if (Array.isArray(externalValue) && value?.[0]?.id !== ALL && externalValue !== value) {
      setNewValue(null, externalValue);
    }
  }, [externalValue]);

  useEffect(() => {
    if (externalInputValue?.length && externalInputValue !== inputValue) {
      setInputValue(externalInputValue);
    }
  }, [externalInputValue]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (value.some((item) => item === ALL) && !includeAllItemsOption) {
      onChangeFunction(null, []);
    }
  }, [includeAllItemsOption]);

  const searchDelayed = useMemo(() => debounce(setSearch, 500), [search]);

  useEffect(() => {
    if (!inputValue) {
      setItems([]);
    } else if (data !== items) {
      setItems(data);
    }
  }, [inputValue]);

  const getSearchValue = () =>
    value?.map((v) => (v === ALL ? t('SEARCH_ENTITY__ALL_RIGHTS') : getItemLabel(v)));

  return (
    <FilterBarSearch
      searchTestId="search_input"
      selectorTestId="selector"
      selectorOptionTestId="option"
      clearButtonTestId="clear_button"
      searchValue={getSearchValue()}
      onSearch={onChangeFunction}
      onClear={() => onChangeFunction(null, [])}
      placeholder={maxItemsRaised ? null : placeholder || t('SEARCH_ENTITY__PLACEHOLDER')}
      clearSearchesLabel={clearLabel || t('RESTORE_SEARCH_BUTTON')}
      disabled={disabled}
      entitycolors={entityColors}
      selecteditem={selecteditem}
      TextFieldProps={{ inputProps: { maxLength: 100 } }}
      AutocompleteProps={
        searchEntities
          ? {
              onChange: onChangeFunction,
              inputValue,
              value,
              freeSolo: includeFreeSearch,
              options: loading ? [] : getAutoCompleteItems(),
              getOptionLabel: getItemLabel,
              isOptionEqualToValue: (option, v) =>
                typeof v === 'string' ? v === option.key : v.key === option.key,
              filterOptions: (options) => options,
              renderOption: (p, item) => (
                <StyledItem {...p} stickyLastChild={includeAllItemsOption}>
                  <SearchItem key={item.id} searchValue={search} {...item} />
                </StyledItem>
              ),
              loading,
              loadingText: t('LOADING_MESSAGE'),
              onInputChange: (e) => {
                if (e && searchEntities && !maxItemsRaised) {
                  if (e.target?.value?.length >= minChars) {
                    searchDelayed(e.target.value);
                  }
                  setInputValue(e.target?.value || '');
                }
              },
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.defaultMuiPrevented = true;
                  onChangeFunction(search, value);
                }
              },
              renderTags: (values, getTagProps) =>
                values.map((itemValue, index) => {
                  const v = typeof itemValue === 'string' ? itemValue : itemValue.key;
                  return (
                    <Chip
                      label={
                        <ColorChips value={v} selecteditem={selecteditem} color={entityColors?.[v]}>
                          <span />{' '}
                          {featureFlags.usersEnabledDemo && itemValue?.id !== ALL
                            ? anonymizeEntityTypes[itemValue?.family](v, itemValue?.productType)
                            : getItemLabel(itemValue)}
                        </ColorChips>
                      }
                      size="small"
                      {...(selecteditem ? { onClick: () => onSelectItem(v) } : {})}
                      deleteIcon={<Cancel data-testid={`delete_chip_${v}`} />}
                      {...getTagProps({ index })}
                    />
                  );
                }),
              ...props
            }
          : props
      }
      SelectorProps={SelectorProps}
      options={selectorOptions}
      selectorValue={selectorValue}
      onSelect={setSelectorValue}
      hasSelector={hasSelector}
    />
  );
}

function SearchInputMultiple({ avoidCard, style, ...props }) {
  return avoidCard ? (
    <SearchInputMultipleElement {...props} />
  ) : (
    <StyledCard outlined style={style}>
      <SearchInputMultipleElement {...props} />
    </StyledCard>
  );
}

SearchInputMultipleElement.propTypes = {
  searchFamilies: PropTypes.arrayOf(PropTypes.string).isRequired,
  supplyPointTypes: PropTypes.arrayOf(PropTypes.string),
  tenantIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  includeAllItemsOption: PropTypes.bool,
  includeFreeSearch: PropTypes.bool,
  searchEntities: PropTypes.bool,
  multiple: PropTypes.bool,
  maxChips: PropTypes.number,
  minChars: PropTypes.number,
  queryParamKey: PropTypes.string,
  selectedItem: PropTypes.string,
  onSelectItem: PropTypes.func,
  entityColors: PropTypes.shape({}),
  clearLabel: PropTypes.string,
  disabled: PropTypes.bool,
  SelectorProps: PropTypes.shape({}),
  selectorOptions: PropTypes.arrayOf(PropTypes.string),
  selectorValue: PropTypes.string,
  setSelectorValue: PropTypes.func,
  hasSelector: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  inputValue: PropTypes.arrayOf(PropTypes.string)
};

SearchInputMultipleElement.defaultProps = {
  supplyPointTypes: undefined,
  tenantIds: undefined,
  placeholder: null,
  maxChips: 4,
  minChars: 2,
  includeAllItemsOption: false,
  multiple: true,
  includeFreeSearch: true,
  searchEntities: false,
  queryParamKey: null,
  onSelectItem: null,
  selectedItem: null,
  entityColors: null,
  clearLabel: null,
  disabled: false,
  SelectorProps: {},
  selectorOptions: null,
  selectorValue: null,
  setSelectorValue: null,
  hasSelector: false,
  value: null,
  inputValue: null
};

SearchInputMultiple.propTypes = {
  style: PropTypes.shape({}),
  avoidCard: PropTypes.bool
};

SearchInputMultiple.defaultProps = {
  style: {},
  avoidCard: false
};

SearchInputMultiple.displayName = 'SearchInputMultiple';

export default SearchInputMultiple;
