import { modalTypes } from '@acua/common/constants';
import {
  useAppContext,
  useModalsContext,
  useSnackbarsContext,
  useUserContext
} from '@acua/common/hooks';
import { useMutation } from '@apollo/client';
import { useTranslations } from '@react-utils/intl';
import { updateProductTags } from './updateProductTags.gql';

export default ({ productId, productType }) => {
  const t = useTranslations();
  const { refetch } = useUserContext();
  const { addSnackbar } = useSnackbarsContext();
  const { openModal } = useModalsContext();
  const { currentApp, refreshSearchKey } = useAppContext();

  const { id: tenantId } = currentApp || {};

  const [handleUpdate, { error }] = useMutation(updateProductTags);

  const onSubmit = async ({ productTags }) => {
    await handleUpdate({
      variables: { product: { productId, tenantId, productType }, newTags: productTags }
    }).catch((err) => {
      openModal({ type: modalTypes.ERROR_DIALOG, data: { error: err } });
      throw err;
    });

    return new Promise((resolve) =>
      setTimeout(() => {
        addSnackbar(t('SNACKBAR_ADD_TAGS_SUCCESS'));
        refreshSearchKey();
        refetch(true);
        resolve();
      }, 5000)
    );
  };
  return { onSubmit, error };
};
