import { useAppContext, useSnackbarsContext } from '@acua/common/hooks';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import moment from 'moment';
import { changeSite } from './mutation.gql';

export default (tenantId, productId) => {
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  const [modalError, setModalError] = useState({});
  const [handleActivate] = useMutation(changeSite);

  return {
    sendData: (info) => {
      const { date, time, ...restInfo } = info;
      const momentTime = moment(time, 'HH:mm');
      const unlinkageDate = moment(date, 'DD/MM/YYYY').toDate();
      unlinkageDate.setHours(momentTime.get('hour'));
      unlinkageDate.setMinutes(momentTime.get('minute'));
      unlinkageDate.setSeconds(0);

      return handleActivate({
        variables: {
          ...restInfo,
          unlinkageDate,
          tenantId,
          productId
        }
      })
        .then(() => {
          addSnackbar('SNACKBAR_SAVE_SUCCESS');
          refreshSearchKey();
        })
        .catch((error) => {
          setModalError({ error });
          throw error;
        });
    },
    modalError
  };
};
