import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import useAppContext from './useAppContext';
import usePrevious from './usePrevious';

const useProduct = (id, query, options = {}) => {
  const { skip, ...rest } = options;
  const { currentApps, searchKey } = useAppContext();
  const tenantId = id.split('_')[0];

  const { data, loading, error, refetch } = useQuery(query, {
    skip: skip || !id,
    variables: {
      input: { id, tenantId },
      ...currentApps.find((app) => app.id === tenantId)?.parsedCapabilities
    },
    ...rest
  });

  const prevSearchKey = usePrevious(searchKey);
  useEffect(() => {
    if (prevSearchKey) refetch();
  }, [searchKey]);

  return {
    data,
    loading,
    error,
    refetch
  };
};

export default useProduct;
