import React from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '@react-utils/intl';

import { DateRangePickerMaterial } from '@acua/common/components';
import { FormContainer, SearchInput } from './components';
import consumptionTypes from './constants/consumptionTypes';

const MAX_ITEMS = 1;

function Intradiary({
  state: {
    search,
    dateRange: { startDate, endDate }
  },
  setState
}) {
  const t = useTranslations();

  return (
    <FormContainer>
      <SearchInput
        placeholder={t('SEARCH_DEVICE__LABEL')}
        entities={search}
        onChange={(event) => {
          const tenantId = event.length > 0 ? event[0].tenantId : undefined;
          setState({ search: event, tenantId });
        }}
        maxItems={MAX_ITEMS}
        consumptionType={consumptionTypes.INTRADIARY}
      />
      <span>{t('SEARCH_DEVICE__HINT')}</span>
      <DateRangePickerMaterial
        value={{
          startDate,
          endDate
        }}
        onChange={(newDates) => setState({ dateRange: newDates })}
        maxRange={[1, 'year']}
      />
    </FormContainer>
  );
}

Intradiary.propTypes = {
  state: PropTypes.shape({
    search: PropTypes.array,
    dateRange: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })
  }).isRequired,
  setState: PropTypes.func.isRequired
};

export default Intradiary;
