import React, { Suspense, useEffect } from 'react';
import styled from '@emotion/styled';
import { Router, useMatch } from '@reach/router';
import TagManager from 'react-gtm-module';
import { modules } from '@acua/common/constants';
import {
  LoadingComponent,
  PageError,
  ErrorBoundary,
  FabContainer,
  LegalPage
} from '@acua/common/components';
import {
  Admin,
  Alarms,
  Analytics,
  Configurations,
  Consumptions,
  Dashboard,
  FileManager,
  Devices,
  Transmitters,
  Metrics,
  Sidebar
} from '../lazyModules';
import Modals from './Modals';
import Snackbars from './Snackbars';
import Menu from './Menu';
import UserGuard from './UserGuard';
import Version from './Version';
import ModuleTranslationsProvider from '../../common/ModuleTranslationsProvider';
import { acuaEnvironment } from '../../common/config';
import useVersionCheck from '../../common/hooks/useVersionCheck';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

const StyledDrawerAppContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AppContent = styled.div`
  flex: 1;
`;

const Environment = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 20px;
  span {
    background-color: ${(prop) => (prop.where === 'DEV' ? '#ff4200' : '#7eb242')};
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
  }
`;

const PATHS_WITH_SIDEBAR = [
  modules.analytics.path,
  modules.devices.path,
  modules.transmitters.path,
  modules.alarms.path,
  modules.consumptions.path
];

const hasSidebarModule = (match) => {
  if (!match) return false;
  return PATHS_WITH_SIDEBAR.includes(match.module);
};

const tagManagerArgs = {
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID
};

export default function () {
  useEffect(() => {
    if (tagManagerArgs.gtmId) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useVersionCheck();
  const match = useMatch(':module/*');

  return (
    <ErrorBoundary>
      <Container applyMargin={acuaEnvironment !== 'PROD'}>
        <ModuleTranslationsProvider moduleNames={['web']}>
          <UserGuard>
            <Menu>
              <StyledDrawerAppContent>
                <AppContent id="app_content">
                  {acuaEnvironment !== 'PROD' && (
                    <Environment where={acuaEnvironment} id="env_banner">
                      <span>{`${acuaEnvironment} ENVIRONMENT`}</span>
                    </Environment>
                  )}
                  <Suspense fallback={<LoadingComponent />}>
                    <Router>
                      <Dashboard path="/" />
                      <Admin path={`${modules.admin.path}/*`} />
                      <Alarms path={`${modules.alarms.path}/*`} />
                      <Analytics path={`${modules.analytics.path}/*`} />
                      <Consumptions path={`${modules.consumptions.path}/*`} />
                      <Devices path={`${modules.devices.path}/*`} />
                      <Transmitters path={`${modules.transmitters.path}/*`} />
                      <Metrics path={`${modules.metrics.path}/*`} />
                      <FileManager path={`${modules.fileManager.path}/*`} />
                      <Configurations path={`${modules.configurations.path}/*`} />
                      <LegalPage path="/legal" />
                      <PageError path="internal-error" type="CRITICAL" />
                      <PageError default type="NOT_FOUND" />
                    </Router>
                    <FabContainer forceUpdate={{}} />
                  </Suspense>
                </AppContent>
                <Version />
                <Snackbars />
              </StyledDrawerAppContent>
              {hasSidebarModule(match) ? <Sidebar /> : null}
            </Menu>
          </UserGuard>
        </ModuleTranslationsProvider>
        <Modals />
      </Container>
    </ErrorBoundary>
  );
}
