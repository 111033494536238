import React from 'react';
import PropTypes from 'prop-types';
import Loading from './Loading';

function LoadingComponent({ error }) {
  if (error) {
    throw error;
  } else {
    return (
      <Loading.AbsoluteContainer>
        <Loading />
      </Loading.AbsoluteContainer>
    );
  }
}

LoadingComponent.propTypes = {
  error: PropTypes.string.isRequired
};

export default LoadingComponent;
