import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Dialog } from '@rmwc/dialog';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Tooltip } from '@acua/common/components';

const ChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CloseBtn = styled(FullscreenExitIcon)`
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 40px;
  padding: 5px;
  cursor: pointer;
  color: #415d9a;
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  margin: 0 6px;
  font-size: 22px;
  text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 15px #fff,
    0 0 15px #fff;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute !important;
  right: 10px;
  top: 10px;
  z-index: 1;
  &:before {
    margin-top: 35px;
    margin-right: 74px !important;
  }
  &:after {
    margin-top: 35px;
    margin-right: 80px !important;
  }
`;

const StyledDialog = styled(Dialog)`
  .mdc-dialog__surface {
    position: relative;
    max-width: none;
    max-height: none;
    min-width: none;
    min-height: none;
    width: 95vw;
    height: 95vh;

    > ${ChildrenWrapper} {
      z-index: 1;
    }
    > ${CloseBtn} {
      z-index: 2;
    }
  }
  .map-legend {
    top: 25px;
    right: 80px;
  }

  .map-total {
    bottom: 16px;
    left: 16px;
  }

  .map-layer-menu {
    top: 25px;
    left: 16px;
  }
`;

function FullSizeFreeDialog({ deleteModal, legend, children, ...props }) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(true);
  const [hasFinishedAnimation, setHasFinishedAnimation] = useState(false);
  return (
    <StyledDialog
      {...props}
      open={isOpen}
      onClosed={() => deleteModal()}
      onOpened={() => setHasFinishedAnimation(true)}
    >
      {hasFinishedAnimation && <ChildrenWrapper>{children}</ChildrenWrapper>}
      <StyledTooltip label={t('TOOLTIP_MAP_MINIMIZE')} position="left">
        <CloseBtn title="Close" onClick={() => setIsOpen(false)} />
      </StyledTooltip>
    </StyledDialog>
  );
}

FullSizeFreeDialog.propTypes = {
  deleteModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  legend: PropTypes.arrayOf(
    PropTypes.shapeOf({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

export default FullSizeFreeDialog;
