export const consumptionOriginTypes = {
  ESTIMATED: 'estimated',
  INVALID: 'invalid',
  RECALCULATED: 'recalculated',
  CALCULATED: 'calculated'
};

export const metaTagsTypes = {
  index_reset: 'index_reset',
  no_estimation: 'no_estimation'
};

export const CHART_TYPES = Object.freeze({
  LINES: 'lines',
  BARS: 'bars'
});
