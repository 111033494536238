import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { Button } from '@rmwc/button';

function RefetchButton({ refetch }) {
  const t = useTranslations();
  return <Button onClick={() => refetch()}>{t('ERROR_MSG_QUERY_DATA_RETRY_BTN')}</Button>;
}

RefetchButton.propTypes = {
  refetch: PropTypes.func.isRequired
};

export default RefetchButton;
