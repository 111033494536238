import React from 'react';
import { useTranslations } from '@react-utils/intl';
import { useField } from 'react-final-form';
import { Select } from '@rmwc/select';
import { required } from '@acua/common/utils';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@rmwc/circular-progress';
import ErrorLabel from '../common/ErrorLabel';
import { getLanguagesOfTemplate } from './queries.gql';

function Language({ tenantId }) {
  const t = useTranslations();
  const { input, meta } = useField('language', { validate: required });
  const {
    input: { value: template }
  } = useField('template');
  const { data, loading } = useQuery(getLanguagesOfTemplate, {
    skip: !template?.id,
    variables: {
      tenantId,
      templateId: template.id
    }
  });
  const languageOptions = data
    ? data.getLanguagesOfTemplate.languages.map((value) => ({
        label: t(`ALARM_SUBSCRIPTION_LANG_TYPE__${value.split('_')[0].toUpperCase()}`),
        value
      }))
    : [];
  return (
    <div>
      <Select
        {...input}
        label={t('MODAL__ADD_ALARM_SUBSCRIPTION__FIELD__LANG')}
        id="modals_language_input"
        options={languageOptions}
        disabled={!template}
        icon={loading ? <CircularProgress id="module_loading" /> : null}
        placeholder=""
      />
      {meta.touched && meta.invalid && <ErrorLabel>{t(meta.error)}</ErrorLabel>}
      {data && languageOptions.length === 0 && (
        <ErrorLabel>{t('MODAL__ADD_ALARM_SUBSCRIPTION__NOT_FOUND')}</ErrorLabel>
      )}
    </div>
  );
}

Language.propTypes = {
  tenantId: PropTypes.string
};

Language.defaultProps = {
  tenantId: ''
};

export default Language;
