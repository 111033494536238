export const ADD_COMMENT_TO_PRODUCT = 'ADD_COMMENT_TO_PRODUCT';
export const ADD_COMMENT_TO_ALARM = 'ADD_COMMENT_TO_ALARM';
export const ALARM_SUBSCRIPTION = 'ALARM_SUBSCRIPTION';
export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ERROR_DIALOG = 'ERROR_DIALOG';
export const FULL_SIZE_MAP = 'FULL_SIZE_MAP';
export const IMPORT_DETAILS = 'IMPORT_DETAILS';
export const INSERT_READING = 'INSERT_READING';
export const MANAGE_TAGS = 'MANAGE_TAGS';
export const REJECT_PRODUCT = 'REJECT_PRODUCT';
export const SEND_PRODUCT_TO_REVISION = 'SEND_PRODUCT_TO_REVISION';
export const SEND_PRODUCT_TO_INSTALLED = 'SEND_PRODUCT_TO_INSTALLED';
export const SEND_PRODUCT_TO_WAREHOUSE = 'SEND_PRODUCT_TO_WAREHOUSE';
export const TENANT_LOCATOR = 'TENANT_LOCATOR';
export const TEST_PRODUCT = 'TEST_PRODUCT';
export const VALIDATE_PRODUCT = 'VALIDATE_PRODUCT';
export const IMPORT_SELECT_SITE = 'IMPORT_SELECT_SITE';
export const REPROCESS_PRODUCT = 'REPROCESS_PRODUCT';
export const DOWNLOAD_CONSUMPTIONS = 'DOWNLOAD_CONSUMPTIONS';
export const CHANGE_TENANT = 'CHANGE_TENANT';
export const CHANGE_TENANT_DEVICES = 'CHANGE_TENANT_DEVICES';
export const CHANGE_BATTERY_DEVICES = 'CHANGE_BATTERY_DEVICES';
export const CHANGE_SITE = 'CHANGE_SITE';
export const CHANGE_SITE_DEVICE = 'CHANGE_SITE_DEVICE';
