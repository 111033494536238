import { useAppContext, useSnackbarsContext } from '@acua/common/hooks';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import moment from 'moment';
import { updateSendOtherTenant } from './mutation.gql';

export default (productId) => {
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  const [modalError, setModalError] = useState({});
  const [handleActivate] = useMutation(updateSendOtherTenant);

  return {
    sendData: ({ time, date: rawDate, ...info }) => {
      const momentTime = moment(time, 'HH:mm');
      const date = moment(rawDate, 'DD/MM/YYYY').toDate();
      date.setHours(momentTime.get('hour'));
      date.setMinutes(momentTime.get('minute'));
      date.setSeconds(0);
      return handleActivate({
        variables: {
          ...info,
          date,
          productId
        }
      })
        .then(() => {
          addSnackbar('SNACKBAR_SAVE_SUCCESS');
          refreshSearchKey();
        })
        .catch((error) => {
          setModalError({ error });
          throw error;
        });
    },
    modalError
  };
};
