import { readStatusInstalled, readStatusWarehouse, readStatusWithdrawal } from '../permissions';
import {
  deviceTechnologies,
  deviceTypes,
  fileFormats,
  downloadImportsTypes,
  meterTypes,
  transmitterTypes,
  productStatus,
  productSubstatus,
  exportsStatus,
  distributionNetworkTypes,
  productStatusIOTTypes,
  connectionStatusTypes,
  entityTypes,
  lnsStatusTypes,
  coverageLevel
} from './types';

export const deviceTechnologiesI18nKeys = {
  [deviceTechnologies.MODULE_VHF]: 'EQUIPMENT_TECHNOLOGY__MODULE_VHF',
  [deviceTechnologies.BUS_VHF]: 'EQUIPMENT_TECHNOLOGY__BUS_VHF',
  [deviceTechnologies.IMETER]: 'EQUIPMENT_TECHNOLOGY__IMETER',
  [deviceTechnologies.HYDROSENSE]: 'EQUIPMENT_TECHNOLOGY__HYDROSENSE',
  [deviceTechnologies.GENERIC]: 'PRODUCT_TYPE_GENERIC',
  [deviceTechnologies.BUSNBIOT]: 'PRODUCT_TYPE_BUSNBIOT',
  [deviceTechnologies.LORA]: 'PRODUCT_TYPE_LORA',
  [deviceTechnologies.NBIOT]: 'PRODUCT_TYPE_NBIOT',
  [deviceTechnologies.ondeoTransmitter]: 'PRODUCT_TYPE_ONDEO_TRANSMITTER',
  [deviceTechnologies.ondeoTransmitter1G]: 'PRODUCT_TYPE_ONDEO_TRANSMITTER_1G',
  [deviceTechnologies.ondeoTransmitter2G]: 'PRODUCT_TYPE_ONDEO_TRANSMITTER_2G'
};

export const deviceTypesI18nKeysAnalytics = {
  [deviceTypes.BUSVHF_V1]: 'PRODUCT_TYPE_BUS_VHF_V1',
  [deviceTypes.MODULE_VHF_1G]: 'PRODUCT_TYPE_VHF_1G',
  [deviceTypes.MODULE_VHF_2G]: 'PRODUCT_TYPE_VHF_2G',
  [deviceTypes.IMETER_C]: 'PRODUCT_TYPE_IMETER_C',
  [deviceTypes.IMETER_E]: 'PRODUCT_TYPE_IMETER_E',
  [deviceTypes.HYDROSENSE_METERING]: 'PRODUCT_TYPE_HYDROSENSE_METERING',
  [deviceTypes.BUSNBIOT]: 'PRODUCT_TYPE_BUSNBIOT',
  [deviceTypes.LORA]: 'PRODUCT_TYPE_LORA',
  [deviceTypes.NBIOTDEVICE]: 'PRODUCT_TYPE_NBIOTDEVICE'
};

export const deviceTypesI18nKeys = {
  ...deviceTypesI18nKeysAnalytics,
  [deviceTypes.GENERIC]: 'PRODUCT_TYPE_GENERIC'
};

export const coverageLevelsI18nKeys = {
  [coverageLevel.EXCELENT]: 'COVERAGE_LEVEL_EXCELENT',
  [coverageLevel.GOOD]: 'COVERAGE_LEVEL_GOOD',
  [coverageLevel.REGULAR]: 'COVERAGE_LEVEL_REGULAR',
  [coverageLevel.BAD]: 'COVERAGE_LEVEL_BAD',
  [coverageLevel.WEAK]: 'COVERAGE_LEVEL_WEAK',
  [coverageLevel.NO_CONNECTION]: 'COVERAGE_LEVEL_NO_CONNECTION'
};

export const meterTypesI18nKeys = {
  [meterTypes.EMETER]: 'PRODUCT_TYPE_EMETER',
  [meterTypes.EMETER_SPDE]: 'PRODUCT_TYPE_EMETER_SPDE',
  [meterTypes.MMETER]: 'PRODUCT_TYPE_MMETER',
  [meterTypes.MMETER_SPDE]: 'PRODUCT_TYPE_MMETER_SPDE'
};

export const transmitterTypesI18nKeys = {
  [transmitterTypes.ONDEO_1G]: 'PRODUCT_TYPE_ONDEO_1G',
  [transmitterTypes.ONDEO_2G]: 'PRODUCT_TYPE_ONDEO_2G',
  [transmitterTypes.LORA_GATEWAY]: 'PRODUCT_TYPE_LORA_GATEWAY'
};

export const productTypesI18NKeys = {
  ...deviceTypesI18nKeys,
  ...meterTypesI18nKeys,
  ...transmitterTypesI18nKeys,
  modem: 'PRODUCT_TYPE_MODEM',
  sim: 'PRODUCT_TYPE_SIM'
};

export const productStatusI18nKeys = {
  [productStatus.WAREHOUSE]: {
    key: 'PRODUCT_STATUS_WAREHOUSE',
    permission: readStatusWarehouse
  },
  [productStatus.INSTALLED]: {
    key: 'PRODUCT_STATUS_INSTALLED',
    permission: readStatusInstalled
  },
  [productStatus.REVIEW]: {
    key: 'PRODUCT_STATUS_REVIEW',
    permission: readStatusInstalled
  },
  [productStatus.REMOVED]: {
    key: 'PRODUCT_STATUS_REMOVED',
    permission: readStatusWithdrawal
  }
};

export const productSubstatusI18nKeys = {
  [productSubstatus.PENDING_VALIDATION]: {
    key: 'PRODUCT_SUBSTATUS_PENDING_VALIDATION',
    permission: readStatusInstalled
  },
  [productSubstatus.VALIDATED]: {
    key: 'PRODUCT_SUBSTATUS_VALIDATED',
    permission: readStatusInstalled
  },
  [productSubstatus.REJECTED]: {
    key: 'PRODUCT_SUBSTATUS_REJECTED',
    permission: readStatusInstalled
  },
  [productSubstatus.TEST]: {
    key: 'PRODUCT_SUBSTATUS_TEST',
    permission: readStatusInstalled
  },
  [productSubstatus.NEW]: { key: 'PRODUCT_SUBSTATUS_NEW', permission: readStatusWarehouse },
  [productSubstatus.USED]: {
    key: 'PRODUCT_SUBSTATUS_USED',
    permission: readStatusWarehouse
  },
  [productSubstatus.ASIGNED]: {
    key: 'PRODUCT_SUBSTATUS_ASIGNED',
    permission: readStatusWithdrawal
  }
};

export const connectionStatusTypesI18nKeys = {
  [connectionStatusTypes.OK]: 'CONNECTION_STATUS_TYPE_OK',
  [connectionStatusTypes.KO]: 'CONNECTION_STATUS_TYPE_KO'
};

export const productStatusIOTTypesI18nKeys = {
  [productStatusIOTTypes.OK]: 'PRODUCT_STATUSIOT_TYPE_OK',
  [productStatusIOTTypes.KO]: 'PRODUCT_STATUSIOT_TYPE_KO',
  [productStatusIOTTypes.PENDING]: 'PRODUCT_SEARCH_FORM_STATUSIOT_PENDING'
};

export const fileFormatsI18nKeys = {
  [fileFormats.XLSX]: {
    key: 'EXPORT_FILE__OPTION_XLSX',
    maxNumberOfRows: 1000000
  },
  [fileFormats.CSV]: {
    key: 'EXPORT_FILE__OPTION_CSV'
  }
};

export const downloadImportsI18nKeys = {
  [downloadImportsTypes.ALL]: 'FILE_MANAGER__IMPORTS__DETAILS__ALL',
  [downloadImportsTypes.ERRORS]: 'FILE_MANAGER__IMPORTS__DETAILS__ERRORS'
};

export const exportsStatusI18nKeys = {
  [exportsStatus.PENDING]: 'EXPORT_STATUS_PENDING',
  [exportsStatus.RUNNING]: 'EXPORT_STATUS_RUNNING',
  [exportsStatus.DONE]: 'EXPORT_STATUS_DONE',
  [exportsStatus.ERROR]: 'EXPORT_STATUS_ERROR'
};

export const distributionNetworkI18nKeys = {
  [distributionNetworkTypes.PRIMARY]: 'PRODUCT__SUPPLY_POINT__DISTRIBUTION_NETWORK__PRIMARY',
  [distributionNetworkTypes.SECONDARY]: 'PRODUCT__SUPPLY_POINT__DISTRIBUTION_NETWORK__SECONDARY'
};

export const allOptionI18nKey = 'BOTH_OPTIONS';

export const booleanI18nKeys = {
  true: 'YES',
  false: 'NO'
};

export const withAlarmsI18nKeys = {
  true: 'PRODUCT_SEARCH_FORM_WITH_ALARMS_OPTION_YES',
  false: 'PRODUCT_SEARCH_FORM_WITH_ALARMS_OPTION_NO'
};

export const withCriticalMetersI18nKeys = {
  true: 'PRODUCT_SEARCH_FORM_WITH_CRITICAL_METERS_OPTION_YES',
  false: 'PRODUCT_SEARCH_FORM_WITH_CRITICAL_METERS_OPTION_NO'
};

export const entityTypesI18nKeys = {
  [entityTypes.TRANSMITTERS]: 'ENTITY_TYPE_TRANSMITTERS_LABEL',
  [entityTypes.DEVICES]: 'ENTITY_TYPE_DEVICES_LABEL',
  [entityTypes.METERS]: 'ENTITY_TYPE_METERS_LABEL',
  [entityTypes.SIM]: 'ENTITY_TYPE_SIM_LABEL',
  [entityTypes.MODEM]: 'ENTITY_TYPE_MODEM_LABEL',
  [entityTypes.SUPPLY_POINTS]: 'ENTITY_TYPE_SUPPLY_POINTS_LABEL',
  [entityTypes.SITES]: 'ENTITY_TYPE_SITE_LABEL',
  [entityTypes.SECTORS]: 'ENTITY_TYPE_SECTOR_LABEL',
  [entityTypes.SUBSECTORS]: 'ENTITY_TYPE_SUBSECTORS_LABEL',
  [entityTypes.ALARM_CONFIGURATIONS]: 'ENTITY_TYPE_ALARM_CONFIGURATIONS_LABEL',
  CONTRACT: 'CONTRACT_LABEL'
};

export const statusLNSTypesI18nKeys = {
  [lnsStatusTypes.OK]: 'PRODUCT_STATUSLNS_TYPE_OK',
  [lnsStatusTypes.KO]: 'PRODUCT_STATUSLNS_TYPE_KO',
  [lnsStatusTypes.PENDING]: 'PRODUCT_SEARCH_FORM_STATUSLNS_PENDING'
};
