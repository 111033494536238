import { Typography } from '@rmwc/typography';
import styled from '@emotion/styled';
import WarningIcon from '@mui/icons-material/Warning';

export const StyledWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.colorFontError};
`;

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.textSecondaryOnBackground};
  display: flex;
  align-items: center;

  > i:first-of-type {
    margin-right: 0.5em;
  }
`;

export const Title = styled(Typography)`
  margin: 0;
  text-align: center;
`;

export const Ul = styled.ul`
  list-style: circle;
  color: ${({ theme }) => theme.textSecondaryOnBackground};
`;
