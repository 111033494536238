import React, { createContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

export const AppContext = createContext();

const getCurrentTime = () => new Date().getTime();

export function AppProvider({ children }) {
  // used by useModuleGuard, TenantSelector and MultitenantLocator
  const [currentApp, setCurrentApp] = useState(null);
  const [currentApps, setCurrentApps] = useState([]);

  const [waitingUserToChooseApp, setWaitingUserToChooseApp] = useState(false);
  // It defaults to true because Acua's root (Dashboard) is Multitenant
  const [isMultitenant, setIsMultitenant] = useState(true);

  // Used to force refresh of fetchs and queries (after REST posts and puts)
  const [searchKey, setSearchKey] = useState(getCurrentTime);
  const [importsKey, setImportsKey] = useState(getCurrentTime);

  // Used for confirm dialog
  const [actionToConfirm, setActionToConfirm] = useState(null);

  // This ref solves the UX requisite of positioning FabContainer like sticky to the sidebar (not over it)
  const fabContainerRef = useRef();

  return (
    <AppContext.Provider
      value={{
        currentApp,
        setCurrentApp,
        currentApps,
        setCurrentApps,

        waitingUserToChooseApp,
        setWaitingUserToChooseApp,
        isMultitenant,
        setIsMultitenant,

        searchKey,
        refreshSearchKey: () => setSearchKey(getCurrentTime),
        importsKey,
        refreshImportsKey: () => setImportsKey(getCurrentTime),

        actionToConfirm,
        initActionToConfirm: (key, data) =>
          setActionToConfirm({
            key,
            data,
            isConfirmed: false
          }),
        confirmAction: () =>
          setActionToConfirm({
            ...actionToConfirm,
            isConfirmed: true
          }),
        resetActionToConfirm: () => setActionToConfirm(null),
        fabContainerRef
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};
