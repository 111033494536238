import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import WarningIcon from '@mui/icons-material/Warning';
import { ErrorBoundary, ErrorMsg } from '@acua/common/components';
import CustomSimpleDialog from '../components/CustomSimpleDialog';

const ErrorIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.colorFontError};
  margin-right: 0.5rem;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  margin-top: -24px;
`;

function ErrorModal({ data: { error }, ...props }) {
  const t = useTranslations();
  return (
    <CustomSimpleDialog
      {...props}
      title={
        <StyledTitle>
          <ErrorIcon />
          {t('MODAL_ERROR_TITLE')}
        </StyledTitle>
      }
      body={
        <ErrorBoundary>
          <ErrorMsg error={error || t('ERROR_MSG_UNKNOWN')} icon={null} />
        </ErrorBoundary>
      }
    />
  );
}

ErrorModal.propTypes = {
  data: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Error)])
  })
};

ErrorModal.defaultProps = {
  data: { error: null }
};

export default ErrorModal;
