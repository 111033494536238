import qs from 'qs';
import { is, mergeDeepRight, when, pipe, omit, pick, reject, isNil } from 'ramda';
import { encodeObject, decodeObject } from 'use-query-params';
import { QS_KEY_APP_ID } from '../constants/queryStringKeys';
import { removeNils } from './objects';

const qsParseOptions = Object.freeze({
  ignoreQueryPrefix: true,
  encode: false
});

const qsStringifyOptions = Object.freeze({
  addQueryPrefix: true,
  arrayFormat: 'brackets',
  encode: false
});

export const getSearchParams = (queryString, { parseOptions } = {}) => {
  const options = parseOptions ? { ...qsParseOptions, ...parseOptions } : qsParseOptions;
  return qs.parse(queryString || window.location.search, options);
};

const stringifySearchParams = (obj) => qs.stringify(obj, qsStringifyOptions);

const updateSearchParams = (newSearch, replace = true) => {
  const { protocol, host, pathname } = window.location;
  const newurl = `${protocol}//${host}${pathname}${
    is(String, newSearch) ? newSearch : stringifySearchParams(newSearch)
  }`;
  if (replace) {
    window.history.replaceState({ path: newurl }, '', newurl);
  } else {
    window.history.pushState({ path: newurl }, '', newurl);
  }
};

export const mergeAndRemoveSearchParams = (params, updater = updateSearchParams) =>
  pipe(mergeDeepRight(getSearchParams()), reject(isNil), updater)(params);

const getApplicationIdFromQueryParams = () => {
  const query = getSearchParams();
  return query[QS_KEY_APP_ID];
};

const getApplicationIdFromLocalStorage = () => {
  const tenantId = window.localStorage.getItem(QS_KEY_APP_ID);
  if (tenantId && tenantId[0] === '"') {
    return JSON.parse(tenantId);
  }
  return tenantId;
};

export const getApplicationId = () =>
  getApplicationIdFromQueryParams() || getApplicationIdFromLocalStorage();

export const createNavigateWithSearch =
  (navigate) =>
  (path, { params = {}, removeParams = [], pickParams = [] } = {}) => {
    const currentSearch = pipe(
      when(() => removeParams.length > 0, omit(removeParams)),
      when(() => pickParams.length > 0, pick(pickParams))
    )(getSearchParams());
    const mergedSearch = mergeDeepRight(currentSearch, params);
    const s = stringifySearchParams(mergedSearch);
    navigate(`${path}${s}`);
  };

export const DateRangeObjectParam = {
  encode: (obj) => obj && encodeObject(removeNils(obj), '_', '~'),
  decode: (str) => str && decodeObject(str, '_', '~')
};
