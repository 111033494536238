import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { useModalsContext } from '../../hooks';
import { modalTypes } from '../../constants';
import { StyledMap, StyledTooltip, OpenMapFullPageIcon, LoadingLabel } from './elements';
import Legend from './Legend';
import Total from './Total';
import LayerMenu from './LayerMenu';

function Maps({
  fullScreen,
  fullScreenCompute,
  geolocation,
  layerMenu,
  layerOptions,
  totalCount,
  loading,
  layers,
  mapTheme,
  tooltip,
  initialView,
  legend,
  ...props
}) {
  const { openModal } = useModalsContext();
  const t = useTranslations();
  const [initialCoords, setInitialCoords] = useState(initialView);
  const [refresh, setRefresh] = useState(0);
  const [opts, setOpts] = useState(layerOptions.map((o) => ({ ...o, onChange: () => {} })));

  useEffect(() => {
    if (geolocation && navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (['granted', 'prompt'].includes(result.state)) {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const hasData = layers && layers[0] && layers[0].data;
              if (!hasData || hasData.length === 0) {
                setInitialCoords({
                  ...initialCoords,
                  longitude: pos.coords.longitude,
                  latitude: pos.coords.latitude
                });
              }
            },
            () => {},
            {
              enableHighAccuracy: true,
              timeout: 2000,
              maximumAge: 0
            }
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    if (geolocation) {
      const layerWithInfo = layers.find((l) => !['tripMap', 'lineMap'].includes(l.type) && l.data);
      if (layerWithInfo && layerWithInfo.data.length) {
        setInitialCoords({
          ...initialCoords,
          zoom: 13,
          longitude: layerWithInfo.data[0][0],
          latitude: layerWithInfo.data[0][1]
        });
      }
    }
  }, [layers]);

  useEffect(() => {}, [refresh]);

  const trigger = (option) => {
    if (!fullScreen && fullScreenCompute) {
      const newOpts = opts;
      newOpts.find((o) => o.id === option.id).checked = option.checked;
      const { totalRows: computeTotalRows, ...compute } = fullScreenCompute(
        newOpts.reduce((a, key) => ({ ...a, [key.id]: key.checked }), {})
      );
      newOpts.forEach((o) => {
        if (o.disabledByParent) {
          const parentChecked = newOpts.find((p) => p.id === o.disabledByParent).checked;
          // eslint-disable-next-line no-param-reassign
          o.disabled = !parentChecked;
        }
      });

      const newLayers = layers;
      Object.keys(compute).forEach((key) => {
        newLayers[key].data = compute[key];
      });
      const newTotalCount = totalCount;
      if (totalCount && computeTotalRows) {
        newTotalCount.totalRows = computeTotalRows;
      }
      setOpts(newOpts);
      setRefresh(refresh + 1);
    }
  };

  return (
    <>
      {layerMenu && (
        <LayerMenu
          layerOptions={!fullScreen && fullScreenCompute ? opts : layerOptions}
          triggerChange={trigger}
        />
      )}
      {legend.length > 0 && <Legend legend={legend} />}
      {fullScreen && (
        <StyledTooltip label={t('TOOLTIP_MAP_EXPAND')} position="left">
          <OpenMapFullPageIcon
            onClick={() =>
              openModal({
                type: modalTypes.FULL_SIZE_MAP,
                data: {
                  layers,
                  mapTheme,
                  initialView,
                  tooltip,
                  legend,
                  totalCount,
                  layerMenu,
                  layerOptions,
                  fullScreenCompute
                }
              })
            }
          />
        </StyledTooltip>
      )}
      <Total totalCount={totalCount} />
      <StyledMap
        {...props}
        mapTheme={mapTheme}
        blend="normal"
        initialView={initialCoords}
        tooltip={tooltip}
        layers={layers}
      />
      {loading && <LoadingLabel>{t('LOADING_MAPS_RESULTS')}</LoadingLabel>}
    </>
  );
}

Maps.propTypes = {
  layers: PropTypes.array.isRequired,
  initialView: PropTypes.shapeOf({
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
    minZoom: PropTypes.number.isRequired,
    maxZoom: PropTypes.number.isRequired
  }).isRequired,
  latitude: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  mapTheme: PropTypes.string.isRequired,
  tooltip: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  fullScreenCompute: PropTypes.func,
  legend: PropTypes.arrayOf(
    PropTypes.shapeOf({
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  layerMenu: PropTypes.bool,
  layerOptions: PropTypes.arrayOf(
    PropTypes.shapeOf({
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.fn,
      disabled: PropTypes.bool,
      disabledByParent: PropTypes.string
    })
  ),
  totalCount: PropTypes.shapeOf({
    totalRows: PropTypes.number.isRequired,
    totalTenants: PropTypes.number.isRequired,
    tenantsLoaded: PropTypes.number.isRequired
  }),
  loading: PropTypes.bool,
  geolocation: PropTypes.bool
};

Maps.defaultProps = {
  fullScreen: false,
  fullScreenCompute: null,
  legend: [],
  layerMenu: false,
  layerOptions: [],
  loading: false,
  geolocation: false,
  totalCount: null
};

export default Maps;
