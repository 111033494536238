import { memoizeWith, identity } from 'ramda';
import {
  productStatusI18nKeys,
  productSubstatusI18nKeys,
  deviceTypesI18nKeys,
  meterTypesI18nKeys,
  transmitterTypesI18nKeys,
  connectionStatusTypesI18nKeys,
  fileFormatsI18nKeys,
  downloadImportsI18nKeys,
  exportsStatusI18nKeys,
  withAlarmsI18nKeys,
  withCriticalMetersI18nKeys,
  distributionNetworkI18nKeys,
  productStatusIOTTypesI18nKeys,
  statusLNSTypesI18nKeys
} from '../../constants/products';
import {
  devicesAlarmTypesI18nKeys,
  transmittersAlarmTypesI18nKeys,
  metersAlarmTypesI18nKeys,
  productOptionsAlarmTypesI18nKeys,
  managerAlarmConfigurationTypesI18nKeys,
  customerAlarmConfigurationTypesI18nKeys,
  criticityTypesI18nKeys,
  alarmStatusI18nKeys,
  weekdayTypesShortI18nKeys,
  alarmRoleTypesI18nKeys,
  customerNotifiedTypesI18nKeys,
  alarmAnalyticsCriticityTypesI18nKeys,
  alarmAnalyticsCommentTypesI18nKeys,
  alarmAnalyticsJustifiedTypesI18nKeys
} from '../../constants/alarms';

import { consumptionOriginTypesI18nKeys } from '../../constants/consumptions';

const compareOptionLabels = (a, b) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export const buildOptions = (i18nKeys, translations) => {
  return Object.keys(i18nKeys).reduce((acc, key) => {
    return acc.concat({
      label: translations(i18nKeys[key]),
      value: key
    });
  }, []);
};

const buildOptionsGetter = (i18nKeys, { parseKey = identity, sortAlphabetically = true } = {}) =>
  memoizeWith(identity, (translations) => {
    const options = Object.keys(i18nKeys).reduce((acc, key) => {
      const parsedKey = parseKey(key);
      return acc.concat({
        label: translations(i18nKeys[key]),
        value: parsedKey
      });
    }, []);
    return sortAlphabetically ? options.sort(compareOptionLabels) : options;
  });

const buildOptionsGetterWithAdditionalProps = (
  i18nKeys,
  { parseKey = identity, sortAlphabetically = true } = {}
) =>
  memoizeWith(identity, (translations) => {
    const options = Object.keys(i18nKeys).reduce((acc, key) => {
      const { key: i18nKey, ...props } = i18nKeys[key];
      const parsedKey = parseKey(key);
      return acc.concat({
        label: translations(i18nKey),
        value: parsedKey,
        ...props
      });
    }, []);
    return sortAlphabetically ? options.sort(compareOptionLabels) : options;
  });

const buildOptionsGetterWithPermissions = (
  i18nKeys,
  capabilities,
  translations,
  parseKey = identity
) =>
  Object.keys(i18nKeys)
    .reduce((acc, key) => {
      const parsedKey = parseKey(key);
      // TODO: review next condition
      if (!capabilities || capabilities.includes(i18nKeys[key].permission)) {
        return acc.concat({
          label: translations(i18nKeys[key].key),
          value: parsedKey
        });
      }
      return acc;
    }, [])
    .sort(compareOptionLabels);

export const getStatusOptions = (capabilities, translations) =>
  buildOptionsGetterWithPermissions(productStatusI18nKeys, capabilities, translations, Number);
export const getSubstatusOptions = (capabilities, translations) =>
  buildOptionsGetterWithPermissions(productSubstatusI18nKeys, capabilities, translations, Number);

export const getNetworkOptions = buildOptionsGetter(distributionNetworkI18nKeys);
export const getStatusIOTOptions = buildOptionsGetter(productStatusIOTTypesI18nKeys);
export const getExportStatusOptions = buildOptionsGetter(exportsStatusI18nKeys, {
  parseKey: Number
});

export const getDeviceTypeOptions = buildOptionsGetter(deviceTypesI18nKeys);
export const getMeterTypeOptions = buildOptionsGetter(meterTypesI18nKeys);
export const getTransmitterTypeOptions = buildOptionsGetter(transmitterTypesI18nKeys);

export const getConnectionStatusOptions = buildOptionsGetter(connectionStatusTypesI18nKeys);
export const getFileFormatsOptions = buildOptionsGetterWithAdditionalProps(fileFormatsI18nKeys, {
  sortAlphabetically: false
});
export const getDownloadImportsOptions = buildOptionsGetter(downloadImportsI18nKeys, {
  sortAlphabetically: false
});

export const getDevicesAlarmOptions = buildOptionsGetter(devicesAlarmTypesI18nKeys);
export const getTransmittersAlarmOptions = buildOptionsGetter(transmittersAlarmTypesI18nKeys);
export const getMetersAlarmOptions = buildOptionsGetter(metersAlarmTypesI18nKeys);
export const getAlarmTypesOptions = buildOptionsGetter(productOptionsAlarmTypesI18nKeys);

export const getManagerAlarmConfigurationOptions = buildOptionsGetter(
  managerAlarmConfigurationTypesI18nKeys
);
export const getCustomerAlarmConfigurationOptions = buildOptionsGetter(
  customerAlarmConfigurationTypesI18nKeys
);

const parseBoolean = (val) => val === 'true';

export const getWithAlarmsOptions = buildOptionsGetter(withAlarmsI18nKeys, {
  parseKey: parseBoolean
});
export const getWithCriticalMetersOptions = buildOptionsGetter(withCriticalMetersI18nKeys, {
  parseKey: parseBoolean
});

export const getCriticityOptions = buildOptionsGetter(criticityTypesI18nKeys);
export const getAlarmAnalyticsCriticityOptions = buildOptionsGetter(
  alarmAnalyticsCriticityTypesI18nKeys
);
export const getAlarmAnalyticsCommentOptions = buildOptionsGetter(
  alarmAnalyticsCommentTypesI18nKeys
);
export const getAlarmAnalyticsJustifiedOptions = buildOptionsGetter(
  alarmAnalyticsJustifiedTypesI18nKeys
);
export const getAlarmStatusOptions = buildOptionsGetter(alarmStatusI18nKeys);

export const getWeekdayShortOptions = buildOptionsGetter(weekdayTypesShortI18nKeys, {
  sortAlphabetically: false
});

export const getAlarmRoleTypesOptions = buildOptionsGetter(alarmRoleTypesI18nKeys);
export const getCustomerNotifiedOptions = buildOptionsGetter(customerNotifiedTypesI18nKeys);

export const getConsumptionOriginTypesOptions = buildOptionsGetter(consumptionOriginTypesI18nKeys);

export const getLnsStatusOptions = buildOptionsGetter(statusLNSTypesI18nKeys);
