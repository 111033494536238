import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Icon } from '@mui/material';
import { Typography } from '@rmwc/typography';
import { CloseButton } from '@acua/common/components';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.inputBg};
  padding: 12px;
  margin: 6px 0;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  color: ${({ theme }) => theme.textPrimaryOnLight};
  > * {
    margin: 0;
    margin-left: 6px;
  }
  > p {
    flex-grow: 1;
    font-weight: 400;
  }
  i {
    font-size: 22px;
  }
`;

function SelectedItem({ icon, displayName, onRemove }) {
  return (
    <Container>
      {icon && <Icon>{icon}</Icon>}
      <Typography use="headline6" tag="p">
        {displayName}
      </Typography>
      <CloseButton onClick={onRemove} />
    </Container>
  );
}

SelectedItem.propTypes = {
  icon: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default SelectedItem;
