import { path } from 'ramda';
import auth from '@acua/common/auth';
import { platformUrl } from '../config';

const extractDataSelfApps = path(['data', 'self', 'apps']);

const getUserApps = () => {
  return auth
    .authFetch(`${platformUrl}/appNavigator`, {
      json: true
    })
    .then(extractDataSelfApps);
};

export default {
  getUserApps
};
