import auth from '@acua/common/auth';
import { gatewayUrl } from '../config';

const exportsUrl = `${gatewayUrl}/exports`;

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const downloadFile = (fileId) => {
  const url = `${exportsUrl}/download/${fileId}`;
  return auth
    .authFetch(url)
    .then(handleErrors)
    .then((res) => res.blob());
};

const exportFile = (body) => {
  const url = `${exportsUrl}`;
  return auth.authFetch(url, {
    method: 'POST',
    json: true,
    body
  });
};

export default {
  downloadFile,
  exportFile
};
