import auth from '@acua/common/auth';
import { gatewayUrl } from '../config';

const insertReading = (id, instanceId, body) =>
  auth.authFetch(`${gatewayUrl}/publish/readings/${id}`, {
    method: 'POST',
    body,
    json: true,
    instanceId
  });

export default { insertReading };
