import { useTranslations } from '@react-utils/intl';
import { Select } from '@rmwc/select';
import { useFeatureFlagContext } from '@acua/common/hooks';
import { anTenant } from '@acua/common/utils/anonymize/anonymize';
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form';
import { deviceTypes } from '@acua/common/constants';
import ErrorLabel from '../common/ErrorLabel';

const tech = {
  LORA: 'LoRa',
  NBIOTDEVICE: 'NB-IOT',
  BUSNBIOT: 'BUS NB-IOT',
  MODULE_VHF_1G: 'VHF',
  MODULE_VHF_2G: 'VHF',
  GENERIC: 'Generic',
  HYDROSENSE_METERING: 'Hydrosense',
  IMETER_C: 'iMeter',
  IMETER_E: 'iMeter'
};
const mapDeviceTypeToTechnology = {
  [deviceTypes.LORA]: tech.LORA,
  [deviceTypes.NBIOTDEVICE]: tech.NBIOTDEVICE,
  [deviceTypes.BUSNBIOT]: tech.BUSNBIOT,
  [deviceTypes.MODULE_VHF_1G]: tech.MODULE_VHF_1G,
  [deviceTypes.MODULE_VHF_2G]: tech.MODULE_VHF_2G,
  [deviceTypes.GENERIC]: tech.GENERIC,
  [deviceTypes.HYDROSENSE_METERING]: tech.HYDROSENSE_METERING,
  [deviceTypes.IMETER_C]: tech.IMETER_C,
  [deviceTypes.IMETER_E]: tech.IMETER_E,
  loraGateway: 'transmitterLora',
  ondeoTransmitterG1: 'transmitterWize',
  ondeoTransmitterG2: 'transmitterWize'
};
const checkTenantTechnology = (destinyTenantTech, productType) => {
  const technology = mapDeviceTypeToTechnology[productType];

  return !destinyTenantTech[technology];
};

function Tenant({ tenantId, tenants, productType }) {
  const t = useTranslations();
  const { featureFlags } = useFeatureFlagContext();
  const { input, meta } = useField('tenantId', {
    validate: (value) => {
      if (!value) {
        return t('FORM_VALIDATION_ERROR_REQUIRED');
      }
      if (
        checkTenantTechnology(
          tenants.find((tenant) => tenant.id === value).settings.data.inventory.Technologies || {},
          productType
        )
      ) {
        return 'El tenant no tiene habilitada la tecnología. Contacta con el equipo de soporte.';
      }
      return undefined;
    }
  });

  return (
    <div>
      <Select
        {...input}
        id="modals_tenant_input"
        label={t('MODAL__CHANGE_TENANT_TENANT')}
        placeholder=""
        options={tenants
          .map((tenant) => ({
            value: tenant.id,
            label: featureFlags.usersEnabledDemo ? anTenant(tenant.id) : tenant.name
          }))
          .filter(({ value }) => value !== tenantId)
          .sort((a, b) => a.label.localeCompare(b.label))}
      />
      {meta.touched && meta.invalid && <ErrorLabel>{meta.error}</ErrorLabel>}
    </div>
  );
}

Tenant.propTypes = {
  tenantId: PropTypes.string.isRequired,
  tenants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  productType: PropTypes.shape({}).isRequired
};
export default Tenant;
