import React from 'react';
import PropTypes from 'prop-types';

import { useField } from 'react-final-form';

import { required } from '@acua/common/utils';
import { TimePickerInput } from '@acua/common/components';

function TimeField({ disabled }) {
  const {
    input: { onChange, value, ...inputRest },
    meta
  } = useField('time', { validate: required });

  const getTimeValue = (time, timeProp) => {
    if (time[timeProp] && typeof time[timeProp] === 'function') {
      return time[timeProp]();
    }
    if (['string', 'number'].includes(typeof time[timeProp])) {
      return time[timeProp];
    }
    return undefined;
  };

  const onTimeChange = (time) => {
    if (!time) {
      onChange(null);
      return;
    }

    const inputHour = getTimeValue(time, 'hour');
    const inputMinute = getTimeValue(time, 'minute');

    onChange(`${inputHour}:${inputMinute}`);
  };

  return (
    <TimePickerInput
      disabled={disabled}
      value={value}
      id="modals_time_picker_input"
      onChange={onTimeChange}
      inputProps={{
        ...meta,
        ...inputRest
      }}
    />
  );
}
TimeField.propTypes = {
  disabled: PropTypes.bool
};

TimeField.defaultProps = {
  disabled: false
};

export default TimeField;
