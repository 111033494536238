import React from 'react';
import PropTypes from '@acua/common/propTypes';
import { ErrorMsg, Notification } from '@acua/common/components';
import { useTranslations } from '@react-utils/intl';

function Status({ data }) {
  const t = useTranslations();
  const { error } = data;

  return !error ? null : (
    <Notification
      label={t('INSERT_READING__STATUS_TITLE_ERROR')}
      type="error"
      style={{ alignItems: 'start' }}
      id="modals_status_error"
      message={<ErrorMsg error={error} />}
    />
  );
}

Status.propTypes = {
  data: PropTypes.shape({
    error: PropTypes.string.isRequired
  }).isRequired
};

export default Status;
