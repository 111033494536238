import React from 'react';

function LegalPage() {
  return (
    <div style={{ width: '900px', margin: '0 auto' }}>
      <h1>POLÍTICA DE PRIVACIDAD DE SERVICIOS WEB DE AQUALOGY SOLUTIONS , S.A.U.</h1>
      <ol>
        <li>
          <strong>¿Qué finalidad tiene esta Política de Protección de Datos Personales?</strong>
          <br />
          Esta Política de Privacidad describe el tratamiento de los datos personales que Aqualogy
          Solutions, S.A.U. (Solutions), lleva a cabo en relación con los Usuarios que utilicen los
          servicios web y utilizan los formularios de contacto e inscripción incluidos en los
          mismos.
          <br />
          <br />
          En la relación de servicios web se pueden encontrar distintos enlaces a plataformas y
          páginas gestionadas por terceras entidades, desde las que se ofrecen servicios comunes
          para empresas del grupo Agbar que pertenece la sociedad. Estas páginas disponen de su
          propia Política de Privacidad en la que se detalla la entidad responsable del tratamiento
          de los datos y las finalidades del mismo.
          <br />
          <br />
        </li>
        <li>
          <strong>
            ¿Quién es el Responsable del tratamiento de los datos recabados a través de los
            servicios web?
          </strong>
          <br />
          El responsable del tratamiento de los datos personales recabados a través de los servicios
          web, es Aqualogy Solutions, S.A.U. (en adelante, “la Sociedad”), con domicilio social en
          Paseo De La Castellana 259c Pl 31 Sur 28046 - Madrid y NIF nº A-08018954, que dispone de
          un canal de contacto accesible en <a href="mailto:info@solutions.es">info@solutions.es</a>
          <br />
          <br />
        </li>
        <li>
          <strong>
            ¿Qué tipo de datos recoge la Sociedad a través de este Sitio web y para qué se utilizan?
          </strong>
          <br />
          Visitar la Web de la Sociedad y navegar por sus distintas secciones no requiere que se
          facilite ningún dato de carácter personal. Sin embargo, recogeremos determinada
          información relacionada con la navegación que realiza (que no siempre nos permitirá
          identificar al Usuario) a través de cookies instaladas en su equipo. Por favor, consulte
          más detalles en nuestra Política de Cookies.
          <br />
          <br />
          <strong>
            El Usuario del servicio web puede realizar diferentes gestiones para las que será
            necesario tratar sus datos personales:
          </strong>
          <br />
          <ol>
            <li>
              Gestiones para las que no será necesario que sea cliente ni esté registrado en nuestro
              sistema, por ejemplo: formular quejas, presentar reclamaciones, realizar consultas.
              <br />
              <ul>
                <li>
                  Se le solicitarán datos identificativos (nombre, apellidos y DNI en algunos
                  casos), datos de contacto (dirección postal, teléfono, correo electrónico según la
                  petición), otros datos relacionados con la petición que se efectúa.
                </li>
                <li>
                  Sus datos se tratarán con la finalidad de gestionar la solicitud contenida en la
                  comunicación que realice.
                </li>
              </ul>
            </li>
            <li>
              La base de legitimación será el consentimiento prestado por el Usuario que los
              facilita, que se pone en contacto de forma voluntaria con la Sociedad. Este
              consentimiento es revocable en todo caso, sin efectos retroactivos (es decir, no
              afectaría a las peticiones o solicitudes ya tramitadas).
              <br />
              <ul>
                <li>
                  Servicio web que tiene por finalidad la suscripción a eventos presenciales o a
                  distancia (eventos).
                  <br />
                  Se le solicitarán en el momento de la suscripción, datos identificativos (nombre,
                  apellidos), datos de contacto (teléfono, correo electrónico) y otros datos
                  profesionales relacionados con su cargo y/o experiencia, así como en el transcurso
                  del evento, podrán recogerse imagen y/o voz de los participantes (ponentes y
                  asistentes) y en el caso que el evento sea por medios telemáticos, se podrán
                  recoger datos relacionados con la conexión del dispositivo utilizado.
                </li>
                <li>
                  De inscribirse el Usuario en eventos organizados por la Sociedad, sus datos
                  personales serán tratados con el fin de gestionar su inscripción y asistencia, así
                  como atender aquellas consultas y/o incidencias que puedan plantearse en relación
                  con los mismos , siendo la base de legitimación la correcta ejecución del
                  contrato, pues su tratamiento es necesario para formalizar la inscripción y
                  participación en dichos eventos, y también con la finalidad de gestionar noticias
                  o convocatorias de próximos eventos así como ofertas de servicios propios, siendo
                  la base de legitimación el interés legítimo de la sociedad.
                  <br />
                  <br />
                </li>
              </ul>
            </li>
            <li>
              Servicio que tiene por finalidad remitirle comunicaciones comerciales.
              <br />
              Siempre que usted nos otorgue su consentimiento, La sociedad podrá remitirle
              información comercial (por medios electrónicos, impresos y/o telefónicos) sobre la
              Sociedad y, de otras sociedades del grupo empresarial al que pertenece, si se indica
              de forma expresa en el consentimiento otorgado. Este servicio se mantendrá hasta que
              nos solicite que dejemos de hacerlo.
              <br />
              Se le solicitarán datos identificativos (nombre, apellidos), datos de contacto
              (teléfono, correo electrónico)
              <br />
              Tenga en cuenta que para el envío de información comercial por medios electrónicos
              utilizamos tecnologías de seguimiento de terceros que nos permiten obtener información
              sobre la apertura de los correos electrónicos y la pulsación de los enlaces contenidos
              en los mismos por parte de los destinatarios, a los efectos de medir la efectividad de
              las campañas y para conocer la información que a usted le interesa en función de su
              interacción con nuestras campañas, a los efectos de remitirle, en base a su perfil,
              contenidos relacionados.
              <br />
              <br />
            </li>
            <li>
              <strong>
                Servicio web que tiene por finalidad la inscripción a servicios específicos
              </strong>
              <br />
              <ul>
                <li>
                  Se le solicitarán en el momento de la inscripción, datos identificativos (nombre,
                  apellidos), datos de contacto (teléfono, correo electrónico) y otros datos
                  profesionales relacionados con su cargo.
                  <br />
                  De inscribirse el Usuario a servicios específicos prestados por la sociedad, sus
                  datos personales serán tratados con el fin de gestionar su inscripción al
                  servicio, así como atender aquellas consultas y/o incidencias que puedan
                  plantearse en relación con los mismos , siendo la base de legitimación la correcta
                  ejecución del contrato, pues su tratamiento es necesario para formalizar la
                  inscripción para la prestación del servicio, y también con la finalidad de
                  gestionar, noticias o eventos, así como ofertas de servicios propios, siendo la
                  base de legitimación el interés legítimo de la Sociedad.
                  <br />
                  <br />
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          <strong>Plazo de conservación de los datos</strong>
          <br />
          Los datos personales serán conservados durante el tiempo que sea necesario para tramitar y
          prestar el servicio objeto de la solicitud efectuada. Una vez finalizado dicho plazo
          deberemos conservar sus datos debidamente bloqueados durante un periodo adicional en que
          pudieran existir cualesquiera responsabilidades derivadas de servicios facilitados o de
          los tratamientos realizado
          <br />
          <br />
          Para el servicio web de eventos, sus datos personales serán tratados mientras sean
          necesarios para la gestión de su participación en dichos eventos, y hasta el momento en
          que nos indique que no quiera que le mantengamos informado de futuros eventos y una vez
          finalizado dicho plazo sus datos se conservarán debidamente bloqueados a efectos de poder
          atender posibles responsabilidades legales surgidas del tratamiento.
          <br />
          <br />
          Para los servicios gestionados por inscripción, sus datos personales serán tratados
          mientras sean necesarios para la prestación del servicio, y hasta el momento en que nos
          indique la cancelación de su inscripción y una vez finalizado dicho plazo sus datos se
          conservarán debidamente bloqueados a efectos de poder atender posibles responsabilidades
          legales surgidas del tratamiento
          <br />
          <br />
          En caso de que haya autorizado el envío de comunicaciones comerciales, mantendremos sus
          datos hasta el momento en que nos indique que quiere dejar de recibir nuestras
          comunicaciones.
          <br />
          <br />
        </li>
        <li>
          <strong>
            ¿Quiénes pueden acceder a los datos personales facilitados a través de este sitio web?
          </strong>
          <br />
          La Sociedad utiliza prestadores de servicios para la realización de algunas tareas propias
          del contrato que en su caso deberán acceder a los datos de usuarios para poder realizar la
          gestión que solicita. Estos prestadores tendrán la condición de Encargados del
          tratamiento, y únicamente tratarán los datos personales conforme a las instrucciones de la
          Sociedad, tales como proveedores de servicios tecnológicos. En ningún caso utilizarán los
          datos personales del Usuario para fines propios o diferentes a los detallados en esta
          Política.
          <br />
          <br />
          Algunos de nuestros proveedores están ubicados en países situados fuera del Espacio
          Económico Europeo (EEE) o, estando ubicadas en EEE, comparten la información con otras
          entidades ubicadas fuera de dicho territorio. Solutions garantiza que:
          <br />
          <ul>
            <li>
              Las transferencias se realizan a países respecto de los que la Comisión Europea ha
              declarado que proporcionan un nivel de protección equiparable al europeo.
            </li>
            <li>
              En ausencia de dicha declaración de adecuación (en la actualidad se realizan
              transferencias a países como EE UU, entre otros), se han firmado las Cláusulas
              Contractuales Tipo aprobadas por la Comisión.
              <br />
              Puedes consultar esta información en la página de la AEPD:
              <a href="https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/medidas-de-cumplimiento/transferencias-internacionales">
                https://www.aepd.es/es/derechos-y-deberes/cumple-tus-deberes/medidas-de-cumplimiento/transferencias-internacionales
              </a>
              .<br />
              Para más información, puede contactar con el Delegado de Protección de datos de
              Solutions.
              <br />
              <br />
            </li>
          </ul>
        </li>
        <li>
          <strong>¿A quién comunicamos sus datos?</strong>
          <br />
          La Sociedad puede comunicar sus datos personales a:
          <br />
          <br />
          <ul>
            <li>
              Administraciones públicas, juzgados, tribunales entre otros, a requerimientos de estos
              o cuando venga impuesto por una obligación legal.
            </li>
            <li>
              Empresas del grupo empresarial al que pertenece la sociedad para las mismas
              finalidades de tratamiento descritas, basado en el interés legítimo y respetándose en
              todos los casos las medidas de seguridad exigidas por la legislación vigente en
              materia de protección de datos de carácter personal
              <br />
              <br />
            </li>
          </ul>
        </li>
        <li>
          <strong>
            ¿Cuáles son sus derechos en materia de protección de datos y cómo puede ejercitarlos?
          </strong>
          <br />
          La Ley le reconoce, en caso de que haya otorgado su consentimiento a poder retirarlo en
          cualquier momento, así como el poder ejercer los siguientes derechos en materia de
          protección de datos:
          <br />
          <br />
          <table style={{ width: '710.375px' }}>
            <thead>
              <tr>
                <th style={{ width: '117px' }}>
                  <p>
                    <strong>Derecho</strong>
                  </p>
                </th>
                <th style={{ width: '576.375px' }}>
                  <p>
                    <strong>Contenido</strong>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Acceso</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Podrá consultar qué datos personales están incluidos en las bases de datos de
                      la Sociedad.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Rectificación</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Podrá modificar sus datos personales cuando sean inexactos o incompletos.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Supresión</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Podrá solicitar que eliminemos sus datos personales.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Oposición</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Solicitar que no tratemos sus datos para algunas finalidades concretas.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Limitación del tratamiento</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Podrá solicitar la limitación al tratamiento de sus datos en los siguientes
                      casos:
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      -Mientras se comprueba la impugnación de la exactitud de sus datos.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      -Cuando el tratamiento es ilícito, pero Ud. se opone a la supresión de sus
                      datos.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      -Cuando la Sociedad no necesite tratar sus datos, pero Ud. los necesite para
                      el ejercicio o la defensa de reclamaciones.
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      -Cuando Ud. se haya opuesto al tratamiento de sus datos para el cumplimiento
                      de una misión en interés público o para la satisfacción de un interés
                      legítimo, mientras se verifica si los motivos legítimos para el tratamiento
                      prevalecen sobre los tuyos.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ width: '117px' }}>
                  <p>
                    <strong>Portabilidad</strong>
                  </p>
                </td>
                <td style={{ width: '576.375px' }}>
                  <p>
                    <span style={{ fontWeight: '400' }}>
                      Podrá recibir, en formato electrónico, los datos personales que nos haya
                      facilitado, así como a transmitirlos a otro responsable.
                      <br />
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <strong>
            Para ejercer los anteriores derechos, o revocar el consentimiento prestado
          </strong>
          , bastará con enviar una solicitud, por cualquiera de los siguientes medios:
          <br />
          <br />- Dirigiéndonos una carta por correo postal a la dirección del domicilio social:
          Paseo De La Castellana 259c Pl 31 Sur 28046 - Madrid, a la att. del delegado de protección
          de datos de Solutions.
          <br />- Por medio del correo de atención al cliente: info@solutions.es
          <br />
          <br />
          El solicitante deberá estar suficientemente identificado en la solicitud, que habrá de
          estar firmada. Si la solicitud la formula un tercero, deberá acreditarse oportunamente la
          representación otorgada para ello. Cuando el responsable del tratamiento tenga dudas
          razonables en relación con la identidad de la persona física que cursa la solicitud, podrá
          solicitar que se facilite la información adicional necesaria para confirmar su identidad.
          <br />
          <br />
          El ejercicio de estos derechos es gratuito si bien podrá cobrarse un canon cuando las
          solicitudes sean infundadas, excesivas o repetitivas.
          <br />
          <br />
          Adicionalmente, cualquier duda en materia de protección de datos puede ser remitida al
          Data Protection Officer (DPO) de la Sociedad, cuya información de contacto es la
          siguiente:
          <br />
          <br />- Correo electrónico: privacy.spain@agbar.es
          <br />- Correo Postal: Paseo de la Zona Franca, 48 Planta 6, Barcelona (08038) A/A del
          DPO.
          <br />
          <br />
          Su reclamación se investigará de forma confidencial y recibirá una respuesta del DPO.
          <br />
          En todo caso, como afectado o interesado, puede presentar las reclamaciones que considere
          oportunas ante la autoridad de control competente en materia de protección de datos.
          <br />
          <br />
          APDCAT - Agencia Catalana de protección de datos
          <a href="https://apdcat.gencat.cat/ca/inici">https://apdcat.gencat.cat/ca/inici</a>
          <br />
          AEPD - Agencia Española de Protección de datos
          <a href="https://www.aepd.es/">https://www.aepd.es/</a>
          <br />
          <br />
        </li>
        <li>
          <strong>Redes sociales</strong>
          <br />
          Como norma general, los perfiles corporativos de la Sociedad en redes sociales (Twitter,
          Facebook, etc.) tienen un carácter meramente informativo. Si nos facilita datos a través
          de mensajes privados en redes sociales con motivo de una consulta o petición realizada por
          ese medio, los datos personales facilitados serán utilizados exclusivamente para atender
          la petición efectuada, en los términos expuestos en esta política. Le recordamos que no
          debe incluir datos personales suyos o de terceros (como e-mails, nombre, teléfono o número
          de contrato) a través de mensajes abiertos en ninguna red social. Si no está seguro de si
          su mensaje puede o no ser leído por terceros, le aconsejamos que nos contacte por otro
          canal.
        </li>
      </ol>
    </div>
  );
}

export default LegalPage;
