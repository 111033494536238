import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import CustomSimpleDialog from '../components/CustomSimpleDialog';

function InfoModal({ actionKey, title, ...props }) {
  const t = useTranslations();

  return (
    <CustomSimpleDialog
      key="confirm_modal"
      title={title || t('MODAL_CONFIRM__TITLE')}
      // you can pass custom props as title, body, acceptLabel,... but they should be only serializable props (strings)
      {...props}
    />
  );
}

InfoModal.propTypes = {
  actionKey: PropTypes.string.isRequired,
  title: PropTypes.string
};

InfoModal.defaultProps = {
  title: null
};

export default InfoModal;
