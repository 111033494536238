import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@rmwc/textfield';
import { path } from 'ramda';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import { Typography } from '@rmwc/typography';
import { Switch } from '@rmwc/switch';
import CustomSimpleDialog from '../../components/CustomSimpleDialog';
import Status from '../../components/Status';
import { HelpIcon } from '../../../../../common/components';
import useUpdateAlarmMetadata from './useUpdateAlarmMetadata';

const CommentField = styled(TextField)`
  border-color: rgba(112, 114, 115, 0.73) !important;
  textarea {
    resize: none !important;
    border: solid 0.5px #d0d2d3;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  border: solid 1px #dadada;
  height: 56px;
  padding: 6px 16px;
  margin-top: 20px;
`;
const Content = styled.div`
  overflow: hidden;
`;
const extractComment = path(['comment']);

function AddCommentToAlarm({ data: { row }, ...props }) {
  const t = useTranslations();
  const [metadata, setMetadata] = useState({
    comment: extractComment(row) || '',
    justify: row.justify || false
  });
  const { updateMetadata, updateCommentStatus } = useUpdateAlarmMetadata(row);

  return (
    <CustomSimpleDialog
      {...props}
      onAccept={() => updateMetadata(metadata)}
      title={t('PRODUCT_ACTION_ADD_COMENT')}
      body={
        <Content>
          <Status data={updateCommentStatus} />
          <CommentField
            id="modals_comment_field"
            name="textareaRevisionModal"
            textarea
            fullwidth
            outlined
            rows="4"
            value={metadata.comment}
            maxLength="1000"
            characterCount
            onChange={(event) => setMetadata({ ...metadata, comment: event.target.value })}
          />
          <Container>
            <Typography use="button" tag="label">
              {t('ALARMS_JUSTIFIED_MESSAGE')}{' '}
              <HelpIcon tooltip={t('ALARMS_JUSTIFIED_MESSAGE_HELP')} />
            </Typography>
            <Switch
              id="modals_comment_switch"
              checked={metadata.justify}
              onChange={(e) => setMetadata({ ...metadata, justify: !!e.currentTarget.checked })}
            />
          </Container>
        </Content>
      }
    />
  );
}

AddCommentToAlarm.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.object
  }).isRequired
};

export default AddCommentToAlarm;
