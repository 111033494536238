import { ImportsService } from '@acua/common/services';

export const GET_IMPORT_DETAILS = 'GET_IMPORT_DETAILS';
export const UPDATE_PAGE_TOKENS = 'UPDATE_PAGE_TOKENS';
export const CLEAN_DATA = 'CLEAN_DATA';

export const importDetails = (id, token, filterField, externalVisible) => ({
  type: GET_IMPORT_DETAILS,
  payload: ImportsService.getInventoryImport(id, token, filterField, externalVisible)
});

export const updatePageTokens = (pageToken) => ({
  type: UPDATE_PAGE_TOKENS,
  payload: pageToken
});

export const cleanData = () => ({
  type: CLEAN_DATA
});
