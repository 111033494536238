import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import ErrorMsg from './ErrorMsg';
import RefetchButton from './RefetchButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
`;

function ErrorRefetch({ refetch, errorMsg, ...props }) {
  const t = useTranslations();
  return (
    <Container {...props}>
      <ErrorMsg error={errorMsg || t('ERROR_MSG_QUERY_DATA_RETRY')} titleUse="body1" />
      {refetch && <RefetchButton refetch={refetch} />}
    </Container>
  );
}

ErrorRefetch.propTypes = {
  refetch: PropTypes.func.isRequired,
  errorMsg: PropTypes.string
};

ErrorRefetch.defaultProps = {
  errorMsg: null
};

export default ErrorRefetch;
