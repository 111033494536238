import { useMutation } from '@apollo/client';
import { changeBattery } from './mutation.gql';

export default (id, tenantId) => {
  const [send, status] = useMutation(changeBattery);

  const sendMutation = (date) => send({ variables: { id, tenantId, date } });

  return { sendMutation, ...status };
};
