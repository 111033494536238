import React from 'react';
import Base from './base';
import Overrides from './overrides';

export default function () {
  return (
    <>
      <Base />
      <Overrides />
    </>
  );
}
