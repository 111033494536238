import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import auth from '@acua/common/auth';
import { gatewayUrl, gatewayMapsUrl } from '../../config';

const productFieldsConfig = {
  fields: {
    inventory: { merge: true },
    analytics: { merge: true },
    configuration: { merge: true }
  }
};

const cacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        analytics: {
          keyFields: ['tenantId'],
          // Concatenate the incoming list items with the existing list items by tenantId.
          merge(existing, incoming) {
            return { ...(existing || {}), ...incoming };
          }
        }
      }
    },
    User: {
      keyFields: []
    },
    Tag: {
      keyFields: ['name']
    },
    CriticalMeter: {
      keyFields: ['serialNumber']
    },
    Exports: {
      keyFields: ['tenantId']
    },
    // TODO: Sidebar deberia tener su propia cache?
    Equipment: productFieldsConfig,
    Meter: productFieldsConfig,
    Transmitter: productFieldsConfig
  },
  possibleTypes: {
    Product: ['Transmitter', 'Sim', 'Modem', 'Equipment', 'Meter']
  }
};

export const cache = new InMemoryCache(cacheConfig);
export const persistedCache = new InMemoryCache(cacheConfig);
export const exportCache = new InMemoryCache(cacheConfig);
export const mapsCache = new InMemoryCache(cacheConfig);

const httpLink = createUploadLink({
  uri: `${gatewayUrl}/graphql`,
  fetchOptions: {
    credentials: 'include'
  },
  fetch: auth.graphqlAuthFetch
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, response }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Response: ${response?.body?.error}`
      )
    );
  }
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const exportsHttpLink = createUploadLink({
  uri: `${gatewayUrl}/exports`,
  fetch: auth.authFetch
});

const mapsHttpLink = createUploadLink({
  uri: `${gatewayMapsUrl}/graphql`,
  fetchOptions: {
    credentials: 'include'
  },
  fetch: auth.graphqlAuthFetch
});

export const getApolloClients = () => {
  const link = ApolloLink.from([errorLink, httpLink]);

  const exportClient = new ApolloClient({
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'network-only'
      }
    },
    cache: exportCache,
    link: ApolloLink.from([errorLink, exportsHttpLink])
  });

  const mapsClient = new ApolloClient({
    cache: mapsCache,
    link: ApolloLink.from([errorLink, mapsHttpLink]),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-first',
        errorPolicy: 'all'
      }
    }
  });

  const client = new ApolloClient({
    cache,
    link,
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        // fetchPolicy defaults to "cache-first"
        nextFetchPolicy: 'cache-first',
        errorPolicy: 'all'
      }
    }
  });

  return { link, exportClient, mapsClient, client };
};
