import React from 'react';
import PropTypes from 'prop-types';
import Highlight from 'react-highlighter';

function LinkedEntity({ label, value, inputSearch }) {
  return (
    <div style={{ display: 'flex', marginBottom: 10 }}>
      <span style={{ marginRight: 4 }}>{`${label}:`}</span>
      <Highlight search={inputSearch}>{value}</Highlight>
    </div>
  );
}

LinkedEntity.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  inputSearch: PropTypes.string.isRequired
};

export default LinkedEntity;
