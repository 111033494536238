import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '@react-utils/intl';
import styled from '@emotion/styled';
import { Typography } from '@rmwc/typography';
import { TabBar, Tab } from '@rmwc/tabs';

import consumptionTypes from './constants/consumptionTypes';
import Diary from './Diary';
import Intradiary from './Intradiary';

const StyledBody = styled.div`
  .MuiInputBase-root {
    height: 56px;
    background-color: #f5f5f5 !important;

    fieldset {
      border: none;
    }
  }
  .MuiBox-root {
    height: auto;
  }
  .DateRangePickerInput {
    width: 100%;
    #date_picker {
      background: none;
      border: none;
      input {
        text-align: center;
        font-size: 14px;
      }
    }
  }
  #tabs_bar_div {
    .mdc-tab-indicator {
      .mdc-tab-indicator__content--underline {
        border-color: #415d9a;
      }
    }
  }
`;

function DialogBody(props) {
  const t = useTranslations();
  const { state, setState } = props;
  const { consumptionType } = state;

  useEffect(() => {
    if (consumptionType === consumptionTypes.INTRADIARY) {
      setState({
        ...state,
        dateRange: {
          startDate: null,
          endDate: null
        }
      });
    }
  }, [consumptionType]);

  return (
    <StyledBody>
      <Typography use="subtitle1" theme="textSecondaryOnBackground">
        {t('DOWNLOAD_CONSUMPTIONS__SUBTITLE')}
      </Typography>
      <TabBar
        id="tabs_bar_div"
        activeTabIndex={consumptionType}
        onActivate={(evt) =>
          setState({ consumptionType: evt.detail.index, tenantId: undefined, search: [] })
        }
        style={{ margin: '10px 0' }}
      >
        <Tab>{t('DOWNLOAD_CONSUMPTIONS__DIARY')}</Tab>
        <Tab>{t('DOWNLOAD_CONSUMPTIONS__INTRADIARY')}</Tab>
      </TabBar>
      {consumptionType === consumptionTypes.DIARY && <Diary {...props} />}
      {consumptionType === consumptionTypes.INTRADIARY && <Intradiary {...props} />}
    </StyledBody>
  );
}

DialogBody.propTypes = {
  state: PropTypes.shape({
    consumptionType: PropTypes.number.isRequired
  }).isRequired,
  setState: PropTypes.func.isRequired
};

export default DialogBody;
