import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import { useTranslations } from '@react-utils/intl';
import moment from 'moment';

import { Grid, GridCell } from 'rmwc';
import { useSnackbarsContext, useAppContext } from '@acua/common/hooks';
import TimeField from '../common/TimeField';
import DateField from '../common/DateField';
import { StyledOverflowDialog } from '../common/elements';
import useChangeBattery from './useChangeBattery';

function ChangeBattery({ data: { row }, ...props }) {
  const t = useTranslations();
  const { productId, tenantId } = row;
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();
  const { sendMutation, loading, error } = useChangeBattery(productId, tenantId);
  return (
    <Form
      onSubmit={({ date, time }) => {
        const changeBatteryDate = moment(`${date} ${time}`, 'DD/MM/YYYY hh:mm');

        return sendMutation(changeBatteryDate)
          .then(() => {
            addSnackbar('SNACKBAR_SAVE_SUCCESS');
            refreshSearchKey();
          })
          .catch((e) => {
            addSnackbar(e.message);
          });
      }}
      subscription={{ invalid: true }}
    >
      {({ handleSubmit, form }) => (
        <StyledOverflowDialog
          {...props}
          onAccept={handleSubmit}
          acceptDisabled={error || loading || form.getState().invalid}
          title={t('SET_CHANGE_BATTERY_DATE')}
          body={
            <>
              <p>{t('DESCRIPTION_CHANGE_BATTERY_DATE')}</p>
              <Grid>
                <GridCell desktop="6" tablet="4">
                  <DateField />
                </GridCell>
                <GridCell desktop="6" tablet="4">
                  <TimeField />
                </GridCell>
              </Grid>
            </>
          }
        />
      )}
    </Form>
  );
}

ChangeBattery.propTypes = {
  data: PropTypes.shape({
    row: PropTypes.object
  }).isRequired
};

export default ChangeBattery;
