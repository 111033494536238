import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';
import { Form } from 'react-final-form';
import styled from '@emotion/styled';
import { Grid, GridCell } from '@rmwc/grid';
import { useTranslations } from '@react-utils/intl';
import { useSnackbarsContext, useAppContext } from '@acua/common/hooks';
import { getEntityType, entityTypes } from '@acua/common/constants';
import { ProductsService } from '@acua/common/services';
import { Notification, Loading, ErrorRefetch } from '@acua/common/components';
import { Typography } from '@rmwc/typography';
import { Radio } from '@rmwc/radio';
import { TextField } from '@rmwc/textfield';
import moment from 'moment';
import Status from '../../components/Status';
import useAssociatedProducts from './useAssociatedProducts';
import TransmitterDown from './TransmitterDown';
import MeterDown from './MeterDown';
import DateField from '../common/DateField';
import TimeField from '../common/TimeField';
import { StyledOverflowDialog } from '../common/elements';
import DeviceDown from '../common/DeviceDown';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0 12px;
  & > * {
    margin-right: 12px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentField = styled(TextField)`
  margin-top: 12px;
  textarea {
    resize: none !important;
    border: solid 0.5px #d0d2d3;
  }
`;

const infoMessages = {
  [entityTypes.TRANSMITTERS]: 'MODAL_INFO_TRANSMITTER_NO_ASSOC',
  [entityTypes.DEVICES]: 'MODAL_INFO_EQUIPMENT_NO_ASSOC',
  [entityTypes.METERS]: 'MODAL_INFO_EQUIPMENT_NO_ASSOC'
};

const extractComments = path(['inventory', 'comments']);

const extractThresholdDate = (obj, data) => {
  const installationDate = path(['inventory', 'installationDate'], obj);
  const associationDate = path(['product', 'inventory', 'lastLinkageDate'], data);
  const creationDate = path(['inventory', 'creationDate'], obj);

  return [installationDate, associationDate, creationDate]
    .filter((e) => e)
    .sort()
    .pop();
};

function DeleteProduct({ data: { entityType, row }, ...props }) {
  const t = useTranslations();
  const [deleteStatus, setDeleteStatus] = useState({});
  const { productId, productType, tenantId, id } = row;
  const [commentsValue, setCommentsValue] = useState(extractComments(row) || '');
  const { addSnackbar } = useSnackbarsContext();
  const { refreshSearchKey } = useAppContext();

  const [deleteReason, setDeleteReason] = useState('down');
  const [metersAction, setMetersAction] = useState('down');

  const { data, setAction, actions, loading, error, refetch, hasHistoric, associatedProducts } =
    useAssociatedProducts(id, entityType);

  const startDate = extractThresholdDate(row, data);
  const disabledDateAndTime = deleteReason === 'error' && hasHistoric;

  const showFeedbackAndRefresh = () => {
    addSnackbar('SNACKBAR_SAVE_SUCCESS');
    refreshSearchKey();
  };

  const handleConfirmDeleteError = (err) => {
    setDeleteStatus({ error: err });
    throw error;
  };

  const confirmDelete = async (formValue) => {
    const [hours, minutes] = formValue.time.split(':');
    const downDateValue = moment(formValue.date, 'DD/MM/YYYY').toDate();
    downDateValue.setHours(hours);
    downDateValue.setMinutes(minutes);

    if (deleteReason === 'error') {
      await ProductsService.fullDelete(productId, tenantId, { downDate: downDateValue })
        .then(showFeedbackAndRefresh)
        .catch(handleConfirmDeleteError);
    } else if (entityType === entityTypes.TRANSMITTERS) {
      const trimmedComments = commentsValue.trim();
      await ProductsService.transmitterDown(productId, tenantId, {
        ...actions.reduce(
          (acc, item) => ({ ...acc, [item.productType]: { action: item.action, id: item.id } }),
          {}
        ),
        ...(trimmedComments ? { comments: trimmedComments } : {}),
        downDate: downDateValue
      })
        .then(showFeedbackAndRefresh)
        .catch(handleConfirmDeleteError);
    } else if (entityType === entityTypes.METERS) {
      await ProductsService.meterDown(productId, tenantId, {
        ...actions.reduce(
          (acc, item) => ({
            ...acc,
            [getEntityType(item.productType)]: { action: item.action, id: item.id }
          }),
          {}
        ),
        downDate: downDateValue
      })
        .then(showFeedbackAndRefresh)
        .catch(handleConfirmDeleteError);
    } else if (entityType === entityTypes.DEVICES) {
      await ProductsService.deviceDown(productId, tenantId, {
        metersAction,
        productType,
        downDate: downDateValue
      })
        .then(showFeedbackAndRefresh)
        .catch(handleConfirmDeleteError);
    }
  };

  return (
    <Form onSubmit={confirmDelete} subscription={{ invalid: true }}>
      {({ form, handleSubmit }) => (
        <StyledOverflowDialog
          {...props}
          tag="form"
          acceptDisabled={
            error || loading || form.getState().invalid || (deleteReason === 'error' && hasHistoric)
          }
          onAccept={handleSubmit}
          title={t('MODAL_CHANGE_STATUS_TITLE', { status: t('PRODUCT_ACTION_DELETE') })}
          body={
            <BodyWrapper>
              <Status data={deleteStatus} />
              {loading && (
                <Loading.AbsoluteContainer withBackdrop>
                  <Loading message={t('LOADING_MESSAGE')} spinnerSize={30} />
                </Loading.AbsoluteContainer>
              )}
              {error ? (
                <ErrorRefetch refetch={refetch} />
              ) : (
                <>
                  <Typography use="body2">{t('MODAL_SUBTITLE_DELETE_REASON')}</Typography>
                  <Row>
                    <Radio
                      value="down"
                      checked={deleteReason === 'down'}
                      onChange={(evt) => setDeleteReason(evt.currentTarget.value)}
                      id="modals_down_option"
                    >
                      {t('MODAL_OPTION_DOWN')}
                    </Radio>
                    <Radio
                      value="error"
                      checked={deleteReason === 'error'}
                      onChange={(evt) => setDeleteReason(evt.currentTarget.value)}
                      id="modals_error_option"
                    >
                      {t('MODAL_OPTION_WRONG_REGISTER')}
                    </Radio>
                  </Row>
                  {deleteReason === 'error' && !hasHistoric && (
                    <Notification
                      type="warning"
                      label={t('ERRONEOUS_REGISTER')}
                      message={(t('MODAL_ALERT_WARNING'), t('MODAL_ALERT_DOWN_ERROR'))}
                    />
                  )}
                  {deleteReason === 'error' && hasHistoric && (
                    <Notification
                      type="error"
                      label={t('MODAL_ALERT_WARNING')}
                      message={t('MODAL_DOWN_HISTORIC')}
                    />
                  )}
                </>
              )}
              <Typography use="body2">{t('MODAL_SUBTITLE_DOWNDATE')}</Typography>
              <Grid>
                <GridCell desktop="6" tablet="4">
                  <DateField initialDate={startDate} disabled={disabledDateAndTime} />
                </GridCell>
                <GridCell desktop="6" tablet="4">
                  <TimeField disabled={disabledDateAndTime} />
                </GridCell>
              </Grid>

              {associatedProducts &&
                associatedProducts.length === 0 &&
                (deleteReason !== 'error' || !hasHistoric) && (
                  <Typography use="caption" style={{ marginLeft: '10px' }}>
                    {t(infoMessages[entityType])}
                  </Typography>
                )}
              {deleteReason === 'down' && associatedProducts && associatedProducts.length > 0 && (
                <>
                  {entityType === entityTypes.TRANSMITTERS && (
                    <TransmitterDown actions={actions} setAction={setAction} />
                  )}
                  {entityType === entityTypes.DEVICES && (
                    <DeviceDown
                      metersAction={metersAction}
                      setMetersAction={setMetersAction}
                      dissasociateText="MODAL_OPTION_DOWN"
                      linkedText="MODAL_OPTION_SEND_WAREHOUSE"
                      showModalLabel
                    />
                  )}
                </>
              )}
              {deleteReason === 'down' && entityType === entityTypes.METERS && (
                <MeterDown
                  associatedProducts={associatedProducts}
                  actions={actions}
                  setAction={setAction}
                  productType={productType}
                />
              )}
              {deleteReason === 'down' && entityType === entityTypes.TRANSMITTERS && (
                <CommentField
                  placeholder={t('PRODUCT_ACTION_ADD_COMENT')}
                  textarea
                  outlined
                  fullwidth
                  rows={3}
                  value={commentsValue}
                  maxLength="1000"
                  characterCount
                  onChange={(event) => setCommentsValue(event.target.value)}
                  id="modals_comment_input"
                />
              )}
            </BodyWrapper>
          }
        />
      )}
    </Form>
  );
}

DeleteProduct.propTypes = {
  data: PropTypes.shape({
    entityType: PropTypes.oneOf([entityTypes.TRANSMITTERS, entityTypes.DEVICES, entityTypes.METERS])
      .isRequired,
    row: PropTypes.object
  }).isRequired
};

export default DeleteProduct;
