import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import { Typography } from '@rmwc/typography';
import React, { useState } from 'react';
import { Grid, GridCell } from '@rmwc/grid';
import { Form } from 'react-final-form';
import { Loading } from '@acua/common/components';
import { deviceTypes, meterTypes } from '../../../../../common/constants';
import { useProduct, useUserContext } from '../../../../../common/hooks';
import Status from '../../components/Status';
import { StyledOverflowMarginDialog } from '../common/elements';
import Site from './Site';
import DateField from '../common/DateField';
import TimeField from '../common/TimeField';
import useChangeSite from './useChangeSite';
import { getProductById } from './getProductById.gql';
import DeviceDown from '../common/DeviceDown';
import { extractThresholdDate } from './utils';

const hasAssociatedMeter = (data) =>
  data?.product?.inventory?.linkeds?.length &&
  data.product.inventory.linkeds.some((a) => Object.values(meterTypes).includes(a.productType));

const hasAssociatedDevice = (data) =>
  data?.product?.inventory?.linkeds?.length &&
  data.product.inventory.linkeds.some((a) => Object.values(deviceTypes).includes(a.productType));

function ChangeSite({ data: { row }, ...props }) {
  const t = useTranslations();
  const { allSites } = useUserContext();
  const { sendData, modalError } = useChangeSite(row.tenantId, row.productId);
  const sites = allSites
    .get(row.tenantId)
    .map((s) => ({ value: s.id, label: s.name }))
    .filter((s) => s.value !== row.inventory.site.id);

  const { data, loading } = useProduct(row.id, getProductById);
  const startDate = extractThresholdDate(row, data);
  const [meterLinked, setMeterLinked] = useState(true);
  const hasAssociatedItem = hasAssociatedMeter(data) || hasAssociatedDevice(data);

  return (
    <Form
      onSubmit={(values) => sendData({ ...values, keepLinkage: meterLinked })}
      subscription={{ invalid: true }}
    >
      {({ form, handleSubmit }) => (
        <StyledOverflowMarginDialog
          {...props}
          tag="form"
          title={t('PRODUCT_ACTION_CHANGE_SITE')}
          onAccept={handleSubmit}
          acceptLabel={t('SEND')}
          acceptDisabled={form.getState().invalid}
        >
          <Status data={modalError} />
          <Typography tag="p" use="body2" style={{ marginTop: '15px' }}>
            {t('CHANGE_SITE_MODAL_TEXT')}
          </Typography>
          <Site sites={sites} />

          <Typography tag="p" use="body2" style={{ marginBottom: '0px' }}>
            {t('CHANGE_SITE_MODAL_TEXT_DATE')}
          </Typography>
          <Grid style={{ marginTop: '0px' }}>
            <GridCell desktop="6" tablet="4">
              <DateField initialDate={startDate} />
            </GridCell>
            <GridCell desktop="6" tablet="4">
              <TimeField />
            </GridCell>
          </Grid>
          {loading && (
            <Loading.AbsoluteContainer withBackdrop>
              <Loading message={t('LOADING_MESSAGE')} spinnerSize={30} />
            </Loading.AbsoluteContainer>
          )}
          {!loading && hasAssociatedItem && (
            <DeviceDown
              dissasociateText="MODAL_OPTION_UNLINKED_AND_KEEP_SITE"
              linkedText="MODAL_OPTION_LINKED_PLURAL_SITE"
              meterLinked={meterLinked}
              setMeterLinked={setMeterLinked}
              showModalLabel={false}
            />
          )}
        </StyledOverflowMarginDialog>
      )}
    </Form>
  );
}

ChangeSite.propTypes = {
  data: PropTypes.shapeOf({
    row: PropTypes.shapeOf({
      productId: PropTypes.string
    })
  }).isRequired
};
export default ChangeSite;
