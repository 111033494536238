import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslations } from '@react-utils/intl';
import ErrorWrapper from './ErrorWrapper';

const errorTypes = {
  CRITICAL: 'INTERNAL_SERVER_ERROR',
  NOT_FOUND: 'ERR_404_NOT_FOUND',
  NOT_ALLOWED: 'NOT_ACCESS_ALLOWED'
};

function Error({ type }) {
  const t = useTranslations();
  return (
    <>
      <Helmet>
        <title>
          {t('MAIN_TITLE')} - {t(errorTypes[type])}
        </title>
      </Helmet>
      <ErrorWrapper label={t(errorTypes[type])} />
    </>
  );
}

Error.propTypes = {
  type: PropTypes.oneOf(Object.keys(errorTypes)).isRequired
};

export default Error;
