import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Maps, ErrorBoundary } from '@acua/common/components';
import FullSizeFreeDialog from '../components/FullSizeFreeDialog';

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

function FullSizeMap({ data: { ...propsMaps }, ...props }) {
  return (
    <FullSizeFreeDialog {...props}>
      <ErrorBoundary>
        <MapWrapper>
          <Maps blend="normal" {...propsMaps} fullScreen={false} />
        </MapWrapper>
      </ErrorBoundary>
    </FullSizeFreeDialog>
  );
}

FullSizeMap.propTypes = {
  data: PropTypes.shape({
    layers: PropTypes.array.isRequired,
    initialView: PropTypes.shapeOf({
      longitude: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      zoom: PropTypes.number.isRequired,
      minZoom: PropTypes.number.isRequired,
      maxZoom: PropTypes.number.isRequired
    }).isRequired,
    mapTheme: PropTypes.string.isRequired,
    tooltip: PropTypes.func.isRequired,
    fullScreenCompute: PropTypes.func,
    legend: PropTypes.arrayOf(
      PropTypes.shapeOf({
        color: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

export default FullSizeMap;
