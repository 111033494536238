import { useAppContext } from '@acua/common/hooks';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useTranslations } from '@react-utils/intl';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Notification } from '../../../../../common/components';
import { alarmOriginTypes, alarmSubscriptionFrequencyTypes } from '../../../../../common/constants';
import { StyledMarginDialog } from '../common/elements';
import Email from './Email';
import Frequency from './Frequency';
import Language from './Language';
import { getTemplates } from './queries.gql';

const defaultValues = {
  email: '',
  template: {
    id: '',
    name: ''
  },
  language: ''
};

const DuplicatedContainer = styled('div')`
  span,
  p {
    color: #000000;
  }
`;

const duplicatedEntry = (values, subscriptions, origin) => {
  return subscriptions.find((item) =>
    origin === alarmOriginTypes.MANAGER
      ? item.freq === values.freq && item.email === values.email
      : item.email === values.email
  );
};

function AlarmSubscriptionForm({
  alarmType,
  origin,
  individualNotificationDisabled,
  form,
  handleSubmit,
  freq,
  setFreq,
  subscriptions,
  ...props
}) {
  const t = useTranslations();
  const { currentApp } = useAppContext();
  const [disabledAdd, setDisabledAdd] = useState(true);
  const [duplicatedValue, setDuplicatedValue] = useState(false);
  const { data: templates } = useQuery(getTemplates, {
    skip: !freq,
    variables: {
      tenantId: currentApp.id,
      tags: [alarmType, freq]
    }
  });

  form.subscribe(
    () => {
      const duplicatedEntryValue = duplicatedEntry(form.getState().values, subscriptions, origin);
      const disabledValue = form.getState().invalid || duplicatedEntryValue;
      if (disabledValue !== disabledAdd) {
        setDisabledAdd(disabledValue);
      }
      if (duplicatedEntryValue !== duplicatedValue) {
        setDuplicatedValue(duplicatedEntryValue);
      }
    },
    { values: true }
  );

  useEffect(() => {
    form.change('template', templates?.getTemplates[0]);
  }, [templates]);

  return (
    <StyledMarginDialog
      {...props}
      tag="form"
      title={t('MODAL__ADD_ALARM_SUBSCRIPTION__TITLE')}
      onAccept={handleSubmit}
      acceptLabel={t('MODAL__ADD_ALARM_SUBSCRIPTION__ACCEPT')}
      acceptDisabled={disabledAdd}
      body={
        <div style={{ width: '500px' }}>
          {duplicatedValue && (
            <DuplicatedContainer>
              <Notification
                type="error"
                label={t('SUBSCRIPTION_DUPLICATED_TITLE')}
                message={t('SUBSCRIPTION_DUPLICATED_SUBTITLE')}
              />
            </DuplicatedContainer>
          )}
          <div style={{ margin: '20px 0' }}>
            <Email />
          </div>
          {origin === alarmOriginTypes.MANAGER && (
            <div style={{ margin: '20px 0' }}>
              <Frequency
                individualNotificationDisabled={individualNotificationDisabled}
                setFreq={setFreq}
              />
            </div>
          )}
          <div style={{ margin: '20px 0' }}>
            <Language tenantId={currentApp?.id} />
          </div>
        </div>
      }
    />
  );
}

function AlarmSubscription({ onAccept, data, origin, ...props }) {
  const [freq, setFreq] = useState(
    origin === alarmOriginTypes.CUSTOMER ||
      (data && data.freq === alarmSubscriptionFrequencyTypes.ACTIVATION)
      ? alarmSubscriptionFrequencyTypes.ACTIVATION
      : (data && data.freq) || null
  );
  return (
    <Form
      onSubmit={(values) => onAccept({ freq, ...values })}
      initialValues={data || defaultValues}
      subscription={{ invalid: true }}
    >
      {({ form, handleSubmit }) => (
        <AlarmSubscriptionForm
          form={form}
          handleSubmit={handleSubmit}
          freq={freq}
          setFreq={setFreq}
          origin={origin}
          {...props}
        />
      )}
    </Form>
  );
}

AlarmSubscriptionForm.propTypes = {
  alarmType: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired,
  freq: PropTypes.string.isRequired,
  individualNotificationDisabled: PropTypes.bool.isRequired,
  form: PropTypes.shape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.shapeOf({})).isRequired,
  setFreq: PropTypes.func.isRequired
};

AlarmSubscription.propTypes = {
  onAccept: PropTypes.func.isRequired,
  origin: PropTypes.string.isRequired,
  data: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
    language: PropTypes.string,
    freq: PropTypes.string,
    template: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  })
};

AlarmSubscription.defaultProps = {
  data: null
};

export default AlarmSubscription;
