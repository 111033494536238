import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { getInstanceWarehouses } from './getInstanceWarehouses.gql';

export default (tenantId) => {
  const { data, ...queryStatus } = useQuery(getInstanceWarehouses, {
    variables: {
      tenantId
    },
    notifyOnNetworkStatusChange: true
  });

  return {
    ...useMemo(() => {
      return data && data.warehouses
        ? {
            warehouses: data.warehouses,
            warehouseOptions: data.warehouses.map((item) => ({
              label: item.name,
              value: item.name
            }))
          }
        : {
            warehouses: [],
            warehouseOptions: []
          };
    }, [data]),
    queryStatus
  };
};
