import { required } from '@acua/common/utils';
import { useFeatureFlagContext } from '@acua/common/hooks';
import { anSite } from '@acua/common/utils/anonymize/anonymize';
import { useTranslations } from '@react-utils/intl';
import { Select } from '@rmwc/select';
import PropTypes from 'prop-types';
import React from 'react';
import { useField } from 'react-final-form';
import ErrorLabel from '../common/ErrorLabel';

function Site({ sites }) {
  const t = useTranslations();
  const { featureFlags } = useFeatureFlagContext();
  const { input, meta } = useField('siteId', { validate: required });

  return (
    <div>
      <Select
        {...input}
        key={sites.length}
        id="modals_site_input"
        label={t('MODAL__CHANGE_TENANT_SITE')}
        disabled={!sites.length}
        placeholder=""
        options={sites.map(({ label, ...s }) => ({
          ...s,
          label: featureFlags.usersEnabledDemo ? anSite(s.value || label) : label
        }))}
        icon={!sites.length ? 'loop' : null}
      />
      {meta.touched && meta.invalid && <ErrorLabel>{t(meta.error)}</ErrorLabel>}
    </div>
  );
}

Site.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shapeOf({ value: PropTypes.string, label: PropTypes.string }))
    .isRequired,
  loadSites: PropTypes.func.isRequired
};
export default Site;
