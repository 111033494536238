import { entityTypes, STORAGE_KEY_CONSUMPTIONS_ESTIMATED_VISIBLE } from '@acua/common/constants';
import {
  useAppContext,
  useMergeState,
  useSnackbarsContext,
  useUserContext,
  useLocalStorage
} from '@acua/common/hooks';
import { useTranslations } from '@react-utils/intl';
import React, { useEffect, useState } from 'react';
import meterColumns from '../../../../../Consumptions/Home/Results/helpers/columns/meterColumns';
import meterIntradayColumns from '../../../../../Consumptions/Home/Results/helpers/columns/meterIntradayColumns';
import { StyledOverflowDialog } from '../common/elements';
import consumptionTypes from './constants/consumptionTypes';
import DialogBody from './DialogBody';
import useExport from './useExport';

const defaultDates = Object.freeze({
  startDate: null,
  endDate: null
});

const isFormFilled = ({ search, dateRange: { startDate, endDate } }) =>
  search.length > 0 && startDate !== null && endDate !== null;

const filterByFamily = (entities, searchFamily) =>
  entities.filter(({ family }) => family === searchFamily).map(({ productId }) => productId);

function DownloadConsumptions({ ...props }) {
  const t = useTranslations();
  const { addSnackbar } = useSnackbarsContext();
  const { getTenant } = useUserContext();
  const { currentApps } = useAppContext();
  const [enableEstimated, setEnableEstimated] = useState(false);
  const [enableEstimatedParam] = useLocalStorage(STORAGE_KEY_CONSUMPTIONS_ESTIMATED_VISIBLE, false);
  const [state, setState] = useMergeState({
    consumptionType: consumptionTypes.DIARY,
    tenantId: undefined,
    search: [],
    dateRange: defaultDates
  });

  useEffect(() => {
    if (state.tenantId) {
      const appSelected = currentApps.find((app) => app.id === state.tenantId);
      if (appSelected) {
        setEnableEstimated(
          enableEstimatedParam && appSelected.capabilities.includes('view_estimated_consumptions')
        );
      }
    } else {
      setEnableEstimated(enableEstimatedParam);
    }
  }, [state, enableEstimatedParam]);

  const {
    profile: {
      preferences: { volumeUnit, volumeFlowUnit }
    }
  } = useUserContext();

  const entities =
    state.consumptionType === consumptionTypes.DIARY
      ? {
          meters: filterByFamily(state.search, entityTypes.METERS),
          sites: filterByFamily(state.search, entityTypes.SITES),
          supplyPoints: filterByFamily(state.search, entityTypes.SUPPLY_POINTS)
        }
      : { meters: filterByFamily(state.search, entityTypes.METERS) };

  const cols = (
    state.consumptionType === consumptionTypes.DIARY ? meterColumns : meterIntradayColumns
  )(t, {
    volumeUnit,
    volumeFlowUnit,
    getTenant,
    enableEstimated,
    timeZone: getTenant(state.tenantId)?.settings?.data?.timeZone
  });

  const { handleExport } = useExport({ ...state, entities, enableEstimated }, cols);

  return (
    <StyledOverflowDialog
      {...props}
      title={t('DOWNLOAD_CONSUMPTIONS__TITLE')}
      acceptLabel={t('DOWNLOAD_CONSUMPTIONS__LABEL')}
      acceptDisabled={!isFormFilled(state)}
      onAccept={() =>
        handleExport('csv')
          .then(() =>
            addSnackbar(t('EXPORT_REQUEST_SUCCESS_SNACKBAR'), {
              isExport: true,
              tenantId: state.tenantId
            })
          )
          .catch(() => addSnackbar(t('INTERNAL_SERVER_ERROR')))
      }
      body={<DialogBody state={state} setState={setState} />}
    />
  );
}

export default DownloadConsumptions;
