import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useMergeState from '../hooks/useMergeState';

const defaultState = {};

export const StorageContext = createContext(defaultState);

export function StorageProvider({ children }) {
  return <StorageContext.Provider value={useMergeState()}>{children}</StorageContext.Provider>;
}

StorageProvider.propTypes = {
  children: PropTypes.node.isRequired
};
