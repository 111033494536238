import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { Typography } from '@rmwc/typography';
import { translateAndFormat } from '@acua/common/utils';

function Messages({ messages }) {
  const t = useTranslations();

  const getMessage = (message, args) => {
    let m = translateAndFormat(t, message, args);
    if (m === message) {
      m = t('INTERNAL_SERVER_ERROR');
    }
    return m;
  };

  return messages.map(({ message, args }) => (
    <Typography use="body1" style={{ display: 'block' }} key={message}>
      {`- ${getMessage(message, args)}`}
    </Typography>
  ));
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      args: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array]))
    })
  )
};

Messages.defaultProps = {
  messages: []
};

export default Messages;
