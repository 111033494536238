import { useEffect, useState, useCallback } from 'react';

const useService = (
  service,
  { resolveCondition = [], initialData, compute, initialLoading = false, skip } = {}
) => {
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState(null);
  const [refetchAttempts, setRefetchAttempts] = useState(0);

  const refetch = () => setRefetchAttempts((prev) => prev + 1);

  const memoService = useCallback(service, resolveCondition);

  // RESET ALL
  useEffect(() => {
    setData(initialData);
    setLoading(false);
    setError(null);
  }, resolveCondition);

  useEffect(() => {
    if (skip) return undefined;

    let mounted = true;
    setLoading(true);
    setData(initialData);
    setError(null);
    memoService()
      .then((raw) => {
        if (mounted) {
          const computed = compute ? compute(raw) : raw;
          setData(computed);
          setError(null);
        }
      })
      .catch((err) => mounted && setError(err))
      .finally(() => mounted && setLoading(false));

    return () => {
      mounted = false;
    };
  }, [memoService, refetchAttempts]);

  return {
    data,
    loading,
    error,
    refetch
  };
};

export default useService;
