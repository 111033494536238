import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';

function Uninformed({ value, i18nKey }) {
  const t = useTranslations();
  return value != null && (typeof value === 'number' || value.length > 0) ? (
    <span title={value}>{value}</span>
  ) : (
    <span style={{ fontStyle: 'italic', color: 'rgba(0,0,0,0.3)' }} title={t(i18nKey)}>
      {t(i18nKey)}
    </span>
  );
}

Uninformed.propTypes = {
  value: PropTypes.string.isRequired,
  i18nKey: PropTypes.string
};

Uninformed.defaultProps = {
  i18nKey: 'TABLE_CELL__UNINFORMED'
};

export default Uninformed;
