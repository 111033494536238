// DASHBOARD
export const globalSearch = 'global_search';

// INVENTORY: Meters & Equipments
export const readDevices = 'read_devices';
export const allowComplementaryActionsDevices = 'allow_complementary_actions_devices';
export const updateDevice = 'update_device';
export const retireDevice = 'retire_device';
export const reprocessDevice = 'reprocess_device';
export const importDevices = 'import_devices';
export const importExternalDevices = 'import_external_devices';
export const updateMeterReadings = 'update_meter_readings';
export const allowChangeTenantDevices = 'allow_change_tenant_devices';
export const allowChangeSiteDevices = 'allow_change_site_devices';
export const manualWatervoulmeSetOffset = 'acua_manualwatervolume_set_offset';
export const readLnsStatus = 'read_lns_status';
export const readProductKeys = 'read_product_keys';
export const readProductAudit = 'read_product_audit';

// INVENTORY: Transmitters
export const readTransmitters = 'read_transmitters';
export const allowComplementaryActionsTransmitters = 'allow_complementary_actions_transmitters';
export const importTransmitters = 'import_transmitters';
export const allowChangeTenantTransmitter = 'allow_change_tenant_transmitter';
export const allowChangeSiteTransmitter = 'allow_change_site_transmitter';

// ADMIN
export const adminTasks = 'admin_tasks';

// ALARMS
// export const readAlarms = 'read_alarms';
export const readAlarmConfigurationsManager = 'read_alarm_configurations_manager';
export const readAlarmConfigurationsTechnical = 'read_alarm_configurations_technical';
export const readAlarmConfigurationsCustomer = 'read_alarm_configurations_customer';
export const updateAlarmConfigurationsManager = 'update_alarm_configurations_manager';
export const updateAlarmConfigurationsTechnical = 'update_alarm_configurations_technical';
export const updateAlarmConfigurationsCustomer = 'update_alarm_configurations_customer';
export const allowComplementaryActionsAlarms = 'allow_complementary_actions_alarms';

// ANALYTICS
export const readAnalytics = 'read_analytics';
export const readPwBIReportTransmittersPerformance = 'read_pwbi_report_transmitters_performance';
export const readPwBIReportCriticalMeters = 'read_pwbi_report_critical_meters';
export const readPwBIReportEquipmentAnalytics = 'read_pwbi_report_equipment_analytics';
export const readPwBIReportKpiPerformance = 'read_pwbi_report_kpi_performance';
export const readPwBIReportAquadomMeterConsumptionComparison =
  'read_pwbi_report_transmitters_performance';
export const readAnalyticsGeneral = 'read_analytics_general';
export const readAnalyticsTransmitter = 'read_analytics_transmitters';
export const readAnalyticsDevices = 'read_analytics_devices';
export const readAnalyticsCoverge = 'read_analytics_coverage';
export const readAnalyticsPerformance = 'read_analytics_performance';

// CONSUMPTIONS
export const readConsumptions = 'read_consumptions';
export const readHistoricConsumptions = 'read_historic_consumptions';

// CONFIGURATIONS
export const readDeviceConfiguration = 'read_device_configuration';
export const updateDeviceConfiguration = 'update_device_configuration';
export const updateDeviceConfigurationAdvanced = 'update_device_configuration_advanced';

// EXPORT DATA
export const exportData = 'export_data';

// STATUS
export const readStatusWarehouse = 'read_status_warehouse';
export const readStatusInstalled = 'read_status_installed';
export const readStatusWithdrawal = 'read_status_withdrawal';

// METRICS
export const readMetrics = 'read_metrics';
