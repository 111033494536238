import { Maps as NauiMaps } from '@naui/core';
import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';
import CropFreeIcon from '@mui/icons-material/CropFree';
import LayersIcon from '@mui/icons-material/Layers';
import MapIcon from '@mui/icons-material/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '../Tooltip';

export const StyledMap = styled(NauiMaps)`
  width: 100%;
  height: 100%;
  min-height: 20rem;
  z-index: 0;
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute !important;
  right: 42px;
  top: 8px;
  z-index: 1;
  &:before {
    margin-top: 15px;
  }
  &:after {
    margin-top: 15px;
  }
`;

export const OpenMapFullPageIcon = styled(CropFreeIcon)`
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
  color: #415d9a;
  position: absolute;
  z-index: 1;
  font-size: 22px;
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledLayersIcon = styled(LayersIcon)`
  color: #415d9a;
  padding-right: 4px;
  width: 20px;
`;

export const StyledLegendIcon = styled(MapIcon)`
  color: #415d9a;
  padding-right: 4px;
  width: 20px;
`;

export const Dot = styled.span`
  height: 14px;
  width: 14px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
`;

export const Square = styled.span`
  height: 14px;
  width: 14px;
  background-color: #bbb;
  border-radius: 0px;
  display: inline-block;
`;

export const StyledLocationOnIcon = styled(LocationOnIcon)`
  color: #415d9a;
  font-size: 30px;
  padding: 5px;
`;

export const LayerContainerClosed = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 16px;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  font-weight: 500;
  top: 8px;
  left: 8px;
  padding-right: 10px;
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const LegendContainerClosed = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 16px;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  font-weight: 500;
  top: 8px;
  right: 50px;
  padding-right: 10px;
  transition: all 0.5s ease;
  &:hover {
    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.2);
  }
`;

export const TotalInfo = styled.div`
  background-color: #ffffff;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 8px 8px 8px 0px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  font-size: 16px;
  font-weight: 500;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
`;

export const LayerContent = styled.div`
  margin-top: 5px;
  padding: 5px;
`;

export const LayerOption = styled.div`
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 4px 0px 4px 16px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LoadingLabel = styled.div`
  background-color: #f4f5f6;
  border-radius: 3px;
  padding: 5px;
  position: relative;
  float: right;
  right: 8px;
  top: -55px;
  color: #000;
  font-size: 0.6em;
  z-index: 1;
`;

export const CustomSwitch = styled(Switch)`
  transform: scale(0.8);
`;

export const TitlePopUp = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledLocationLoading = styled(StyledLocationOnIcon)`
  -webkit-animation: blink-2 1.2s ease-out infinite both;
  animation: blink-2 1.2s ease-out infinite both;
  @-webkit-keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes blink-2 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;
