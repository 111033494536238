import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { pipe, uniqWith } from 'ramda';

import { getUser, getProfile } from './queries.gql';
import { dissocTypenameDeeply } from '../../utils';
import useApolloClientsContext from '../../hooks/useApolloClientsContext';
import { networkStatusValues } from '../../constants';
import computeUser from './computeUser';
import computeProfile from './computeProfile';

export const UserContext = createContext();

const defaultState = {
  instances: [],
  appModulePermissions: {},
  tags: []
};

const defaultProfile = {};

export function UserProvider({ children }) {
  const { persistedClient } = useApolloClientsContext();
  const [allSites, setAllSites] = useState(new Map());
  const [refetchInBackGround, setRefetchInBackGround] = useState(false);

  const { data, error, refetch, networkStatus } = useQuery(getUser, {
    client: persistedClient,
    notifyOnNetworkStatusChange: true
  });

  const { data: dataProfile } = useQuery(getProfile, {
    client: persistedClient,
    notifyOnNetworkStatusChange: true
  });

  const user = useMemo(() => {
    if (data && data.user) {
      const computedUser = computeUser(dissocTypenameDeeply(data.user));
      const sites = data.user?.instances?.map((instance) => [instance.id, instance.sites]);
      setAllSites(new Map(sites));
      return computedUser;
    }
    return null;
  }, [data]);

  const profile = useMemo(() => {
    if (dataProfile && dataProfile.user) {
      return computeProfile(dissocTypenameDeeply(dataProfile.user));
    }
    return null;
  }, [dataProfile]);

  const getEOs = pipe(
    (prop) =>
      Array.from(allSites.values())
        .flatMap((s) => s)
        .map((s) => s.organizationalStructure[prop])
        .filter((o) => !!o),
    uniqWith((a, b) => a.id === b.id)
  );

  return (
    <UserContext.Provider
      value={{
        ...{
          ...defaultState,
          ...(user || {})
        },
        allSites,
        areaManagers: getEOs('areaManager'),
        concessionManagements: getEOs('concessionManagement'),
        zoneManagements: getEOs('zoneManagement'),
        getSitesByTenant: (tenantId) => {
          const instance = data.user?.instances?.find((i) => i.id === tenantId);
          if (instance) {
            return instance.sites;
          }
          return [];
        },
        getTenant: (tenantId) => data.user?.instances?.find((i) => i.id === tenantId),
        profile: profile || defaultProfile,
        initialLoading: networkStatus === networkStatusValues.initialLoading,
        refetching: networkStatus === networkStatusValues.refetching && !refetchInBackGround,
        error,
        refetch: (inBackground = false) => {
          setRefetchInBackGround(inBackground);
          refetch();
        }
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};
