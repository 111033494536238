import auth from '@acua/common/auth';
import { gatewayUrl } from '../../config';

const productsUrl = `${gatewayUrl}/products`;

const update = (id, instanceId, data) =>
  auth.authFetch(`${productsUrl}/${id}/status`, {
    method: 'PUT',
    body: data,
    json: true,
    instanceId
  });

const sendToWarehouse = (id, instanceId, data) =>
  auth.authFetch(`${productsUrl}/${id}/sendToWarehouse`, {
    method: 'POST',
    body: data,
    json: true,
    instanceId
  });

const reprocess = (id, instanceId) =>
  auth.authFetch(`${productsUrl}/${id}/reprocess`, {
    method: 'POST',
    instanceId
  });

const fullDelete = (id, instanceId, body) =>
  auth.authFetch(`${productsUrl}/${id}`, {
    method: 'DELETE',
    body,
    json: true,
    instanceId
  });

const transmitterDown = (id, instanceId, body) =>
  auth.authFetch(`${productsUrl}/transmitter/${id}/down`, {
    method: 'PUT',
    body,
    json: true,
    instanceId
  });

const meterDown = (id, instanceId, body) =>
  auth.authFetch(`${productsUrl}/meter/${id}/down`, {
    method: 'PUT',
    body,
    json: true,
    instanceId
  });

const updateComments = (id, instanceId, comments) =>
  auth.authFetch(`${productsUrl}/${id}/comments`, {
    method: 'PUT',
    body: { comments },
    json: true,
    instanceId
  });

const deviceDown = (id, instanceId, body) =>
  auth.authFetch(`${productsUrl}/device/${id}/down`, {
    method: 'PUT',
    body,
    json: true,
    instanceId
  });

export default {
  update,
  sendToWarehouse,
  reprocess,
  fullDelete,
  transmitterDown,
  updateComments,
  deviceDown,
  meterDown
};
