import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { alarmStatusTypes, alarmStatusI18nKeys } from '@acua/common/constants';
import Badge from './Badge';

const notFound = { i18nKey: 'EMPTY_DETAIL_ITEM', color: 'TRANSPARENT' };

const statusColors = {
  [alarmStatusTypes.ENABLED]: 'GREEN',
  [alarmStatusTypes.DISABLED]: 'GREY'
};

const AlarmStatusBadge = memo(({ label, value, fitContent }) => {
  const t = useTranslations();
  return (
    <Badge
      title={label || t(alarmStatusI18nKeys[value]) || t(notFound.i18nKey)}
      color={statusColors[value] || notFound.color}
      use="overline"
      fitContent={fitContent}
    >
      {label || t(alarmStatusI18nKeys[value]) || t(notFound.i18nKey)}
    </Badge>
  );
});

AlarmStatusBadge.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  fitContent: PropTypes.bool
};

AlarmStatusBadge.defaultProps = {
  fitContent: false,
  label: null
};

export default AlarmStatusBadge;
