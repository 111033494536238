const { path } = require('ramda');

const extractThresholdDate = (row, data) => {
  const lastUninstallationDate = path(['product', 'inventory', 'unInstallationDate'], data);
  const associationDate = path(['product', 'inventory', 'associationDate'], data);
  const creationDate = path(['inventory', 'creationDate'], row);
  return [lastUninstallationDate, associationDate, creationDate]
    .filter((e) => e)
    .sort((a, b) => (new Date(a).getTime() < new Date(b).getTime() ? 1 : -1))[0];
};

module.exports = { extractThresholdDate };
