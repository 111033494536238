import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { Form } from 'react-final-form';
import useUpdateTags from './useUpdateTags';
import CustomSimpleDialog from '../../components/CustomSimpleDialog';
import Body from './Body';

function ManageTags({ data: { productId, productType, productTags }, ...props }) {
  const t = useTranslations();
  const { onSubmit } = useUpdateTags({ productId, productType });
  return (
    <Form onSubmit={onSubmit} subscription={{ pristine: true }}>
      {({ handleSubmit, form }) => (
        <CustomSimpleDialog
          {...props}
          tag="form"
          title={t('MODAL_MANAGE_PRODUCT_TAGS_TITLE')}
          onAccept={handleSubmit}
          acceptLabel={t('MODAL_BTN_SAVE')}
          acceptDisabled={form.getState().pristine}
          body={<Body productTags={productTags} />}
        />
      )}
    </Form>
  );
}

ManageTags.propTypes = {
  data: PropTypes.shape({
    productId: PropTypes.string,
    productType: PropTypes.string,
    productTags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

export default ManageTags;
