import React from 'react';
import styled from '@emotion/styled';
import { useModalsContext } from '@acua/common/hooks';
import { modalTypes } from '@acua/common/constants';

import AddCommentToProduct from './Dialogs/AddCommentToProduct';
import AlarmSubscription from './Dialogs/AlarmSubscription';
import ConfirmModal from './Dialogs/ConfirmModal';
import ConfirmReprocessModal from './Dialogs/ConfirmReprocessModal';
import DeleteProduct from './Dialogs/DeleteProduct';
import ErrorModal from './Dialogs/ErrorModal';
import ImportDetails from './Dialogs/ImportDetails';
import InfoModal from './Dialogs/InfoModal';
import ManageTags from './Dialogs/ManageTags';
import RejectProduct from './Dialogs/RejectProduct';
import SendProductToRevision from './Dialogs/SendProductToRevision';
import SendProductToWarehouse from './Dialogs/SendProductToWarehouse';
import TestProduct from './Dialogs/TestProduct';
import TenantLocator from './Dialogs/TenantLocator';
import ValidateProduct from './Dialogs/ValidateProduct';
import InsertReading from './Dialogs/InsertReading';
import FullSizeMap from './Dialogs/FullSizeMap';
import ImportSelectSite from './Dialogs/ImportSelectSite';
import DownloadConsumptions from './Dialogs/DownloadConsumptions';
import AddCommentToAlarm from './Dialogs/AddComentToAlarm';
import ChangeTenant from './Dialogs/ChangeTenant/transmitter';
import ChangeTenantDevices from './Dialogs/ChangeTenant/devices';
import ChangeSite from './Dialogs/ChangeSite';
import ModuleTranslationsProvider from '../../../common/ModuleTranslationsProvider';
import SendProductToInstalled from './Dialogs/SendProductToInstalled';
import ChangeBattery from './Dialogs/ChangeBattery';

const Container = styled.div`
  z-index: ${({ theme }) => theme.zIndexModal};
`;

const dialogs = {
  [modalTypes.ADD_COMMENT_TO_PRODUCT]: AddCommentToProduct,
  [modalTypes.ADD_COMMENT_TO_ALARM]: AddCommentToAlarm,
  [modalTypes.ALARM_SUBSCRIPTION]: AlarmSubscription,
  [modalTypes.CONFIRM_DIALOG]: ConfirmModal,
  [modalTypes.DELETE_PRODUCT]: DeleteProduct,
  [modalTypes.ERROR_DIALOG]: ErrorModal,
  [modalTypes.FULL_SIZE_MAP]: FullSizeMap,
  [modalTypes.IMPORT_DETAILS]: ImportDetails,
  [modalTypes.INFO_DIALOG]: InfoModal,
  [modalTypes.IMPORT_SELECT_SITE]: ImportSelectSite,
  [modalTypes.INSERT_READING]: InsertReading,
  [modalTypes.MANAGE_TAGS]: ManageTags,
  [modalTypes.REJECT_PRODUCT]: RejectProduct,
  [modalTypes.REPROCESS_PRODUCT]: ConfirmReprocessModal,
  [modalTypes.SEND_PRODUCT_TO_REVISION]: SendProductToRevision,
  [modalTypes.SEND_PRODUCT_TO_WAREHOUSE]: SendProductToWarehouse,
  [modalTypes.SEND_PRODUCT_TO_INSTALLED]: SendProductToInstalled,
  [modalTypes.TENANT_LOCATOR]: TenantLocator,
  [modalTypes.TEST_PRODUCT]: TestProduct,
  [modalTypes.VALIDATE_PRODUCT]: ValidateProduct,
  [modalTypes.DOWNLOAD_CONSUMPTIONS]: DownloadConsumptions,
  [modalTypes.CHANGE_TENANT]: ChangeTenant,
  [modalTypes.CHANGE_TENANT_DEVICES]: ChangeTenantDevices,
  [modalTypes.CHANGE_SITE]: ChangeSite,
  [modalTypes.CHANGE_SITE_DEVICE]: ChangeSite,
  [modalTypes.CHANGE_BATTERY_DEVICES]: ChangeBattery
};

function Modals(props) {
  const { modals, modalsContainerRef, deleteModal } = useModalsContext();
  return (
    <Container {...props} ref={modalsContainerRef}>
      {modals.map(({ id, type, data, payload = {} }) => {
        const modalProps = {
          data,
          ...payload,
          key: id || type,
          deleteModal: () => deleteModal(id)
        };
        const Dialog = dialogs[type];
        return Dialog ? (
          <ModuleTranslationsProvider
            moduleNames={['web', 'consumptions', 'inventory']}
            key={modalProps.key}
          >
            <Dialog {...modalProps} id="dialog_container" />
          </ModuleTranslationsProvider>
        ) : null;
      })}
    </Container>
  );
}

export default Modals;
