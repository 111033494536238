import React from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { entityTypesI18nKeys, getEntityType } from '@acua/common/constants';
import styled from '@emotion/styled';
import LinkedEntity from './LinkedEntity';

const StyledLinkedEntities = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0.5;
  font-weight: normal;
  margin-top: 5px;
`;

function LinkedEntities({ linkedEntities, contract, inputSearch }) {
  const t = useTranslations();
  return (
    <StyledLinkedEntities>
      {linkedEntities &&
        linkedEntities.map((linkedEntity) => (
          <LinkedEntity
            key={linkedEntity.key}
            label={t(entityTypesI18nKeys[getEntityType(linkedEntity.productType)])}
            value={linkedEntity.key}
            inputSearch={inputSearch}
          />
        ))}
      {contract && contract.key && (
        <LinkedEntity
          label={t(entityTypesI18nKeys.CONTRACT)}
          value={contract.key}
          inputSearch={inputSearch}
        />
      )}
    </StyledLinkedEntities>
  );
}

LinkedEntities.propTypes = {
  linkedEntities: PropTypes.arrayOf({
    productType: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired
  }).isRequired,
  contract: PropTypes.objectOf({
    key: PropTypes.string.isRequired
  }).isRequired,
  inputSearch: PropTypes.string.isRequired
};

export default LinkedEntities;
