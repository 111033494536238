import { groupBy, props } from 'ramda';
import { consumptionOriginTypes } from './types';

export const showEstimated = (item, enableEstimated) => {
  return !!(enableEstimated && item.estimatedValues && item.estimatedValues?.value !== item.value);
};

export const filterEstimatedAndInvalidValues = (consumptions, enableEstimated) => {
  let result = consumptions;
  if (consumptions) {
    if (!enableEstimated) {
      result = consumptions.filter((value) => value.origin !== consumptionOriginTypes.ESTIMATED);
    }
    result = Object.values(groupBy(props(['sampleDate', 'id']), result)).reduce((acc, curr) => {
      if (curr.length > 1) {
        const calculated = curr.find((c) => c.origin === consumptionOriginTypes.CALCULATED);
        return [...acc, ...(calculated ? [calculated] : curr)];
      }
      return [...acc, ...curr];
    }, []);
  }
  return result;
};

export const treatIntraDailyConsumptions = (data, enableEstimated, parent = {}) => {
  let lastItem = null;
  const filteredData = filterEstimatedAndInvalidValues(data, enableEstimated) || [];
  return filteredData
    .map((item) => {
      const newItemValues = {};
      if (
        lastItem &&
        lastItem.origin === consumptionOriginTypes.ESTIMATED &&
        item.origin === consumptionOriginTypes.CALCULATED &&
        lastItem.sampleDate !== item.sampleDate
      ) {
        newItemValues.origin = consumptionOriginTypes.ESTIMATED;
        if (lastItem.value !== 0) {
          newItemValues.value = item.reading - lastItem.reading;
        }
        lastItem = item;
      } else if (
        !lastItem ||
        lastItem.sampleDate !== item.sampleDate ||
        lastItem.origin !== consumptionOriginTypes.ESTIMATED
      ) {
        lastItem = item;
      }
      return {
        ...item,
        ...newItemValues,
        ...(parent.serialNumber
          ? {
              meterSerialNumber: parent.serialNumber
            }
          : {}),
        ...(parent.inventory
          ? {
              associatedEquipmentTechnology: parent.inventory.associatedEquipment?.productType
            }
          : {})
      };
    })
    .sort((a, b) => (new Date(a.sampleDate).getTime() > new Date(b.sampleDate).getTime() ? 1 : -1));
};
