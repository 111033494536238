import { required } from '@acua/common/utils';
import { useTranslations } from '@react-utils/intl';
import { Select } from '@rmwc/select';
import { useFeatureFlagContext } from '@acua/common/hooks';
import { anSite } from '@acua/common/utils/anonymize/anonymize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import ErrorLabel from '../common/ErrorLabel';

function Site({ sites }) {
  const t = useTranslations();
  const { featureFlags } = useFeatureFlagContext();
  const {
    input: { value: selectedTenant }
  } = useField('tenantId');
  const { input, meta } = useField('siteId', { validate: required });
  const [siteOptions, setSiteOptions] = useState([]);

  useEffect(() => {
    setSiteOptions(
      selectedTenant ? sites.get(selectedTenant).map((s) => ({ value: s.id, label: s.name })) : []
    );
  }, [selectedTenant]);

  return (
    <div>
      <Select
        {...input}
        key={siteOptions.length}
        id="modals_site_input"
        label={t('MODAL__CHANGE_TENANT_SITE')}
        disabled={!selectedTenant || !siteOptions.length}
        placeholder=""
        options={siteOptions.map(({ label, ...s }) => ({
          ...s,
          label: featureFlags.usersEnabledDemo ? anSite(s.value || label) : label
        }))}
        icon={selectedTenant && !siteOptions.length ? 'loop' : null}
      />
      {meta.touched && meta.invalid && <ErrorLabel>{t(meta.error)}</ErrorLabel>}
    </div>
  );
}

Site.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.shapeOf({ name: PropTypes.string })).isRequired,
  loadSites: PropTypes.func.isRequired
};
export default Site;
