import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useAppContext } from '../hooks';

const Container = styled.div`
  position: fixed;
  bottom: 50px;
  right: ${({ moveToRight }) => (moveToRight ? '500px' : '50px')};
`;

function FabContainer(props) {
  const { fabContainerRef } = useAppContext();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  setTimeout(() => {
    setIsSidebarVisible(!!document.querySelector('#right_sb > div'));
  }, 0);
  return <Container moveToRight={isSidebarVisible} ref={fabContainerRef} {...props} />;
}

export default FabContainer;
