import * as configcat from 'configcat-js';
import { configCatKey } from '../config';

let client = null;

export const featureFlags = {
  createBulkAlarms: 'createBulkAlarms',
  coverage: 'coverage',
  qualityReadings: 'qualityReadings',
  tokenAuthentication: 'tokenAuthentication',
  usersEnabledDemo: 'usersEnabledDemo',
  enableNewAnalyticsPage: 'enableNewAnalyticsPage',
  inventoryCore: 'inventorycore_frontend'
};

export const getFeatureFlags = async (userObject, value) => {
  if (!client) {
    const logger = configcat.createConsoleLogger(2);
    client = configcat.createClient(configCatKey, {
      logger
    });
  }
  if (value) {
    return client.getValueAsync(value, false, userObject);
  }
  return client.getAllValuesAsync(userObject).then((d) =>
    d.reduce((acc, curr) => {
      const key = Object.keys(featureFlags).find(
        (featureKey) => featureFlags[featureKey] === curr.settingKey
      );

      return { ...acc, [key || curr.settingKey]: curr.settingValue };
    }, {})
  );
};
