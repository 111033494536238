import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { padLeftZero } from './strings';

export const FORMAT_DATE = 'DD/MM/YYYY';
export const ISO_FORMAT_DATE = 'YYYY-MM-DD';
export const FORMAT_DATE_TIME = 'DD/MM/YYYY HH:mm';
export const FORMAT_TIME = 'HH:mm';

const getDateInstance = (date) => {
  if (!date) return false;
  try {
    const parsedDate = new window.Date(date);
    if (!parsedDate || Number.isNaN(parsedDate.getDate())) return false;
    return parsedDate;
  } catch (error) {
    return false;
  }
};

export const isValidDate = (d) => {
  return /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(d);
};

export const isValidTime = (t) => {
  return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(t);
};

export const getMoment = (date, timeZone) => {
  let momentDate = null;
  if (date && moment(date).isValid()) {
    if (timeZone) {
      momentDate = momentTimeZone(date).tz(timeZone);
    } else {
      momentDate = moment(date);
    }
  }
  return momentDate;
};

export const formatDate = (date, dateFormat = FORMAT_DATE, timeZone = null) => {
  const m = getMoment(date, timeZone);
  return m ? m.format(dateFormat) : null;
};

export const formatDateTime = (date, dateFormat = FORMAT_DATE_TIME, timeZone = null) =>
  formatDate(date, dateFormat, timeZone);

export function getISOStartDate(date, timeZone = null) {
  const m = getMoment(date, timeZone);
  return m ? m.startOf('day').toISOString() : null;
}

export function getISOEndDate(date, timeZone = null) {
  const m = getMoment(date, timeZone);
  return m ? m.endOf('day').toISOString() : null;
}

export const isBeforeDay = (dateA, dateB) => {
  const dateInstanceA = getDateInstance(dateA);
  const dateInstanceB = getDateInstance(dateB);
  if (!dateInstanceA || !dateInstanceB) return false;
  const a = moment(dateInstanceA);
  const b = moment(dateInstanceB);
  const aYear = a.year();
  const aMonth = a.month();
  const bYear = b.year();
  const bMonth = b.month();
  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;
  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

export const getLast15Days = () =>
  Array.from(new Array(15))
    .map((_, index) => {
      const date = new Date(new Date().setHours(0, 0, 0, 0));
      date.setDate(date.getDate() - index - 1);
      return date;
    })
    .reverse();

// ----- weekdays -------

const WEEKDAYS = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const getWeekdayIntValue = (value) => {
  if (value) {
    if (WEEKDAYS.includes(value)) return WEEKDAYS.indexOf(value);
    const intValue = parseInt(value, 10);
    if (!Number.isNaN(intValue)) return intValue;
  }
  return null;
};

export const getWeekdayStrValue = (value) => WEEKDAYS[getWeekdayIntValue(value)];

export const getWeekdayOptions = () =>
  moment.weekdays().map((label, i) => ({ label, value: WEEKDAYS[i] }));

// ----- i18n -------

// Human date literals:
// "DATE_FORMAT_SAME_DAY": "[Hoy] [a las] HH:mm",
// "DATE_FORMAT_NEXT_DAY": "[Mañana] [a las] HH:mm",
// "DATE_FORMAT_NEXT_WEEK": "[El próximo] dddd D [de] MMMM, [a las] HH:mm",
// "DATE_FORMAT_LAST_DATE": "[Ayer] [a las] HH:mm",
// "DATE_FORMAT_LAST_WEEK": "[El pasado] dddd D [de] MMMM, [a las] HH:mm",
// "DATE_FORMAT_SAME_ELSE": "[El día] DD/MM/YYYY, [a las] HH:mm"
export const getHumanDateFormat = (t, date) =>
  moment(date).calendar(null, {
    sameDay: t('DATE_FORMAT_SAME_DAY'),
    nextDay: t('DATE_FORMAT_NEXT_DAY'),
    nextWeek: t('DATE_FORMAT_NEXT_WEEK'),
    lastDay: t('DATE_FORMAT_LAST_DATE'),
    lastWeek: t('DATE_FORMAT_LAST_WEEK'),
    sameElse: t('DATE_FORMAT_SAME_ELSE')
  });

// Period literals:
// "DATE_PERIOD_LABEL_HOURLY": "Cada hora, en el minuto {min}"
// "DATE_PERIOD_LABEL_DAILY": "Todos los días, a las {hourMin}"
// "DATE_PERIOD_LABEL_WEEKLY": "Cada {weekday}, a las {hourMin}"
// "DATE_PERIOD_LABEL_MONTHLY": "Cada día {day}, a las {hourMin}"
// "DATE_PERIOD_LABEL_DATE": "Cada día {day} de {month}, a las {hourMin}"
export const getPeriodLabel = (t, { type, month, weekday, day, hour, minute, hmark }) => {
  const mm = padLeftZero(minute);
  switch (type) {
    case 'hourly':
    case 'daily':
    case 'weekly':
    case 'monthly':
    case 'date':
      return t(`DATE_PERIOD_LABEL_${type.toUpperCase()}`, {
        min: mm,
        hourMin: `${padLeftZero(hour)}:${mm}`,
        day: day || '',
        weekday: weekday ? moment.weekdays(getWeekdayIntValue(weekday)) : '',
        month: month ? moment.months(parseInt(month, 10) - 1) : ''
      });
    case 'pattern':
      return `Periodo sin formato: ${hmark}`;
    default:
      return 'Periodo en formato desconocido';
  }
};

export const isAfterToday = (date) => moment.isMoment(date) && date.isAfter(moment().endOf('day'));

export const getCurrentDate = () => new Date().getTime();

export const getDaysActive = (startAt) =>
  Math.floor(moment.duration(moment(new Date()).diff(moment(startAt))).asDays());

export const transformToUTCDates = ({ startDate, endDate }) => {
  // const offsetStartDate = new Date(startDate).getTimezoneOffset();
  // const offsetEndDate = new Date(endDate).getTimezoneOffset();
  // const returnStartDate = moment.utc(startDate).add(offsetStartDate, 'minute');
  // const returnEndDate = moment.utc(endDate).add(offsetEndDate, 'minute');
  // return {
  //   startDate: returnStartDate.toISOString(),
  //   endDate: returnEndDate.toISOString()
  // }

  const start = new Date(startDate);
  start.setHours(0, 0);
  const end = new Date(endDate);
  end.setHours(23, 59);

  return {
    startDate: start.toISOString(),
    endDate: end.toISOString()
  };
};

export const splitIsoDate = (isoDate) => isoDate.split('T')[0];

export const isDateBetweenSlot = (item, date) => {
  if (item.maxRange && item.minRange) {
    const d = new Date(date).getTime();
    const max = new Date(item.maxRange).getTime();
    const min = new Date(item.minRange).getTime();
    return d >= min && d < max;
  }
  return item.isoDate === date;
};

export const times = (n) => (f) => {
  const iter = (i) => {
    if (i === n) return;
    f(i);
    iter(i + 1);
  };
  return iter(0);
};

export const buildArrayOfHourSlots = (day, expectedReadings) => {
  const slots = [];
  const slotLength = 24 / expectedReadings;
  const slotMinutes = 60 * slotLength;
  times(expectedReadings)((index) => {
    const date = new Date(day);
    date.setMinutes(slotMinutes * index);
    const minDate = new Date(date);
    date.setMinutes(date.getMinutes() + slotMinutes);
    slots.push({
      minRange: minDate.toISOString(),
      maxRange: date.toISOString()
    });
  });
  return slots;
};

export const buildBaseArrayOfDates = ({ startDate, endDate }, expectedReadings, timeZone) => {
  const arr = [];
  const currentDay = getMoment(startDate, timeZone);
  const lastDay = getMoment(endDate, timeZone);
  while (currentDay.isSameOrBefore(lastDay, 'day')) {
    if (expectedReadings) {
      arr.push(...buildArrayOfHourSlots(currentDay, expectedReadings));
    } else {
      arr.push({ day: currentDay.format(ISO_FORMAT_DATE) });
    }
    currentDay.add(1, 'days');
  }
  return arr;
};

export const splitDate = (date, timeZone) => {
  const momentDate = getMoment(date, timeZone);
  return {
    day: momentDate.format(ISO_FORMAT_DATE),
    hour: padLeftZero(momentDate.get('hour')),
    mm: padLeftZero(momentDate.get('minute'))
  };
};
