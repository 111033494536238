import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import rootReducer from '../store/reducers';

// const hiddenActions = [];

const logger = createLogger({
  // predicate: (getState, action) => !hiddenActions.includes(action.type),
  collapsed: (getState, action, logEntry) => !logEntry.error
});

const middlewares = [promise, thunk];

const configureStore = () => {
  if (process.env.NODE_ENV === 'development') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    middlewares.push(logger);
    return createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
  }
  return createStore(rootReducer, applyMiddleware(...middlewares));
};

function ReduxProvider({ children }) {
  return <Provider store={configureStore()}>{children}</Provider>;
}

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ReduxProvider;
