import { useContext } from 'react';
import { ModalsContext, SnackbarsContext } from '../contexts';
import { entityTypes } from '../constants';
import useSearchProducts from './useSearchProducts';
import useSearchAlarmsForm from './useSearchAlarmsForm';

// hooks with state
// ----------------
export const useModalsContext = () => useContext(ModalsContext);
export const useSnackbarsContext = () => useContext(SnackbarsContext);
// ? these hooks are in a separated file to avoid Dependency cycle (used in useModuleGuard, useProduct and useSearchEntity)
export { default as useAppContext } from './useAppContext';
export { default as useApolloClientsContext } from './useApolloClientsContext';
export { default as useUserContext } from './useUserContext';
export { default as useFeatureFlagContext } from './useFeatureFlagContext';

// hooks with queries
// ------------------
export { default as useEntities } from './useEntities';
export { default as useProduct } from './useProduct';
export { default as useProducts } from './useProducts';
export { default as useSearchEntity } from './useSearchEntity';
// prettier-ignore
export const useSearchEquipments = (...args) => useSearchProducts(entityTypes.DEVICES, ...args);
export const useSearchMeters = (...args) => useSearchProducts(entityTypes.METERS, ...args);
export const useSearchTransmitters = (...args) =>
  useSearchProducts(entityTypes.TRANSMITTERS, ...args);
export const useSearchAlarms = (...args) => useSearchAlarmsForm(entityTypes.ALARMS, ...args);

// utils to manage state
// ---------------------
export { default as useLocalStorage } from './useLocalStorage';
export { default as useMergeState } from './useMergeState';

// utils to manage forms
// ---------------------
export { default as usePagination } from './usePagination';
export { default as useProductColumns } from './useProductColumns';
export { default as useProductSearchForm } from './useProductSearchForm';
export { default as useSort } from './useSort';
export { default as useAlarmColumns } from './useAlarmColumns';

// Other useful hooks
// ------------------
export { default as useBeforeUnload } from './useBeforeUnload';
export { default as useElementSize } from './useElementSize';
export { default as useIsMounted } from './useIsMounted';
export { default as useModuleGuard } from './useModuleGuard';
export { default as useNavigateWithSearch } from './useNavigateWithSearch';
export { default as useOnClickOutside } from './useOnClickOutside';
export { default as usePrevious } from './usePrevious';
export { default as useService } from './useService';
export { default as useVisibleColumns } from './useVisibleColumns';
