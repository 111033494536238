import { startsWith } from 'ramda';
import { CLEAN_DATA, GET_IMPORT_DETAILS, UPDATE_PAGE_TOKENS } from '../actions';

const defaultState = {
  loading: false,
  loaded: false,
  error: null,
  data: null,
  pageTokens: [null, null]
};

const safeRegex = (reg) => (str) => (reg.exec(str) || []).slice(1);
const ACTION_STATUS_REGEX = /\w+_(PENDING|REJECTED|FULFILLED)/;
const getStatus = (type) => safeRegex(ACTION_STATUS_REGEX)(type)[0];

export default function (state, { type, payload }) {
  if (typeof state === 'undefined') return defaultState;

  if (startsWith(`${GET_IMPORT_DETAILS}_`, type)) {
    switch (getStatus(type)) {
      case 'PENDING':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'REJECTED':
        return {
          ...state,
          loading: false,
          error: payload
        };
      case 'FULFILLED':
        return {
          ...state,
          loaded: true,
          loading: false,
          data: payload
        };
      default:
        break;
    }
  }
  if (type === UPDATE_PAGE_TOKENS) {
    return payload
      ? {
          ...state,
          pageTokens: [...state.pageTokens, payload]
        }
      : {
          ...state,
          pageTokens: [null, null]
        };
  }
  if (type === CLEAN_DATA) {
    return {
      ...state,
      ...defaultState
    };
  }
  return state;
}
