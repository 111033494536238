import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@react-utils/intl';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import { Loading } from '@acua/common/components';
import styled from '@emotion/styled';

const CustomDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

function CustomSimpleDialog({
  deleteModal,
  onAccept,
  onCancel,
  cancelLabel,
  acceptLabel,
  acceptCssProps,
  title,
  body,
  children,
  loadingMessage,
  acceptDisabled,
  ...props
}) {
  const t = useTranslations();
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  return (
    <Dialog {...props} open={isOpen} onClosed={() => deleteModal()}>
      <DialogTitle id="dialog_title">{title}</DialogTitle>
      <DialogContent id="dialog_content">
        {body}
        {children}
      </DialogContent>
      <CustomDialogActions>
        {onAccept !== null && (
          <DialogButton
            id="cancel_button"
            onClick={(e) => {
              e.preventDefault();
              if (onCancel) onCancel();
              close();
            }}
          >
            {cancelLabel || t('MODAL_BTN_CANCEL')}
          </DialogButton>
        )}
        <DialogButton
          id="accept_button"
          type="submit"
          style={acceptCssProps}
          onClick={(e) => {
            e.preventDefault();
            if (onAccept !== null) {
              setLoading(true);
              Promise.resolve(onAccept())
                .then(close)
                .finally(() => setLoading(false));
            } else {
              close();
            }
          }}
          disabled={acceptDisabled}
          unelevated
          isDefaultAction
        >
          {acceptLabel || t('MODAL_BTN_ACCEPT')}
        </DialogButton>
      </CustomDialogActions>
      {loading && (
        <Loading.AbsoluteContainer withBackdrop>
          <Loading message={loadingMessage || t('LOADING_MESSAGE')} />
        </Loading.AbsoluteContainer>
      )}
    </Dialog>
  );
}

CustomSimpleDialog.propTypes = {
  deleteModal: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.node,
  body: PropTypes.node,
  children: PropTypes.node,
  cancelLabel: PropTypes.string,
  acceptLabel: PropTypes.string,
  loadingMessage: PropTypes.string,
  acceptDisabled: PropTypes.bool,
  acceptCssProps: PropTypes.shapeOf({})
};

CustomSimpleDialog.defaultProps = {
  onAccept: null,
  onCancel: null,
  cancelLabel: null,
  acceptLabel: null,
  title: null,
  body: null,
  children: null,
  loadingMessage: null,
  acceptDisabled: false,
  acceptCssProps: {}
};

export default CustomSimpleDialog;
