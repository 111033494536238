import styled from '@emotion/styled';
import CustomSimpleDialog from '../../components/CustomSimpleDialog';

export const StyledOverflowDialog = styled(CustomSimpleDialog)`
  .mdc-dialog__surface,
  .mdc-dialog__content {
    overflow: initial;
  }
`;

export const StyledMarginDialog = styled(CustomSimpleDialog)`
  .mdc-dialog__content > * {
    margin-top: 20px;
  }
`;

export const StyledOverflowMarginDialog = styled(CustomSimpleDialog)`
  .mdc-dialog__surface,
  .mdc-dialog__content {
    overflow: initial;
  }
  .mdc-dialog__content > * {
    margin-top: 20px;
    .rmwc-select__native-control {
      padding: 0;
    }
  }
`;
