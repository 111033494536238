import { defaultAvatarUrl } from '@acua/common/config';

const computeAvatar = (avatar) =>
  avatar
    ? {
        userImageSmall: avatar.small.url,
        userImageLarge: avatar.large.url,
        images: {
          small: avatar.small.url,
          large: avatar.large.url
        }
      }
    : {
        userImageSmall: defaultAvatarUrl,
        userImageLarge: defaultAvatarUrl,
        images: {
          small: defaultAvatarUrl,
          large: defaultAvatarUrl
        }
      };

export default (user) => ({
  ...user.profile,
  accountInfo: {
    name: user.profile.fullName,
    email: user.profile.email,
    ...computeAvatar(user.profile.avatar)
  }
});
